import axios from "axios";
import { useEffect, useState } from "react";

export const useGetFaq = () => {
  const [datas, setDatas] = useState();

  const getApi = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/preguntas-frecuente`
    );
    setDatas(data);
  };
  useEffect(() => {
    getApi();
  }, []);

  const getCliente = () => {
    const filterFap = datas?.filter((e) => e.preguntas.role === "cliente");

    return filterFap;
  };
  const getDistribuidor = () => {
    const filterFap = datas?.filter((e) => e.preguntas.role === "distributor");
    return filterFap;
  };

  return {
    datas,
    getCliente,
    getDistribuidor,
  };
};
