import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";

import { Card } from "../Cards/Card";
import { Paginacion } from "../Pagination/Paginacion";
import styles from "./Empresas.module.css";
import { useSelector } from "react-redux";

export const EmpresasResultados = () => {
  const { isAuthenticated, token, user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [productos, setProductos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pedidos, setPedidos] = useState([]);
  const location = useLocation();

  const query = location.state;
  // Actualiza la función getData para aceptar un parámetro de página y usar el valor de currentPage
  const getData = async (page) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos?page=${page}&pageSize=9&query=${query}`
      );
      setProductos(response.data.detalle);
      setCurrentPage(parseInt(response.data.page, 10));
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error al realizar la petición:", error);
      setProductos([]); // En caso de error, limpia los productos para evitar el error de tipo.
    } finally {
      setIsLoading(false);
    }
  };
  // Asegúrate de llamar a getData con el valor actual de currentPage como argumento.
  useEffect(() => {
    getData(currentPage);
  }, [currentPage, query]);

  // Actualiza la página actual cuando se selecciona una nueva página en la paginación.
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getPedidos = async () => {
    if (isAuthenticated) {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${user.email}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setPedidos(res.data);
    }
  };

  useEffect(() => {
    getPedidos();
  }, []);

  return (
    <>
      <div className={styles.contenedorEmpresas}>
        {isLoading ? (
          <h2 className={styles.cargandoo}>Cargando...</h2>
        ) : (
          <div className={styles.contenedorCards}>
            {productos.map((elem, index) => (
              <Card
                key={index}
                data={elem}
                agregar="pedido"
                pedidos={pedidos}
                onPedido={getPedidos}
              />
            ))}
          </div>
        )}
        <Paginacion
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};
