import * as React from "react"
const SvgCarrito = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={26}
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill="#8F2732"
      d="M31.62 10.501A3.012 3.012 0 0 0 29.07 9H9.872l-.868-3.391a1.507 1.507 0 0 0-1.502-1.111h-3a1.501 1.501 0 1 0 0 3.005h1.863l4.142 15.389c.18.672.804 1.132 1.502 1.11h13.5c.567 0 1.08-.322 1.334-.824l4.92-9.84c.434-.91.38-1.973-.138-2.836h-.005Zm-7.052 10.501H13.136l-2.444-8.998H29.07l-4.502 8.998ZM11.252 31.498a2.248 2.248 0 1 0 0-4.497 2.248 2.248 0 0 0 0 4.497ZM26.25 31.498a2.248 2.248 0 1 0 0-4.497 2.248 2.248 0 0 0 0 4.497Z"
    />
  </svg>
)
export default SvgCarrito