import styles from "./VerTodo.module.css";
import { Card } from "../Cards/Card";
import { Cargando } from "../Cargando/Cargando";
import banner from "../../../assets/img/FINAL-BannerSitioWeb-3.jpg";
import banner2 from "../../../assets/img/FINAL-BannerSitioWeb.jpg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Paginacion } from "../Pagination/Paginacion";
import axios from "axios";
import { MensajeWhasapps } from "../MensajeFlotant/MensajeWhasapps";
import { useSelector } from "react-redux";

export const VerTodoProductos = ({ titulo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productos, setProductos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pedidos, setPedidos] = useState([]);
  const images = [banner, banner2];
  const paths = ["/", "/videos"];
  const { isAuthenticated, token, user, userType } = useSelector((state) => state.auth);



  const apiGet = async () => {
    if (isAuthenticated) {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${user.email}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setPedidos(res.data);
    }
  };

  const fetchProductos = async (page) => {
    setIsLoading(true); // Iniciar carga
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos/marca?id=117&page=${page}&pageSize=9`
      );
      setProductos(response.data.detalle || []);
      setCurrentPage(parseInt(response.data.page) || 1);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error("Error al cargar los productos:", error);
      setProductos([]); // Asegúrate de que productos sea siempre un array
    }
    setIsLoading(false); // Finalizar carga
  };

  useEffect(() => {
    fetchProductos(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (!isLoading) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  const BotonCompra = ({ text, path, currentIndex, index }) => {
    const buttonStyle =
      index === currentIndex ? styles.buttonActive : styles.buttonInactive;
    const isFirstButton = index === 0 ? styles.primerBoton : "";
    const isSecondButton = index === 1 ? styles.segundoBoton : "";

    return (
      <Link to={path}>
        <button
          className={`${styles.buttons} ${buttonStyle} ${isFirstButton} ${isSecondButton}`}
        >
          {text}
        </button>
      </Link>
    );
  };

  if (!productos || isLoading) {
    return <Cargando />;
  }

  return (
    <>
      <MensajeWhasapps />
      {/* <div className={styles.carrusels}>
        {images.map((image, index) => (
          <div
            key={index}
            style={{ display: index === currentIndex ? "block" : "none" }}
          >
            <img src={image} alt={`Banner ${index + 1}`} />
            <BotonCompra
              text="Comprar aquí"
              path={paths[index]}
              currentIndex={currentIndex}
              index={index}
            />
          </div>
        ))}
      </div> */}
      <h2 className={styles.titulo}>{titulo}</h2>

      {isLoading ? (
        <h2 className={styles.cargandoo}>Cargando...</h2>
      ) : (
        <div className={styles.contenedor}>
          <div className={styles.contyenedorCards}>
            {productos.length > 0 ? (
              productos.map((ele, index) => (
                <Card key={index} data={ele} agregar="pedido" detalle={true} pedidos={pedidos} />
              ))
            ) : (
              <h2>No se encontraron productos</h2>
            )}
          </div>
        </div>
      )}
      <Paginacion
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
};
