import React from "react";
import { Link } from "react-router-dom";

export const RolRegistro = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="contenedor-rol-usuario">
        <div className="contenedor-rol">
          {/* Rol distribuidor */}

          <div className="contenedor-distribuidora">
            <h2>SOY DISTRIBUIDORA</h2>
            <Link
              className="boton-distribuidora"
              to={"/login-distribuidora"}
              onClick={handleScrollToTop}
            >
              PORTAL PARA DISTRIBUIDORAS REGISTRADAS
            </Link>
          </div>
          {/* Rol Cliente  */}
          <div className="contenedor-cliente">
            <h2 >SOY CONSUMIDOR <br />
              FINAL</h2>
            <Link
              className="boton-distribuidora"
              to={"/login-cliente"}
              onClick={handleScrollToTop}
            >
              PORTAL PARA CONSUMIDOR FINAL
            </Link>
          </div>
        </div>
      </div>

    </>
  );
};
