import React from 'react'
import styles from '../SectionDescriptionCremaSolar/SectionDescription.module.css'

export const SectionDescriptionTratamiCabello = () => {
  return (
    <div className={styles.contenedorDescripcion}>
      <section className={styles.decripcionParrafo}>
        <h1 className={styles.title}>Colores a prueba del sol</h1>
        <p>Los rayos UV del sol pueden provocar la decoloración del color del cabello teñido, especialmente si es un color vibrante o de tonos claros. La exposición prolongada al sol puede hacer que el color se desvanezca más rápido de lo normal, dejando el cabello con un aspecto opaco y deshidratado.</p>
        <section>
          <p>Te compartimos una rutina complementaria para cuidar tu cabello tinturado:</p>
          <p>Shampoo: Para proteger el color de tu cabello te recomendamos nuestro shampoo libre de sulfatos y parabenos. Brindan luminosidad, limpieza y lo más importante preservan el color de tu cabello.</p>
          <p>Tratamiento: Nuestro tratamiento Active pro posee propiedades nutritivas y protectoras para tu cabello, combina aceites esenciales de mango, uva y los ingredientes top la proteína de arroz y vitamina E. </p>
          <p>Los cabellos teñidos son especialmente vulnerables a los daños causados por el sol, incluyendo la decoloración del color, el daño a la estructura del cabello y la aparición de tonos no deseados. Es importante proteger el cabello teñido del sol utilizando productos capilares que contengan protección UV y limitando la exposición al sol siempre que sea posible.</p>
        </section>
      </section>
    </div>
  )
}
