import { Outlet } from "react-router-dom";
import { MenuCategoriaDistribuidor } from "../MenuCategoria/MenuCategoriaDistribuidor";
import { NavDistribuidor } from "../NavDistribuidor";

export const LayoutDisribuidor = () => {
  return (
    <>
      <NavDistribuidor />
      <MenuCategoriaDistribuidor />
      <Outlet />
    </>
  );
};
