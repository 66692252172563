import * as React from "react";
const SvgBuscador = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    cursor="pointer"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#89182F"
        d="m24.6 22.694-4.436-4.436a11.254 11.254 0 0 0 2.411-6.974C22.575 5.054 17.522 0 11.291 0 5.061 0 0 5.053 0 11.284c0 6.23 5.053 11.284 11.291 11.284 2.628 0 5.053-.897 6.974-2.411l4.436 4.436a1.354 1.354 0 0 0 1.906 0 1.35 1.35 0 0 0 0-1.906l-.006.007ZM2.698 11.284c0-4.738 3.855-8.586 8.586-8.586 4.73 0 8.586 3.855 8.586 8.586 0 4.73-3.855 8.586-8.586 8.586-4.73 0-8.586-3.848-8.586-8.586Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h25v25H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBuscador;
