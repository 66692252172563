// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Banner_contenedor__Mm7RR {\n  width: 100%;\n  height: auto;\n  margin-top: 40px;\n  margin-bottom: 40px;\n  display: flex;\n  background-color: red;\n}\n.Banner_imagen__6wgVt {\n  width: 100%;\n  height: auto;\n}\n.Banner_parrafo__-LWXO {\n  position: relative;\n  padding-bottom: 14%;\n  color: #fff;\n  font-family: \"Lato\";\n  font-size: 58px;\n  font-weight: 800;\n  margin-left: 2%;\n}\n\n/* Responsive style */\n@media (max-width: 1920px) {\n  .Banner_parrafo__-LWXO {\n    font-size: 65px;\n  }\n}\n\n@media (max-width: 851px) {\n  .Banner_parrafo__-LWXO {\n    font-size: 30px;\n  }\n}\n@media (max-width: 651px) {\n  .Banner_parrafo__-LWXO {\n    font-size: 25px;\n  }\n}\n\n@media (max-width: 351px) {\n  .Banner_parrafo__-LWXO {\n    font-size: 20px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/HomeDistribuidor/components/Banner/Banner.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA,qBAAqB;AACrB;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".contenedor {\n  width: 100%;\n  height: auto;\n  margin-top: 40px;\n  margin-bottom: 40px;\n  display: flex;\n  background-color: red;\n}\n.imagen {\n  width: 100%;\n  height: auto;\n}\n.parrafo {\n  position: relative;\n  padding-bottom: 14%;\n  color: #fff;\n  font-family: \"Lato\";\n  font-size: 58px;\n  font-weight: 800;\n  margin-left: 2%;\n}\n\n/* Responsive style */\n@media (max-width: 1920px) {\n  .parrafo {\n    font-size: 65px;\n  }\n}\n\n@media (max-width: 851px) {\n  .parrafo {\n    font-size: 30px;\n  }\n}\n@media (max-width: 651px) {\n  .parrafo {\n    font-size: 25px;\n  }\n}\n\n@media (max-width: 351px) {\n  .parrafo {\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contenedor": "Banner_contenedor__Mm7RR",
	"imagen": "Banner_imagen__6wgVt",
	"parrafo": "Banner_parrafo__-LWXO"
};
export default ___CSS_LOADER_EXPORT___;
