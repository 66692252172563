// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonClosedForm_buttonClosed__TF6ci {\n  position: absolute;\n  background-color: #89182f;\n  border-radius: 10px;\n  width: 30px;\n  height: 30px;\n  color: white;\n  border: none;\n  top: 15px;\n  right: 15px;\n}\n.ButtonClosedForm_buttonClosed__TF6ci:hover {\n  background-color: #a09e9e;\n}\n@media(max-width:777px){\n  .ButtonClosedForm_buttonClosed__TF6ci{\n    right: 1px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/homeComun/components/ButtonClosedForm/ButtonClosedForm.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,YAAY;EACZ,SAAS;EACT,WAAW;AACb;AACA;EACE,yBAAyB;AAC3B;AACA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".buttonClosed {\n  position: absolute;\n  background-color: #89182f;\n  border-radius: 10px;\n  width: 30px;\n  height: 30px;\n  color: white;\n  border: none;\n  top: 15px;\n  right: 15px;\n}\n.buttonClosed:hover {\n  background-color: #a09e9e;\n}\n@media(max-width:777px){\n  .buttonClosed{\n    right: 1px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonClosed": "ButtonClosedForm_buttonClosed__TF6ci"
};
export default ___CSS_LOADER_EXPORT___;
