import React from 'react';
import styles from './Categoria.module.css';

export const PaginacionCategoria = ({ currentPage, onPageChange, totalPages, pagesToShow }) => {
    const handleNextPage = () => {
        onPageChange(currentPage + 1);
        window.scrollTo({top: 0, behavior: "smooth"}); // Desplaza hacia arriba cuando cambia de página
    };

    const handlePreviousPage = () => {
        onPageChange(currentPage - 1);
        window.scrollTo({top: 0, behavior: "smooth"}); // Desplaza hacia arriba cuando cambia de página
    };

    const handlePageClick = (pageNumber) => {
        onPageChange(pageNumber);
        window.scrollTo({top: 0, behavior: "smooth"}); // Desplaza hacia arriba cuando cambia de página
    };

    return (
        <div className={styles.contenedorPaginacion}>
            <button onClick={handlePreviousPage} disabled={currentPage === 1} className={styles.btn}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#DD7F93" />
                    <path d="M6 12.5L15 5.13878L15 19.8612L6 12.5Z" fill="white" />
                </svg>
            </button>
            {Array.from({ length: pagesToShow }, (_, i) => (
                <button
                    key={i}
                    onClick={() => handlePageClick(i + 1)}
                    className={`${styles.pageButton} ${
                        Number(currentPage) === i + 1 ? styles.currentPage : ''
                    }`}
                >
                    {i + 1}
                </button>
            ))}
            <button onClick={handleNextPage} disabled={currentPage === totalPages} className={styles.btn}>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#DD7F93" />
                    <path d="M19 12.5L10 5.13878L10 19.8612L19 12.5Z" fill="white" />
                </svg>
            </button>
        </div>
    );
};
