import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Carrito/Carrito.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { MdError } from "react-icons/md";
import { Cargando } from "../../../homeComun/components/Cargando/Cargando";
import { useBorrador } from "../../../state/context/borradorContext";
import { SvgError } from "../../../assets/svg/errorSvg";
import { usePedidos } from "../../../state/context/pedidosContextProvider";

export const TodoOk = () => {
  const navigate = useNavigate();
  const { token, user, userType } = useSelector((state) => state.auth);
  const { setBorrador, getBorrador } = useBorrador();
  const [statePedido, setStatePedido] = useState(false);
  const { handleGetData } = usePedidos()
  const [montoCorreo, setMontoCorreo] = useState(null);
  const [allDataReady, setAllDataReady] = useState({
    idPedido: null,
    satisfactory: null,
    ready: false,
  });

  const [payme, setPayme] = useState();
  const [loading, setLoading] = useState(true);
  const isDistributor = userType === "DIS";
  const [correoEnviado, setCorreoEnviado] = useState(false);

  const [carritoFinalizado, setCarritoFinalizado] = useState(false);

  // const [PHPSESSID, setPHPSESSID] = useState(null);


  // console.log(token);
  
  const getFacturaTotal = async () => {
    try {
      await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
    } catch (error) {
      console.error("Error al obtener el total de la factura:", error);
    }
  };

  const getPedido = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${isDistributor ? user.cliente_id : user.email}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
  
      const fetchedPedidoID = response?.data?.[0]?.carrito?.[0]?.idPedido;
  
      // Convertimos paymentTransactionId a número
      const paymentTransactionId = Number(response?.data?.[0]?.paymentTransactionId);
  
      setPayme(paymentTransactionId); // Ahora es un número
      // console.log("Response data:", response?.data?.[0]);
  
      if (paymentTransactionId) {
        await getStatus(paymentTransactionId, fetchedPedidoID);
      }
    } catch (error) {
      console.error("Error al obtener el pedido:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const getStatus = async (transactionId, idPedido) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/transaccion/${transactionId}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setMontoCorreo(response.data.Monto);
      setStatePedido(response.data.SatisFactorio);
      // localStorage.setItem("loading", response?.data?.SatisFactorio);
      // localStorage.setItem("satisFactorio", response?.data?.SatisFactorio);
      setAllDataReady({
        idPedido: idPedido,
        satisfactory: response.data.SatisFactorio,
        ready: true,
      });

    } catch (error) {
      console.error("Error al obtener el estado de la transacción:", error);
    }
  };

  const getEmptyCart = async (idCarrito) => {
    setCarritoFinalizado(true);
    try {
      let url = 'https://mivogue.com:83/APIs'
      const username = 'c2ac07cafartwetsdADS2356FEDGeiE7xz7Gnn3XcxEkAQlPxtRIqf2gFu7q';
      const password = 'c2ac07cafartwetsdADS2356FEDGe3LZNmqXlF8NgGg83fp4RjvUdzxswwmO';
      const authHeader = 'Basic ' + btoa(username + ":" + password);

      let resPrev = await axios.post(`${process.env.REACT_APP_API_URL}/pedidos/pre-data/finalizar`,
        {
          idPedido: idCarrito,
          tipo: isDistributor ? "DIS" : "CON",
          proceso: isDistributor ? "E" : "F"
        },
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      if (resPrev.status === 200) {
        const formData = new FormData();
        formData.append("proceso", resPrev.data.proceso);
        formData.append("cargo_administrativo", resPrev.data.cargoAdmon);
        formData.append("PHPSESSID", resPrev.data.cookie);
        resPrev.data.incentivos && formData.append("incentivo", resPrev.data.incentivos);
        resPrev.data.cantidad_incentivos && formData.append("incentivo_cantidad", resPrev.data.cantidad_incentivos);

        const headers = {
          Authorization: authHeader,
          'Content-Type': 'multipart/form-data'
        };
        let resFinish = await axios.post(
          `${url}/pedidos/${isDistributor ? user.cliente_id : user.email}`, formData,
          {
            headers
          }
        );
        // console.log("exito", resFinish.data.success);

        if (resFinish.data.success) {
          // console.log("Datos para actualizar/finalizar:", { idCarrito, tipo: resPrev.data.tipo, token });

          await axios.post(`${process.env.REACT_APP_API_URL}/pedidos/actualizar/finalizar`,
            {
              idPedido: idCarrito,
              tipo: resPrev.data.tipo,
            },
            {
              headers: {
                Authorization: `Bearer ${token ?? ""}`,
              },
            }
          );
          await handleGetData()
          await getBorradorAndClear();
          await envioCorreo();
        }
      }
    } catch (error) {
      console.error("Error al vaciar el carrito:", error);
    } finally {
      setCarritoFinalizado(false);
    }
  };



  const getBorradorAndClear = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/borrador`, {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      });
      setBorrador(null);
      // getBorrador();
    } catch (error) {
      console.error("Error al eliminar el borrador:", error);
    }
  };

  const envioCorreo = async () => {
    setCorreoEnviado(true);
    try {
      const data = {
        transaccionId: payme,
        monto: montoCorreo,
      };
      await axios.post(
        `${process.env.REACT_APP_API_URL}/email/send-manager/`,
        data,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Error al enviar el correo de confirmación:", error);
    } finally {
      setCorreoEnviado(false);
    }
  };

  const isReady = allDataReady.satisfactory;

  useEffect(() => {
    if (userType === "DIS") {
      getFacturaTotal();
    }
  }, []);

  useEffect(() => {
    getPedido();
  }, []);

  useEffect(() => {
    if (isReady) {
      getEmptyCart(allDataReady.idPedido);
    }
  }, [isReady]);

  useEffect(() => {
    const handlePopState = (event) => {
      navigate("/", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);
  }, [navigate]);

  if (loading || correoEnviado || carritoFinalizado) {
    return <Cargando />;
  }



  return (
    <div className={styles.contenedorH}>
      <div className={styles.contenedorGris}>
        {statePedido || localStorage.getItem("satisFactorio") === "true" ? (
          <>
            <SvgError />
            <h3 className={styles.orden}>Tu orden ha sido exitosa</h3>
            <p className={styles.parrafo}>
              Cuentas con 3 días máximo a partir de recibido el pedido para
              presentar algún reclamo. Luego de ese lapso, ya no podrá ser
              procesado.
              <br />
              Un miembro de atención al cliente se comunicará contigo para los
              siguientes pasos.
            </p>
            <Link
              className={styles.continuar}
              to={"/tienda-linea-cliente/05"}
            >
              Continuar comprando
            </Link>
          </>
        ) : (
          <>
            <MdError color="#ff0000" size={80} />
            <h3 className={styles.orden}>Tu orden ha sido rechazada</h3>
            <p className={styles.parrafo}>
              Esto puede deberse a muchos motivos. Por favor intente nuevamente
              verificando que su tarjeta o datos sean ingresados correctamente o
              comuníquese a nuestra zona de soporte. Disculpe los
              inconvenientes.
            </p>
            <Link className={styles.continuar} to={"/tienda-linea-cliente/05"}>
              Continuar comprando
            </Link>
          </>
        )}
      </div>
    </div>
  );
};
