import banner from "../../../assets/img/Banner/Mesa de trabajo 4.png";
import banner2 from "../../../assets/img/nuevosBanners/Lonuevo-BannerSitioWeb-04.jpg";
import styles from "../VerTodoProductos/VerTodo.module.css";
import { Card } from "../Cards/Card";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BotonCompra } from "../../../HomeDistribuidor/components/TiendaEnLinea/BotonCompra";
import { InfoNotAvailable } from "../../../components/InfoNotAvailable";
import { Cargando } from "../Cargando/Cargando";

export const ProductoNuevoResultado = () => {
  const { userType } = useSelector((state) => state.auth);
  const images = [
    { image: banner, text: "Haz click" },
    { image: banner2, text: "Descubrir más" },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [productos, setProductos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [datos, setDatosBanner] = useState();


  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(interval);
  }, [images.length]);


  const getData = async () => {
    setIsLoading(true); // Activar el indicador de carga
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=1000`
      );
      const productosFiltrados = response.data.detalle.filter(
        (producto) => producto.tipo_incentivo_id === "n"
      );
      setProductos(productosFiltrados);
    } catch (error) {
      console.error("Error al obtener los datos", error);
    } finally {
      setIsLoading(false); // Desactivar el indicador de carga una vez que los datos han sido procesados
    }
  };

  const dataBanner = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=1&query=1054020`);
      const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=1&query=017835`);

      if (currentIndex === 0) {
        if (response.data.detalle.length === 1) {
          setDatosBanner(response.data.detalle[0]);
        } else {
          setDatosBanner(response.data.detalle);
        }
      } else if (currentIndex === 1) {
        if (response2.data.detalle.length === 1) {
          setDatosBanner(response2.data.detalle[0]);
        } else {
          setDatosBanner(response2.data.detalle);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);  // Manejo de errores
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => { dataBanner() }, [currentIndex])

  const estilos = currentIndex === 0 ? ["contenedorNuevo", "NuevoTwoBtn"] : ["contenedorNuevo2", "NuevoTwoBtn2"];

  if (isLoading) {
    return <Cargando />
  }
  return (
    <>

      <div className={styles.carrusels}>
        {images.map((item, index) => (
          <div
            key={index}
            style={{ display: index === currentIndex ? "block" : "none" }}
          >
            <img style={{ width: "100%" }} src={item.image} alt={`Banner ${index + 1}`} />

            <BotonCompra
              text={item.text} // Aquí pasas el texto correspondiente
              path={`/detalle/${datos?.producto_id}`}
              currentIndex={currentIndex}
              index={index}
              data={datos}
              estilos={estilos}
            />

          </div>
        ))}
      </div>
      <h2 className={styles.titulo}>Productos nuevos</h2>

      {productos.length > 0 && !isLoading ? (
        <div className={styles.contenedor}>
          <div className={styles.contyenedorCards}>
            {productos.map((ele, index) => (
              <Card
                key={index}
                data={ele}
                agregar="pedido"
                cantidadProp={userType === "DIS" ? true : false}
                detalle={true}
              />
            ))}
          </div>
        </div>
      ) : (
        <InfoNotAvailable message="Productos no disponibles momentaneamente" />
      )}
    </>
  );
};
