import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/slices/authSlice";


const PedidosContext = React.createContext();

export const PedidosContextProvider = ({ children }) => {
  const { token, user, userType, isAuthenticated } = useSelector((state) => state.auth);
  const [pedidos, setPedidos] = useState(null);
  const [loadingPedidos, setLoadingPedidos] = useState(false);
  const isDistributor = userType === "DIS";
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const handleGetData = async () => {

    const handleLogout = () => {
      dispatch(logout());
      navigate("/rol-registro");
    };

    if (!user || (!isDistributor && !user?.email) || (isDistributor && !user?.cliente_id)) {
      console.error("Faltan datos para realizar la solicitud");
      return;
    }

    setLoadingPedidos(true);
    try {
      const resPedido = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${isDistributor ? user?.cliente_id : user?.email}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setPedidos(resPedido?.data);
    } catch (error) {
      // Verificar si el error es debido a un token inválido
      if (
        error.response?.status === 400 &&
        error.response?.data?.response === "Token is invalid"
      ) {
        toast.error("Su sesión ha expirado. Por favor, vuelva a iniciar sesión.");
        handleLogout(); // Redirigir al login
      } else {
        console.error("Error al obtener los pedidos:", error);
      }
    } finally {
      setLoadingPedidos(false);
    }
  };






  useEffect(() => {
    if (isAuthenticated) {
      handleGetData();
    }
  }, []);

  return (
    <PedidosContext.Provider
      value={{
        pedidos,
        handleGetData,
        loadingPedidos,
        setPedidos
      }}
    >
      {children}
    </PedidosContext.Provider>
  );
};

export const usePedidos = () => {
  const context = React.useContext(PedidosContext);
  if (!context) {
    throw new Error(
      "useBorrador must be used within a BorradorContextProvider"
    );
  }
  return context;
};
