import React from 'react'
import styles from '../SectionDescriptionCremaSolar/SectionDescription.module.css'

export const SectionDescriptionAguaBifasica = () => {
  return (
    <div className={ styles.contenedorDescripcion }>
      <section className={styles.decripcionParrafo}>
        <h1 className={styles.title}>Descubre la mejor versión de tu piel</h1>
        <p>Hoy nos sumergimos en el fascinante mundo del agua bifásica, un innovador producto que está revolucionando la industria de la belleza más que un simple desmaquillante es un verdadero elixir de belleza que hidrata, refresca y revitaliza la piel al instante.</p>
        <p>Las aguas micelares están enriquecidas con valiosos ingredientes que ayudan a cuidar de tu piel entre ellos aceite de jojoba, argán y pantenol. El agua bifásica aporta:</p>
        <ul>
          <li>Hidratación Profunda: La combinación de agua y aceite en el agua bifásica proporciona una hidratación intensa que penetra en las capas más profundas de la piel, dejándola suave y tersa.</li>
          <li>Remueve eficazmente el maquillaje: Gracias a su capacidad para disolver el maquillaje y las impurezas, el agua bifásica es un excelente desmaquillante que elimina incluso el maquillaje resistente al agua sin dejar residuos grasos.</li>
          <li>Refrescante y revitalizante: El agua bifásica refresca y revitaliza la piel al instante, proporcionando una sensación de frescura y vitalidad.</li>
        </ul>
        <section>
          <h2>¿Cómo usar el Agua Bifásica en tu rutina de belleza?</h2>
          <ul>
            <li>Agitar bien: Antes de usar el agua bifásica, asegúrate de agitar bien el producto para mezclar las dos fases y activar sus beneficios.</li>
            <li>Aplicar con un algodón: Empapa un disco de algodón con el agua bifásica y desliza suavemente sobre la piel para remover el maquillaje y las impurezas.</li>
            <li>Masaje Facial: Para una hidratación adicional, puedes masajear suavemente el agua bifásica sobre la piel con movimientos circulares ascendentes.</li>
          </ul>
          <p>Incorpora este innovador producto en tu rutina de cuidado de la piel y descubre los beneficios del agua bifásica para lucir una piel radiante y saludable.</p>
        </section>
      </section>
    </div>
  )
}
