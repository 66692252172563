import React from "react";
import styles from "./Cargando.module.css";

export const Cargando = () => {
  return (
    <>
      <div className={styles.cargandoContainer}>
        <div className={styles.spinner}></div>
        <div className={styles.contenedorTexto}>
          <h2>Cargando...</h2>
          <p className={styles.parrafo}>Por favor, espere mientras se carga.</p>
        </div>
      </div>
    </>
  );
};
