import { Suspense, useEffect } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { HomePage } from "./homeComun/pages/HomePage";
import { Contactanos } from "./homeComun/components/Footer/Contactanos";
import { LayoutComun } from "./homeComun/components/layout/LayoutComun";
import { QuienesSomos } from "./homeComun/components/Footer/QuienesSomos";
import { VerTodoProductos } from "./homeComun/components/VerTodoProductos/VerTodoProductosEspañol";
import { NuevaContraseña } from "./homeComun/components/Contraseña/NuevaContraseña";
import { RegistroCliente } from "./homeComun/components/RolRegistro/LoginCliente";
import { RolRegistro } from "./homeComun/components/RolRegistro/RolRegistro";
import { LoginDistribuidora } from "./homeComun/components/RolRegistro/LoginDistribuidora";
import { ClienteRegistro } from "./homeComun/components/RolRegistro/RegistroClientes";
import { Contraseña } from "./homeComun/components/RolRegistro/Contraseña";
import { Distribuidora } from "./homeComun/components/Footer/Distribuidora";
import { PreguntasFrecuentes } from "./homeComun/components/Footer/PreguntasFrecuentes";
import { Resultado } from "./homeComun/components/ResultadoBusqueda/Resultado";
import { EmpresasResultados } from "./homeComun/components/Empresas/EmpresasResultados";
import { SubLineas } from "./homeComun/components/MenuCategoria/SubLineas";
import { TiendaEnLineas } from "./homeComun/components/TiendaEnLinea/TiendaEnLineas";
import { DetalleProducto } from "./HomeDistribuidor/DetalleProducto";
import { Videos } from "./homeComun/components/Videos/Videos";
import { HomeBlog } from "./homeComun/components/Blog/HomeBlog";
import { DetalleBlog } from "./homeComun/components/Blog/DetalleBlog";
import { TienEnLineaDistribuidor } from "./HomeDistribuidor/components/TiendaEnLinea/TienEnLineaDistribuidor";
import { LayoutCliente } from "./components/homeCliente/components/layout/LayoutCliente";
import { HomeDistribuidor } from "./HomeDistribuidor/pages/Home";
import { LayoutDisribuidor } from "./HomeDistribuidor/components/layout/LayoutDisribuidor";
import { CarritoDistribuidor } from "./HomeDistribuidor/components/Carrito/Carrito";
import { FoooterDistribuidora } from "./HomeDistribuidor/components/Footer/FoooterDistribuidora";
import { Chekout } from "./HomeDistribuidor/components/Carrito/Chekout";
import { TodoOk } from "./HomeDistribuidor/components/Carrito/TodoOk";
import { DatosGeneralesDistribuidor } from "./HomeDistribuidor/components/DatosDistribuidor/DatosGenerales";
import { EstadoCuenta } from "./HomeDistribuidor/components/EstadoCuenta/EstadoCuenta";
import { FacturasPendientes } from "./HomeDistribuidor/FacturasPendientes/FacturasPendientes";
import { PreguntasFrecuentesDistribuidor } from "./HomeDistribuidor/components/Footer/PreguntasFrecuentesDistribuidora";
import { ContactanosDistribuidor } from "./HomeDistribuidor/components/Footer/ContactanosDistriuidor";
import { ProductosOferta } from "./homeComun/components/ProductoNuevos/ProductosOferta";
import { ProductoNuevoResultado } from "./homeComun/components/ProductoNuevos/ProductoNuevoResultado";
import { DatosGenerales } from "./components/homeCliente/components/Perfil/DatosGenerales";
import { Carrito } from "./components/homeCliente/components/Carrito";
import { InformacionPago } from "./components/homeCliente/components/InformacionPago";
import { Order } from "./components/homeCliente/components/Order";
import { Ecommerce } from "./components/homeCliente/components/EcommerceCliente";
import { Pedidos } from "./components/homeCliente/components/Perfil/Pedidos";
import { InformacionPagoDistribuidor } from "./HomeDistribuidor/components/InformacionPagoDistribuidor";
import { Footer } from "./homeComun/components/Footer/Footer";
import { VoguePlus } from "./HomeDistribuidor/components/VoguePlus/VoguePlus";
import { PagoPendiente } from "./components/PagoPendiente";
import { PedidosContextProvider } from "./state/context/pedidosContextProvider";
import { BorradorContextProvider } from "./state/context/borradorContext";
import { LoadingProvider } from "./state/context/loadingContext";
import { Catalogo } from "./homeComun/components/Catalogo/Catalogo";
import Abonos from "./HomeDistribuidor/components/Carrito/Abonos";
import { Cargando } from "./homeComun/components/Cargando/Cargando";
import axios from "axios";
import { toast } from "react-toastify";
import { useValidateCreditCart } from "./hooks/use-validateCreditCart";
import { redondearDecimales } from "./hooks/use-redondearDecimales";
import { ValidarCorreo } from "./homeComun/components/RolRegistro/ValidarCorreo";

const App = () => {
  const { isAuthenticated, userType, token, user } = useSelector(
    (state) => state.auth
  );

  const { validateProductCart } = useValidateCreditCart();

  const apiGet = async () => {
    try {
      if (isAuthenticated) {
        let url = "";

        if (userType === "DIS") {
          url = `${process.env.REACT_APP_API_URL}/pedidos/user/${user.cliente_id}`;
        } else {
          url = `${process.env.REACT_APP_API_URL}/pedidos/user/${user.email}`;
        }
        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        });

        return res.data;
      }
    } catch (error) {
      console.error("Error al obtener los pedidos:", error);
      throw error;
    }
  };

  const agregarAlCarrito = async () => {
    try {
      const pedidos = await apiGet();
      let idPedido;

      if (pedidos && pedidos.length > 0) {
        idPedido = pedidos[0]._id;
      }

      const carritoGuardadoLocalStorage = JSON.parse(
        localStorage.getItem("carrito")
      );

      if (
        !carritoGuardadoLocalStorage ||
        carritoGuardadoLocalStorage.length === 0
      ) {
        return;
      }

      for (const item of carritoGuardadoLocalStorage) {
        let existingProductQuantity = 0;

        if (pedidos && pedidos.length > 0) {
          const carritoUsuario = pedidos[0]?.carrito;

          if (carritoUsuario) {
            const existingProduct = carritoUsuario.find(
              (prod) => prod.nameItem === item.nameItem
            );

            if (existingProduct) {
              existingProductQuantity = existingProduct.quantity;
            }
          }
        }

        let quantityToAdd = item.quantity;

        // Limitar la cantidad de productos en el carrito a 38
        if (userType === "DIS" && existingProductQuantity >= 38) {
          continue; // Omitir si ya hay 38 unidades del producto en el carrito
        } else if (
          userType === "DIS" &&
          existingProductQuantity + item.quantity > 38
        ) {
          quantityToAdd = Math.max(0, 38 - existingProductQuantity);
        }

        // Validar el límite de crédito solo para userType "DIS"
        if (userType === "DIS") {
          const { esLimiteSuperado, excedente, valorRestante } =
            await validateProductCart(
              item.itemId,
              (item.price_dis || item.price_con) * quantityToAdd
            );

          if (esLimiteSuperado) {
            const exede = redondearDecimales(excedente, 2);
            toast.error(
              `No se pudo agregar el producto ${
                item.nameItem
              }. Crédito excedido por $${Number(
                exede
              )}. Crédito disponible: $${Number(exede)}`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );

            // Eliminar el producto del local storage si no se puede agregar
            localStorage.removeItem("carrito");
            continue;
          }
        }

        // Si no se excede el límite, se procede a agregar al carrito
        const body = {
          nameItem: item.nameItem,
          itemId: item.itemId,
          categoria: item.categoria,
          price: userType === "DIS" ? item.price_dis : item.price_con,
          description: "",
          quantity: quantityToAdd,
          idPedido: idPedido,
          priceCatalogo: item.price_catalogo,
        };

        if (!idPedido) {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/pedidos/car`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token ?? ""}`,
              },
            }
          );
          idPedido = response.data._id;
        } else {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/pedidos/car/add-item`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token ?? ""}`,
              },
            }
          );
        }
      }

      // Limpiar el carrito del localStorage después de agregar productos
      localStorage.removeItem("carrito");
    } catch (error) {
      console.error("Error al agregar productos al carrito:", error);
    }
  };

  useEffect(() => {
    if (userType === "DIS" || userType === "CON") {
      agregarAlCarrito();
    }
  }, [token]);

  // console.log(token);

  return (
    <Router>
      <BorradorContextProvider>
        <PedidosContextProvider>
          <LoadingProvider>
            <Suspense fallback={<div>Cargando...</div>}>
              <Routes>
                {/* Rutas Públicas */}
                <Route path="/" element={<LayoutComun />}>
                  <Route index element={<HomePage />} />

                  <Route
                    path="/subLineas/:categoria/:sublineaId"
                    element={<SubLineas />}
                  />

                  <Route path="/contactanos" element={<Contactanos />} />
                  <Route path="/catalogo" element={<Catalogo />} />

                  <Route
                    path="/ver-todo-productos"
                    element={<VerTodoProductos titulo={"Instituto Español"} />}
                  />
                  <Route path="/nuevos" element={<ProductoNuevoResultado />} />
                  <Route path="/oferta" element={<ProductosOferta />} />
                  <Route path="/vogue-plus" element={<VoguePlus />} />

                  <Route
                    path="/auth/forgot-password/"
                    element={<NuevaContraseña />}
                  />

                  <Route path="/quienes-somos" element={<QuienesSomos />} />

                  <Route path="/login-cliente" element={<RegistroCliente />} />

                  {!isAuthenticated &&
                    userType !== "CON" &&
                    userType !== "DIS" && (
                      <Route path="/rol-registro" element={<RolRegistro />} />
                    )}

                  {!isAuthenticated &&
                    userType !== "CON" &&
                    userType !== "DIS" && (
                      <Route
                        path="/login-distribuidora"
                        element={<LoginDistribuidora />}
                      />
                    )}

                  {!isAuthenticated &&
                    userType !== "CON" &&
                    userType !== "DIS" && (
                      <Route
                        path="/registro-cliente"
                        element={<ClienteRegistro />}    
                      />
                    )}

                  <Route path="/contraseña" element={<Contraseña />} />
                  <Route path="/distribuidora" element={<Distribuidora />} />

                  <Route
                    path="/preguntasFrencuentes"
                    element={<PreguntasFrecuentes />}
                  />
                  <Route
                    path="/preguntasFrencuentesDistribuidora"
                    element={<PreguntasFrecuentesDistribuidor />}
                  />
                  <Route path="/resultados" element={<Resultado />} />
                  <Route path="/empresas" element={<EmpresasResultados />} />

                  <Route
                    path="/tienda-linea-cliente/:categoria"
                    element={<TienEnLineaDistribuidor />}
                  />

                  <Route path="/todas-lineas" element={<TiendaEnLineas />} />
                  <Route
                    path={"/detalle/:productId"}
                    element={<DetalleProducto />}
                  />
                  <Route path="/videos" element={<Videos />} />
                  <Route path={"/blog"} element={<HomeBlog />} />
                  <Route path="/detalle-blog" element={<DetalleBlog />} />
                  {/* Añade más rutas públicas según sea necesario */}

                  <Route
                    path="/ecommerce-distribuidor"
                    element={<Ecommerce />}
                  />

                  <Route
                    path="/carrito-distribuidor"
                    element={<CarritoDistribuidor />}
                  />
                  {isAuthenticated && (
                    <Route path="/pago-pendiente" element={<PagoPendiente />} />
                  )}

                  <Route
                    path="/informacio-pago-distribuidor"
                    element={<InformacionPagoDistribuidor />}
                  />
                  <Route
                    path="/guia-distribuidora"
                    element={<FoooterDistribuidora />}
                  />
                  <Route
                    path="/distribuidorass"
                    element={<FoooterDistribuidora />}
                  />
                  <Route path="/chekout" element={<Chekout />} />
                  <Route path="/finalizar-comprass" element={<TodoOk />} />

                  <Route
                    path="/finalizar-abonos/:invoice"
                    element={<Abonos />}
                  />
                  <Route
                    path="/datos-generales-distribuidor"
                    element={<DatosGeneralesDistribuidor />}
                  />

                  <Route path="/estado-cuenta" element={<EstadoCuenta />} />
                  <Route
                    path="/facturas-pendientes"
                    element={<FacturasPendientes />}
                  />
                  <Route path="/auth/verify" element={<ValidarCorreo />} />

                  <Route
                    path="/preguntas-frecuentes-distribuidor"
                    element={<PreguntasFrecuentesDistribuidor />}
                  />
                  <Route
                    path="/contactanoss"
                    element={<ContactanosDistribuidor />}
                  />

                  <Route path="/carrito-cliente" element={<Carrito />} />
                </Route>

                {/* Rutas para usuarios autenticados con rol "CON" */}
                {isAuthenticated && userType === "CON" ? (
                  <Route path="/home-cliente" element={<LayoutCliente />}>
                    {/* Define aquí las rutas específicas para clientes */}
                    <Route path="/home-cliente" element={<Cargando />} />
                    <Route
                      path="/home-cliente/carrito-cliente"
                      element={<Carrito />}
                    />

                    <Route
                      path="/home-cliente/datos-generales"
                      element={<DatosGenerales />}
                    />
                    <Route
                      path="/home-cliente/informacio-pago"
                      element={<InformacionPago />}
                    />

                    <Route path="/home-cliente/orden" element={<Order />} />
                    <Route
                      path="/home-cliente/historial"
                      element={<Pedidos />}
                    />
                    <Route
                      path={"home-cliente/detalle/:productId"}
                      element={<DetalleProducto />}
                    />
                  </Route>
                ) : null}

                <Route
                  path="/home-distribuidor"
                  element={<LayoutDisribuidor />}
                >
                  <Route
                    path="/home-distribuidor"
                    element={<HomeDistribuidor />}
                  />
                </Route>

                {/* Redireccionamiento condicional */}
                <Route
                  path="*"
                  element={
                    <Navigate
                      replace
                      to={
                        isAuthenticated
                          ? userType === "CON"
                            ? "/home-cliente"
                            : "/home-distribuidor"
                          : "/"
                      }
                    />
                  }
                />
              </Routes>
            </Suspense>
          </LoadingProvider>
          <Footer />
        </PedidosContextProvider>
      </BorradorContextProvider>
    </Router>
  );
};

export default App;
