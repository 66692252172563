import React, { useState } from "react";
import styles from "./Videos.module.css";
import imgPrueba from "../../../assets/img/03Copia de Tutorial Oleos.jpg";
import imgPrueba2 from "../../../assets/img/08Copia de Tutorial Baño de color.jpg";
import imgPrueba3 from "../../../assets/img/06Copia de Tutorial Color Magic.jpg";
import imgPrueba4 from "../../../assets/img/10Copia de Tutorial Tarro Avena colageno.jpg";
import imgPrueba5 from "../../../assets/img/02Cuidadocorporal.jpg";
import imgPrueba6 from "../../../assets/img/05Copia de Tutorial Lash Legend.jpg";
import imgPrueba7 from "../../../assets/img/01Tintes.jpg";
import imgPrueba8 from "../../../assets/img/07Cleanlook.jpg";

import ModalVideo from "react-modal-video"; // Asegúrate de haber instalado este paquete
import "react-modal-video/css/modal-video.min.css"; // Importa el CSS necesario para el modal
import { MensajeWhasapps } from "../MensajeFlotant/MensajeWhasapps";

export const Videos = () => {
  const [isOpen, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  const videos = [
    {
      id: "118FeIjrvuNWFfLNGo-LEds-M45hVoVKk",
      title: "Rutina para hidratar tu cabello",
      imagen: imgPrueba
    },
    {
      id: "1GVqbPLLxpKLmQjE180v64UUDixE41txB",
      title: "Intensifica el color de tu cabello",
      imagen: imgPrueba2
    },
    {
      id: "1b4InIcD5ZKkmfepZU7dGkBn8S7AkUei9",
      title: "Descubre el poder de color magic",
      imagen: imgPrueba3
    },
    {
      id: "1rNZFDKv0NwBEyXPcv8slukTdkRTLIYws",
      title: "¡Disfruta tu piel! Ahora más joven",
      imagen: imgPrueba4
    },
    // Últimos videos
    {
      id: "17HjBRw-mHcdxBuW_JivZdmEidvevmEUA",
      title: "Descubre el secreto para una piel radiante",
      imagen: imgPrueba5

    },
    {
      id: "1r2MoNdq0pOrsaSd2VcYOjYNqJTerUxCv",
      title: "Un look increíble con solo dos productos",
      imagen: imgPrueba6

    },
    {
      id: "1iAHO-Afasr_TpBOf56KjgvRPgmtngQsU",
      title: "¡Teñir tu cabello nunca fue así de fácil!",
      imagen: imgPrueba7

    },
    {
      id: "1lFL_0rVJuzJCP98oeQc_ThG6QYpz84R3",
      title: "Clean Girl Make Up",
      imagen: imgPrueba8

    },
  ];

  const openModal = (videoId) => {
    setCurrentVideo(videoId);
    setOpen(true);
  };

  return (
    <div className={styles.wrapperC}>
      <MensajeWhasapps titulo="¿Quieres mantenerte actualizado de nuestras novedades?" />
      <h3 className={styles.titulo}>Conoce cómo utilizar nuestros productos</h3>
      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen}
        url={`https://drive.google.com/file/d/${currentVideo}/preview`}
        onClose={() => setOpen(false)}
      />

      <div className={styles.contenedorGeneral}>
        {/* Tutoriales */}
        <div className={styles.tutoriales}>
          <h3 className={styles.tutorialesTitulo}>Tutoriales</h3>
          <div className={styles.contenedorCards}>
            {videos.slice(0, 4).map((video) => (
              <div
                key={video.id}
                className={styles.contenedorImg}
                onClick={() => openModal(video.id)}
              >
                <img
                  src={video.imagen}
                  alt="Portada del video"
                  style={{
                    width: "320px",
                    height: "240px",
                    borderRadius: "20px",
                    objectFit: 'cover'
                  }}
                />
                <p className={styles.parrafo}>{video.title}</p>
              </div>
            ))}
          </div>
        </div>
        {/* Últimos videos */}
        <div className={styles.ultimosVideos}>
          <h3 className={styles.tituloUltimoVideos}>Últimos videos </h3>
          <div className={styles.contenedorUltimo}>
            {videos.slice(4).map((video) => (
              <div
                key={video.id}
                className={styles.contenedorImg}
                onClick={() => openModal(video.id)}
                style={{
                  marginBottom: "20px", // Añade espacio entre los videos
                }}
              >
                <img
                  src={video.imagen}
                  className={styles.imgNuevos}
                  alt="Portada del video"
                />
                <p className={styles.parrafoVideos}>{video.title}</p>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
};
