import React from "react";
import { useState } from "react";
import { useRef } from "react";
import styles from "../../../homeComun/components/Footer/Contactanos.module.css";
import { Link } from "react-router-dom";
import celular from "../../../assets/img/celular.png";
import contactanos from "../../../assets/img/contac.jpg";

export const ContactanosDistribuidor = () => {
  const [copied, setCopied] = useState(false);
  const linkRef = useRef(null);

  const handleCopyLink = () => {
    linkRef.current.select();
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <div className={styles.contenedorPrincipalContacto}>
      <div className={styles.contenedorImagen}>
        <img className={styles.contenedorImgs} src={contactanos} alt="img" />

        <p className={styles.parrafoBannersDis}>
          Comunícate con un agente de atención al cliente y ventas que te
          asistirá de forma personalizada . <br />
        </p>
        <button className={styles.btnAquiDis}>Aquí</button>
      </div>
      {/* whasappt */}
      <div className="contenedor-contactanos">
        {/* Email */}

        <div className="contenedor-icons">
          <div className="iconos">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="87"
              height="70"
              viewBox="0 0 87 70"
              fill="none"
            >
              <path
                d="M73.4591 0.769775H13.1062C5.95916 0.769775 0.171265 6.55765 0.171265 13.7047V56.816C0.171265 63.963 5.95916 69.7509 13.1062 69.7509H73.4591C80.6061 69.7509 86.394 63.963 86.394 56.816V13.7047C86.394 6.55765 80.6061 0.769775 73.4591 0.769775ZM70.5728 9.39814L43.2826 29.8771L15.9925 9.39814H70.5728ZM73.4591 61.1225H13.1062C10.7239 61.1225 8.79965 59.1983 8.79965 56.816V14.789L40.7018 38.7193C41.4501 39.2844 42.3511 39.5745 43.2826 39.5745C44.2142 39.5745 45.1305 39.2691 45.8635 38.7193L77.7656 14.789V56.816C77.7656 59.1983 75.8414 61.1225 73.4591 61.1225Z"
                fill="#89182F"
              />
            </svg>
          </div>
          <div className="numero-contactanos">
            <p className="texto-contactanos">
              Escríbenos a: <br />
              <strong className="numero">vogue@gmail.com</strong>
            </p>
          </div>
        </div>

        {/* Llamanos  */}
        <div className="contenedor-icons">
          <div className="iconos">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="87"
              height="87"
              viewBox="0 0 87 87"
              fill="none"
            >
              <g clipPath="url(#clip0_65_604)">
                <path
                  d="M47.4535 29.4469C50.3119 29.4469 53.0533 30.5825 55.0745 32.6037C57.0958 34.6249 58.2313 37.3663 58.2313 40.2248C58.2313 41.1776 58.6098 42.0914 59.2835 42.7651C59.9573 43.4389 60.8711 43.8174 61.8239 43.8174C62.7767 43.8174 63.6905 43.4389 64.3643 42.7651C65.038 42.0914 65.4165 41.1776 65.4165 40.2248C65.4165 35.4607 63.524 30.8917 60.1553 27.523C56.7865 24.1542 52.2176 22.2617 47.4535 22.2617C46.5006 22.2617 45.5868 22.6402 44.9131 23.314C44.2393 23.9877 43.8608 24.9015 43.8608 25.8543C43.8608 26.8071 44.2393 27.7209 44.9131 28.3947C45.5868 29.0684 46.5006 29.4469 47.4535 29.4469Z"
                  fill="#89182F"
                />
                <path
                  d="M47.4535 15.0768C54.1232 15.0768 60.5198 17.7264 65.236 22.4426C69.9522 27.1588 72.6018 33.5553 72.6018 40.2251C72.6018 41.1779 72.9803 42.0917 73.654 42.7654C74.3278 43.4392 75.2415 43.8177 76.1944 43.8177C77.1472 43.8177 78.061 43.4392 78.7347 42.7654C79.4085 42.0917 79.787 41.1779 79.787 40.2251C79.787 31.6497 76.3804 23.4256 70.3167 17.3619C64.253 11.2982 56.0288 7.8916 47.4535 7.8916C46.5006 7.8916 45.5868 8.27011 44.9131 8.94385C44.2393 9.6176 43.8608 10.5314 43.8608 11.4842C43.8608 12.437 44.2393 13.3508 44.9131 14.0246C45.5868 14.6983 46.5006 15.0768 47.4535 15.0768Z"
                  fill="#89182F"
                />
                <path
                  d="M78.8892 57.8648C78.6916 57.2881 78.3505 56.7711 77.8979 56.3626C77.4454 55.954 76.8964 55.6674 76.3025 55.5296L54.7468 50.6077C54.1617 50.4751 53.5527 50.491 52.9754 50.6542C52.398 50.8174 51.8707 51.1225 51.4416 51.5418C50.9387 52.0088 50.9027 52.0448 48.5675 56.4996C40.819 52.9291 34.6102 46.6949 31.0715 38.9317C35.6341 36.6325 35.67 36.6325 36.1371 36.0936C36.5564 35.6645 36.8615 35.1372 37.0247 34.5598C37.1878 33.9825 37.2038 33.3735 37.0712 32.7884L32.1493 11.4842C32.0115 10.8903 31.7248 10.3413 31.3163 9.88877C30.9078 9.43625 30.3908 9.09513 29.8141 8.89753C28.9751 8.59787 28.1087 8.38128 27.2274 8.25086C26.3194 8.04032 25.3928 7.91998 24.4611 7.8916C20.0781 7.8916 15.8747 9.63273 12.7754 12.732C9.67619 15.8312 7.93506 20.0346 7.93506 24.4176C7.95407 39.0852 13.7892 53.1466 24.1607 63.5181C34.5323 73.8897 48.5937 79.7248 63.2613 79.7438C65.4315 79.7438 67.5805 79.3163 69.5856 78.4858C71.5906 77.6553 73.4124 76.438 74.947 74.9034C76.4816 73.3688 77.6989 71.547 78.5294 69.542C79.3599 67.537 79.7873 65.388 79.7873 63.2178C79.7885 62.3034 79.7164 61.3903 79.5718 60.4874C79.4208 59.595 79.1924 58.7175 78.8892 57.8648ZM63.2613 72.5586C50.4964 72.549 38.2571 67.474 29.231 58.4479C20.2048 49.4218 15.1298 37.1825 15.1203 24.4176C15.1298 21.9432 16.1169 19.5728 17.8666 17.8231C19.6163 16.0734 21.9867 15.0863 24.4611 15.0768H25.6466L29.4907 31.7465L27.5507 32.7525C24.4611 34.3691 22.0181 35.6625 23.3114 38.4647C25.4175 44.4269 28.8253 49.8451 33.2872 54.3256C37.7491 58.8062 43.153 62.2365 49.1064 64.3674C52.1242 65.5889 53.3098 63.3256 54.9265 60.2L55.9683 58.224L72.6021 62.0322V63.2178C72.5927 65.6922 71.6055 68.0626 69.8558 69.8123C68.1061 71.5619 65.7357 72.5491 63.2613 72.5586Z"
                  fill="#89182F"
                />
              </g>
              <defs>
                <clipPath id="clip0_65_604">
                  <rect
                    width="86.2227"
                    height="86.2226"
                    fill="white"
                    transform="translate(0.748657 0.706055)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="numero-contactanos">
            <p className="texto-contactanos">
              Número de teléfono y Whatsapp : <br />
              <strong className="numero">22618181</strong>
            </p>
          </div>
        </div>

        {/* Hubicación */}
        <div className="contenedor-icons">
          <div className="iconos">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="87"
              height="87"
              viewBox="0 0 87 87"
              fill="none"
            >
              <path
                d="M43.6179 48.5371C45.0899 48.5371 46.5474 48.2471 47.9074 47.6838C49.2673 47.1205 50.503 46.2949 51.5438 45.254C52.5847 44.2132 53.4103 42.9775 53.9736 41.6176C54.5369 40.2576 54.8269 38.8001 54.8269 37.3281C54.8269 35.8561 54.5369 34.3985 53.9736 33.0386C53.4103 31.6787 52.5847 30.443 51.5438 29.4022C50.503 28.3613 49.2673 27.5357 47.9074 26.9724C46.5474 26.4091 45.0899 26.1191 43.6179 26.1191C40.6451 26.1191 37.7941 27.3001 35.692 29.4022C33.5899 31.5043 32.4089 34.3553 32.4089 37.3281C32.4089 40.3009 33.5899 43.1519 35.692 45.254C37.7941 47.3561 40.6451 48.5371 43.6179 48.5371Z"
                fill="#89182F"
                stroke="#89182F"
                strokeWidth="5.38892"
              />
              <path
                d="M13.5113 30.7897C20.5887 -0.322333 66.682 -0.286406 73.7235 30.8256C77.855 49.0761 66.5024 64.5244 56.5508 74.0807C53.0734 77.4345 48.4306 79.3087 43.5994 79.3087C38.7683 79.3087 34.1254 77.4345 30.6481 74.0807C20.7324 64.5244 9.37978 49.0402 13.5113 30.7897Z"
                stroke="#89182F"
                strokeWidth="5.38892"
              />
            </svg>
          </div>
          <div className="numero-contactanos">
            <p className="texto-contactanos">
              Estamos ubicados en: <br />
              <strong className="numero">
                Edificio Vogue, Alameda Franklin Delano Roosevelt 2117, San
                Salvador
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div className="descargar-catalogo-contactatnos">
        <div className="contenedor-texto-btn">
          <p>
            Ver nuestro catálogo y encuentra más información sobre nuestro
            productos
          </p>
          <div className={styles.contenedorBotones}>
            <Link
              className={styles.descargar}
              to={"https://issuu.com/vogueelsalvador"}
              target="_blank"
            >
              Ver catálogo
            </Link>
            <div>
              <button className={styles.compartir} onClick={handleCopyLink}>
                Compartir catálogo
              </button>
              <input
                type="text"
                ref={linkRef}
                readOnly
                value="https://issuu.com/vogueelsalvador"
                style={{ position: "absolute", left: "-9999px" }}
              />
              {copied && (
                <p className={styles.enlaceCopiado}>¡Enlace copiado!</p>
              )}
            </div>
          </div>
        </div>

        <div>
          <img src={celular} alt="" />
        </div>
      </div>
    </div>
  );
};
