import * as React from "react"
export const SvgError = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={80}
    height={80}
    fill="none"
    {...props}
  >
    <path
      fill="#30CC00"
      d="M40 73.333c18.41 0 33.333-14.924 33.333-33.334C73.333 21.59 58.41 6.666 40 6.666c-18.41 0-33.334 14.924-33.334 33.333C6.666 58.41 21.59 73.333 40 73.333Z"
    />
    <path
      stroke="#fff"
      d="m25 40 10 10 20-20m18.333 10c0 18.409-14.924 33.333-33.333 33.333-18.41 0-33.334-14.924-33.334-33.334C6.666 21.59 21.59 6.666 40 6.666c18.41 0 33.333 14.924 33.333 33.333Z"
    />
  </svg>
)