import mundo from "../assets/img/mundo.png";
import vip from "../assets/img/1-Logonuevo-VIP-2023-02.png";

const benefitOne = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
  >
    <g clipPath="url(#clip0_65_87)">
      <path
        d="M51.5348 14.3161V11.453C51.5348 9.93431 50.9315 8.47783 49.8576 7.40397C48.7837 6.3301 47.3272 5.72681 45.8086 5.72681H22.9038C21.3852 5.72681 19.9287 6.3301 18.8548 7.40397C17.7809 8.47783 17.1777 9.93431 17.1777 11.453V14.3161C14.8996 14.3161 12.7149 15.221 11.1041 16.8318C9.49332 18.4426 8.58838 20.6273 8.58838 22.9054V54.3994C8.58838 56.6774 9.49332 58.8621 11.1041 60.4729C12.7149 62.0837 14.8996 62.9886 17.1777 62.9886H51.5348C53.8128 62.9886 55.9975 62.0837 57.6083 60.4729C59.2191 58.8621 60.124 56.6774 60.124 54.3994V22.9054C60.124 20.6273 59.2191 18.4426 57.6083 16.8318C55.9975 15.221 53.8128 14.3161 51.5348 14.3161ZM22.9038 11.453H45.8086V22.9054H22.9038V11.453ZM54.3978 54.3994C54.3978 55.1587 54.0962 55.887 53.5593 56.4239C53.0223 56.9608 52.2941 57.2625 51.5348 57.2625H17.1777C16.4183 57.2625 15.6901 56.9608 15.1531 56.4239C14.6162 55.887 14.3146 55.1587 14.3146 54.3994V22.9054C14.3146 22.146 14.6162 21.4178 15.1531 20.8808C15.6901 20.3439 16.4183 20.0423 17.1777 20.0423V22.9054C17.1777 24.424 17.7809 25.8805 18.8548 26.9544C19.9287 28.0282 21.3852 28.6315 22.9038 28.6315H45.8086C47.3272 28.6315 48.7837 28.0282 49.8576 26.9544C50.9315 25.8805 51.5348 24.424 51.5348 22.9054V20.0423C52.2941 20.0423 53.0223 20.3439 53.5593 20.8808C54.0962 21.4178 54.3978 22.146 54.3978 22.9054V54.3994Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_65_87">
        <rect width="68.7142" height="68.7142" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const benefitThree = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
  >
    <g clipPath="url(#clip0_65_92)">
      <path
        d="M34.3587 5.72607C28.6961 5.72607 23.1606 7.40525 18.4522 10.5513C13.7439 13.6973 10.0742 18.1688 7.9072 23.4004C5.74019 28.632 5.1732 34.3888 6.27793 39.9426C7.38266 45.4965 10.1095 50.598 14.1136 54.6021C18.1177 58.6062 23.2192 61.333 28.7731 62.4378C34.327 63.5425 40.0837 62.9755 45.3153 60.8085C50.5469 58.6415 55.0184 54.9718 58.1645 50.2635C61.3105 45.5551 62.9896 40.0197 62.9896 34.357C62.9896 30.5971 62.2491 26.8741 60.8102 23.4004C59.3714 19.9268 57.2625 16.7705 54.6038 14.1119C51.9452 11.4532 48.789 9.34431 45.3153 7.90547C41.8416 6.46663 38.1186 5.72607 34.3587 5.72607ZM34.3587 57.2617C29.8286 57.2617 25.4002 55.9184 21.6335 53.4016C17.8669 50.8848 14.9311 47.3075 13.1975 43.1223C11.4639 38.937 11.0103 34.3316 11.8941 29.8885C12.7779 25.4454 14.9593 21.3642 18.1626 18.1609C21.3659 14.9576 25.4471 12.7762 29.8902 11.8924C34.3333 11.0086 38.9387 11.4622 43.124 13.1958C47.3093 14.9294 50.8865 17.8651 53.4033 21.6318C55.9201 25.3985 57.2635 29.8269 57.2635 34.357C57.2635 40.4317 54.8503 46.2576 50.5548 50.5531C46.2593 54.8486 40.4334 57.2617 34.3587 57.2617Z"
        fill="white"
      />
      <path
        d="M42.0895 24.0216L31.267 38.337L26.6002 32.2959C26.1332 31.696 25.447 31.3062 24.6926 31.2123C23.9383 31.1183 23.1774 31.3279 22.5776 31.7949C21.9777 32.2619 21.5879 32.948 21.4939 33.7024C21.4 34.4568 21.6095 35.2176 22.0765 35.8175L29.0338 44.7217C29.3035 45.063 29.6473 45.3383 30.0392 45.5268C30.4311 45.7153 30.8608 45.8121 31.2957 45.8097C31.7329 45.8087 32.1641 45.7075 32.5562 45.5139C32.9483 45.3204 33.2908 45.0396 33.5575 44.6931L46.6418 27.5146C47.105 26.9071 47.3079 26.1405 47.2059 25.3834C47.1039 24.6263 46.7053 23.9408 46.0979 23.4776C45.4904 23.0144 44.7238 22.8115 43.9667 22.9135C43.2096 23.0155 42.5241 23.4141 42.0609 24.0216H42.0895Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_65_92">
        <rect width="68.7142" height="68.7142" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const benefitFour = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
  >
    <g clipPath="url(#clip0_65_82)">
      <path
        d="M54.3983 59.409L47.7846 33.6412C50.2299 30.5988 51.5542 26.8079 51.5353 22.9046C51.5353 18.3486 49.7254 13.9792 46.5038 10.7576C43.2822 7.53595 38.9127 5.72607 34.3567 5.72607C29.8007 5.72607 25.4312 7.53595 22.2096 10.7576C18.988 13.9792 17.1782 18.3486 17.1782 22.9046C17.1703 26.838 18.5261 30.6525 21.0147 33.6985L14.3151 59.409C14.1726 59.9586 14.1966 60.538 14.3842 61.0739C14.5718 61.6097 14.9145 62.0776 15.3686 62.4182C15.8228 62.7588 16.368 62.9568 16.9349 62.9868C17.5018 63.0169 18.0648 62.8778 18.5524 62.5871L33.8127 53.6256L50.0751 62.6157C50.5145 62.8809 51.0225 63.0104 51.5353 62.9879C51.9798 63.0021 52.4216 62.9125 52.8255 62.7262C53.2294 62.54 53.5844 62.2622 53.8622 61.9148C54.1401 61.5675 54.3332 61.1602 54.4263 60.7253C54.5193 60.2903 54.5098 59.8397 54.3983 59.409ZM34.3567 11.4523C36.6218 11.4523 38.836 12.1239 40.7193 13.3823C42.6026 14.6407 44.0705 16.4293 44.9373 18.522C45.8041 20.6146 46.0309 22.9173 45.589 25.1389C45.1471 27.3604 44.0564 29.401 42.4548 31.0027C40.8531 32.6043 38.8125 33.695 36.591 34.1369C34.3694 34.5788 32.0667 34.352 29.9741 33.4852C27.8814 32.6184 26.0928 31.1505 24.8344 29.2672C23.576 27.3839 22.9043 25.1697 22.9043 22.9046C22.9043 19.8673 24.1109 16.9543 26.2587 14.8066C28.4064 12.6588 31.3194 11.4523 34.3567 11.4523ZM35.2443 47.8422C34.809 47.5909 34.3153 47.4586 33.8127 47.4586C33.3101 47.4586 32.8164 47.5909 32.3812 47.8422L21.6446 54.141L25.7674 37.8213C28.346 39.3034 31.2681 40.0833 34.2422 40.0833C37.2163 40.0833 40.1384 39.3034 42.7169 37.8213L47.0975 54.3986L35.2443 47.8422Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_65_82">
        <rect width="68.7142" height="68.7142" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const benefitFive = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
  >
    <g clipPath="url(#clip0_65_76)">
      <path
        d="M34.359 5.72607C28.6963 5.72607 23.1608 7.40525 18.4525 10.5513C13.7441 13.6973 10.0744 18.1688 7.90744 23.4004C5.74043 28.632 5.17344 34.3888 6.27818 39.9426C7.38291 45.4965 10.1097 50.598 14.1138 54.6021C18.1179 58.6062 23.2195 61.333 28.7733 62.4378C34.3272 63.5425 40.0839 62.9755 45.3155 60.8085C50.5472 58.6415 55.0187 54.9718 58.1647 50.2635C61.3107 45.5551 62.9899 40.0197 62.9899 34.357C62.9899 30.5971 62.2493 26.8741 60.8105 23.4004C59.3716 19.9268 57.2627 16.7705 54.6041 14.1119C51.9454 11.4532 48.7892 9.34431 45.3155 7.90547C41.8419 6.46663 38.1188 5.72607 34.359 5.72607ZM34.359 57.2617C29.8288 57.2617 25.4004 55.9184 21.6338 53.4016C17.8671 50.8848 14.9313 47.3075 13.1977 43.1223C11.4641 38.937 11.0105 34.3316 11.8943 29.8885C12.7781 25.4454 14.9596 21.3642 18.1629 18.1609C21.3661 14.9576 25.4474 12.7762 29.8905 11.8924C34.3336 11.0086 38.9389 11.4622 43.1242 13.1958C47.3095 14.9294 50.8867 17.8651 53.4035 21.6318C55.9204 25.3985 57.2637 29.8269 57.2637 34.357C57.2637 40.4317 54.8505 46.2576 50.5551 50.5531C46.2596 54.8486 40.4337 57.2617 34.359 57.2617Z"
        fill="white"
      />
      <path
        d="M45.8123 31.4941H37.223V22.9048C37.223 22.1455 36.9214 21.4173 36.3844 20.8803C35.8475 20.3434 35.1193 20.0417 34.3599 20.0417C33.6006 20.0417 32.8723 20.3434 32.3354 20.8803C31.7985 21.4173 31.4968 22.1455 31.4968 22.9048V34.3572C31.4968 35.1165 31.7985 35.8448 32.3354 36.3817C32.8723 36.9187 33.6006 37.2203 34.3599 37.2203H45.8123C46.5716 37.2203 47.2999 36.9187 47.8368 36.3817C48.3737 35.8448 48.6754 35.1165 48.6754 34.3572C48.6754 33.5979 48.3737 32.8696 47.8368 32.3327C47.2999 31.7958 46.5716 31.4941 45.8123 31.4941Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_65_76">
        <rect width="68.7142" height="68.7142" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const benefits = [
  {
    id: 1,
    title: "Inscripción gratis",
    img: benefitOne,
  },
  {
    id: 2,
    title: "Ganancias sin límites",
    img: "$",
  },
  {
    id: 3,
    title: "Productos de alta calidad",
    img: benefitThree,
  },
  {
    id: 4,
    title: "Premios en todas tus campañas",
    img: benefitFour,
  },
  {
    id: 5,
    title: "Haces tus propios horarios",
    img: benefitFive,
  },
  {
    id: 6,
    title: "Club VIP programa de fidelización",
    img: mundo,
  },
  {
    id: 7,
    title: "Club VIP programa de fidelización",
    img: vip,
  },
];
