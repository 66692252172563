export const images = {
  logo: require("./vogue.png"),
  blog: {
    large: {
      1: require("./blog/large/agua_micelar_modelo.webp"),
      2: require("./blog/large/agua_micelar.webp"),
      3: require("./nuevosBanners/700.jpg"),
      4: require("./blog/large/cicatrifin_2.webp"),
      5: require("./nuevosBanners/Blogs 700x700 Duo tinturados.jpg"),
      6: require("./blog/large/protector_modelo.webp"),
      7: require("./blog/large/protector.webp"),
      8: require("./blog/large/romero_y_salvia.webp"),
    },
    medium: {
      1: require("./blog/medium/agua_micelar_modelo.webp"),
      2: require("./blog/medium/agua_micelar.webp"),
      3: require("./blog/medium/cicatrifin_1.webp"),
      4: require("./blog/medium/cicatrifin_2.webp"),
      5: require("./blog/medium/duo_tinturados.webp"),
      6: require("./blog/medium/protector_modelo.webp"),
      7: require("./blog/medium/protector.webp"),
      8: require("./blog/medium/romero_y_salvia.webp"),
    },
    small: {
      1: require("./blog/small/agua_micelar_modelo.webp"),
      2: require("./blog/small/agua_micelar.webp"),
      3: require("./nuevosBanners/265.jpg"),
      4: require("./blog/small/cicatrifin_2.webp"),
      5: require("./nuevosBanners/265x265-Tinturados-VideoBlog.png"),
      6: require("./blog/small/protector_modelo.webp"),
      7: require("./blog/small/protector.webp"),
      8: require("./blog/small/romero_y_salvia.webp"),
    },
  },
};
