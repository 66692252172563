import React, { useEffect, useState } from 'react';
import styles from "../../../components/homeCliente/components/Perfil/Perfil.module.css";
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Importar los iconos de ojo
import { FaCheckCircle } from 'react-icons/fa'; // Importar icono de check

export const CambioContraseña = ({ setCambioContraseña, setActualizar }) => {
    const { token, user, userType } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false); // Estado de carga
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [success, setSuccess] = useState(false); // Estado para manejar el éxito

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        watch,
        reset,
    } = useForm({
        mode: "onChange",
    });

    const password = watch("password");
    const passwordNew = watch("password_new");

    useEffect(() => {
        trigger(); // Dispara las validaciones al cargar el componente
    }, [trigger]);

    const handlePasswordSubmit = async (data) => {
        setLoading(true); // Inicia la carga
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/auth/change-password`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        proceso: "P",
                        tipo: userType,
                        username: userType === "DIS" ? user.cliente_id : user.email,
                        password: data.password,
                        password_new: data.password_new,
                    }),
                }
            );

            const result = await response.json();

            if (result.success) {

                reset(); // Resetea el formulario después de un cambio exitoso
                setSuccess(true); // Actualiza el estado de éxito
                setActualizar(false)

                // Cierra el modal después de 4 segundos
                setTimeout(() => {
                    setCambioContraseña(false);
                    setActualizar(true)
                }, 3000);
            } else {




                toast.error(result.detalle === "La contraseña anterior no coicide" && "La contraseña anterior no coinciden" || "Ocurrió un error. Por favor intente más tarde.");
                setActualizar(true)
            }
        } catch (error) {
            setActualizar(true)
            console.error("Error en la solicitud:", error);
            toast.error("Ocurrió un error. Por favor intente más tarde.");
        } finally {
            setActualizar(true)
            setLoading(false); // Finaliza la carga
        }
    };

    if (success) {
        return (
            <div className={styles.successMessage}>
                <FaCheckCircle className={styles.successIcon} />
                <div>
                    <p className={styles.gracias}>Gracias.</p>
                    <p className={styles.cmbioContraseñap}>Tu contraseña está actualizada</p>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.cambioContraseñaMain}>
            <form className={styles.formContraseña} onSubmit={handleSubmit(handlePasswordSubmit)}>
                <div className={styles.inputContainer}>
                    <input
                        className={styles.inputCambioContraseña}
                        type={showPassword ? "text" : "password"}
                        placeholder="Contraseña actual:"
                        {...register("password", {
                            required: { value: true, message: "Contraseña es requerida" },
                        })}
                    />
                    <button
                        type="button"
                        className={styles.eyeButton}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                </div>
                {errors.password && <p className={styles.error}>{errors.password.message}</p>}

                <div className={styles.inputContainer}>
                    <input
                        className={styles.inputCambioContraseña}
                        type={showNewPassword ? "text" : "password"}
                        placeholder="Contraseña nueva:"
                        {...register("password_new", {
                            required: { value: true, message: "Contraseña nueva es requerida" },
                            minLength: { value: 8, message: "Al menos 8 caracteres" },
                            validate: {
                                containsUpperCase: (value) =>
                                    /[A-Z]/.test(value) || "Una letra mayúscula",
                                containsLowerCase: (value) =>
                                    /[a-z]/.test(value) || "Una letra minúscula",
                                containsNumber: (value) =>
                                    /\d/.test(value) || "Un número",
                                containsSpecialChar: (value) =>
                                    /[@#$%&]/.test(value) ||
                                    "Uno de estos caracteres especiales: @, #, $, %, &",
                                notSameAsCurrent: (value) =>
                                    value !== password || "La nueva contraseña no puede ser igual a la contraseña actual",
                            },
                        })}
                    />
                    <button
                        type="button"
                        className={styles.eyeButton}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                        {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                </div>
                {errors.password_new && <p className={styles.error}>{errors.password_new.message}</p>}
                <ul className={styles.requisitosContraseña}>
                    <li>Al menos 8 caracteres</li>
                    <li>Una letra mayúscula</li>
                    <li>Una letra minúscula</li>
                    <li>Un número</li>
                    <li>Uno de estos caracteres especiales: @, #, $, %, &</li>
                </ul>

                <div className={styles.inputContainer}>
                    <input
                        className={styles.inputCambioContraseña}
                        type={showRepeatPassword ? "text" : "password"}
                        placeholder="Repetir nueva contraseña:"
                        {...register("repeat_password", {
                            required: { value: true, message: "Confirmar Contraseña es requerida" },
                            validate: {
                                matchPassword: (value) =>
                                    value === passwordNew || "Las contraseñas no coinciden",
                            },
                        })}
                    />
                    <button
                        type="button"
                        className={styles.eyeButton}
                        onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                    >
                        {showRepeatPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                </div>
                {errors.repeat_password && <p className={styles.error}>{errors.repeat_password.message}</p>}

                <button
                    className={styles.cambiarContraseña}
                    type="submit"
                    disabled={loading} // Desactiva el botón si está cargando
                >
                    {loading ? (
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Cargando...</span>
                        </div>
                    ) : (
                        'Cambiar'
                    )}
                </button>
            </form>
        </div>
    );
};
