import React from "react";
import styles from "../Banner/Banner.module.css";
import banner from "../../../assets/img/nuevosBanners/Premio-BannerSitioWeb-04.jpg";

export const Nanner = () => {
  return (
    <div className={styles.contenedor}>
      <img className={styles.imagen} src={banner} alt="Banner de promoción" />
      {/* <h2 className={styles.parrafo}>
        <b> RECLAMA TU PREMIO DEL MES </b>
      </h2> */}
    </div>
  );
};
