import React from "react";
import empresa1 from "../../../assets/img/empresa1.png";
import empresa2 from "../../../assets/img/empresa2.png";
import empresa3 from "../../../assets/img/empresa3.png";
import empresa4 from "../../../assets/img/Kanechomlogo.png";
import empresa5 from "../../../assets/img/Lattafa-Logo.png";
import { Link } from "react-router-dom";
import style from "./Empresas.module.css";

const brands = [
  {
    img: empresa1,
    link: "/todas-lineas#instituto",
  },
  {
    img: empresa2,
    link: "/todas-lineas#gigot",
  },
  {
    img: empresa3,
    link: "/todas-lineas#dicorakanechom",
  },
  {
    img: empresa4,
    link: "/todas-lineas#dicorakanechom",
  },
  {
    img: empresa5,
    link: "/todas-lineas#lattafa",
  },
];

export const Empresas = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };
  return (
    <div className={style.contenedor}>
      {brands.length > 0 &&
        brands.map((brand, index) => (
          <div className={style.empresas} key={index}>
            <div className={style.contenedorImg}>
              <img className={style.img} src={brand.img} alt="" />
            </div>
            <Link
              onClick={handleScrollToTop}
              to={brand.link}
              className={style.parrafo}
            >
              Ver más
            </Link>
          </div>
        ))}
    </div>
  );
};
