import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const categorias = {
  "Higiene y cuidado de la piel": "05",
  "Capilar": "01",
  "Maquillaje": "06",
  "Facial": "04",
  "Perfumería": "02"
};

export const useGetCategories = () => {
  const { token } = useSelector((state) => state.auth);
  const [subLineasPorCategoria, setSubLineasPorCategoria] = useState({});

  const obtenerSubLineasDisponibles = async () => {
    try {
      const subLineasTemp = {};

      for (const [categoria, id] of Object.entries(categorias)) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/productos/categoria?id=${id}&page=1&pageSize=1000&`
        );

        const detalle = response?.data?.detalle;

        const subLineas = {};

        detalle.forEach((item) => {
          if (!subLineas[item.sublinea_id]) {
            subLineas[item.sublinea_id] = {
              sublinea_id: item.sublinea_id,

              descripcion_sublinea: item.descripcion_sublinea,
            };
          }
        });

        subLineasTemp[categoria] = Object.values(subLineas);
      }

      setSubLineasPorCategoria(subLineasTemp);
    } catch (error) {
      console.error("Error al obtener las sublíneas disponibles:", error);
    }
  };

  useEffect(() => {
    obtenerSubLineasDisponibles();
  }, [token]); // Dependencia en `token` para obtener el borrador cuando el token cambie

  return {
    subLineasPorCategoria,
    obtenerSubLineasDisponibles,
    setSubLineasPorCategoria,
  };
};
