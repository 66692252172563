import React, { useRef, useState } from "react";
import styles from "../../../css/Carrito.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CarritoValidacion } from "../../../HomeDistribuidor/components/Carrito/CarritoValidacion";
import { useValidateCreditCart } from "../../../hooks/use-validateCreditCart";
import { useBorrador } from "../../../state/context/borradorContext";
import { usePedidos } from "../../../state/context/pedidosContextProvider";

export const Table = ({
  titulo,
  carrito,
  idPedido,
  onCarrito,
  cargandoCarrito,
  setCargandoCarrito,
  dataAllPedido
}) => {
  const [inputValues, setInputValues] = useState({});
  const [previousValue, setPreviousValue] = useState("");
  const [lastPostEvent, setLastPostEvent] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const inputRefs = useRef({});
  const [products, setProducts] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inputPlaceholderValue, setInputPlaceholderValue] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [carritoGuardado, setCarritoGuardado] = useState([]);
  const [hasPagarDespues, setHasPagarDespues] = useState(false);
  const [discleimer, setDiscleimer] = useState(true);
  const [facturaTotales, setFacturaTotales] = useState(false)

  const [isIncreasing, setIsIncreasing] = useState(false);
  const { borrador, getBorrador } = useBorrador();
  const { userType, token, isAuthenticated, user } = useSelector(
    (state) => state.auth
  );

  const isDistributor = userType === "DIS";
  let { validateProductCart } = useValidateCreditCart();
  const [hasPendingInvoice, setHasPendingInvoice] = useState(false);
  const navigate = useNavigate();
  // const { handleGetData } = usePedidos();
  const [cargandoFactura, setCargandoFacturas] = useState(false)
  // console.log("carrito", carrito);

  useEffect(() => {
    setProducts(carrito);
    setInputValues((prevState) => ({
      ...prevState,
      [selectedProductId]: "",
    }));
  }, [carrito]);

  const handleGetInvoice = async () => {
    setCargandoFacturas(false)
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      const facturas = res?.data?.detalle?.lista_global || [];
      // console.log(facturas);

      setFacturaTotales(facturas);


      // Verificar si hay alguna factura en el mes actual
      const hasInvoiceThisMonth = verificarFacturaEnMesActual(facturas);
      setHasPendingInvoice(hasInvoiceThisMonth);
      setCargandoFacturas(true)

    } catch (error) {
      console.error(error);
    }
  };

  // Función para verificar si hay una factura en el mes actual
  const verificarFacturaEnMesActual = (facturas) => {
    const mesActual = new Date().getMonth() + 1; // El mes actual (1 es enero, 12 es diciembre)
    const anioActual = new Date().getFullYear(); // El año actual

    // Recorrer las facturas y buscar si alguna tiene una fecha en el mes y año actual
    for (let factura of facturas) {
      const fechaFactura = new Date(factura.fecha); // Convertir la fecha de la factura en un objeto Date
      const mesFactura = fechaFactura.getMonth() + 1; // Obtener el mes de la factura
      const anioFactura = fechaFactura.getFullYear(); // Obtener el año de la factura

      // Comparar mes y año
      if (mesFactura === mesActual && anioFactura === anioActual) {
        return true; // Si se encuentra una factura en el mes actual, devolver true
      }
    }

    return false; // Si no se encontró ninguna factura en el mes actual, devolver false
  };


  useEffect(() => {
    if (!isAuthenticated) {
      const carritoLocal = localStorage.getItem("carrito");
      if (carritoLocal) {
        setCarritoGuardado(JSON.parse(carritoLocal));
      }
    }
  }, [isAuthenticated]);


  useEffect(() => {
    setProducts(isAuthenticated ? carrito : carritoGuardado);

  }, [carrito, carritoGuardado, isAuthenticated]);

  useEffect(() => {
    // Llama a updateCartUI cuando el componente se monta y cuando cambia el carrito

    if (userType === "DIS") {
      handleGetInvoice()
    }
  }, []);



  const increaseQuantity = async (value) => {
    setIsIncreasing(true);
    setCargandoCarrito(true);
    try {
      if (userType === "DIS") {
        const { esLimiteSuperado, excedente } = await validateProductCart(
          value.producto_id,
          value.price * 1
        );
        if (esLimiteSuperado) {
          toast.error(
            `No puedes agregar más del crédito permitido. Te queda restante ${excedente.toFixed(
              2
            )} de crédito.`
          );
          return;
        }
      }

      if (borrador) {
        toast.error(
          "No puedes sumar unidades mientras tengas un pago pendiente",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }

      // Verificar si hay suficientes existencias para usuarios autenticados
      if (token) {
        console.log({ token });

        if (value.quantity >= value.existencias && userType === "DIS") {
          toast.error("No puedes agregar más de la existencia disponible", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setInputValue("");
          return;
        }

        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}/pedidos/car/add-item`,
          {
            nameItem: value.nameItem,
            quantity: 1,
            itemId: value.producto_id,
            idPedido: value.idPedido,
            categoria: value.categoria,
            price: value.precio_CON,
            description: value.description,
          },
          {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          }
        );

        if (res.status === 200) {
          setInputPlaceholderValue("");
          onCarrito();

          toast.success("Producto agregado", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setIsIncreasing(false);
        }
      } else {
        const carrito = JSON.parse(localStorage.getItem("carrito")) || [];
        const itemIndex = carrito.findIndex(
          (item) => item.itemId === value.itemId
        );

        if (itemIndex !== -1) {
          const updatedProducts = [...carritoGuardado];
          updatedProducts[itemIndex] = {
            ...updatedProducts[itemIndex],
            quantity: (updatedProducts[itemIndex].quantity += 1),
          };
          setCarritoGuardado(updatedProducts);
        } else {
          carrito.push({
            nameItem: value.nameItem,
            quantity: 1,
            itemId: value.producto_id,
            categoria: value.categoria,
            price: value.precio_con,
            description: value.description,
          });
        }
        localStorage.setItem("carrito", JSON.stringify(carrito));
        // updateCartUI(); // 1995
        toast.success("Producto agregado al carrito local", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setProducts(carrito);
      }
    } catch (error) {
      console.error("Error completo:", error); // Mostrar el error completo para depuración

      // Verificar si el servidor respondió con un mensaje de error
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        // Mostrar el mensaje de error específico del servidor
        toast.error(`Error: ${error.response.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (error.response && error.response.status === 500) {
        // Mostrar el mensaje específico del error si está disponible en el error.response
        const serverMessage =
          error.response.data && error.response.data.message
            ? error.response.data.message
            : "No hay existencias disponibles de este producto. No se pueden añadir más unidades a tu carrito.";

        toast.error(`Error del servidor: ${serverMessage}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Ocurrió un error al agregar el producto.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setIsIncreasing(false);
    } finally {
      setIsIncreasing(false);
      setCargandoCarrito(false);
    }
  };

  const decreaseQuantity = async (product) => {
    if (userType === "DIS") {
      document.activeElement.blur();
    }
    if (!token) {
      // Si el usuario no está autenticado, disminuir la cantidad en el almacenamiento local
      const productIndex = carritoGuardado.findIndex(
        (p) => p.itemId === product.itemId
      );
      if (productIndex !== -1) {
        const updatedProducts = [...carritoGuardado];
        updatedProducts[productIndex] = {
          ...updatedProducts[productIndex],
          quantity: updatedProducts[productIndex].quantity - 1,
        };

        if (updatedProducts[productIndex].quantity > 0) {
          toast.success("Unidad eliminada del carrito local", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        // Verificar si la cantidad llega a 0 y eliminar el producto del carrito
        if (updatedProducts[productIndex].quantity === 0) {
          updatedProducts.splice(productIndex, 1);
          toast.success("Producto eliminado del carrito local", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (updatedProducts[productIndex].quantity < 0) {
          toast.error("La cantidad no puede ser menor que 0", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }

        setCarritoGuardado(updatedProducts); // Actualizar el carrito guardado en el estado

        localStorage.setItem("carrito", JSON.stringify(updatedProducts)); // Actualizar el almacenamiento local
      }
      return;
    }

    // Resto de la lógica para decrementar la cantidad cuando el usuario esté autenticado
    try {
      if (borrador) {
        toast.error(
          "No puedes restar unidades mientras tengas un pago pendiente",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }

      // Si está autenticado, disminuir la cantidad del producto específico en el carrito
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}/pedidos/car/delete-item`,
        {
          _id: product._id,
          quantity: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      if (res.status === 200) {
        onCarrito();

        toast.success("Producto eliminado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const carrito = localStorage.getItem("carrito");
    if (carrito) {
      setCarritoGuardado(JSON.parse(carrito));
    }
  }, []);

  const removeProduct = async (productId, quantity) => {
    if (token) {
      // Si el usuario está autenticado, enviar solicitud al servidor para eliminar el producto del carrito
      try {
        if (borrador) {
          toast.error(
            "No puedes eliminar un producto mientras tengas un pago pendiente",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          return;
        }
        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}/pedidos/car/delete-item`,
          {
            _id: productId,
            quantity: quantity,
          },
          {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          }
        );
        if (res.status === 200) {
          onCarrito();

          toast.success("¡Producto eliminado correctamente del carrito!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        console.error("Error al eliminar el producto del carrito:", error);
        // Manejo de errores si la solicitud al servidor falla
        toast.error(
          "¡Error al eliminar el producto del carrito! Por favor, inténtalo de nuevo.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } else {
      // Si el usuario no está autenticado, eliminar el producto del almacenamiento local
      try {
        const carritoLocal = JSON.parse(localStorage.getItem("carrito")) || [];
        const updatedCarritoLocal = carritoLocal.filter(
          (item) => item.itemId !== productId
        );
        localStorage.setItem("carrito", JSON.stringify(updatedCarritoLocal));
        setCarritoGuardado(updatedCarritoLocal);
        toast.success("¡Producto eliminado correctamente del carrito local!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.error(
          "Error al eliminar el producto del carrito local:",
          error
        );
        // Manejo de errores si no se puede eliminar el producto del carrito local
        toast.error(
          "¡Error al eliminar el producto del carrito local! Por favor, inténtalo de nuevo.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  };

  const emptyCart = async () => {
    if (token) {
      // Si el usuario está autenticado, enviar solicitud al servidor para vaciar el carrito
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}/pedidos/cancelar-pedido`,
          { idPedido },
          {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          }
        );
        if (res.status === 200) {
          onCarrito();

          toast.success("¡Carrito vaciado correctamente!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (error) {
        console.error("Error al vaciar el carrito:", error);
        // Manejo de errores si la solicitud al servidor falla
        toast.error(
          "¡Error al vaciar el carrito! Por favor, inténtalo de nuevo.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } else {
      // Si el usuario no está autenticado, eliminar todos los elementos del carrito local
      try {
        localStorage.removeItem("carrito");
        setCarritoGuardado([]);
        toast.success("¡Carrito local vaciado correctamente!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.error("Error al vaciar el carrito local:", error);
        // Manejo de errores si no se puede eliminar el carrito local
        toast.error(
          "¡Error al vaciar el carrito local! Por favor, inténtalo de nuevo.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    }
  };

  const totalPrecioProductos =
    products?.reduce((total, product) => {
      const price = parseFloat(product.price || product.price_con);
      return total + (isNaN(price) ? 0 : price * product.quantity);
    }, 0) || 0;

  const totalPrecioProductosCatalogo = products?.reduce((total, product) => {
    const price = parseFloat(product.priceCatalogo);
    return total + (isNaN(price) ? 0 : price * product.quantity);
  }, 0);

  const handleChange = (e, product) => {
    let value = e.target.value;
    value = value.replace(/\D/g, "");

    if (value.length > 2) {
      value = value.slice(0, 3);
    }

    setInputValues((prevState) => ({
      ...prevState,
      [product.producto_id]: value,
    }));

    setSelectedProductId(product.producto_id);

    setPreviousValue(value);
  };

  const handleBlur = async (e, product) => {
    let value = inputValues[product.producto_id] || "";
    const { producto_id, idPedido } = product;
    if (parseInt(value, 10) > product.existencia) {
      value = product.existencia;
      toast.error("No puedes ingresar mayor ala existencia");
      setInputValue("");
      setTimeout(() => {
        inputRefs.current[product.producto_id].blur();
      }, 0);
    } else if (value !== "0" && value !== "") {
      await agregarCantidadInputs(value, producto_id, idPedido, product);
    } else if (value === "0") {
      toast.error("No se puede agregar 0 cantidad");
      setInputValue("");
    }
    setInputValues((prevState) => ({
      ...prevState,
      [product.producto_id]: "",
    }));
  };
  const handleBlurInput = async (id) => {
    setIsInputFocused(false);

    const product = products.find((p) => p.producto_id === id);
    if (!product) {
      return;
    }

    const precio = parseFloat((product.price * product.quantity).toFixed(2));

    if (userType === "DIS") {
      const { esLimiteSuperado, excedente } = await validateProductCart(
        id,
        precio,
        true
      );
      if (esLimiteSuperado) {
        toast.error(
          `No puedes agregar más del crédito permitido. Te queda restante ${excedente.toFixed(
            2
          )} de crédito.`,
          {
            onClose: () => {
              // Cuando se cierre la notificación de error, vaciar el input
              setInputValues((prevState) => ({
                ...prevState,
                [product.producto_id]: "",
              }));
              const inputElement = document.activeElement;
              if (inputElement.tagName.toLowerCase() === "input") {
                inputElement.blur();
              }
            },
          }
        );
        return;
      }
    }
    let value = inputValues[id] || "";

    if (parseInt(value, 10) > product.existencia) {
      value = product.existencia;
      toast.error("No puedes ingresar un número mayor a la existencia");
      setInputValues((prevState) => ({
        ...prevState,
        [product.producto_id]: "",
      }));
    } else if (value !== "0" && value !== "") {
      await agregarCantidadInputs(
        value,
        product.producto_id,
        product.idPedido,
        product
      );
    } else if (value === "0") {
      toast.error("No se puede agregar 0 cantidad");
      setInputValues((prevState) => ({
        ...prevState,
        [product.producto_id]: "",
      }));
    }
  };

  const agregarCantidadInputs = async (
    value,
    producto_id,
    idPedido,
    product
  ) => {
    if (value > Number(product.existencias)) {
      toast.error(
        `No hay existencias disponibles de este producto. No se pueden añadir más unidades a tu carrito.`,
        {
          onClose: () => {
            // Cuando se cierre la notificación de error, vaciar el input
            setInputValues((prevState) => ({
              ...prevState,
              [product.producto_id]: "",
            }));
            const inputElement = document.activeElement;
            if (inputElement.tagName.toLowerCase() === "input") {
              inputElement.blur();
            }
          },
        }
      );
      document.activeElement.blur(); // Mover el focus fuera del input

      return;
    }

    if (userType === "DIS") {
      const { esLimiteSuperado, excedente } = await validateProductCart(
        product.producto_id,
        product.price * value,
        true
      );

      if (esLimiteSuperado) {
        toast.error(
          `No puedes agregar más del crédito permitido. Te queda restante ${excedente.toFixed(
            2
          )} de crédito.`,
          {
            onClose: () => {
              // Cuando se cierre la notificación de error, vaciar el input
              setInputValues((prevState) => ({
                ...prevState,
                [product.producto_id]: "",
              }));
              const inputElement = document.activeElement;
              if (inputElement.tagName.toLowerCase() === "input") {
                inputElement.blur();
              }
            },
          }
        );
        return;
      }
    }

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/pedidos/car/input-item`,
        {
          itemId: producto_id,
          idPedido,
          quantity: parseInt(value, 10) || 3,
        },
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      if (response.status === 200) {
        onCarrito();

        toast.success("Producto agregado", {
          onClose: () => {
            // Cuando se cierre la notificación de éxito, perder el foco del input
            const inputElement = document.activeElement;
            if (inputElement.tagName.toLowerCase() === "input") {
              inputElement.blur();
            }
          },
        });
        document.activeElement.blur(); // Mover el focus fuera del input
      }
    } catch (err) {
      toast.error("Error al agregar cantidad", {
        onClose: () => {
          // Cuando se cierre la notificación de error, perder el foco del input
          const inputElement = document.activeElement;
          if (inputElement.tagName.toLowerCase() === "input") {
            inputElement.blur();
          }
        },
      });
    }
  };

  useEffect(() => {
    if (selectedProductId && inputRefs.current[selectedProductId]) {
      inputRefs.current[selectedProductId].value = inputValue;
    }
  }, [inputValue]);

  const handleFocus = () => {
    setIsInputFocused(true);
  };

  const handleKeyDown = async (e, product) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setLastPostEvent("keydown");
      handleBlur(e, product);
    }
  };
  // console.log(token);

  const getPagarDespues = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos-seguimiento`,
        {
          headers: { Authorization: `Bearer ${token ?? ""}` },
          params: { id: user.cliente_id },
        }
      );

      // console.log("pagar despues ", response.data);

      if (response.data && response.data.detalle && Array.isArray(response.data.detalle.detalles)) {
        // Verificamos si algún objeto en `detalle.detalle` tiene `pendiente` con valor "x"
        const hasPagarDespues = response.data.detalle.detalles.some(
          (item) => item.pendiente === "x"
        );

        setHasPagarDespues(hasPagarDespues);
        // console.log("¿Pagar después?", hasPagarDespues);
      } else {
        setHasPagarDespues(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setHasPagarDespues(false);
        // console.error("No hay borrador");
      } else {
        console.error("Error al obtener el pedido pagar después:", error);
      }
    }
  };




  useEffect(() => {
    if (userType === "DIS") {
      getPagarDespues();
    }
  }, []);
  // console.log("este es el carrito ", carrito);

  // console.log(totalPrecioProductosCatalogo);


  const verificacionTotal = (totalPrecioProductosCatalogo) => {
    if (totalPrecioProductosCatalogo < 65 && products.length > 35) {
      toast.error(
        "Para continuar con la compra, el total catálogo debe ser mayor a $65 y menor a 35 items."
      );
      return false; // Retorna falso si el total no es suficiente
    }
    return true; // Retorna verdadero si el total es suficiente
  };

  const precioMostrar = cargandoCarrito
    ? "Cargando..."
    : dataAllPedido?.length === 0 && userType === "CON"
      ? totalPrecioProductos?.toFixed(2)
      : totalPrecioProductos?.toFixed(2);

  const sortedProducts =
    products && Array.isArray(products)
      ? [...products].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )
      : [];

  const formatearPrecio = (
    price,
    quantity,
    cargandoCarrito,
    dataAllPedido,
    userType
  ) => {
    if (cargandoCarrito) {
      return <div className={styles.spinner}></div>;
    }

    // Calcula el precio total
    const total = parseFloat(price || 0) * quantity;
    const precioFormateado = total.toFixed(2);

    // Determina si mostrar el símbolo $
    const mostrarSimbolo =
      dataAllPedido.length !== 0 || userType !== "CON" ? "$" : "";

    return `${mostrarSimbolo}${precioFormateado}`;
  };

  // console.log(carrito);
  useEffect(() => {
    if (carrito !== undefined) {
      const tienePromociones = carrito.some(producto =>
        producto?.promocion?.tipo_promocion === "CANT" || producto?.promocion?.tipo_promocion === "COMB" &&
        (Array.isArray(producto.promocion) ? producto.promocion.length > 0 : Object.keys(producto.promocion).length > 0)

      )
      // console.log(tienePromociones);

      setDiscleimer(tienePromociones);
    }
  }, [carrito]);


  const handleRedirect = async () => {
    await getBorrador();
  };

  useEffect(() => {
    if (borrador) {
      navigate("/pago-pendiente");
    }
  }, [borrador, navigate]);




  // console.log(products.length);

  return (
    <>
      {products && products.length > 0 ? (
        <div className={styles.contenedorPrincipalTable}>
          <h2 className={styles.tituloCarrito}>Tu {titulo}</h2>
          <div className={styles.contenedorGeneral}>
            <table className={styles.tablaContenedor}>
              <thead>
                <tr
                  className={
                    userType === "DIS"
                      ? styles.barraSuperiorDis
                      : styles.barraSuperior
                  }
                >
                  <th></th>
                  <th>Precio</th>
                  {userType === "DIS" && (
                    <th className={styles.priceCatalogo}>Precio Catálogo</th>
                  )}
                  <th>Cantidad</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {sortedProducts.map((product) => (
                  <tr
                    className={
                      userType === "DIS"
                        ? styles.barraInferior
                        : styles.barraInferiorCliente
                    }
                    key={product._id}
                  >
                    <td className={styles.productsImage}>
                      {product.imagen && product.imagen.length > 0 && (
                        <img
                          className={styles.productoImg}
                          src={`${process.env.REACT_APP_URL_IMG}/${product.imagen[0]}`}
                          alt={product.nameItem}
                        />
                      )}
                      <p className={styles.titleText}>{product.nameItem}</p>
                    </td>

                    <td>
                      ${product.price || product.price_con} <br />
                      {userType === "DIS" && (
                        <td className={styles.priceCatalogoInferior}>
                          Precio catálogo:
                          <p>${product.priceCatalogo}</p>
                        </td>
                      )}
                    </td>

                    {userType === "DIS" && (
                      <th className={styles.tachado}>
                        ${product.priceCatalogo}
                      </th>
                    )}
                    <td style={{ margin: "auto" }}>
                      <div
                        className={
                          userType === "DIS"
                            ? styles.cantidadDis
                            : styles.cantidad
                        }
                      >
                        <button
                          className={`${styles.botonCantidad} ${isInputFocused ? styles.focused : ""
                            }`}
                          onClick={() => decreaseQuantity(product)}
                        >
                          -
                        </button>
                        {userType !== "DIS" ? (
                          <div className={styles.botonCantidad}>
                            {product.quantity}
                          </div>
                        ) : (
                          <div className={styles.botonCantidads}>
                            <input
                              className={`${styles.inputCantidad} ${styles.inputWhitePlaceholder
                                } ${isInputFocused ? styles.inputFocused : ""}`}
                              ref={(el) =>
                                (inputRefs.current[product.producto_id] = el)
                              }
                              type="text"
                              placeholder={product.quantity}
                              value={inputValues[product.producto_id] || ""}
                              onChange={(e) => {
                                handleChange(e, product);
                              }}
                              onBlur={(e) => {
                                if (lastPostEvent) {
                                  setLastPostEvent(false);
                                } else {
                                  handleBlurInput(product.producto_id);
                                }
                              }}
                              onFocus={handleFocus}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleKeyDown(e, product);
                                }
                                if (
                                  e.target.value.length >= 3 &&
                                  e.key !== "Backspace"
                                ) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        )}

                        <button
                          className={`${styles.botonCantidad} ${isInputFocused ? styles.focused : ""
                            }`}
                          onClick={() => increaseQuantity(product)}
                          disabled={isIncreasing}
                        >
                          +
                        </button>
                      </div>
                    </td>
                    <td>
                      {formatearPrecio(
                        product.price || product.price_con,

                        product.quantity,
                        cargandoCarrito,
                        dataAllPedido,
                        userType
                      )}
                    </td>
                    <td>
                      <button
                        className={styles.eliminar}
                        onClick={() =>
                          removeProduct(
                            product._id || product.itemId,
                            product.quantity
                          )
                        }
                      >
                        X
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* // validar que muestre true cuando hay promociones en el carrito
           */}


          <div className={styles.botonesContenedorEliminar}>
            <div className={styles.totalCarrito}>
              {discleimer && <p className={styles.promociones}>Recuerda que las promociones se aplicarán al finalizar tu compra.</p>}
              {userType === "DIS" && (
                <>
                  <div className={styles.contenedorPrecios}>
                    <p className={styles.totalCarritos}>Total catálogo:</p>
                    <span className={styles.pesos}>
                      {cargandoCarrito ? (
                        <div className={styles.spinner}></div>
                      ) : (
                        <>${totalPrecioProductosCatalogo.toFixed(2)}</>
                      )}
                    </span>
                  </div>
                </>
              )}

              <div className={styles.contenedorPrecios}>
                <p className={styles.totalCarritos}>Total a pagar:</p>
                {cargandoCarrito ? (
                  <span className={styles.pesos}>
                    <div className={styles.spinner}></div>
                  </span>
                ) : (
                  <span className={styles.pesos}>
                    {dataAllPedido.length !== 0 || userType !== "CON" ? "$" : ""} {precioMostrar}
                  </span>
                )}
              </div>
            </div>

            {products.length >= 35 ? (
              <div className={styles.totalCarritoss}>
                <h4 className={styles.compraMayor}>No puedes llevar más de 35 items</h4>
              </div>
            ) : (
              <div className={styles.totalCarritoss}>
                <button onClick={emptyCart} className={styles.btnCompra}>Vaciar todo el carrito</button>

                {userType === "DIS" && facturaTotales && (
                  (hasPendingInvoice === false && (totalPrecioProductosCatalogo < 65 || totalPrecioProductos <= 5)) ? (
                    <p className={styles.compraMayor}>Para continuar con la compra, el total catálogo debe ser mayor a $65</p>
                  ) : hasPagarDespues ? (
                    <p className={styles.compraMayor}>Tiene un pedido en proceso</p>
                  ) : totalPrecioProductos <= 5 ? (

                    <p className={styles.compraMayor}>Para continuar con la compra, el monto debe ser mayor a $5.</p>

                  ) : (
                    <Link
                      onClick={handleRedirect}
                      className={styles.btnCompra}
                      to={
                        !isAuthenticated
                          ? "/rol-registro"
                          : verificacionTotal(totalPrecioProductosCatalogo, products)
                            ? "/informacio-pago-distribuidor"
                            : null
                      }
                      state={{ products }}
                    >
                      Proceder a la compra
                    </Link>
                  )
                )}


                {userType !== "DIS" && totalPrecioProductos >= 5 && (

                  <Link
                    onClick={handleRedirect}
                    className={styles.btnCompra}
                    to={
                      !isAuthenticated
                        ? "/rol-registro"
                        : borrador
                          ? "/pago-pendiente"
                          : "/home-cliente/informacio-pago"
                    }
                    state={
                      borrador
                        ? { products: borrador, enviar: precioMostrar }
                        : { products }
                    }
                  >
                    Proceder a la compra
                  </Link>


                )}
                {userType !== "DIS" && totalPrecioProductos < 5 && (
                  <p className={styles.compraMayor}>Para continuar con la compra, el monto debe ser mayor a $5.</p>
                )}
              </div>
            )}
          </div>

        </div>
      ) : (
        <div className={styles.mensajeCarritoVacio}>
          <p className={styles.carritoVacio}>Tu carrito está vacío.</p>
          <Link
            to="/ecommerce-distribuidor"
            className={styles.volverAProductos}
          >
            Volver al Ecommerce
          </Link>
        </div>
      )}

      {products && userType === "DIS" && products.length > 0 && (
        <CarritoValidacion
          validacionLongitud={products.length}
          totalProducto={totalPrecioProductosCatalogo > 65}
        />
      )}
    </>
  );
};
