import React from "react";
import styles from "./Invoice.module.css";
import { ButtonClosedForm } from "../../../homeComun/components/ButtonClosedForm";
import LogoVogue from "../../../assets/svg/carritio";
import slogan from "../../../assets/img/invoice/Slogan Vogue.png";

export const Invoice = ({ saldosFactura, invoice, setOpenInvoice, saldo, intereses, fecha }) => {
  if (!invoice) {
    return null;
  }
  const {
    factura_no,
    nombre,
    cliente_id,
    direccion,

    detalles_factura = [],
  } = invoice.detalle;


  // console.log("detalle factura",saldo);

  const totalVendido = detalles_factura
    .reduce((acc, item) => acc + parseFloat(item.vendido), 0);


  function redondearDecimales(numero, decimales) {
    const numeroRegexp = new RegExp('\\d\\.(\\d){' + decimales + ',}');   // Expresion regular para numeros con un cierto numero de decimales o mas
    if (numeroRegexp.test(numero)) {         // Ya que el numero tiene el numero de decimales requeridos o mas, se realiza el redondeo
      return Number(numero.toFixed(decimales));
    } else {
      return Number(numero.toFixed(decimales)) === 0 ? 0 : numero;  // En valores muy bajos, se comprueba si el numero es 0 (con el redondeo deseado), si no lo es se devuelve el numero otra vez.
    }
  }
  const totalVendidoRedondeado = redondearDecimales(totalVendido, 2);
  // console.log(invoice);


  return (
    <div className={styles.contenedorPopupInvoice}>
      <div className={styles.invoiceContainer}>
        <ButtonClosedForm setOpenPopup={setOpenInvoice} />
        <section className={styles.sectionInfoTop}>
          <section className={styles.sectionTopLogo}>
            <LogoVogue />
            <img
              src={slogan}
              alt="Eslogan de la empresa: Realza tu bellaza natural"
              className={styles.slogan}
            />
          </section>
          <section className={styles.sectionDataUser}>
            <div className={styles.sectionDataUserLeft}>
              <p className={styles.textBold}>A nombre de: {nombre}</p>
              <p>Código: {cliente_id}</p>
              <p>Dirección: {direccion}</p>
            </div>
            <div className={styles.sectionDataUserRight}>
              <p className={styles.textBold}>Factura: Nº {factura_no}</p>
              <p>Fecha de Vencimiento: {fecha}</p>
            </div>
          </section>
        </section>
        <section className={styles.sectionInfoBuy}>
          <div className={styles.sectionTableInfoTitles}>
            <p className={styles.cantText}>Cantidad</p>
            <p>Código</p>
            <p>Descripción</p>
            <p>Total</p>
          </div>
          {detalles_factura.length > 0 ? (
            detalles_factura.map((datail) => (
              <div key={datail.producto_id} className={styles.sectionTableInfo}>
                <p>{datail.cantidad}</p>
                <p>{datail.producto_id}</p>
                <p>{datail.descripcion}</p>
                <p>{datail.subtotal}</p>
              </div>
            ))
          ) : (
            <p>Aún no tienes compra</p>
          )}
          <div className={styles.sectionPrice}>
            <h3 className={styles.priceTotal}>Total cátalogo: ${totalVendidoRedondeado}</h3>
            <h3 className={styles.priceTotal}>Total factura: ${saldosFactura?.total }</h3>
            <h3 className={styles.priceTotal}>Saldo factura: ${saldosFactura?.saldo }</h3>
          </div>
        </section>
      </div>
    </div>
  );
};
