import React, { useEffect, useState } from "react";
import styles from "../../../homeComun/components/FiltrosMenuNavegacion/filtros.module.css";
import { Card } from "../../../homeComun/components/Cards/Card";
import { Paginacion } from "../../../homeComun/components/Pagination/Paginacion";
import axios from "axios";

import { useSelector } from "react-redux";
import { BotonCompra } from "./BotonCompra";
import { categoriasNombres } from "../../../assets/utils/CategoriasNombre";
import { Categoria } from "./Categoria";
import { useParams } from "react-router-dom";
import higuiene1 from "../../../assets/img/Banner/VG_WEB_BANNERS_SIN_TXT_PRODUCTO_3.jpg";
import higuiene2 from "../../../assets/img/blog/VG_WEB_BANNERS_SIN_TXT_PRODUCTO_1.jpg";
import cuidado1 from "../../../assets/img/Banner/baño.jpg";
import cuidado2 from "../../../assets/img/Banner/VG_WEB_BANNERS_SIN_TXT_SKINCARE.jpg";
import maquillaje1 from "../../../assets/img/Banner/VG_WEB_BANNERS_3_Mesa de trabajo 17.jpg";
import maquillaje2 from "../../../assets/img/Banner/VG_WEB_BANNERS_3_Mesa de trabajo 15.jpg";
import maquillaje3 from "../../../assets/img/Banner/VG_WEB_BANNERS_3_Mesa de trabajo 14.jpg";
import capilar1 from "../../../assets/img/Banner/BannersMesa-de-trabajo-3 2.jpg";
import perfumeria1 from "../../../assets/img/Banner/perfumes.jpg";
import { SectionFilters } from "./SectionFilters";
import { useCustomQueryParams } from "../../../hooks/useCustomQueryParams";
import { LoadingText } from "../../../components/LoadingText";
import { Inventario } from "./Inventario";
import { Contruccion } from "../../../homeComun/components/Construccion/Contruccion";




export const TienEnLineaDistribuidor = () => {
  const { userType } = useSelector(
    (state) => state.auth
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [waitPedidos, setWaitPedidos] = useState(false);
  const [productos, setProductos] = useState([]);
  const { currentPage, setSearchParams, subCategory } = useCustomQueryParams(
    {}
  );

  const [totalPages, setTotalPages] = useState(1);
  const [inventario, setInventario] = useState(false)



  const [todasLasSubLineasArray, setTodasLasSubLineasArray] = useState([]);

  const { categoria } = useParams();


  const bannersPorCategoria = {
    "01": [
      {
        imagen: capilar1,
        boton: "Capilar",
        productos: "017704",
        textoBoton: "Comprar aquí",
        estilos: ["capilarContenedor", "capilarButton"],
      },
    ],
    "05": [
      {
        imagen: higuiene1,
        boton: "Higiene",
        productos: "07485",
        textoBoton: "Comprar ahora",
        estilos: ["higieneHidratanteContenedor", "higieneHidratante"],
      },
      {
        imagen: higuiene2,
        boton: "Higiene",
        productos: "074869",
        textoBoton: "Comprar aquí",
        estilos: ["higieneContenedor", "higuienebtn"],
      },
    ],
    "02": [
      {
        imagen: perfumeria1,
        boton: "Perfumería",
        productos: "0211011",
        textoBoton: "Descubrir aquí",
        estilos: ["perfumeriaContenedor", "perfumeriaBtn"],
      },
    ],
    "06": [
      {
        imagen: maquillaje1,
        boton: "Maquillaje",
        productos: "/tienda-linea-cliente/06?page=1&subCategory=067",
        textoBoton: "Comprar polvos",
        estilos: ["polvoContenedor", "polvoBtn"],
      },
      {
        imagen: maquillaje2,
        boton: "Maquillaje",
        productos: "/tienda-linea-cliente/06?page=1&subCategory=068",
        textoBoton: "Descubrir aquí",
        estilos: ["contenedorBase", "baseBtn"],
      },
      {
        imagen: maquillaje3,
        boton: "Maquillaje",
        productos: "/tienda-linea-cliente/06?page=1&subCategory=062",
        textoBoton: "Comprar ahora",
        estilos: ["contenedorLabial", "labialBtn"],
      },
    ],
    "04": [
      {
        imagen: cuidado1,
        boton: "Cuidado Facial",
        productos: "109003",
        textoBoton: "Comprar aquí",
        estilos: ["contenedorfacialOne", "facialOneBtn"],
      },
      {
        imagen: cuidado2,
        boton: "Cuidado Facial",
        textoBoton: "Comprar aquí",
        estilos: ["contenedorfacialtwo", "facialTwoBtn"],
      },
    ],
  };



  const fetchData = async () => {
    setIsLoading(true);
    try {
      const page = currentPage || 1;
      let url = `${process.env.REACT_APP_API_URL}/productos/categoria?id=${categoria}&page=${page}&pageSize=9`;
      if (subCategory) {
        url += `&subLinea=${subCategory}`;
      }
      const response = await axios.get(url);

      // Asegúrate de que `detalle` siempre sea un arreglo
      const detalle = Array.isArray(response.data.detalle)
        ? response.data.detalle
        : [];
      setInventario(response.data.inventario);

      setProductos(detalle);


      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error al realizar la petición:", error);
      setProductos([]); // Por defecto, inicializa como un arreglo vacío en caso de error
    } finally {
      setIsLoading(false);
    }
  };

  // console.log(inventario);

  useEffect(() => {
    const obtenerSubLineasDisponibles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/productos/categoria?id=${categoria}&page=1&pageSize=1000&`
        );
        const detalle = response.data.detalle;
        const todasLasSubLineas = {};
        // console.log("data", response.data);

        detalle.forEach((item) => {
          let descripcionSublinea = item.descripcion_sublinea;

          if (!todasLasSubLineas[item.sublinea_id]) {
            todasLasSubLineas[item.sublinea_id] = {
              sublinea_id: item.sublinea_id,
              descripcion_sublinea: descripcionSublinea,
            };
          }
        });

        const todasLasSubLineasArray = Object.values(todasLasSubLineas);
        setTodasLasSubLineasArray(todasLasSubLineasArray);
      } catch (error) {
        console.error("Error al obtener las sublíneas disponibles:", error);
      }
    };

    const timeoutId = setTimeout(obtenerSubLineasDisponibles, 1000); // Retraso de 1 segundo

    // Cleanup en caso de que el componente se desmonte antes de que se ejecute el efecto
    return () => clearTimeout(timeoutId);

  }, []); // Dependencias vacías para que solo se ejecute una vez




  const handleSubLineaClick = (subLinea) => {
    const isSameCategory = subCategory === subLinea?.sublinea_id;
    setSearchParams({
      page: isSameCategory ? currentPage : 1,
      subCategory: subLinea.sublinea_id,
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % bannersPorCategoria[categoria]?.length
      );
    }, 10000);
    return () => clearInterval(interval);
  }, [bannersPorCategoria[categoria]?.length]);



  useEffect(() => {
    const timer = setTimeout(() => {
      fetchData();
    }, 500); // Espera 1 segundo (1000 ms)

    return () => clearTimeout(timer);
  }, []);

  const handlePageChange = (newPage) => {
    if (!isLoading) {
      setSearchParams({
        page: newPage,
        ...(subCategory && { subCategory }),
      });
    }
  };

  const nombreCategoria = categoriasNombres[categoria];




  if (isLoading) {
    return <LoadingText />;
  }
  // console.log("este es el producto", productos);


  return (
    <>
      <div className={styles.carrusels}>
        {bannersPorCategoria[categoria]?.map((banner, index) => (
          <div
            key={index}
            style={{ display: index === currentIndex ? "block" : "none" }}
          >
            <img
              src={banner.imagen}
              alt={`Banner ${index + 1}`}
              style={{ width: "100%" }}
            />
            <BotonCompra
              text={banner.textoBoton}
              path={
                isNaN(banner.productos)
                  ? banner.productos // Asume que ya es la ruta completa si es una cadena
                  : `/detalle/${banner.productos}` // Si es un número, usa esta ruta
              }
              currentIndex={currentIndex}
              index={index}
              estilos={banner.estilos}
            />
          </div>
        ))}
      </div>
      <h2 className={styles.titulos}>Tienda en Línea</h2>

      <section className={styles.sectionFilters}>
        <SectionFilters
          todasLasSubLineasArray={todasLasSubLineasArray}
          handleSubLineaClick={handleSubLineaClick}
          subLineaSeleccionada={subCategory}
          nombreCategoria={nombreCategoria}
        />
      </section>

      <div className={styles.contenedorTienda}>
        <div className={styles.contenedorFiltros}>
          <h2 className={styles.categoria}>{nombreCategoria}</h2>
          {todasLasSubLineasArray.map((subLinea, index) => {
            const subCategorySelectedId = subCategory;
            return (
              <div key={index} className={styles.filtroSubLinea}>
                <button
                  className={`${styles.subLineas} ${subLinea.sublinea_id === subCategorySelectedId
                    ? styles.subLineaSeleccionada
                    : ""
                    }`}
                  onClick={() => handleSubLineaClick(subLinea)}
                >
                  {subLinea.descripcion_sublinea.charAt(0).toUpperCase() +
                    subLinea.descripcion_sublinea.slice(1).toLowerCase()}
                </button>
              </div>
            );
          })}
        </div>


        {(() => {
          const filteredProductos =
            (userType === "CON" || userType === null)
              ? productos.filter(
                (producto) => producto.marca_id !== "118" && producto.linea_id !== "118"
              )
              : productos;

          return (
            <>
              {subCategory || productos ? (
                <div
                  className={
                    filteredProductos.length > 0
                      ? styles.contenedorCards
                      : styles.sinProductos
                  }
                >
                  {isLoading ? (
                    <div className={styles.spinner}>Cargandoo</div> // Spinner mientras se carga
                  ) : inventario ? (
                    <Inventario />
                  ) : filteredProductos.length === 0 ? (
                    <h3 className={styles.spinner}>Cargandoo...</h3>
                  ) : (
                    filteredProductos.map((elem, index) => (
                      <Card
                        key={index}
                        data={elem}
                        agregar="pedido"
                        waitPedidos={waitPedidos}
                      />
                    ))
                  )}
                </div>
              ) : (
                <Categoria categoria={categoria} />
              )}
            </>
          );
        })()}


      </div>
      {(subCategory || productos) && (
        <Paginacion
          currentPage={Number(currentPage)}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};
