import React, { useState } from "react";
import styles from "./MensajeFlotante.module.css";
import SvgWhastapp from "../../../assets/svg/SvgWhastapp";
export const MensajeWhasapps = ({ titulo = "¡Hola!    ¿Necesitas Ayuda?" }) => {
  const numeroDestino = "+50322618181";
  const urlWhatsapp = `https://wa.me/${numeroDestino}`;
  const [mostrarTexto, setMostrarTexto] = useState(false);
  const toggleTexto = (e) => {
    e.stopPropagation(); // Previene la propagación para evitar el clic en el texto que desencadene la función
    setMostrarTexto(!mostrarTexto);
  };
  const handleOnClick = (e) => {
    e.stopPropagation(); // Previene la propagación para evitar el clic en el ícono que desencadene el toggle
    window.open(urlWhatsapp, "_blank");
  };
  return (
    <div className={styles.contenedorWhasapp}>
      <div
        className={`${styles.contenedorBlanco} ${mostrarTexto ? styles.mostrar : ""
          }`}
      >
        <div className={styles.tituloWhastapp2}>
          <div
            className={styles.buttonHere}
          >
            <h4 className={styles.tituloWhastapp}>{titulo}</h4>
            <button className={styles.btnEnviars} onClick={handleOnClick}>Haz click aquí</button>
          </div>
        </div>
      </div>
      <div className={styles.contenedorIcons} onClick={toggleTexto}>
        <SvgWhastapp className={styles.icons} />
      </div>
    </div>
  );
};