import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./Slider.module.css";
import Banner1 from "../../../assets/img/VG_WEB_BANNERS_SIN_TXT_Mesa de trabajo 13.webp";
import Banner2 from "../../../assets/img/VG_WEB_BANNERS_SIN_TXT_PRODUCTO_6.webp";
import Banner3 from "../../../assets/img/VG_WEB_BANNERS_SIN_TXT_PRODUCTO_5.png";
import { Link } from "react-router-dom";
import axios from "axios";

export const Sliders = () => {
  const data = [
    {
      buttonTexts: "Comprar polvos",
      buttonClasses: "comprar",
      img: Banner1,
      to: "/tienda-linea-cliente/06", // Ruta específica para el primer elemento
    },
    {
      buttonTexts: "Encuéntralo aquí",
      buttonClasses: "encuentralo",
      img: Banner2,
      to: "/tienda-linea-cliente/02", // Ruta específica para el segundo elemento
    },
    {
      buttonTexts: "Comprar aquí",
      buttonClasses: "accion",
      img: Banner3,
      to: "/detalle/1054005", // Ruta específica para el tercer elemento
    },
  ];

  const settings = {
    pauseOnHover: false,
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    customPaging: (i) => (
      <button className={`${styles.button} `}>{data[i].buttonTexts}</button> // Asegúrate de que esto se refiera al elemento correcto, aquí parece haber un error en la referencia a data.
    ),
  };
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };



  return (
    <div className={styles.sliderContainer}>
      <Slider {...settings}>
        {data.map((data, index) => (
          <div className={styles.contenedorImg} key={index}>
            <img
              src={data.img}
              alt={`Banner ${index + 1}`}
              className={styles.sliderImage}
            />
            <Link
              to={data.to}
              onClick={handleScrollToTop}
              className={`${styles.button} ${styles[data.buttonClasses]}`}
            >
              {data.buttonTexts}
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};
