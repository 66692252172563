import { redondearDecimales } from "../../../../hooks/use-redondearDecimales";
import styles from "./ProductsTotal.module.css";

export const ProductsTotal = ({ product, banderaCon = false }) => {
  let { imagen, quantity, nameItem, price, descripcion, cantidad, subtotal } = product;

  // Verificar si la cantidad es "0" y cambiarla a "1"
  if (cantidad === "0") {
    cantidad = "1";
  }


  return (
    <div className={styles.productCheckoutWrapper}>
      <section className={styles.productCheckout}>
        <div className={styles.sectionImgAndDescription}>
          <div className={styles.sectionImg}>
            <img
              className={styles.imgProduct}
              src={`${process.env.REACT_APP_URL_IMG}/${imagen[0]}`}
              alt={`Imagen ilustrativa del producto ${nameItem || descripcion}`}
            />
          </div>
          <p className={styles.description}>{nameItem || descripcion}</p>
        </div>
        <section className={styles.sectionPriceAndquantity}>
          <p className={styles.quantity}>{quantity || cantidad}x</p>
          <p className={styles.quantity}>
            ${(Number(subtotal) || Number(price) * Number(quantity) || Number(cantidad)).toFixed(2)}
          </p>
        </section>
      </section>
      <div className={styles.linea}></div>
    </div>
  );
};
