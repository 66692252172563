export const menuData = [
  {
    name: "Productos  ",
    subMenus: [
      {
        name: "Higiene y cuidado de la piel",
        items: [
          { name: "Gel de baño", path: "/subLineas/baño" },
          { name: "Lociones", path: "/subLineas/locion" },
          { name: "Tarro", path: "/subLineas/tarro" },
          // { name: "Roll On", path: "/subLineas/Roll on" },
          { name: "Gel Íntimo", path: "/subLineas/gel intimo" },
          { name: "Crema de manos", path: "/subLineas/crema" },
          { name: "Champú", path: "/subLineas/Champu" },
          // { name: "Aceite corporal", path: "/subLineas/aceite corporal" },
          {
            name: "Exfoliante corporal",
            path: "/subLineas/corporal",
          },
          { name: "Colonia de baño", path: "/subLineas/colonia" },
          { name: "Óleos", path: "/subLineas/oleos" },
        ],
      },
      {
        name: "Capilar",
        items: [
          { name: "Shampoo", path: "/subLineas/shampoo" },
          { name: "Acondicionador", path: "/subLineas/acondicionador" },
          { name: "Mascarilla", path: "/subLineas/mascarilla" },
          { name: "Tratamientos", path: "/subLineas/tratamiento" },
          // { name: "Crema de Peinar", path: "/subLineas/peinar" },
          { name: "Aceites", path: "/subLineas/aceite" },
          { name: "Tintes", path: "/subLineas/tinte" },
          { name: "Sachets", path: "/subLineas/sachet" },
          { name: "Baño de color", path: "/subLineas/color" },
          { name: "Ampolletas", path: "/subLineas/ampolleta" },
        ],
      },

      {
        name: "Maquillaje",
        items: [
          { name: "Bases", path: "/subLineas/base" },
          { name: "Iluminador", path: "/subLineas/iluminador" },
          { name: "Sombras", path: "/subLineas/sombras" },
          { name: "Máscara", path: "/subLineas/mascarilla" },
          { name: "Delineador", path: "/subLineas/delineador" },
          { name: "Labiales", path: "/subLineas/labial" },
          { name: "Polvos", path: "/subLineas/polvo" },
        ],
      },
      {
        name: "Facial",
        items: [
          { name: "Exfoliante", path: "/subLineas/exfoliante" },
          { name: "Crema Hidratante", path: "/subLineas/hidratante" },
          { name: "Agua micelar", path: "/subLineas/agua micelar" },
          { name: "Mascarilla facial", path: "/subLineas/mascarilla" },
          { name: "Serum", path: "/subLineas/serum" },

          { name: "Contorno de ojos", path: "/subLineas/contorno" },
        ],
      },
      {
        name: "Perfumería",
        items: [
          { name: "Dama", path: "/subLineas/ECSTASY" },
          { name: "Caballero", path: "/subLineas/HOMME" },
        ],
      },
    ],
  },
];
