import * as React from "react";
const SvgFacebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={39}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.438}
      d="M27.75 3.25h-4.875a8.125 8.125 0 0 0-8.125 8.125v4.875H9.875v6.5h4.875v13h6.5v-13h4.875l1.625-6.5h-6.5v-4.875a1.625 1.625 0 0 1 1.625-1.625h4.875v-6.5Z"
    />
  </svg>
);
export default SvgFacebook;
