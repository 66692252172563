import * as React from "react"

export function SvgDespliegueUp(props) {
  return (
    <svg
      width="19px"
      height="19px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      cursor="pointer"
      {...props}
    >
      {"{&quot; &quot;}"}
      <path
        d="M6 9l6 6 6-6"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {"{&quot; &quot;}"}
    </svg>
  )
}

