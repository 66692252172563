import React, { useEffect, useState } from "react";
import { Sliders } from "../components/Slider/Slider";
import { ProductoNuevo } from "../components/ProductoNuevos/ProductoNuevo";
import { ProductosFavoritos } from "../components/ProductosFavoritos/ProductosFavoritos";
import { BannerDistribuidora } from "../components/Baners/BannerDistribuidora";
import { ComoUtilizarProductos } from "../components/ComoUtilizarProductos/ComoUtilizarProductos";
import { Baners } from "../components/Baners/Baners";
import { InstitutoEspañol } from "../components/InstitutoEspañol/InstitutoEspañol";
import { Blog } from "../components/Blog/Blog";
import { Empresas } from "../components/Empresas/Empresas";
import styles from "../../css/BannerProductosNuevos.module.css";
import { Card } from "../components/Cards/Card";
import { Cargando } from "../components/Cargando/Cargando";
import axios from "axios";
import { useSelector } from "react-redux";
import { MensajeWhasapps } from "../components/MensajeFlotant/MensajeWhasapps";
import banner from "../../assets/img/Banner/image.png";
import { Link } from "react-router-dom";
import { ChatComponent } from "../components/MensajeFlotant/MensajeFlotante";
import { usePedidos } from "../../state/context/pedidosContextProvider";

export const HomePage = () => {
  const { isAuthenticated, token, user, userType } = useSelector((state) => state.auth);
  const [pedidos, setPedidos] = useState([]);
  const [productos, setProductos] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [productosFiltrados, setProductosFiltrados] = useState([]);
  const { handleGetData } = usePedidos();

  const apiGet = async () => {
    if (isAuthenticated) {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${user.email}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setPedidos(res.data);
    }
  };

  const getData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=1000`
    );
    const productosFiltrados = response.data.detalle.filter(
      (producto) => producto.tipo_incentivo_id === "n"
    );
    setProductos(productosFiltrados.slice(0, 3));
  };

  const filtrarProductos = (productos) => {
    if (isAuthenticated && userType === "CON") {
      const filtrados = productos.filter((producto) => producto.marca_id !== "117");
      setProductosFiltrados(filtrados);
      return filtrados;
    }
    setProductosFiltrados(productos);
    return productos;
  };

  useEffect(() => {
    const loadData = async () => {
      // Solo ejecuta apiGet si el usuario está autenticado
      if (isAuthenticated) {
        await apiGet();
      }
      await getData();
      setTimeout(() => {
        setCargando(false);
      }, 2000); // Esperar 4 segundos antes de cambiar cargando a false
    };
    loadData();
  }, [isAuthenticated]);
  useEffect(() => {
    if (isAuthenticated) {
      handleGetData()
    }
  }, [])

  useEffect(() => {
    if (Array.isArray(productos)) {
      filtrarProductos(productos);
    }
  }, [productos, isAuthenticated, userType]);

  if (cargando) {
    return <Cargando />;
  }

  return (
    <>
      <Sliders />
      <ChatComponent />
      <MensajeWhasapps />
      <ProductoNuevo />
      <div className={styles.contenedorGenerals}>
        {/* Cards */}
        <div className={styles.conenedorCards}>
          {productosFiltrados.length > 0 ? (
            productosFiltrados.map((ele, index) => (
              <Card
                key={index}
                data={ele}
                detalle={true}
                pedidos={pedidos}
                onPedido={apiGet}
              />
            ))
          ) : (
            <h1> Productos no disponibles momentaneamente </h1>
          )}
        </div>
      </div>
      {/* banners */}
      <div className={styles.contenedorSVG}>
        <img src={banner} alt="" className={styles.bannerCatalogo} />
        <Link to={"/catalogo"} className={styles.contenedorBtnsCatalogo}>
          Ver catálogo
        </Link>
      </div>
      <ProductosFavoritos pedidos={pedidos} onPedido={apiGet} />
      <BannerDistribuidora />
      <ComoUtilizarProductos />
      <Baners />
      <InstitutoEspañol pedidos={pedidos} onPedido={apiGet} />
      <Blog />
      <Empresas />
    </>
  );
};
