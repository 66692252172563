import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pedidoID: null, // Puedes iniciar con null o cualquier valor por defecto
};

const pedidoIDSlice = createSlice({
  name: "pedidoID",
  initialState,
  reducers: {
    setPedidoID: (state, action) => {
      state.pedidoID = action.payload;
    },
    clearPedidoID: (state) => {
      state.pedidoID = null; // O puedes establecerlo a cualquier valor por defecto
    },
  },
});

export const { setPedidoID, clearPedidoID } = pedidoIDSlice.actions;
export default pedidoIDSlice.reducer;
