import * as React from "react";
const SvgYoutube = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={39}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M37.338 7.856a4.9 4.9 0 0 1 1.26 2.196c.816 3.062.816 9.448.816 9.448s0 6.387-.816 9.449a4.9 4.9 0 0 1-3.448 3.47c-3.049.82-15.236.82-15.236.82s-12.188 0-15.237-.82a4.901 4.901 0 0 1-3.448-3.47C.414 25.887.414 19.5.414 19.5s0-6.386.815-9.448a4.902 4.902 0 0 1 3.448-3.47c3.05-.82 15.237-.82 15.237-.82s12.187 0 15.236.82a4.901 4.901 0 0 1 2.188 1.274ZM26.118 19.5l-10.193-5.799V25.3L26.118 19.5Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.375 0h39v39h-39z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgYoutube;
