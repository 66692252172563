import React from "react";
import styles from "./TiendaEnLinea.module.css";
import { SvgDown } from "../../../assets/svg/svgDespliegueDow";
import { SvgDespliegueUp } from "../../../assets/svg/svgDEspliegueUp";

export const DespliegueMarcas = ({ categorias, desplegar, handleDespegar }) => {

  return (
    <div className={styles.desplegars} onClick={handleDespegar}>
      <div className={styles.flechass}>
        <div className={styles.nombre}>{categorias.nombres}</div>
        <div className={styles.desplegadoFlecha}>
          {desplegar ? <SvgDown /> : <SvgDespliegueUp />}
        </div>
      </div>
      {
        (categorias.nombres === "Dicora" || categorias.nombres === "Deven" || categorias.nombres === "Kanechom") &&
        <p className={styles.lineaDelgada} />
      }
    </div>
  );
};
