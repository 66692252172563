import React, { useEffect, useState } from "react";
import { Table } from "../../../homeComun/components/TablaProducto/Table";
import { useSelector } from "react-redux";
import axios from "axios";
import { Cargando } from "../../../homeComun/components/Cargando/Cargando";
import { useNavigate } from "react-router-dom"; // Importar useNavigate

export const CarritoDistribuidor = () => {
  const { isAuthenticated, token, user } = useSelector((state) => state.auth);
  const [pedidos, setPedidos] = useState([]);
  const [loading, setLoading] = useState(true); // Estado de carga
  const { userType } = useSelector((state) => state.auth);
  const [cargandoCarrito, setCargandoCarrito] = useState(false);
  const navigate = useNavigate(); // Inicializar useNavigate

  const apiGet = async () => {
    if (isAuthenticated) {
      setCargandoCarrito(true);
      let url;
      if (userType === "DIS") {
        url = `${process.env.REACT_APP_API_URL}/pedidos/user/${user.cliente_id}`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/pedidos/user/${user.email}`;
      }
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      });

      setPedidos(
        res.data?.map((item) => {
          return {
            ...item,
            carrito: item.carrito?.map((el) => {
              return {
                ...el,
                idPedido: item._id,
              };
            }),
          };
        })
      );
      setCargandoCarrito(false);
      setLoading(false); // Desactivar el estado de carga una vez obtenidos los pedidos
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/"); // Redirigir a la ruta principal si no está autenticado
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      apiGet();
    }
  }, [isAuthenticated, pedidos.length]);

  return (
    <>
      {loading ? ( // Mostrar el spinner mientras está cargando
        <Cargando />
      ) : ( // Mostrar la tabla cuando se hayan cargado los datos
        <Table
          titulo={"pedido"}
          carrito={pedidos[0]?.carrito}
          token={token}
          isAuthenticated={isAuthenticated}
          onCarrito={apiGet}
          idPedido={pedidos[0]?._id}
          userType={userType}
          cargandoCarrito={cargandoCarrito}
          setCargandoCarrito={setCargandoCarrito}
        />
      )}
    </>
  );
};
