import React, { useEffect, useState } from "react";
import styles from "./PopupPaymentOption.module.css";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useBorrador } from "../../../../state/context/borradorContext";

export const PopupPaymentOption = ({
  handleScrollToTop,
  loading,
  isDistributor,
  showContent,
  ssid,
}) => {
  const { user, token, userType } = useSelector((state) => state.auth);
  const [pedidoID, setPedidoID] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar el loading
  const navigate = useNavigate();
  const { setBorrador, } = useBorrador();



  const formaPago = () => {
    if (userType === "DIS") {
      return user?.tipo_cre === "CONTADO" ? "Contado" : "Crédito";
    }
    return null; // O el valor que consideres por defecto
  };

  const getEmptyCart = async () => {
    let url = 'https://mivogue.com:83/APIs';
    const username = 'c2ac07cafartwetsdADS2356FEDGeiE7xz7Gnn3XcxEkAQlPxtRIqf2gFu7q';
    const password = 'c2ac07cafartwetsdADS2356FEDGe3LZNmqXlF8NgGg83fp4RjvUdzxswwmO';
    const authHeader = 'Basic ' + btoa(username + ":" + password);

    try {
      setIsLoading(true); // Iniciar el loading

      let resPrev = await axios.post(`${process.env.REACT_APP_API_URL}/pedidos/pre-data/finalizar`,
        {
          idPedido: pedidoID,
          tipo: "DIS",
          proceso: "E"
        },
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      if (resPrev.status === 200) {
        const formData = new FormData();
        formData.append("proceso", resPrev.data.proceso);
        formData.append("cargo_administrativo", resPrev.data.cargoAdmon);
        formData.append("PHPSESSID", resPrev.data.cookie);
        resPrev.data.incentivos && formData.append("incentivo", resPrev.data.incentivos);
        resPrev.data.cantidad_incentivos && formData.append("incentivo_cantidad", resPrev.data.cantidad_incentivos);

        const headers = {
          Authorization: authHeader,
          'Content-Type': 'multipart/form-data'
        };

        let resFinish = await axios.post(
          `${url}/pedidos/${user.cliente_id}`, formData,
          {
            headers
          }
        );

        if (resFinish.data.success) {
          // console.log("entro al if");



          const resActualizar = await axios.post(
            `${process.env.REACT_APP_API_URL}/pedidos/actualizar/finalizar`,
            {
              idPedido: pedidoID,
              tipo: "DIS",
            },
            {
              headers: {
                Authorization: `Bearer ${token ?? ""}`,
              },
            }
          );

          const responseBorrador = await axios.delete(
            `${process.env.REACT_APP_API_URL}/borrador`,
            {
              headers: { Authorization: `Bearer ${token ?? ""}` },
            }

          )
        }
        setBorrador(null);
      }
    } catch (error) {
      console.error("Error al finalizar el pedido:", error);
    } finally {
      setIsLoading(false); // Terminar el loading
    }
  };

  const handleClick = async () => {
    await getEmptyCart();
    navigate("/facturas-pendientes", {
      state: {
        message: "¡Compra exitosa! En 48 horas, podrás acceder a tus facturas en esta sección.",
      },
    });
  };

  const getPedido = async () => {
    try {
      setIsLoading(true); // Iniciar el loading al obtener el pedido

      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${isDistributor && user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        setPedidoID(response.data[0]._id);
      }
    } catch (error) {
      console.error("Error al obtener el pedido:", error);
    } finally {
      setIsLoading(false); // Terminar el loading
    }
  };



  useEffect(() => {
    const paymentType = formaPago();
    if (paymentType === "Crédito") {
      getPedido();
    }
  }, []);

  useEffect(() => {
    if (pedidoID) {
      handleClick(); // Ejecutar handleClick una vez que tenemos el idPedido
    }
  }, [pedidoID]);

  return (
    <div className={styles.popupPaymentOption}>
      <div
        className={
          formaPago() === "Crédito"
            ? styles.containerPopupPaymentOption
            : styles.containerPopupPayment
        }
      >
        {/* Mostrar el Spinner mientras se está cargando */}
        {isLoading && (
          <div className={styles.loadingContainer}>
            <div className={styles.loadingContainer}>
              <Spinner className={styles.spinners} animation="border" role="status" size="lg">

              </Spinner>

            </div>
          </div>
        )}

        <h3 className={styles.titleQuestion}>
          {formaPago() !== "Crédito" &&
            "Estás a un paso de finalizar tu pedido"}
        </h3>
        <div className={styles.sectionButtons}>
          {formaPago() !== "Crédito" && (
            <button
              className={loading || !showContent ? styles.buttonLoading : styles.buttonPay}
              disabled={!showContent}
              onClick={handleScrollToTop}
            >
              {loading ? (
                <Spinner animation="border" role="status" size="sm">
                  <span className="visually-hidden">Cargando...</span>
                </Spinner>
              ) : (
                "Pagar ahora"
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
