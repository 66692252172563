import React, { useMemo } from "react";
import styles from "./DEtalleBlog.module.css";
import { useLocation } from "react-router-dom";
import { ShareSection } from "./components/ShareSection";
import { RelatedArticles } from "./components/RelatedArticles";
import { SectionDescriptionCremaSolar } from "./components/SectionDescriptionCremaSolar";
import { SectionDescriptionAguaBifasica } from "./components/SectionDescriptionAguaBifasica";
import { SectionDescriptionRomeroYSalvia } from "./components/SectionDescriptionRomeroYSalvia";
import { SectionDescriptionCicatrifin } from "./components/SectionDescriptionCicatrifin";
import { SectionDescriptionTratamiCabello } from "./components/SectionDescriptionTratamiCabello";

export const DetalleBlog = () => {
  const location = useLocation();
  const { article } = location.state;

  const Description = useMemo(() => {
    const contentDescription = {
      article_1: SectionDescriptionCremaSolar,
      article_2: SectionDescriptionAguaBifasica,
      article_3: SectionDescriptionRomeroYSalvia,
      article_4: SectionDescriptionTratamiCabello,
      article_5: SectionDescriptionCicatrifin,
    };
    const Component =
      contentDescription?.[article?.id] || contentDescription[0];
    return Component;
  }, [article.id]);

  return (
    <main className={styles.detailBlogWrapper}>
      <h2 className={styles.titulo}>{article.title}</h2>
      <div className={styles.detailBlogContent}>
        <ShareSection />
        <div className={styles.detailArticleWrapper}>
          <div className={styles.contenedorCarruselSimilar}>
            <div className={styles.contenedorCarrusel}>
              <div className={styles.imagenPrincipal}>
                <img
                  className={styles.imagenPrincipal}
                  src={article.largeImage}
                  alt="Imagen principal"
                />
              </div>
            </div>
            <Description />
          </div>
          <RelatedArticles idArticule={article.id} />
        </div>
      </div>
    </main>
  );
};
