import React, { useEffect, useState } from "react";
import styles from "./Registro.module.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export const Contraseña = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controlar el botón
  const { isAuthenticated, userType, token, user } = useSelector(
    (state) => state.auth
  );
  const newPassword = async (data) => {
    setIsSubmitting(true); // Deshabilita el botón al iniciar la petición
    try {
      const dataConTipoProceso = {
        ...data,
        tipo: "CON",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/send-reset-password`,
        dataConTipoProceso,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Correo enviado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "ContraseñaSuccess",
          style: { fontSize: "20px" },
        });
        reset();
        setTimeout(() => {
          navigate("/login-cliente");
        }, 3000);
      }
    } catch (error) {
      const mensajeError = error.response?.data?.detalle || "Algo salió mal";
      toast.error("Error al enviar correo: " + mensajeError, {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: "loginError",
        style: { fontSize: "20px" },
      });
      setIsSubmitting(false); // Reactiva el botón si la petición falla
    }
  };


  useEffect(() => {
    if (isAuthenticated === true) {
      navigate("/")
    }
  }, [])




  return (
    <>

      {isAuthenticated === false && (
        <div className="contenedor-contraseña">
          <div className="contenedor-column">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="102"
              height="102"
              viewBox="0 0 102 102"
              fill="none"
            >
              <path
                d="M30.1793 42.5H29.75V34C29.75 22.2639 39.2639 12.75 51 12.75C62.7361 12.75 72.25 22.2639 72.25 34V42.5H71.8207M51 59.5V68M80.75 63.75C80.75 80.1805 67.4305 93.5 51 93.5C34.5695 93.5 21.25 80.1805 21.25 63.75C21.25 47.3195 34.5695 34 51 34C67.4305 34 80.75 47.3195 80.75 63.75Z"
                stroke="#9C182F"
                strokeWidth="8.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className="p-contraseña">¿Olvidaste tu contraseña?</p>
            <p className="p-subtitulo">
              Ingresa tu correo electronico para recibir un código de verificación
            </p>


            <form
              onSubmit={handleSubmit(newPassword)}
              className={styles.formContraseña}
            >
              <label htmlFor="valor"></label>
              <input
                className="input-contraseña"
                type="email"
                placeholder="Correo"
                {...register("valor", {
                  required: {
                    value: true,
                    message: "Correo es requerido",
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Correo no valido",
                  },
                })}
              />
              {errors.valor && (
                <span className={styles.errors}>{errors.valor.message}</span>
              )}

              <button
                className={styles.butonContraseña}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Enviando..." : "Enviar"}
              </button>
            </form>


          </div>
        </div>
      )

      }



    </>
  );
};
