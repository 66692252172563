// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PreguntasFrecuentes_wrapperMain__cn8HG {\n  min-height: 67vh;\n}\n.PreguntasFrecuentes_titulo__kvWyx {\n  color: #9c182f;\n  font-family: \"Lato\", sans-serif;\n  font-size: 3rem;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  text-align: center;\n  margin-bottom: 40px;\n  margin-top: 40px;\n}\n.PreguntasFrecuentes_contenedor__1I-gr {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  margin: 40px 15px 70px 15px;\n\n}\n@media (max-width: 782px) {\n  .PreguntasFrecuentes_contenedor__1I-gr {\n    flex-direction: column;\n    margin: 0 20px 70px 20px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/css/PreguntasFrecuentes.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,2BAA2B;;AAE7B;AACA;EACE;IACE,sBAAsB;IACtB,wBAAwB;EAC1B;AACF","sourcesContent":[".wrapperMain {\n  min-height: 67vh;\n}\n.titulo {\n  color: #9c182f;\n  font-family: \"Lato\", sans-serif;\n  font-size: 3rem;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  text-align: center;\n  margin-bottom: 40px;\n  margin-top: 40px;\n}\n.contenedor {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  margin: 40px 15px 70px 15px;\n\n}\n@media (max-width: 782px) {\n  .contenedor {\n    flex-direction: column;\n    margin: 0 20px 70px 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperMain": "PreguntasFrecuentes_wrapperMain__cn8HG",
	"titulo": "PreguntasFrecuentes_titulo__kvWyx",
	"contenedor": "PreguntasFrecuentes_contenedor__1I-gr"
};
export default ___CSS_LOADER_EXPORT___;
