import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "../../../homeComun/components/Cards/Card";
import styles from "../../../homeComun/components/VerTodoProductos/VerTodo.module.css";
import banner from "../../../assets/img/nuevosBanners/Vogueplus-BannerSitioWeb-04.jpg";
import { LoadingText } from "../../../components/LoadingText";
import { Inventario } from "../TiendaEnLinea/Inventario";

export const VoguePlus = () => {
  const { userType } = useSelector(
    (state) => state.auth
  );

  const [productos, setProductos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const[inventario, setInventario]= useState(false)

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos/promociones`
      );
      setInventario(response.data)
      const productosFiltrados = response.data.detalle.filter(
        (producto) => producto.tipo_incentivo_id === "v"
      );
      setProductos(productosFiltrados);
    } catch (error) {
      console.error("Error al obtener los datos", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getData();
    }, 1000); // 1000 ms = 1 segundo
  
    return () => clearTimeout(timeoutId); // Limpiar el timeout si el componente se desmonta
  }, []);
  // console.log(inventario);
  

  return (
    <>
      <img className={styles.banner} src={banner} alt="Vogue plus" />
      <h2 className={styles.titulo}>Vogue plus</h2>

      {isLoading ? (
        <LoadingText />
      )


        : inventario.inventario ? (
          <div className={styles.contenedorInventario}>
            <Inventario />
          </div>

        )
          : (
            <div className={styles.contenedor}>
              <div className={styles.contyenedorCards}>
                {productos.map((ele, index) => (
                  <Card
                    key={index}
                    data={ele}
                    agregar="pedido"
                    cantidadProp={userType === "DIS" ? true : false}
                    detalle={true}
                  />
                ))}
              </div>
            </div>
          )}
    </>
  );
};
