import React from "react";

export const LoadingText = ({ small }) => {
  return (
    <p
      style={ small ? {
        color: "#dd8093",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "40px",
        fontWeight: "bold",
        textAlign: "center",
        padding: "30px 0"
      }
      : {
        color: "#dd8093",
        height: "calc(100vh - 411px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "40px",
        fontWeight: "bold",
        marginTop: "2rem",
        textAlign: "center",
      }
    }
    >
      Cargando...
    </p>
  );
};
