import React, { useEffect, useState } from "react";
import ProductDescriptionInfo from "../components/product/ProductDescriptionInfo";
import styles from "../css/ProductoCarruselImagen.module.css";
import { useLocation } from "react-router-dom";
import { Reseñas } from "../components/homeCliente/components/Reseñas";
import { ProductosRelacionados } from "../components/homeCliente/components/ProductosRelacionados";
import { useSelector } from "react-redux";
import axios from "axios";
import { Cargando } from "../homeComun/components/Cargando/Cargando";

export const DetalleProducto = () => {
  const { isAuthenticated, token, user, userType } = useSelector((state) => state.auth);
  const location = useLocation();
  const { ruta = true } = location.state || {};
  const [pedidos, setPedidos] = useState([]);
  const [datas, setDatas] = useState("")
  const [cargando, setCargando] = useState(true)


  const getDetailId = () => {
    const urlParts = window.location.href.split("/detalle/");
    return urlParts[1];
  };

  const detailId = getDetailId();

  const dataInfo = async () => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/productos?id=${detailId}&page=1&pageSize=1`)
    setDatas(response.data.detalle)

  }
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const hasMultipleImages = datas?.imagen?.length > 1;

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  const getCurrentImageUrl = () => {
    return `${process.env.REACT_APP_URL_IMG}/${datas?.imagen?.[currentImageIndex]}`;
  };

  // Filtrar la imagen principal de la lista de imágenes secundarias
  const secondaryImages = datas?.imagen?.filter(
    (_, index) => index !== currentImageIndex
  );

  const getPedidos = async () => {
    if (isAuthenticated) {
      let url = "";

      if (userType === "DIS") {
        url = `${process.env.REACT_APP_API_URL}/pedidos/user/${user.cliente_id}`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/pedidos/user/${user.email}`;
      }
      const res = await axios.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setPedidos(res.data);
      setCargando(false)
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getPedidos();

    } else {
      setCargando(false)
    }

    dataInfo()
  }, [])

  return (
    datas && !cargando ? (
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorGeneral}>
          <div className={`${styles.carouselContainer} ${hasMultipleImages ? '' : styles.sinListaImagen}`}>
            {hasMultipleImages && (
              <div className={styles.imageList}>
                {secondaryImages.map((imageName, index) => (
                  <div
                    key={index}
                    className={styles.imageItem}
                    onClick={() =>
                      handleImageClick(datas.imagen.indexOf(imageName))
                    }
                  >
                    <img
                      className={styles.imagen}
                      src={`${process.env.REACT_APP_URL_IMG}/${imageName}`}
                      alt="Imagen del producto"
                    />
                  </div>
                ))}
              </div>
            )}
            <div className={`${styles.mainImage} ${hasMultipleImages ? '' : styles.centradoImg}`}>
              <img
                className={styles.productoSeleccionado}
                src={getCurrentImageUrl()}
                alt="Imagen del producto principal"
              />
            </div>
          </div>
          <div className={styles.contenedorInfo}>
            <ProductDescriptionInfo
              data={datas}
              pedidos={pedidos}
              onPedido={getPedidos}
              ruta={ruta}
            />
          </div>
        </div>
        <Reseñas data={datas} />
        <ProductosRelacionados data={datas} />
      </div>
    ) : (
      <Cargando />
    )
  );
};