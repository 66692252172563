import React from "react";
import { SubMarcas } from "./SubMarcas";
import { categorias } from "../../../assets/utils/CategoriaMarcas";

export const TiendaEnLineas = () => {
  return (
    <>
      {categorias.map((item, index) => (
        <SubMarcas key={index} categorias={item} index={index} />
      ))}
    </>
  );
};
