import React from "react";
import { ListMenu } from "./components/ListMenu";
import { menuItems } from "./constants/contenMenu";
import { menuItemsDistributor } from "./constants/contenMenuDistribuidor";
import { images } from "../../../assets/img";
import SvgClosedButtonSearch from "../../../assets/svg/SvgClosedButtonSearch";

import styles from "./sidebar.module.css";
import { useSelector } from "react-redux";

export const Sidebar = ({ showSidebar, handleClose }) => {
  const { userType } = useSelector((state) => state.auth);
  return (
    <>
      {showSidebar && (
        <section className={styles.backgroundSidebar}>
          <div className={styles.sidebarWrapper}>
            <div className={styles.sidebarHeader}>
              <img src={images.logo} alt="vogue-logo" />
              <button
                onClick={handleClose}
                className={styles.buttonCloseSidebar}
              >
                <SvgClosedButtonSearch />
              </button>
            </div>
            <div className={styles.sidebarContent}>
              <h3>Categorías</h3>
              <ListMenu data={ userType === 'DIS' ? menuItemsDistributor : menuItems} handleClose={handleClose} />
            </div>
          </div>
        </section>
      )}
    </>
  );
};
