// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".svg_carrito__-55oR {\n  width: 100%; /* Ancho al 100% del contenedor */\n  height: auto; /* Altura automática para mantener la proporción */\n  max-width: 133px; /* Ancho máximo del SVG */\n  max-height: 97px;\n}\n@media (max-width: 420px) {\n  .svg_carritoSvg__DnA4n {\n    max-width: 100px; /* Reducimos el tamaño en pantallas pequeñas */\n    max-height: 72px;\n  }\n  .svg_buscador__KCakl {\n    width: 35%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/assets/svg/svg.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAE,iCAAiC;EAC9C,YAAY,EAAE,kDAAkD;EAChE,gBAAgB,EAAE,yBAAyB;EAC3C,gBAAgB;AAClB;AACA;EACE;IACE,gBAAgB,EAAE,8CAA8C;IAChE,gBAAgB;EAClB;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".carrito {\n  width: 100%; /* Ancho al 100% del contenedor */\n  height: auto; /* Altura automática para mantener la proporción */\n  max-width: 133px; /* Ancho máximo del SVG */\n  max-height: 97px;\n}\n@media (max-width: 420px) {\n  .carritoSvg {\n    max-width: 100px; /* Reducimos el tamaño en pantallas pequeñas */\n    max-height: 72px;\n  }\n  .buscador {\n    width: 35%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"carrito": "svg_carrito__-55oR",
	"carritoSvg": "svg_carritoSvg__DnA4n",
	"buscador": "svg_buscador__KCakl"
};
export default ___CSS_LOADER_EXPORT___;
