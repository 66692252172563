import banner from "../../../assets/img/nuevosBanners/Promos-Banner-Estatico.jpg";
import styles from "../VerTodoProductos/VerTodo.module.css";
import { Card } from "../Cards/Card";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LoadingText } from "../../../components/LoadingText";
import { InfoNotAvailable } from "../../../components/InfoNotAvailable";

export const ProductosOferta = () => {
  const { userType } = useSelector((state) => state.auth);
  const images = [banner];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [productos, setProductos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Number(sessionStorage.getItem("currentPage")) || 1
  );
  const pageSize = 8; // Productos por página
  const [totalPages, setTotalPages] = useState(0);

  const getData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=1000`
      );
      userType === "CON"
        ? setProductos(
          response.data.detalle.filter(
            (producto) => producto.tipo_incentivo_id === "p"
          )
        )
        : setProductos(
          response.data.detalle.filter(
            (producto) => producto.tipo_incentivo_id === "p"
          )
        );
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const productosPaginados = productos.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  useEffect(() => {
    setTotalPages(Math.ceil(productos.length / pageSize));
  }, [productos]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    getData();
  }, []);

  const smoothScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    sessionStorage.setItem("currentPage", page); // Guardar la página actual
    smoothScrollToTop();
  };

  if (isLoading) {
    return <LoadingText />;
  }

  return (
    <>
      <div className={styles.carrusels}>
        {images.map((image, index) => (
          <div
            key={index}
            style={{ display: index === currentIndex ? "block" : "none" }}
          >
            <img src={image} alt={`Banner ${index + 1}`} style={{ width: "100%" }} />
          </div>
        ))}
      </div>
      <h2 className={styles.titulo}>Productos en oferta</h2>

      {productos.length > 0 ? (
        <div className={styles.contenedor}>
          <div className={styles.contyenedorCards}>
            {productosPaginados.map((ele, index) => (
              <Card
                key={index}
                data={ele}
                agregar="pedido"
                cantidadProp={userType === "DIS" ? true : false}
                detalle={true}
                promociones={true}
              />
            ))}
          </div>
        </div>
      ) : (
        <InfoNotAvailable message="Productos no encontrados" />
      )}
      {productos.length > 0 && (
        <div className={styles.paginacion}>
          {/* SVG de paginación hacia la izquierda */}
          <h4
            onClick={() => handlePageChange(currentPage - 1)}
            className={`${styles.btnPaginacion} ${currentPage === 1 ? styles.hidden : ""
              }`}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              cursor={"pointer"}
            >
              <circle cx="12.5" cy="12.5" r="12.5" fill="#DD7F93" />
              <path d="M6 12.5L15 5.13878L15 19.8612L6 12.5Z" fill="white" />
            </svg>
          </h4>

          {Array.from({ length: totalPages }, (_, i) => (
            <p
              key={i + 1}
              onClick={() => handlePageChange(i + 1)}
              className={styles.parrafoBtn}
              style={{
                backgroundColor: currentPage === i + 1 ? "" : "",
                color: currentPage === i + 1 ? "#dd8093" : "#333",
              }}
            >
              {i + 1}
            </p>
          ))}

          {/* SVG de paginación hacia la derecha */}
          <h4
            onClick={() => handlePageChange(currentPage + 1)}
            className={`${styles.btnPaginacion} ${currentPage === totalPages ? styles.hidden : ""
              }`}
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              cursor={"pointer"}
            >
              <circle cx="12.5" cy="12.5" r="12.5" fill="#DD7F93" />
              <path d="M19 12.5L10 5.13878L10 19.8612L19 12.5Z" fill="white" />
            </svg>
          </h4>
        </div>
      )}
    </>
  );
};
