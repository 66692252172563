import { images } from "../assets/img";
import vogueTipsOne from "../assets/img/vogueTips/VogueTips-Banner.jpg";
import vogueTipsTwo from "../assets/img/vogueTips/VogueTips-Banner-2.jpg";

export const articles = [
  {
    title: "Protector solar",
    description: "El secreto para proteger tu piel",
    largeDescription: "Los rayos UVB Y UVA pueden causar daños graves a nuestra piel. Es crucial entender la importancia de proteger nuestra piel de estos dañinos rayos.",
    image: images.blog.small[7],
    mediumImage: images.blog.medium[7],
    largeImage: images.blog.large[7],
    id: "article_1",
  },
  {
    title: "Agua Bifásica",
    description: "Descubre la mejor versión de tu piel",
    largeDescription: "Las aguas micelares están enriquecidas con valiosos ingredientes que ayudan a cuidar de tu piel entre ellos aceite de jojoba, argán y pantenol.",
    image: images.blog.small[2],
    mediumImage: images.blog.medium[2],
    largeImage: images.blog.large[2],
    id: "article_2",
  },
  {
    title: "Romero y Salvia (Toda la línea)",
    description: "S.O.S control de caída",
    largeDescription: "El romero y la salvia son hierbas aromáticas ampliamente conocidas por sus propiedades beneficiosas para la salud.",
    image: images.blog.small[8],
    mediumImage: images.blog.medium[8],
    largeImage: images.blog.large[8],
    id: "article_3",
  },
  {
    title: "Shampoo y Tratamiento para Cabellos Tinturados",
    description: "Colores a prueba del sol",
    largeDescription: "Los rayos UV del sol pueden provocar la decoloración del color del cabello teñido, especialmente si es un color vibrante o de tonos claros.",
    image: images.blog.small[5],
    mediumImage: images.blog.medium[5],
    largeImage: images.blog.large[5],
    id: "article_4",
  },
  {
    title: "Cicatrifín",
    description: "Ama tus cicatrices porque son parte de ti",
    largeDescription: "En la vida, todos llevamos nuestras propias marcas: las cicatrices que cuentan historias de luchas pasadas, desafíos superados y las cicatrices de la piel.",
    image: images.blog.small[3],
    mediumImage: images.blog.medium[3],
    largeImage: images.blog.large[3],
    id: "article_5",
  },
];

export const articlesBanner = [
  {
    title: "Protector solar",
    description: "El secreto para proteger tu piel",
    largeDescription: "Los rayos UVB Y UVA pueden causar daños graves a nuestra piel. Es crucial entender la importancia de proteger nuestra piel de estos dañinos rayos.",
    image: images.blog.small[7],
    mediumImage: images.blog.medium[7],
    largeImage: images.blog.large[7],
    banner: vogueTipsTwo,
    id: "article_1",
  },
  {
    title: "Agua Bifásica",
    description: "Descubre la mejor versión de tu piel",
    largeDescription: "Las aguas micelares están enriquecidas con valiosos ingredientes que ayudan a cuidar de tu piel entre ellos aceite de jojoba, argán y pantenol.",
    image: images.blog.small[2],
    mediumImage: images.blog.medium[2],
    largeImage: images.blog.large[2],
    banner: vogueTipsOne,
    id: "article_2",
  },
] 