// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".listMenu_itemButton__8Y3cA {\n  outline: none;\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  padding: 10px 0;\n  background-color: transparent;\n}\n.listMenu_itemButton__8Y3cA > span {\n  color: #444;\n  font-size: 14px;\n}\n.listMenu_itemCategory__iC54I {\n  margin-left: 10px;\n  font-weight: 400;\n}\n\n.listMenu_itemCategoryTwo__de4LN{\n  padding: 0;\n  padding-left: 20px;\n}\n\n.listMenu_itemTitle__H3inQ {\n  margin-left: 0;\n  font-weight: 600;\n\n}\n.listMenu_itemSubTitle__41n31 {\n  font-weight: 600;\n  padding-left: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/homeComun/components/Sidebar/components/ListMenu/listMenu.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,eAAe;EACf,6BAA6B;AAC/B;AACA;EACE,WAAW;EACX,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,gBAAgB;;AAElB;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".itemButton {\n  outline: none;\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  padding: 10px 0;\n  background-color: transparent;\n}\n.itemButton > span {\n  color: #444;\n  font-size: 14px;\n}\n.itemCategory {\n  margin-left: 10px;\n  font-weight: 400;\n}\n\n.itemCategoryTwo{\n  padding: 0;\n  padding-left: 20px;\n}\n\n.itemTitle {\n  margin-left: 0;\n  font-weight: 600;\n\n}\n.itemSubTitle {\n  font-weight: 600;\n  padding-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemButton": "listMenu_itemButton__8Y3cA",
	"itemCategory": "listMenu_itemCategory__iC54I",
	"itemCategoryTwo": "listMenu_itemCategoryTwo__de4LN",
	"itemTitle": "listMenu_itemTitle__H3inQ",
	"itemSubTitle": "listMenu_itemSubTitle__41n31"
};
export default ___CSS_LOADER_EXPORT___;
