// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".paginacion_contenedor__2JiBb {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  gap: 10px;\n}\n.paginacion_pageNumber__OC\\+yy {\n  /* background-color: #f0f0f0; */\n  color: #333;\n  background-color: transparent;\n  border: none;\n  padding: 5px 10px;\n  margin: 0 5px;\n  cursor: pointer;\n}\n\n.paginacion_currentPage__k9jrw {\n  background-color: transparent;\n  color: #dd8093;\n  border: none;\n}\n.paginacion_btnAumentar__Yqstj {\n  background-color: transparent;\n  border: none;\n}\n", "",{"version":3,"sources":["webpack://./src/homeComun/components/Pagination/paginacion.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,+BAA+B;EAC/B,WAAW;EACX,6BAA6B;EAC7B,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,cAAc;EACd,YAAY;AACd;AACA;EACE,6BAA6B;EAC7B,YAAY;AACd","sourcesContent":[".contenedor {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  gap: 10px;\n}\n.pageNumber {\n  /* background-color: #f0f0f0; */\n  color: #333;\n  background-color: transparent;\n  border: none;\n  padding: 5px 10px;\n  margin: 0 5px;\n  cursor: pointer;\n}\n\n.currentPage {\n  background-color: transparent;\n  color: #dd8093;\n  border: none;\n}\n.btnAumentar {\n  background-color: transparent;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contenedor": "paginacion_contenedor__2JiBb",
	"pageNumber": "paginacion_pageNumber__OC+yy",
	"currentPage": "paginacion_currentPage__k9jrw",
	"btnAumentar": "paginacion_btnAumentar__Yqstj"
};
export default ___CSS_LOADER_EXPORT___;
