import React, { useEffect } from "react";
import { Card } from "../../../homeComun/components/Cards/Card";
import styles from "../../../HomeDistribuidor/components/Ecomerce/Ecomerce.module.css";
import { useState } from "react";
import axios from "axios";
import { Paginacion } from "../../../homeComun/components/Pagination/Paginacion";
import { useSelector } from "react-redux";
import bannerEcomerce from "../../../../src/assets/img/Banner/Mesa de trabajo 10.png";

export const Ecommerce = () => {
  const { userType } = useSelector(
    (state) => state.auth
  );
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/productos?page=${currentPage}&pageSize=9`
    );
    setData(response.data.detalle);
    setTotalPages(response.data.totalPages);
    setIsLoading(false);
  };
  const handlePageChange = (newPage) => {
    if (!isLoading) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage]);

  return (
    <>
      {/* filtro */}
      {userType === "DIS" && (
        <div className={styles.fondoEcommerce}>
          <img src={bannerEcomerce} alt="" style={{ width: "100%" }} />
        </div>
      )}
      {isLoading ? (
        <h2 className={styles.cargandoo}>Cargando.....</h2> // Muestra esto mientras los datos están cargando
      ) : (
        <div className={styles.contenedor}>
          <div className={styles.contenedorCards}>
            {data?.map((elem, index) => (
              <Card
                key={index}
                data={elem}
                agregar="pedido"
                cantidadProp={userType === "DIS" ? true : false}
                detalle={true}
                promociones={true}
              />
            ))}
          </div>
          <Paginacion
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};
