import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card } from "../Cards/Card";
import axios from "axios";

export const InstitutoEspañol = ({ pedidos, onPedido }) => {
  const [data, setData] = useState();
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };
  const getData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/productos/marca?id=117&page=1&pageSize=4`
    );
    setData(response.data.detalle);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <h2 className="titulo-español">
        Descubre los mejores productos <br />
        <strong>de Instituto Español</strong>
      </h2>
      <div className="contenedor-btn-español">
        <Link
          onClick={handleScrollToTop}
          className="español-btn"
          to={"/ver-todo-productos"}
        >
          Ver todos los productos
        </Link>
      </div>
      <div className="contenedor-cards-español">
        {data === "No hay datos" || !data
          ?  <p style={{ color: "#dd8093", fontSize: "30px", marginTop: "2rem" }}>Cargando...</p> 
          : data.map((ele, index) => (
            <Card
              key={index}
              data={ele}
              pedidos={pedidos}
              onPedido={() => onPedido()}
            />
          ))}
      </div>
    </>
  );
};
