import axios from 'axios';
import React, { useEffect, useState } from 'react'
import styles from "./Categoria.module.css"
import { Card } from '../../../homeComun/components/Cards/Card';
import { useSelector } from 'react-redux';
import { PaginacionCategoria } from './PaginacionCategoria';
import { useCustomQueryParams } from '../../../hooks/useCustomQueryParams';

export const Categoria = ({ categoria }) => {

    const { currentPage, setSearchParams } = useCustomQueryParams()

    const [dataDoble, setDataDoble] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Estado para controlar si se están cargando los datos
    const { isAuthenticated, token, user } = useSelector((state) => state.auth);
    const [currentPages, setCurrentPages] = useState(currentPage || 1);
    const [pedidos, setPedidos] = useState([]);
    const [totalPage, setTotalPage] = useState(0);

    const getPedidos = async () => {
        if (isAuthenticated) {
            
            const res = await axios.get(
                `${process.env.REACT_APP_API_URL}/pedidos/user/${user.email ?? user.cliente_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token ?? ""}`,
                    },
                }
            );
            setPedidos(res.data);
        }
    };

    const getBienestarCuidado = async ({pageNumber = 1}) => {
        try {
            const page = currentPage || pageNumber
            const response = await axios.all([
                axios.get(`${process.env.REACT_APP_API_URL}/productos/categoria?id=05&page=${page}&pageSize=4`),
                axios.get(`${process.env.REACT_APP_API_URL}/productos/categoria?id=07&page=${page}&pageSize=5`)
            ]);
            const [categoria05, categoria07] = response;
            const datosConcatenados = [...categoria05.data.detalle, ...categoria07.data.detalle];
            const maxValue = Math.max(categoria05.data.totalPages, categoria07.data.totalPages);
            setTotalPage(maxValue)

            setDataDoble(datosConcatenados);
        } catch (error) {
            console.error("Error al obtener los productos:", error);
            throw error;
        } finally {
            setIsLoading(false); // Indicar que la carga ha finalizado
        }
    };

    useEffect(() => {
        if (categoria === "05") {
            getBienestarCuidado({pageNumber: currentPage || 1});
        }
    }, []);

    useEffect(() => {
        getPedidos();
    }, []);

    const pagesToShow = Math.min(totalPage, 9);

    const handlePageChange = (pageNumber) => {
        setCurrentPages(pageNumber);
        getBienestarCuidado({pageNumber});
        setSearchParams({
            page: pageNumber,
          })
    };

    return (
        <div className={styles.contenedorPrincipal}>
            {isLoading ? ( // Mostrar un mensaje de "Cargando..." mientras se cargan los datos
                <div className={styles.cargandoo}>Cargando...</div>
            ) : (
                <>
                    <div className={styles.contenedor}>
                        {dataDoble && dataDoble.map((elem, index) => (
                            <Card
                                key={index}
                                data={elem}
                                agregar="pedido"
                                pedidos={pedidos}
                                onPedido={getPedidos}
                            />
                        ))}
                    </div>
                    <PaginacionCategoria
                        onPageChange={handlePageChange}
                        currentPage={Number(currentPages)}
                        totalPages={totalPage}
                        pagesToShow={pagesToShow}
                    />
                </>
            )}
        </div>
    );
}
