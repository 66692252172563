import React, { useState } from "react";
import { useGetBorrador } from "../../hooks/use-getBorrador";
import { useGetCategories } from "../../hooks/use-getCategories";
const BorradorContext = React.createContext();

export const BorradorContextProvider = ({ children }) => {
  const { borrador, getBorrador, setBorrador, loadingBorrador } = useGetBorrador();
  const [cardLoading, setCardLoading] = useState(false);
  const {
    subLineasPorCategoria,
    obtenerSubLineasDisponibles,
    setSubLineasPorCategoria,
  } = useGetCategories();
  return (
    <BorradorContext.Provider
      value={{
        borrador,
        cardLoading,
        getBorrador,
        setBorrador,
        setCardLoading,
        loadingBorrador,
        subLineasPorCategoria,
        setSubLineasPorCategoria,
        obtenerSubLineasDisponibles,
      }}
    >
      {children}
    </BorradorContext.Provider>
  );
};

export const useBorrador = () => {
  const context = React.useContext(BorradorContext);
  if (!context) {
    throw new Error(
      "useBorrador must be used within a BorradorContextProvider"
    );
  }
  return context;
};
