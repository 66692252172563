import React, { useEffect, useState } from "react";
import styles from "./Cards.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useBorrador } from "../../../state/context/borradorContext";
import { useValidateCreditCart } from "../../../hooks/use-validateCreditCart";
import { useNavigate } from "react-router-dom";
import { redondearDecimales } from "../../../hooks/use-redondearDecimales";
import { logout } from "../../../store/slices/authSlice";

export const Card = ({
  data,
  agregar = "carrito",
  detalle = true,
  waitPedidos = false,
  promociones = false,
}) => {
  const { isAuthenticated, token, userType, user } = useSelector(
    (state) => state.auth
  );
  const [pedidos, setPedidos] = useState()
  const { borrador } = useBorrador();
  const [hovered, setHovered] = useState(false);
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const [cantidad, setCantidad] = useState(1);
  const [loading, setLoading] = useState(false);
  const { setCardLoading, cardLoading } = useBorrador();
  const [pedidoId, setPedidoId] = useState()
  const [cantidadIngresada, setCantidadIngresada] = useState("1");
  const [descuento, setDescuento] = useState();
  const [, setCarritoGuardado] = useState([]);
  const pedidoGuardado =
    pedidos?.length > 0 ? pedidos?.[0].carrito : []

  const { validateProductCart } = useValidateCreditCart();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetCantidad = () => {
    setCantidad(1);
    setCantidadIngresada("1");
  };

  const handleIncrement = () => {
    const currentQuantity = getCurrentQuantity(data.producto_id);
    const totalQuantity = Number(cantidadIngresada) + currentQuantity;

    if (totalQuantity >= data.existencia && userType === "DIS") {
      toast.error(
        `No puedes agregar más de ${data.existencia} productos en total`
      );
    } else {
      setCantidad((prevCantidad) => Math.min(prevCantidad + 1));
      setCantidadIngresada((prevCantidad) =>
        String(Math.min(Number(prevCantidad) + 1))
      );
    }
  };

  const handleDecrement = () => {
    const currentQuantity = getCurrentQuantity(data.producto_id);
    const totalQuantity = Number(cantidadIngresada) + currentQuantity;

    if (totalQuantity > 1) {
      setCantidad((prevCantidad) => prevCantidad - 1);
      setCantidadIngresada((prevCantidad) =>
        String(Math.max(Number(prevCantidad) - 1, 1))
      );
    }
  };

  const cargarCarritoLocal = () => {
    const carrito = localStorage.getItem("carrito");
    if (carrito) {
      setCarritoGuardado(JSON.parse(carrito));
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      cargarCarritoLocal();
    }
  }, []);

  const getCurrentQuantity = (productId) => {
    const existingProduct = pedidoGuardado?.find(
      (e) => e.producto_id === productId
    );
    return existingProduct ? existingProduct.quantity : 0;
  };

  const rutasDinamicas = () =>
    detalle ? `/detalle/${data.producto_id}` : `/product/${data.producto_id}`;

  const handleInputChange = (e) => {
    const newValue = e.target.value.trim();
    const newValueNumber = Number(newValue);
    if (newValueNumber > data.existencia) {
      return toast.error(`Cantidad máxima ${data.existencia} unidades`);
    }
    if (/^\d{0,3}$/.test(newValue)) {
      setCantidadIngresada(newValue);
    }
  };

  const handleInputBlur = () => {
    if (cantidadIngresada === "" || cantidadIngresada === "0") {
      setCantidad(1); // Establecer la cantidad predeterminada como 1 si está vacía o es 0
      setCantidadIngresada("1"); // Establecer la cantidad ingresada como 1 si está vacía o es 0
    } else {
      // Si no está vacía, convertirla a un número y establecerla como cantidad
      setCantidad(parseInt(cantidadIngresada, 10));
    }
  };
  // console.log(data);



  const formaPago = () => {
    if (userType === "DIS") {
      return user?.tipo_cre === "CONTADO" ? "Contado" : "Crédito";
    }
    return null; // Opcional, por si `userType` no es "DIS"
  };

  const paymentType = formaPago()
  const isDistributor = userType === "DIS";

  const handleGetDataPedidos = async () => {


    const handleLogout = () => {
      dispatch(logout());
      navigate("/rol-registro");
    };



    try {
      const resPedido = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${isDistributor ? user?.cliente_id : user?.email}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      setPedidos(resPedido?.data);
      if (resPedido.data.length > 0) {
        const pedidoId = resPedido.data[0]._id;
        setPedidoId(pedidoId); // Guardamos el pedidoId en el estado
        return pedidoId;
      }
    } catch (error) {
      if (
        error.response?.status === 400 &&
        error.response?.data?.response === "Token is invalid"
      ) {
        toast.error("Su sesión ha expirado. Por favor, vuelva a iniciar sesión.");
        handleLogout();
      } else {
        console.error("Error al obtener los pedidos:", error);
      }
    }

    return null;
  };




  const handleCarrito = async (value) => {
    setLoading(true);
    setCardLoading(true);

    try {
      let currentPedidoId = pedidoId; // Usamos el valor de pedidoId si ya existe en el estado

      if (isAuthenticated) {
        currentPedidoId = await handleGetDataPedidos() || pedidoId; // Esperamos la respuesta de handleGetDataPedidos si no existe en el estado
      }





      if (userType && borrador && paymentType !== "Crédito") {
        toast.error(
          "Tu pedido está en proceso. Para continuar, se debe finalizar el proceso"
        );
        resetCantidad();
        return;
      }

      if (userType === "DIS") {
        const { esLimiteSuperado, excedente } = await validateProductCart(
          value.producto_id,
          (value.precio_DIS || value.psrecio_DIS) * cantidad
        );

        if (esLimiteSuperado) {
          toast.error(
            `No puedes agregar más del crédito permitido. Te queda restante ${excedente.toFixed(
              2
            )} de crédito.`
          );

          resetCantidad();
          return;
        }
      }

      let body = {
        nameItem: value.descripcion_prod,
        itemId: value.producto_id,
        categoria: value.categoria,
        priceCatalogo: value.precio_CON,
        price:
          userType === "DIS"
            ? value.precio_DIS || value.psrecio_DIS
            : value.precio_CON,
        description: value.descripcion_prod,
      };

      if (value.existencia > body.quantity) {
        toast.error(
          `No puedes agregar más de la existencia del producto, producto existente ${value.existencia}`
        );
        resetCantidad();
        return;
      }

      if (isAuthenticated) {
        if (cantidadIngresada >= 1) {
          body.quantity = parseInt(cantidadIngresada, 10);
        } else {
          throw new Error("La cantidad es requerida");
        }
      } else {
        body.quantity =
          cantidadIngresada >= 1 ? parseInt(cantidadIngresada, 10) : 1;
      }

      if (Array.isArray(pedidoGuardado) && pedidos?.length > 0) {
        const existingProduct = pedidos[0].carrito.find(
          (prod) => prod.producto_id === body.itemId
        );
        let totalQuantity = body.quantity;
        if (existingProduct) {
          totalQuantity += existingProduct.quantity;
        }

        if (userType === "DIS" && totalQuantity > value.existencia) {
          const remainingQuantity =
            value.existencia - (existingProduct ? existingProduct.quantity : 0);
          toast.error(
            `No puedes agregar más de ${value.existencia} unidades. Te faltan ${remainingQuantity} unidades para llegar al límite.`
          );
          resetCantidad();
          return;
        }
      }

      const existencia = parseInt(value.existencia, 10);
      if (body.quantity > existencia) {
        toast.error(
          `No hay suficiente existencia. Solo puedes agregar hasta ${existencia} unidades.`
        );
        resetCantidad();
        return;
      }

      if (!isAuthenticated) {
        const carritoGuardadoLocalStorage =
          JSON.parse(localStorage.getItem("carrito")) || [];

        const existingItemIndex = carritoGuardadoLocalStorage.findIndex(
          (item) => item.itemId === value.producto_id
        );

        if (existingItemIndex !== -1) {
          const newQuantity =
            carritoGuardadoLocalStorage[existingItemIndex].quantity +
            body.quantity;
          if (newQuantity > existencia) {
            toast.error(
              `No hay suficiente existencia. Solo puedes agregar hasta ${existencia} unidades.`
            );
            resetCantidad();
            return;
          } else {
            carritoGuardadoLocalStorage[existingItemIndex].quantity = newQuantity;
          }
        } else {
          body = {
            nameItem: value.descripcion_prod,
            itemId: value.producto_id,
            categoria: value.categoria,
            price_catalogo: value.precio_CON,
            price_con: value.precio_CON,
            price_dis: value.precio_DIS || value.psrecio_DIS,
            description: value.descripcion_prod,
            quantity:
              cantidadIngresada >= 1 ? parseInt(cantidadIngresada, 10) : 1,
            imagen: value.imagen,
          };

          carritoGuardadoLocalStorage.push(body);
        }

        localStorage.setItem(
          "carrito",
          JSON.stringify(carritoGuardadoLocalStorage)
        );

        toast.success(
          "Producto agregado al carrito. Recuerda iniciar sesión para completar tu pago del carrito"
        );
        resetCantidad();
        return;
      } else {
        let url;

        if (currentPedidoId) {
          url = `${process.env.REACT_APP_API_URL}/pedidos/car/add-item`;
          body = { idPedido: currentPedidoId, ...body };
        } else {
          url = `${process.env.REACT_APP_API_URL}/pedidos/car`;
        }

        try {
          let res = await axios.post(url, body, {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          });

          if (res.status === 200) {
            setCantidad(1);
            toast.success("Producto agregado al pedido");
            resetCantidad();
          }
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error(
          "No hay existencias disponibles de este producto. No se pueden añadir más unidades a tu carrito."
        );
      } else {
        console.error("Error al agregar producto al carrito:", error);
      }
      resetCantidad();
    } finally {
      resetCantidad();
      setLoading(false);
      setCardLoading(false);
    }
  };


  const truncateDescription = (text, maxLength) => {
    if (text?.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    } else {
      return text;
    }
  };



  let precioConDescuento;

  if (data.producto_id === "1054008") {
    precioConDescuento = data.promocion.precio_regular;
    // console.log(precioConDescuento);
  } else {
    if (data.tipo_incentivo_id === "p") {
      precioConDescuento = data?.precio_CON * (1 + descuento / 100);
    }
  }
  const tieneDescuento =
    data.tipo_incentivo_id === "p" || data.producto_id === "1054008";


  const precioRegularLimpio = redondearDecimales(Number(precioConDescuento), 2);

  useEffect(() => {
    if (data.tipo_incentivo_id === "p") {
      // Obtener el precio de descuento, considerando si es un usuario distribuidor o no
      const precioDIS = parseFloat(data.precio_DIS || data.psrecio_DIS); // Se usa data en lugar de producto
      const precioCON = parseFloat(data.precio_CON);

      // Calcular la diferencia entre el precio CON y el precio DIS
      const diferencia = precioCON - precioDIS;

      // Calcular el porcentaje de descuento y redondearlo hacia abajo
      const porcentajeDescuento = Math.floor((diferencia / precioCON) * 100);
      setDescuento(porcentajeDescuento);
    }

  }, [data]);




  const currentPath = window.location.pathname;
  // console.log(data);


  return (
    <div className={styles.contenedor}>
      <div className={styles.contenido}>
        <div className={styles.imagenContenedor}>
          {/* {descuento && ( Mostrar el descuento si el producto lo tiene */}
          {
            data?.promocion?.tipo_promocion && (
              <div className={styles.promociones}>
                <p className={styles.descuento}>
                  {data?.promocion.tipo_promocion === "CANT" &&
                    `Super promo`}


                  {data?.promocion.tipo_promocion === "COMB" &&
                    `Super promo`}

                  {data?.promocion.tipo_promocion === "DESC" &&
                    `${data?.promocion.descuento} descuento`}
                </p>


              </div>
            )}

          {/* )} */}

          <Link
            onClick={handleScrollToTop}
            className={styles.ver}
            to={rutasDinamicas()}
            state={{ data: { ...data }, ruta: { currentPath } }}
          >
            <img
              onClick={handleScrollToTop}
              className={styles.imagen}
              src={`${process.env.REACT_APP_URL_IMG}/${data.imagen[0]}`}
              alt=""
            />
          </Link>
        </div>

        <p
          className={styles.descripcion}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {hovered
            ? data.descripcion_prod
            : truncateDescription(data.descripcion_prod, 21)}
        </p>
        <div className={styles.contenedorPrecio}>
          <p className={styles.precio}>
            $
            {userType === "DIS"
              ? data.precio_DIS || data.psrecio_DIS
              : data.precio_CON}
          </p>

          {userType !== "DIS" &&
            tieneDescuento &&
            data.promocion.tipo_promocion !== "CANT" && (
              <p>Precio Regular: ${precioRegularLimpio} </p>
            )}
          {userType === "DIS" && (
            // <p className={styles.price}>${data.precio_CON}</p>
            <span className={styles.precioAnterior}>
              <p className={styles.price}>${data.precio_CON}</p>
              <p className={styles.precioAnteriorText}>Precio de catálogo:</p>
            </span>
          )}
        </div>
      </div>
      <div className={styles.botones}>
        {userType === "DIS" ? (
          <div className={styles.cantidadDis}>
            <button className={styles.botonCantidads} onClick={handleDecrement}>
              -
            </button>
            <input
              type="tel"
              pattern="[0-9]*"
              inputMode="numeric"
              maxLength="3"
              required
              value={cantidadIngresada}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              className={styles.inputCantidad}
            />
            <button className={styles.botonCantidads} onClick={handleIncrement}>
              +
            </button>
          </div>
        ) : (
          // Aca quiero la funcionalidad el numero que ingresa el usuario se pueda aumentar y decrementar
          <div className={styles.cantidad}>
            <button className={styles.botonCantidads} onClick={handleDecrement}>
              -
            </button>
            <span className={styles.cantidadnumero}>{cantidad}</span>
            <button
              className={styles.botonCantidads}
              onClick={handleIncrement}
            // onChange={handleInputChange}
            >
              +
            </button>
          </div>
        )}
        <button
          className={styles.agregar}
          onClick={() => !loading && handleCarrito(data)}
          disabled={loading || cardLoading || waitPedidos}
        >
          {loading ? "Agregando..." : `Agregar al ${agregar}`}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g className={styles.strokes} stroke="white">
              <path
                d="M14.0531 4.66723C13.8179 4.26047 13.3877 4.00889 12.9198 3.99948H4.38723L4.00163 2.49236C3.92169 2.19375 3.64425 1.9892 3.33389 1.9986H2.00075C1.63161 1.9986 1.33301 2.29721 1.33301 2.66635C1.33301 3.03549 1.63161 3.33409 2.00075 3.33409H2.82838L4.66938 10.1738C4.74932 10.4724 5.02676 10.6769 5.33712 10.6675H11.3374C11.589 10.6675 11.8171 10.5241 11.9299 10.3007L14.1165 5.92748C14.3093 5.52307 14.2858 5.05048 14.0554 4.66723H14.0531ZM10.9189 9.33438H5.83793L4.75167 5.33497H12.9198L10.9189 9.33438Z"
                fill="white"
              />
              <path
                className={styles.svg}
                d="M5.001 13.9991C5.55288 13.9991 6.00027 13.5518 6.00027 12.9999C6.00027 12.448 5.55288 12.0006 5.001 12.0006C4.44913 12.0006 4.00174 12.448 4.00174 12.9999C4.00174 13.5518 4.44913 13.9991 5.001 13.9991Z"
                fill="white"
              />
              <path
                d="M11.6672 13.9991C12.2191 13.9991 12.6665 13.5518 12.6665 12.9999C12.6665 12.448 12.2191 12.0006 11.6672 12.0006C11.1154 12.0006 10.668 12.448 10.668 12.9999C10.668 13.5518 11.1154 13.9991 11.6672 13.9991Z"
                fill="white"
              />
            </g>
          </svg>
        </button>
      </div>
    </div>
  );
};
