import * as React from "react";
const SvgInstagam = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={39}
    height={39}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.438}
      d="M19.5 26a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Z"
    />
    <path
      stroke="#fff"
      strokeWidth={2.438}
      d="M4.875 26V13A8.125 8.125 0 0 1 13 4.875h13A8.125 8.125 0 0 1 34.125 13v13A8.125 8.125 0 0 1 26 34.125H13A8.125 8.125 0 0 1 4.875 26Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.438}
      d="m28.438 10.579.016-.019"
    />
  </svg>
);
export default SvgInstagam;
