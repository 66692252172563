import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
    currentPage: 1,
    totalPages: 1,
    isLoading: false,
    subCategoria: "",
  },
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    },
    setSubCategoria(state, action) {
      state.categoria = action.payload;
    },
  },
});

export const {
  setProducts,
  setCurrentPage,
  setTotalPages,
  setLoading,
  reset,
  setSubCategoria,
} = productSlice.actions;
export default productSlice.reducer;
