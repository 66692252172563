import React from "react";
import style from "../../../css/Despliegue.module.css";

export const PreguntasDespliegue = ({ pregunta, respuesta, vineta, desplegada, cambiarDespliegue, url, esFactura }) => {
  const handleClick = () => {
    cambiarDespliegue();
  };

  return (
    <div className={`${style.contenedor} ${esFactura ? style.facturaEspecial : ""}`}> {/* Aplica clase especial */}
      <div className={style.contenedorPregunta} onClick={handleClick}>
        <p className={style.parrafo}>{pregunta.toUpperCase()}</p>

        {desplegada ? (
          <svg
            className="ml-15 cursor-pointer"
            width="12"
            height="5"
            viewBox="0 0 12 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.479167 0.562499H11.0417V4.35417H0.479167V0.562499Z"
              fill="black"
            />
          </svg>
        ) : (
          <svg
            className="ml-15 cursor-pointer"
            width="21"
            height="22"
            viewBox="0 0 21 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            cursor="pointer"
          >
            <path
              d="M20.1667 12.7083H11.8958V21.4375H8.25V12.7083H0.0208335V9.33333H8.25V0.624998H11.8958V9.33333H20.1667V12.7083Z"
              fill="black"
            />
          </svg>
        )}
      </div>
      {desplegada && (
        <div className={`${style.contenedorRespuesta} ${esFactura ? style.respuestaEspecial : ""}`}>
          {url ? (
            <img src={`${process.env.REACT_APP_URL_IMG_PREGUNTAS_FRECUENTES}` + url} alt="Imagen de respuesta" className={style.imagenRespuesta} />
          ) : (
            vineta ? (
              <ul>
                {respuesta.split("\n").map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            ) : (
              <ul>
                {respuesta.split("\n").map((item, index) => (
                  <li className={style.li} key={index}>{item}</li>
                ))}
              </ul>
            )
          )}
        </div>
      )}
    </div>
  );
};
