import React from "react";
import Slider from "react-slick";
import style from "../../../Blog/Blog.module.css";
import { articlesBanner } from "../../../../../constants/post";
import { Link } from "react-router-dom";

export const SingleSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: (i) => <p className={style.dotsColor} />,
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };

  return (
    <div className={style.contenedorGenerals}>
      <div className={style.singleSliderContainer}>
        <Slider {...settings}>
          {articlesBanner.length > 0 &&
            articlesBanner.map((article) => (
              <div key={article.title} className={style.containerBanner}>
                <img
                className={style.img}
                  src={article.banner}
                  alt={`Banner del articulo ${article.title}`}
                />
                <Link
                  onClick={handleScrollToTop}
                  to={"/detalle-blog"}
                  state={{ article: article }}
                  className={ article.title === 'Protector solar' ? style.contenedorBtnBannerFirst : style.contenedorBtnBannerSecond}
                >
                  Leer más
                </Link>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};
