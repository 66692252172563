import React from 'react';
import Container from 'react-bootstrap/Container';
import styles from './SectionFilters.module.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

export const SectionFilters = ({ todasLasSubLineasArray, handleSubLineaClick, subLineaSeleccionada, nombreCategoria }) => {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
    <Container>
      <h3 className={styles.titleFilters}>{ nombreCategoria }</h3>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          {
            todasLasSubLineasArray.map((subLinea, index) => {
            const subCategorySelectedId = subLineaSeleccionada
            return (
              <div key={index} className={styles.filtroSubLinea}>
                <button
                  className={`${styles.subLineas} ${subLinea.sublinea_id === subCategorySelectedId
                    ? styles.subLineaSeleccionada
                    : ""
                    }`}
                  onClick={() => handleSubLineaClick(subLinea)}
                >
                  {subLinea.descripcion_sublinea.charAt(0).toUpperCase() + subLinea.descripcion_sublinea.slice(1).toLowerCase()}
                </button>
              </div>
            )})
          }
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}
