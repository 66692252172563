import React, { useState } from "react";
import styles from "./MensajeFlotante.module.css";
import { toast } from "react-toastify";

export const ChatComponent = ({
  title = "¿Quieres mantenerte actualizado de nuestras novedades?",
  subtitle = "Envíanos tu teléfono",
  boton = false,
}) => {
  const [showChat, setShowChat] = useState(true);
  const [showForm, setShowForm] = useState(false); // Estado para controlar la visibilidad del formulario
  const [nombreCompleto, setNombreCompleto] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [showGratitude, setShowGratitude] = useState(false); // Estado para controlar la visibilidad del mensaje de agradecimiento
  const [enviado, setEnviado] = useState(false);

  const toggleChat = () => {
    setShowChat(!showChat);
    localStorage.setItem("chatClosed", String(!showChat));
  };

  const handleMostrarFormulario = () => {
    setShowForm(true); // Cambiar el estado para mostrar el formulario
  };

  const handleEnviarMensaje = async (e) => {
    e.preventDefault();
    // Verificar si algún campo está vacío
    if (!nombreCompleto || !telefono || !email) {
      toast.error("Por favor, completa todos los campos del formulario", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Detener el envío del formulario si algún campo está vacío
    }

    try {
      const formData = {
        nombre: nombreCompleto,
        telefono: telefono,
        email: email,
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clientes/newsletter`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        setShowGratitude(true); // Mostrar el mensaje de agradecimiento
        setNombreCompleto("");
        setTelefono("");
        setEmail("");
        setEnviado(true);
      } else {
        toast.error("Error al enviar el formulario", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  return (
    <>
      {showChat ? (
        <div className={styles.contenedorPopupRosa}>
          <div
            className={`${styles.contenedorChats} ${showChat ? styles.activo : ""
              }`}
          >
            <div className={styles.contenedorChat}>
              {/* Mostrar el formulario solo si showForm es true */}
              {!showForm && (
                <>
                  <div onClick={toggleChat} className={styles.x}>
                    x
                  </div>
                  <div className={styles.contenedorDescripcion}>
                    <div className={styles.contenedorVacio}></div>
                    <div className={styles.contenedorMitad}>
                      <h3 className={styles.tituloChat}>{title}</h3>
                      <p className={styles.ayudartee}>{subtitle}</p>
                      <button
                        className={styles.botonTrue}
                        onClick={handleMostrarFormulario}
                      >
                        Haz click
                      </button>
                    </div>
                  </div>
                </>
              )}
              {showForm && (
                <div
                  className={`${styles.formularioContainer} ${showForm ? styles.activo : ""
                    }`}
                >
                  <div onClick={toggleChat} className={styles.x}>
                    x
                  </div>
                  <div className={styles.contenedorFormularioPrincipal}>
                    <div className={styles.contenedorVacio}></div>
                    <div className={styles.contenedorFormularioContenedor}>
                      <h3 className={styles.tituloChat}>Novedades Vogue</h3>
                      {enviado === false ? (
                        <form
                          onSubmit={handleEnviarMensaje}
                          className={styles.contenedorFormulario}
                        >
                          <input
                            className={styles.inputsForm}
                            type="text"
                            placeholder="Nombre completo"
                            value={nombreCompleto}
                            onChange={(e) => setNombreCompleto(e.target.value)}
                          />
                          <input
                            className={styles.inputsForm}
                            type="text"
                            placeholder="Teléfono"
                            value={telefono}
                            onChange={(e) => setTelefono(e.target.value)}
                          />
                          <input
                            className={styles.inputsForm}
                            type="email"
                            placeholder="Correo electrónico"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <button type="submit" className={styles.btnEnviar}>
                            Enviar
                          </button>
                        </form>
                      ) : (
                        <div
                          className={`${styles.formularioContainer} ${styles.activo}`}
                        >
                          <div onClick={toggleChat} className={styles.x}>
                            x
                          </div>
                          <h3 className={styles.tituloChat}>
                            Gracias por ser parte de nuestra comunidad
                          </h3>
                          <p className={styles.parrafotexto}>
                            Muy pronto recibirás noticias
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {showGratitude && (
                <div
                  className={`${styles.formularioContainer} ${showGratitude ? styles.activo : ""
                    }`}
                >
                  <div onClick={toggleChat} className={styles.x}>
                    x
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
