import React, { useEffect, useState } from "react";
import styles from "./Carrito.module.css";
import SvgCheckVerde from "../../../assets/svg/CheclistVerde";
import SvgCheckError from "../../../assets/svg/ChecklistError";
import { useSelector } from "react-redux";
import axios from "axios";

export const CarritoValidacion = ({ validacionLongitud, totalProducto }) => {
  const { token, user } = useSelector((state) => state.auth);
  const [allInvoice, setAllInvoice] = useState([]);

  const handleGetInvoice = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      const invoiceList = res?.data?.detalle?.lista_facturas ?? [];
      setAllInvoice(invoiceList);
    } catch (error) {
      console.error(error);
      setAllInvoice([]); // Set to empty array in case of an error
    }
  };

  useEffect(() => {
    handleGetInvoice();
  }, []);

  return (
    <div className={styles.contenedorGeneral}>
      <div className={styles.contenedorRequisito}>
        <p className={styles.titulo}>Para proceder a tu compra debes de :</p>
        <div className={styles.contenedors}>
          <div className={styles.columns}>
            <div className={styles.iconotexto}>
              <div className={styles.checkIcon}>
                {allInvoice.length > 0 ? <SvgCheckError /> : <SvgCheckVerde />}
              </div>
              <p className={styles.parrafo}>Estar al día con tus pagos</p>
            </div>
            <div className={styles.iconotexto2}>
              <div className={styles.checkIcon}>
                {totalProducto ? <SvgCheckVerde /> : <SvgCheckError />}
              </div>
              <p className={styles.parrafo}>Tu pedido debe ser mayor a $65</p>
            </div>
            <div className={styles.iconotexto3}>
              <div className={styles.checkIcon}>
                {validacionLongitud <= 35 ? (
                  <SvgCheckVerde />
                ) : (
                  <SvgCheckError />
                )}
              </div>
              <p className={styles.parrafo}>Menos de 35 items en tu pedido</p>
            </div>
            <div className={styles.iconotexto}>
              <div className={styles.checkIcon}>
                <SvgCheckVerde />
              </div>
              <p className={styles.parrafo}>
                Factura de campaña anterior cancelada
              </p>
            </div>
          </div>
          <div className={styles.lineaVertical}></div>
          <div className={styles.columns2}>
            <div className={styles.iconotexto}>
              <div className={styles.checkIcon}>
                {user.letra_cambio.length > 0 ? (
                  <SvgCheckVerde />
                ) : (
                  <SvgCheckError />
                )}
              </div>
              <p className={styles.parrafo}>Tener letra de cambio vigente</p>
            </div>
            <div className={styles.iconotexto2}>
              <div className={styles.checkIcon}>
                <SvgCheckVerde />
              </div>
              <p className={styles.parrafo}>
                Mensajes bloqueados (Comunícate al 2261-8181)
              </p>
            </div>
            <div className={styles.iconotexto3}>
              <div className={styles.checkIcon}>
                <SvgCheckVerde />
              </div>
              <p className={styles.parrafo}>
                Sin calificación (Comunícate al 2261-8181)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
