import React, { useEffect, useState } from "react";
import styles from "../../../components/homeCliente/components/Perfil/Perfil.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FormularioCambioDatos } from "../../../components/homeCliente/components/Perfil/FormularioCambioDatos";
import { FaCheckCircle } from "react-icons/fa";
import { ButtonClosedForm } from "../../../homeComun/components/ButtonClosedForm";
import { toast } from "react-toastify";
import axios from "axios";
import { useLoading } from "../../../state/context/loadingContext";
import { redondearDecimales } from "../../../hooks/use-redondearDecimales";
import { Spinner } from "react-bootstrap";
import { CambioContraseña } from "./CambioContraseña";



export const DatosGeneralesDistribuidor = () => {
  const [actualizar, setActualizar] = useState(true)
  const [cambioNombre, setCambioNombre] = useState(false)
  const [cambioContraseña, setCambioContraseña] = useState(false)
  const { token, user, isAuthenticated, userType } = useSelector((state) => state.auth);
  const navigate = useNavigate()
  const [thankYouPopupOpen, setThankYouPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false); // Estado para controlar la visibilidad del formulario
  const [popupOpen, setPopupOpen] = useState(false);
  const [serfinsa, setSerfinsa] = useState(null);
  const [formData, setFormData] = useState({
    password: '',
    password_new: '',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    // watch,
  } = useForm();
  const [allInvoice, setAllInvoice] = useState([]);
  const { isLoading } = useLoading();
  const location = useLocation();
  const message = location.state?.message;

  useEffect(() => {
    formaPago()
  }, [])


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // console.log(user?.tipo_cre);

  const formaPago = () => {
    if (userType === "DIS") {

      return user?.tipo_cre === "CONTADO" ? "Contado" : "Crédito";
    }

    else {
      navigate("/")
    }
  }

  let dataCard = [];

  if (userType !== "DIS") {
    navigate("/"); // Redirigir si userType no es "DIS"
  } else {
    dataCard = [
      {
        title: "Límite de crédito",
        data: formaPago() === "Contado" ? 0 : (
          redondearDecimales(
            (Number(user.limite_credito.replace(/,/g, "")) -
              Number(user.saldo_no_vencido.replace(/,/g, "")) -
              Number(user.saldo_vencido.replace(/,/g, "")) -
              Number(3.5)), 2
          )
        ),
      },
      {
        title: "Estatus de cuenta",
        data: user.estatus,
      },
      {
        title: "Forma de pago",
        data: formaPago(),
      },
      {
        title: "Facturación por temporada",
        data: 300, // Valor quemado para prueba
      },
    ];
  }
  useEffect(() => {
    if (userType !== "DIS") {
      navigate("/"); // Redirigir si no es distribuidor
    }
  }, [userType, navigate])


  const [loadingGetInvoice, setLoadingGetInvoice] = useState(false);
  const closePopup = () => {
    setIsOpen(false);
  };

  const handleClosePopup = () => {
    setThankYouPopupOpen(false); // Cierra el popup de agradecimiento
    setPopupOpen(false); // Asegúrate de que el popup principal también esté cerrado
    setCambioNombre(false);
  };

  const handleGetData = async () => {
    const resPedido = await axios.get(
      `${process.env.REACT_APP_API_URL}/pedidos/user/${user.cliente_id || user.email
      }`,
      {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      }
    );

    setSerfinsa(resPedido?.data[0]._id);

  };



  useEffect(() => {
    handleGetInvoice();
    handleGetData();
    if (!isLoading && message) {
      toast.success(message);
    }
  }, []);

  const handleGetInvoice = async () => {
    setLoadingGetInvoice(true);
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setAllInvoice(res?.data?.detalle?.lista_facturas);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGetInvoice(false);
    }
  };

  useEffect(() => {
    handleGetInvoice();
    handleGetData();
    if (!isLoading && message) {
      toast.success(message);
    }
  }, []);

  useEffect(() => {
    if (thankYouPopupOpen) {
      const timer = setTimeout(() => {
        setThankYouPopupOpen(false);
      }, 5000);

      // Limpia el temporizador cuando el componente se desmonta o cuando thankYouPopupOpen cambia
      return () => clearTimeout(timer);
    }
  }, [thankYouPopupOpen]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen(false); //cambiarlo al terminar
    }, 1000);

    return () => clearTimeout(timeout); // Limpia el temporizador al desmontar el componente
  }, []);

  const handleNameSubmit = async (value) => {
    if (!/^[a-zA-Z\s]{3,}$/.test(value.newName)) {
      return toast.error("El nombre no debe poseer caracteres especiales");
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clientes/send-update-info`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            nombre: value.newName,
            tipo: userType
          }),
        }
      );

      if (response.ok) {
        setPopupOpen(false);
        setThankYouPopupOpen(true);
        reset();
        // Desmontar el componente de mensaje de satisfacción después de 5 segundos
        setTimeout(() => {
          setThankYouPopupOpen(false);
        }, 5000);
      } else {
        toast.error(
          "Ocurrió un problema de red. Por favor intentelo más tarde"
        );
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      toast.error("Ocurrió un error. Por favor intentelo más tarde");
    } finally {
      setLoading(false);
    }
  };
  // console.log(cambioContraseña);



  // console.log(userType);

  return (
    <>
      {user && user.nombres ? (
        <h2 className={styles.titulo}>Hola, {user.nombres.split(" ")[0]}</h2>
      ) : ""}
      {isOpen && (
        <div className={styles.contenedorPoput}>
          <span className={styles.close} onClick={closePopup}>
            &times;
          </span>
          <p className={styles.titulos}>
            ¿Quieres actualizar tu dirección de residencia?
          </p>
          <p className={styles.parrafo}>
            Puedes acercarte a oficinas Vogue con un recibo de agua o luz para
            realizar el cambio de dirección.
          </p>
        </div>
      )}
      <div className={styles.contenedorGenerals}>
        <div className={styles.contenedorTarjetasDistribuidor}>
          <div className={styles.ContenedorDatosGeneralesHistorial}>
            <div className={styles.informacionGeneralesCompra}>
              <Link className={styles.tituloGenerales} to={""}>
                Datos generales
              </Link>
              <div className={styles.subtitles}>
                <Link
                  className={styles.TituloHistorialCompra}
                  to={"/facturas-pendientes"}
                >
                  Facturas pendientes
                </Link>
                <Link
                  className={styles.TituloHistorialCompra}
                  to={"/estado-cuenta"}
                >
                  Estado de cuenta
                </Link>

              </div>
            </div>
          </div>
          <div className={styles.contenedorTarjeta}>
            {/* Tarjeta  */}
            <div className={styles.contenedorTarjetaInfo}>
              <button
                className={styles.editarDatos}
                onClick={() => setPopupOpen(true)}
              >
                Editar datos
              </button>
              <div className={styles.DatosTarjetas}>
                <p className={styles.datosNombre}>Nombre</p>
                <h2 className={styles.nombreTitular}>
                  {user && user.nombres.split(" ")[0]}
                </h2>
                <div className={styles.vencimientoTarejtas}>
                  <div className={styles.contenedorNumerosTarjetas}>
                    <h4 className={styles.textCod}>Código de Distribuidor:</h4>
                    <p className={styles.sexo}>{user && user.cliente_id}</p>
                  </div>


                </div>
                <div className={styles.contenedorNumerosTarjetas}>
                  <h4 className={styles.textCod}>Correo de Distribuidor:</h4>
                  <p className={styles.sexo}>{user && user.email}</p>
                </div>
              </div>
            </div>
            <div className={styles.ContenedorContactoDistribuidor}>
              <div className={styles.cajaContacto}>
                <button
                  onClick={() => setMostrarFormulario(!mostrarFormulario)}
                  className={styles.editarDatos2}
                >
                  Editar datos
                </button>
                <div className={styles.datosdeContacto}>
                  <h4 className={styles.sexo}>Dui:</h4>
                  <p className={styles.parrafoTarjeta}>{user && user.dui}</p>

                  <h4 className={styles.sexo}>Sexo:</h4>
                  <p className={styles.parrafoTarjeta}>Femenino</p>

                  <p className={styles.telefono}>Teléfono: </p>
                  <p className={styles.numero}>{user && user.cela}</p>
                  <p className={styles.telefono}>Correo: </p>
                  <p className={styles.numero}>{user && user.email}</p>
                  <p className={styles.telefono}>Fecha de nacimiento: </p>
                  <p className={styles.numero}>{user && user.cumple}</p>
                  <p className={styles.telefono}>Dirección de domicilio:</p>
                  <p className={styles.numero}>{user && user.direccion}</p>
                </div>
              </div>
              <div className={styles.DetalleContacto}>
                <h3 className={styles.tituloDetalles}>Detalles del contacto</h3>
              </div>
            </div>
            {/* Tarjeta de Credito  */}
            <div className={styles.contenedorPrincipalTarjeta}>
              <div className={styles.contenedorDatosCredito}>
                <div className={styles.cajaDatos}>Datos de crédito</div>
                <div className={styles.contenedorDatos}>
                  <div className={styles.mitad}>
                    {dataCard.length > 0 &&
                      dataCard.map(({ title, data }) => (
                        <div key={title} className={styles.contenedorCredito}>
                          <p className={styles.parrafoCredito}>{title}:</p>
                          {title !== "Facturación por temporada" && (
                            <p className={styles.inputCredito}>{data}</p>

                          )}
                        </div>
                      ))}
                  </div>
                  {dataCard.length > 0 &&
                    dataCard.map(({ title, data }) => (
                      <div key={title} className={styles.contenedorRango}>

                        {title === "Facturación por temporada" && userType === "DIS" && (
                          <>
                            <div className={styles.precio}>
                              <p className={styles.precioActual}>{user.progreso_club.progreso_club_actual}</p>
                              <p>{user.progreso_club.progreso_club_superior}</p>

                            </div>

                            <div className={styles.customProgressBar}>

                              <div
                                className={styles.progress}
                                style={{
                                  width: `${redondearDecimales((user.progreso_club.progreso_club_temporada_facturado / user.progreso_club.progreso_club_limite_superior * 100), 2)}%`
                                }}
                              />
                              <span className={styles.progressLabel}> ${`${Math.trunc(user.progreso_club.progreso_club_temporada_facturado)}`}</span>
                            </div>

                          </>
                        )}
                      </div>
                    ))}
                  <div className={styles.precio}>
                    <p>${Math.trunc(user && user.progreso_club.progreso_club_limite_inferior)}</p>
                    <p>${Math.trunc(user && user.progreso_club.progreso_club_limite_superior)}</p>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      {mostrarFormulario && userType === "DIS" && (
        <FormularioCambioDatos setMostrarFormulario={setMostrarFormulario} />
      )
      }

      {
        popupOpen && userType === "DIS" && (
          <div className={styles.conteinerPopup}>
            <div className={styles.popup}>
              <ButtonClosedForm setOpenPopup={setPopupOpen} setCambioNombre={setCambioNombre} setCambioContraseña={setCambioContraseña} />
              <div className={styles.popupInner}>

                {actualizar && (
                  <h2 className={styles.actualizarNombre}>


                    Actualización de datos</h2>

                )}

                {!cambioNombre && !cambioContraseña ? (
                  <div className={styles.elegirCambioDatos}>
                    <div className={styles.cambioNombre}>
                      <p className={styles.nombreContraseña}>Nombre</p>
                      <button
                        className={styles.cambiarDatos1}
                        onClick={() => setCambioNombre(true)}
                      >
                        Cambiar
                      </button>
                    </div>
                    <div className={styles.cambioNombre}>
                      <p className={styles.nombreContraseña}>Contraseña</p>
                      <button
                        className={styles.cambiarDatos}
                        onClick={() => setCambioContraseña(true)}
                      >
                        Cambiar
                      </button>
                    </div>
                  </div>
                ) : cambioNombre === true ? (
                  <form
                    onSubmit={handleSubmit(handleNameSubmit)}
                    className={styles.contenedorInputsBtn}
                  >
                    <input
                      className={styles.inputNombres}
                      type="text"
                      placeholder="Nuevo nombre"
                      {...register("newName", { required: true })}
                    />
                    {errors.newName && (
                      <span className={styles.messageError}>
                        Este campo es obligatorio
                      </span>
                    )}
                    <button className={styles.cambiarBtnNombre} type="submit">
                      {loading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Cargando...</span>
                        </Spinner>
                      ) : (
                        "Cambiar"
                      )}
                    </button>
                  </form>
                ) : cambioContraseña === true && (
                  <CambioContraseña setActualizar={setActualizar} setCambioContraseña={setCambioContraseña} />
                )}
              </div>
            </div>
          </div>
        )
      }



      {
        thankYouPopupOpen && userType === "DIS" && (
          <div className={styles.conteinerPopup}>
            <div className={styles.popup}>
              <svg
                onClick={handleClosePopup}
                className={styles.cerrarMensajeConfirmacion}
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Menu / Close_LG">
                  <path
                    id="Vector"
                    d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
              </svg>
              <div className={styles.popupInner}>
                <div className={styles.contenedorRepuesta}>
                  <FaCheckCircle color="green" size={50} />
                  <h2 className={styles.tituloGracias}>Gracias.</h2>
                  <p className={styles.parrafoRespuesta}>
                    En un lapso de 3 días, nuestro equipo te enviará un mensaje de
                    texto cuando tus datos estén actualizados en la página.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      }

    </>
  );
};
