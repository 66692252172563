import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import styles from "./PagoPendiente.module.css";
import { ProductsTotal } from "../homeCliente/components/ProductsTotal";
import { Cargando } from "../../homeComun/components/Cargando/Cargando";
import { useBorrador } from "../../state/context/borradorContext";
import { redondearDecimales } from "../../hooks/use-redondearDecimales";
import { toast } from "react-toastify";
import { logout } from "../../store/slices/authSlice";

export const PagoPendiente = () => {
  const [loading, setLoading] = useState(true); // Comienza en true para mostrar el spinner
  const { token, user, userType, isAuthenticated } = useSelector((state) => state.auth);
  const [serfinsa, setSerfinsa] = useState(null);
  const [pedido, setPedido] = useState(null);
  const [total, setTotal] = useState(0);
  const [envio, setEnvio] = useState(0.01);
  const dispatch = useDispatch();


  const [abonos, setAbonos] = useState(null);
  const navigate = useNavigate();
  const { borrador, loadingBorrador } = useBorrador();
  const detallesFactura =
    borrador?.detalles_factura || borrador?.detalle?.detalles_factura;

  const isDistributor = userType === "DIS";

  const calculateTotalAndShipping = () => {
    if (isAuthenticated === true) {
      let totalAmount = 0;

      let shippingCost = 0;

      if (detallesFactura) {
        detallesFactura.forEach((product) => {
          const subtotal = parseFloat(product.subtotal) || 0;

          if (product.descripcion.includes("CARGO ADMINISTRATIVO")) {
            shippingCost += subtotal;
          } else {
            totalAmount += subtotal;
          }
        });
      }

      // Verifica si el total es mayor a 50 y el userType es "CON"

      if (totalAmount > 50 && userType === "CON" && shippingCost === 0.01) {
        shippingCost = 0.01;

      }

      setEnvio(shippingCost);
      setTotal(Number(totalAmount + shippingCost, 2));
    }

  };
  // console.log(token);

  const handleGetData = async () => {
    const handleLogout = () => {
      dispatch(logout());
      navigate("/rol-registro");
    };

    try {
      const resPedido = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${user.cliente_id || user.email}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      setPedido(resPedido?.data?.[0]);
      setSerfinsa(resPedido?.data?.[0]?._id || null);
    } catch (error) {
      if (
        error.response?.status === 400 &&
        error.response?.data?.response === "Token is invalid"
      ) {
        toast.error("Su sesión ha expirado. Por favor, vuelva a iniciar sesión.");
        handleLogout();
      } else {
        console.error("Error al obtener los datos:", error);
      }
    }
  };

  const getAbonos = async () => {
    try {
      const pedido = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/pagar-despues/${user.cliente_id}`,

        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      setAbonos(pedido.data);
    } catch (error) {
      console.error("Error al obtener abonos:", error);
    } finally {
      setLoading(false); // Detenemos la carga una vez que tenemos el resultado de abonos
    }
  };

  useEffect(() => {

    if (isAuthenticated === true) {
      const fetchData = async () => {
        await handleGetData();

        if (userType === "DIS") {
          await getAbonos(); // Obtener abonos si el usuario es "DIS"
        } else {
          setLoading(false); // Si no es "DIS", detener la carga y mostrar productos directamente
        }

        calculateTotalAndShipping(); // Calcular el total y el envío
      };

      fetchData();
    }

  }, [userType]);

  const totalToPay = isAuthenticated === true &&
    isDistributor && pedido?.detailPayment?.codigoAdmon
    ? total + Number(pedido?.detailPayment?.totalEnvio)
    : total;



  // console.log("este es el total", pedido?.detailPayment?.totalEnvio);

  const totalLimpio = redondearDecimales(totalToPay, 2)

  const handleScrollToTop = async () => {

    if (isAuthenticated === true) {
      if (!serfinsa) {
        console.error("ID de pedido no disponible.");

        return;
      }

      setLoading(true);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-payment`,

          {
            pedidoId: serfinsa,
            Monto: totalLimpio,
            ConceptoPago: "Pago de cosméticos",
            Adicionales: "DATOS ADICIONALES",
            UrlOrigin:
              "https://vogueelsalvador.com/home-cliente/informacio-pago",
            UrlRedirect: "https://vogueelsalvador.com/finalizar-comprass",
          },

          {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          }
        );

        const { Satisfactorio, Datos } = res.data;

        if (Satisfactorio && Datos?.UrlPost) {
          window.location.href = `https://www.serfinsacheckout.com/${Datos.UrlPost}`;
        } else {
          console.error(
            "Pago no satisfactorio o URL de redirección no disponible."
          );
        }
      } catch (error) {
        console.error("Error al procesar el pago:", error);
      } finally {
        setLoading(false);
      }
    }

  };

  useEffect(() => {
    if (!borrador && isAuthenticated === true) {
      userType === "DIS" ? navigate("/carrito-distribuidor") : navigate("/home-cliente/carrito-cliente")
    }
  }, [borrador, navigate]);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/")
    }
  }, [])

  if (loading || loadingBorrador) {
    return <Cargando />; // Mostrar el componente de carga mientras se espera la respuesta
  }
  // console.log(totalLimpio);

  return (


    <>

      {
        isAuthenticated === true && (
          <div className={styles.containerMain}>
            {abonos && userType === "DIS" ? (
              <div className={styles.abonoMessage}>
                <h1>Tienes un abono pendiente</h1>

                <p>
                  Por favor, revisa tus facturas pendientes para completar tu pago.
                </p>

                <button
                  className={styles.BtnRedirect}
                  onClick={() => navigate("/facturas-pendientes")}
                >
                  Ir a facturas pendientes
                </button>
              </div>
            ) : (
              <>
                <div className={styles.sectionCheckout}>
                  {detallesFactura &&
                    detallesFactura

                      .filter(
                        (el) => !el.descripcion.includes("CARGO ADMINISTRATIVO")
                      )

                      .map((product) => (
                        <ProductsTotal
                          key={product.descripcion}
                          product={product}
                          userType={userType}
                          banderaCon={true}
                        />
                      ))}
                </div>
                <div className={styles.priceForShipping}>
                  <p className={styles.textSend}>
                    {userType === "CON" ||
                      pedido?.detailPayment?.codigoAdmon !== "CARADMI011"
                      ? "Envío:"
                      : "Cargo Administrativo:"}

                    {/* {userType === "CON" ? 'Envío:' : pedido?.detailPayment?.codigoAdmon === "CARADMI011" ? 'Cargo Administrativo:' : 'Envío:'} */}
                  </p>

                  <p>${pedido?.detailPayment?.totalEnvio || envio}</p>
                </div>
                <div className={styles.btnTotal}>
                  <p className={styles.productoTotal}>TOTAL:</p>

                  <p className={styles.monto}>${totalLimpio}</p>
                </div>
                <button className={styles.BtnFinalizar} onClick={handleScrollToTop}>
                  {loading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Cargando...</span>
                    </Spinner>
                  ) : (
                    "Finalizar compra"
                  )}
                </button>
              </>
            )}
          </div>
        )
      }
    </>


  );
};
