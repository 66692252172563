import axios from "axios";
import { useSelector } from "react-redux";
import { redondearDecimales } from "./use-redondearDecimales";

export const useValidateCreditCart = () => {
  const { token, user } = useSelector((state) => state.auth);

  const getCart = async () => {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/pedidos/user/${user.cliente_id}`,
      {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      }
    );

    return response.data[0]
      ? response.data[0]
      : { totalPedido: 0, carrito: [] };
  };

  const validateProductCart = async (
    productId,
    quantityToAdd,
    modificar = false
  ) => {
    const { totalPedido, carrito } = await getCart();
    const productInCart = carrito.find(
      (item) => item.producto_id === productId
    );

    const contado = user?.tipo_cre === "CONTADO" ? "Contado" : "Crédito";
    const limite = contado === "Contado" ? "1000000000" : user.limite_credito;

    let nuevoTotalPedido = totalPedido;
    if (modificar) {
      let resultadoCarrito = productInCart.price * productInCart.quantity;
      nuevoTotalPedido = totalPedido - resultadoCarrito;
    }

    let totalCarritoLimit;
    if (modificar) {
      totalCarritoLimit =
        Number(limite.replace(/,/g, "")) -
        Number(nuevoTotalPedido) -
        Number(user.saldo_no_vencido.replace(/,/g, "")) -
        Number(user.saldo_vencido.replace(/,/g, "")) -
        Number(quantityToAdd) -
        Number(3.5);
    } else {
      totalCarritoLimit =
        Number(limite.replace(/,/g, "")) -
        Number(user.saldo_no_vencido.replace(/,/g, "")) -
        Number(user.saldo_vencido.replace(/,/g, "")) -
        Number(3.5) -
        Number(nuevoTotalPedido) -
        Number(quantityToAdd);
    }

    // Asegurarse de que el valor mostrado sea siempre positivo
    const valorRestante = Math.max(redondearDecimales(totalCarritoLimit, 2));

    // Calcular el monto excedente si el límite es superado
    const excedente =
      quantityToAdd - Math.max(redondearDecimales(-totalCarritoLimit, 2));
    // Retornar el resultado de la validación, el valor restante y el excedente
    return {
      esLimiteSuperado: totalCarritoLimit < 0,
      valorRestante,
      excedente,
    };
  };

  return { validateProductCart };
};
