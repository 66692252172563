import React, { useState } from "react";
import style from "../../../css/PreguntasFrecuentes.module.css";
import { PreguntasDespliegue } from "./PreguntasDespliegue";
import { useGetFaq } from "../../../hooks/use-getFaq";

export const PreguntasFrecuentes = () => {
  const { getCliente } = useGetFaq();
  const data = getCliente();
  const [preguntaDesplegada, setPreguntaDesplegada] = useState(null);

  const cambiarDespliegue = (index) => {
    if (preguntaDesplegada === index) {
      setPreguntaDesplegada(null); // Si se hace clic en la pregunta ya desplegada, ciérrala
    } else {
      setPreguntaDesplegada(index); // De lo contrario, establece la nueva pregunta desplegada
    }
  };

  return (
    <div className={style.wrapperMain}>
      <h2 className={style.titulo}>¿Tienes preguntas?</h2>
      <div className={style.contenedor}>
        {data && data.length > 0 ? ( // Verifica que 'data' no sea nulo y que tenga contenido
          data[0].preguntas.texto.map((item, index) => (
            <PreguntasDespliegue
              key={index}
              pregunta={item.pregunta}
              respuesta={item.respuesta}
              vineta={item.vineta}
              desplegada={index === preguntaDesplegada}
              cambiarDespliegue={() => cambiarDespliegue(index)}
              url={item.url}
              esFactura={item.pregunta === "¿DÓNDE PUEDO PAGAR MI FACTURA?"}
            />
          ))
        ) : (
          <h2>Cargando....</h2> // Muestra el mensaje de "Cargando..." mientras 'data' está vacío o no cargado
        )}
      </div>
    </div>
  );
};
