import React, { useEffect, useState } from "react";
import styles from "./NuevaContraseña.module.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const NuevaContraseña = () => {
  const location = useLocation();
  const rute = location.search?.[location.search?.length - 1];
  const [token, setToken] = useState();
  const [email, setEmail] = useState();
  const [showPassword, setShowPassword] = useState(false); // Estado para alternar visibilidad de contraseña
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Estado para visibilidad de la confirmación
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    watch, // Para observar los valores de los inputs
  } = useForm(
    {
      mode: "onChange", // Las validaciones se activarán en cuanto haya algún cambio en los inputs
    }
  );

  useEffect(() => {
    trigger(); // Esto dispara todas las validaciones desde el principio
  }, [trigger])

  const navigate = useNavigate();
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    // Obtener los parámetros de la URL
    const searchParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = searchParams.get("token");
    const emailFromUrl = searchParams.get("email");
    setToken(tokenFromUrl);
    setEmail(emailFromUrl);
  }, []);

  const newPassword = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      toast.error("Las contraseñas no coinciden", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }

    try {
      const dataConTipoProceso = {
        ...data,
        tipo: rute === "S" ? "DIS" : "CON",
        code: token,
        username: email,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgot-password`,
        dataConTipoProceso,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Cambio de contraseña", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "CambioSuccess",
          style: { fontSize: "20px" },
        });
        reset();
        setTimeout(() => {
          navigate(rute === "S" ? "/login-distribuidora" : "/login-cliente");
        }, 2000);
      }
    } catch (error) {
      const mensajeError = error.response?.data?.detalle || "Algo salió mal";
      toast.error("Error al cambiar contraseña: " + mensajeError, {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: "loginError",
        style: { fontSize: "20px" },
      });
    }
  };

  return (
    <div className={styles.contenedorNuevaContraseña}>
      <div className={styles.contenedor}>
        <h2 className={styles.titulo}>Cambiar contraseña</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="102"
          height="102"
          viewBox="0 0 102 102"
          fill="none"
        >
          <path
            d="M30.1793 42.5H29.75V34C29.75 22.2639 39.2639 12.75 51 12.75C62.7361 12.75 72.25 22.2639 72.25 34V42.5H71.8207M51 59.5V68M80.75 63.75C80.75 80.1805 67.4305 93.5 51 93.5C34.5695 93.5 21.25 80.1805 21.25 63.75C21.25 47.3195 34.5695 34 51 34C67.4305 34 80.75 47.3195 80.75 63.75Z"
            stroke="#9C182F"
            strokeWidth="8.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <form
          className={styles.inpustContenedor}
          onSubmit={handleSubmit(newPassword)}
        >
          <label htmlFor="newPassword"></label>
          <div className={styles.inputGroup}>
            <input
              className={styles.inputContraseña}
              type={showPassword ? "text" : "password"} // Mostrar contraseña si showPassword es true
              placeholder="Contraseña nueva"
              {...register("newPassword", {
                required: "Contraseña es requerida",
                minLength: { value: 8, message: "Al menos 8 caracteres" },
                validate: {
                  containsUpperCase: (value) => /[A-Z]/.test(value) || "Una letra mayúscula",
                  containsLowerCase: (value) => /[a-z]/.test(value) || "Una letra minúscula",
                  containsNumber: (value) => /\d/.test(value) || "Un número",
                  containsSpecialChar: (value) => /[@#$%&]/.test(value) || "Uno de estos caracteres especiales: @, #, $, %, &",
                }
              })}
            />
            <span
              className={styles.eyeIcon}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {errors.newPassword && (
            <span className={styles.errors}>{errors.newPassword.message}</span>
          )}
          <ul className={styles.requisitosContraseña}>
            <li>Al menos 8 caracteres</li>
            <li>Una letra mayúscula</li>
            <li>Una letra minúscula</li>
            <li>Un número</li>
            <li>Uno de estos caracteres especiales: @, #, $, %, &</li>
          </ul>

          <label htmlFor="confirmPassword"></label>
          <div className={styles.inputGroup}>
            <input
              className={styles.inputContraseña}
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Repetir contraseña"
              {...register("confirmPassword", {
                required: {
                  value: true,
                  message: "Confirmar contraseña",
                },
                validate: (value) =>
                  value === watch("newPassword") || "Las contraseñas no coinciden",
              })}
            />
            <span
              className={styles.eyeIcon}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          {errors.confirmPassword && (
            <span className={styles.errors}>{errors.confirmPassword.message}</span>
          )}

          <button
            className={styles.enviar}
            type="submit"
            onClick={handleScrollToTop}
          >
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};
