import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { NavHomeCliente } from "../../../components/homeCliente/components/NavHomeCliente";
import { NavDistribuidor } from "../../../HomeDistribuidor/components/NavDistribuidor";
import { Sidebar } from "../Sidebar/Sidebar";
import styles from "./nav.module.css";
import Carrito from "../../../assets/svg/carritio";
import SvgBuscador from "../../../assets/svg/buscadorSvg";
import SvgCarrito from "../../../assets/svg/SvgCarrito";
import SvgUser from "../../../assets/svg/SvgUser";
import SvgSidebar from "../../../assets/svg/SvgSidebar";
import SvgClosedButtonSearch from "../../../assets/svg/SvgClosedButtonSearch";
import { useSidebar } from "../../../hooks/use-sidebar";
import { productos } from "../../../assets/utils/Productos";
import useWindowSize from "./useWindowSize";

export const Nav = () => {
  const navigate = useNavigate();
  const { isAuthenticated, userType } = useSelector((state) => state.auth);
  const [suggestions, setSuggestions] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const { showSidebar, handleClose, setShowSidebar, handleShow } = useSidebar();
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };
  const [query, setQuery] = useState("");
  const size = useWindowSize();
  const inputRef = useRef(null); // Referencia al input
  const wrapperRef = useRef(null);

  //correccion en la peticion


  // console.log(productos);


  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 0) {
      const filteredSuggestions = productos.filter((product) => {
        const productKeywords = product.keywords
          .toLowerCase()
          .split(",")
          .map((kw) => kw.trim());

        // Excluir productos de "118" si el userType es "CON"
        if (userType === "CON" && (product.marca_id === "118" || product.linea_id === "118")) {
          return false;
        }

        return productKeywords.some((kw) => kw.includes(value.toLowerCase()));
      });
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      buscarProductos();
      setSuggestions([]);
    }
  };

  const buscarProductos = (productoId) => {
    setSuggestions([]);
    if (productoId) {
      navigate(`/detalle/${productoId}`);
    } else {
      const productosFiltrados = productos.filter((producto) =>
        producto.descripcion_prod.toLowerCase().includes(query.toLowerCase())
      );

      const productosSimplificados = productosFiltrados.map((producto) => ({
        categoria: producto.categoria,
        descripcion_sublinea: producto.descripcion_sublinea,
        descripcion_corta: producto.descripcion_corta,
      }));

      const productosJSON = JSON.stringify(productosSimplificados);

      navigate(`/resultados?query=${query}&productos=${encodeURIComponent(productosJSON)}`);
    }
    setQuery("")
  };

  const handleClick = (product) => {
    setQuery(product.descripcion_corta);
    setSuggestions([]);
    buscarProductos(product.producto_id);
  };

  const handleBlur = (e) => {
    setTimeout(() => {
      if (!wrapperRef.current.contains(document.activeElement)) {
        setSuggestions([]);
      }
    }, 100);
  };

  const handleOutsideClick = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <>
      {isAuthenticated && userType === "CON" ? (
        <NavHomeCliente />
      ) : isAuthenticated && userType === "DIS" ? (
        <NavDistribuidor />
      ) : (
        <header className={styles.headerWrapper}>
          <nav className={styles.navWrapper}>
            <section className={styles.navWrapperLeftLogos}>
              {/* sidebar */}
              <Sidebar
                showSidebar={showSidebar}
                handleClose={handleClose}
                setShowSidebar={setShowSidebar}
              />
              <button onClick={handleShow} className={styles.sidebarButton}>
                <SvgSidebar />
              </button>

              {/* Logo */}
              <Link to={"/"} onClick={handleScrollToTop}>
                <Carrito className={styles.logoTipo} />
              </Link>
            </section>

            {/* Search  */}
            {size.width < 750 ? (
              <div className={styles.searchWrapperResponsive} ref={wrapperRef} style={{ display: showSearch ? "flex" : "none" }}>
                <button className={styles.searchIconWrapperResponsive} onClick={() => buscarProductos(null)}>
                  <SvgBuscador />
                </button>
                <div className={styles.sectionInput}>
                  <input
                    type="text"
                    className={styles.searchInputResponsive}
                    ref={inputRef}
                    placeholder="Buscar"
                    value={query}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                  />
                  {suggestions.length > 0 && (
                    <ul className={styles.suggestionsListResponsive}>
                      {suggestions.map((product, index) => (
                        <li
                          className={styles.optiondesplegableList}
                          key={index}
                          onClick={() => handleClick(product)}
                          tabIndex="0"
                        >
                          {product.descripcion_prod}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ) : (
              <div className={styles.wrapperSearchMain} ref={wrapperRef}>
                <div className={suggestions.length > 0 ? styles.searchWrapperSuggestions : styles.searchWrapper}>
                  <button className={styles.searchIconWrapper} onClick={() => buscarProductos(null)}>
                    <SvgBuscador />
                  </button>
                  <input
                    type="text"
                    ref={inputRef}
                    className={styles.searchInput}
                    placeholder="Buscar"
                    value={query}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                  />
                </div>
                {suggestions.length > 0 && (
                  <ul className={styles.suggestionsList}>
                    {suggestions.map((product, index) => (
                      <li
                        className={styles.optiondesplegableList}
                        key={index}
                        onClick={() => handleClick(product)}
                        tabIndex="0"
                      >
                        {product.descripcion_prod}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}

            <div className={styles.navIconsWrapper}>
              <div className={styles.searchIcon}>
                {showSearch ? (
                  <SvgClosedButtonSearch
                    onClick={() => setShowSearch(!showSearch)}
                  />
                ) : (
                  <SvgBuscador onClick={() => setShowSearch(!showSearch)} />
                )}
              </div>

              {/* Carrito  */}
              <Link to={"/carrito-cliente"} className={styles.cartButton}>
                <SvgCarrito />
                <p className={styles.cartText}>Carrito</p>
              </Link>

              {/* Iniciar Seccion  */}
              <Link
                className={styles.userIcon}
                onClick={handleScrollToTop}
                to="/rol-registro"
              >
                <SvgUser />
              </Link>
              <div className={styles.logginButton}>
                <Link onClick={handleScrollToTop} to="/rol-registro">
                  <span className={styles.logginButtonText}>
                    Iniciar sesión
                  </span>
                </Link>
              </div>
            </div>
          </nav>
        </header>
      )}
    </>
  );
};
