export const menuItems = [
  {
    name: "Tienda en Línea",
    items: [
      {
        name: "Higiene y Cuidado de la piel",
        path: "/tienda-linea-cliente/05",
      },
      {
        name: "Capilar",
        path: "/tienda-linea-cliente/01",
      },
      {
        name: "Perfumería",
        path: "/tienda-linea-cliente/02",
      },
      {
        name: "Maquillaje",
        path: "/tienda-linea-cliente/06",
      },
      {
        name: "Facial",
        path: "/tienda-linea-cliente/04",
      },
    ],
  },
  {
    name: "Promociones",
    path: "/oferta",
  },
  {
    name: "Lo nuevo",
    path: "/nuevos",
  },
  {
    name: "Distribuidor Vogue",
    items: [
      {
        name: "Quiero ser distribuidor(a)",
        path: "/distribuidora",
      },
    ],
  },
  {
    name: "Conoce Vogue",
    items: [
      {
        name: "Quiénes Somos",
        path: "/quienes-somos",
      },
      {
        name: "Contáctanos",
        path: "/contactanos",
      },
    ],
  },
  {
    name: "Productos",
    items: [
      {
        name: "Todas las líneas",
        path: "/todas-lineas",
      },
      {
        name: "Tutoriales",
        path: "/videos",
      },
      {
        name: "Vogue Tips",
        path: "/blog",
      },
    ],
  },
];
