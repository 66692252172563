import React from "react";
import { Link } from "react-router-dom";
import style from "../Blog/Blog.module.css";
import { articles } from "../../../constants/post";
export const Blog = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };
  const limitedArticles = articles.slice(0, 3);
  return (
    <div className={style.contenedorFondo}>
      <h3 className={style.tituloNovedad}>Vogue Tips</h3>
      <div className="btn-blog">
        <Link className="verTodo" to={"/blog"} onClick={handleScrollToTop}>
          Ver todos los artículos
        </Link>
      </div>
      <div className={style.contenedorCads}>
        {limitedArticles.map((article, index) => (
          <div key={index} className={style.cardsVogueTipsHome}>
            <div className={style.contenedorTitulo}>
              <h4 className={style.tituloCards}>{article.title}</h4>
            </div>
            <div className={style.contenedorImg}>
              <img
                className={style.imagen}
                src={article.image}
                alt={`foto ilustrativa del producto ${article.title}`}
              />
            </div>
            <div className={style.contenedorDescripcion}>
              <p className={style.descripcion}>{article.description}</p>
            </div>
            <Link
              onClick={handleScrollToTop}
              to={"/detalle-blog"}
              className={style.contenedorBtn}
              state={{ article: article }}
            >
              Leer más
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
