import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BannerProductoFavorito } from "../Baners/BannerProductoFavorito";
import { Card } from "../Cards/Card";
import styles from "../Baners/Banner.module.css";
import axios from "axios";

export const ProductosFavoritos = ({ pedidos, onPedido }) => {
  const [data, setData] = useState();
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };
  const getData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=1000`
    );
    const productosFiltrados = response.data.detalle.filter(
      (producto) => producto.tipo_incentivo_id === "p"
    );
    setData(productosFiltrados.slice(0, 3));
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <h2 className="title-productosFavoritos">Las mejores ofertas</h2>
      <div className="contenedor-btn">
        <Link
          onClick={handleScrollToTop}
          className="link-Titulo"
          to={"/oferta"}
        >
          Ver todos los productos
        </Link>
      </div>
      {/* contenedor de las cards y el baner  */}
      <div className={styles.contenedor}>
        {data ? (
          data.map((ele, index) => (
            <Card
              key={index}
              data={ele}
              detalle={true}
              promociones={true}
              pedidos={pedidos}
              onPedido={() => onPedido()}
            />
          ))
        ) : (
          <h2 className={styles.cargando2}>Cargando...</h2>
        )}
        <BannerProductoFavorito />
      </div>
    </>
  );
};
