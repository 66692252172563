// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SectionDescription_contenedorDescripcion__GZPfZ {\n  margin-top: 20px;\n  margin-bottom: 60px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n\n.SectionDescription_decripcionParrafo__DrvBw {\n  font-family: \"Poppins\", sans-serif;\n  font-size: small;\n  color: #000;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.SectionDescription_title__ISY9P {\n  color: #9c182f;\n}", "",{"version":3,"sources":["webpack://./src/homeComun/components/Blog/components/SectionDescriptionCremaSolar/SectionDescription.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,kCAAkC;EAClC,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".contenedorDescripcion {\n  margin-top: 20px;\n  margin-bottom: 60px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n\n.decripcionParrafo {\n  font-family: \"Poppins\", sans-serif;\n  font-size: small;\n  color: #000;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n.title {\n  color: #9c182f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contenedorDescripcion": "SectionDescription_contenedorDescripcion__GZPfZ",
	"decripcionParrafo": "SectionDescription_decripcionParrafo__DrvBw",
	"title": "SectionDescription_title__ISY9P"
};
export default ___CSS_LOADER_EXPORT___;
