import React, { useEffect, useState } from "react";
import { Sliders } from "../../homeComun/components/Slider/Slider";
import { ProductoNuevo } from "../../homeComun/components/ProductoNuevos/ProductoNuevo";
import styles from "../../css/BannerProductosNuevos.module.css";
import { Link } from "react-router-dom";
import { Card } from "../../homeComun/components/Cards/Card";
import banner from "../../assets/img/Banner/image.png";
import { PromocionesVogue } from "../components/Promociones/PromocionesVogue";
import { Nanner } from "../components/Banner/Nanner";
import { ComoUtilizarProductos } from "../../homeComun/components/ComoUtilizarProductos/ComoUtilizarProductos";
import { Empresas } from "../../homeComun/components/Empresas/Empresas";
import { Cargando } from "../../homeComun/components/Cargando/Cargando";
import axios from "axios";
import { MensajeWhasapps } from "../../homeComun/components/MensajeFlotant/MensajeWhasapps";
import { Baners } from "../../homeComun/components/Baners/Baners";
import { ChatComponent } from "../../homeComun/components/MensajeFlotant/MensajeFlotante";
import { useBorrador } from "../../state/context/borradorContext";


export const HomeDistribuidor = () => {
  const [producto, setProductos] = useState([]);
  const [productosOferta, setProductosOferta] = useState([]);
  const [cargando, setCargando] = useState(true); // Estado para controlar la carga
  const { loadingBorrador } = useBorrador();

  const getData = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=1000`
    );
    const productosFiltrados = response.data.detalle.filter(
      (producto) => producto.tipo_incentivo_id === "n"
    );
    setProductos(productosFiltrados.slice(0, 3));
  };

  const getDataOfertas = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=1000`
    );
    const productosFiltrados = response.data.detalle.filter(
      (producto) => producto.tipo_incentivo_id === "v"
    );
    setProductosOferta(productosFiltrados.slice(0, 3));
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDataOfertas();
      // await apiGet();
      await getData();

      // Espera de 2 segundos después de obtener los datos antes de quitar el estado de carga
      setTimeout(() => {
        setCargando(false);
      }, 2000);
    };

    fetchData();
  }, []);

  // Mientras cargando sea true o loadingBorrador sea true, se muestra el componente Cargando
  if (loadingBorrador || cargando) {
    return <Cargando />;
  }



  
  return (
    <>
      <Sliders />
      <ChatComponent />
      <MensajeWhasapps />
      <ProductoNuevo />
      <div className={styles.contenedorGenerals}>
        <div className={styles.conenedorCards}>
          {producto.length > 0 ? (
            producto.map((ele, index) => (
              <Card
                key={index}
                data={ele}
                cantidadProp
                detalle={true}
                agregar="pedido"
              />
            ))
            
          ) : (
            <h2>Productos no disponibles momentaneamente</h2>
          )}
        </div>
      </div>
      <div className={styles.contenedorSVG}>
        <img src={banner} alt="" style={{ width: "100%" }} />
        <Link to="/catalogo" className={styles.contenedorBtnsCatalogo}>
          Ver catálogo
        </Link>
      </div>
      <PromocionesVogue data={productosOferta} />
      <Nanner />
      <ComoUtilizarProductos />
      <Baners />
      <Empresas />
    </>
  );
};
