// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubLineas_contenedor__31s1Q {\n  width: 70%;\n  margin-left: 15%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n}\n.SubLineas_cargandoo__hc5qu {\n  height: 300px;\n  text-align: center;\n  margin-top: 20px;\n  font-weight: 700;\n  color: #dd8093;\n}\n", "",{"version":3,"sources":["webpack://./src/homeComun/components/MenuCategoria/SubLineas.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".contenedor {\n  width: 70%;\n  margin-left: 15%;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n}\n.cargandoo {\n  height: 300px;\n  text-align: center;\n  margin-top: 20px;\n  font-weight: 700;\n  color: #dd8093;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contenedor": "SubLineas_contenedor__31s1Q",
	"cargandoo": "SubLineas_cargandoo__hc5qu"
};
export default ___CSS_LOADER_EXPORT___;
