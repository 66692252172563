import React, { useState } from "react";
import style from "../../../css/LoginDistribuidor.module.css";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import styles from "./Registro.module.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../../store/slices/authSlice";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";

export const RegistroCliente = () => {
  const [seePassword, setSeePassword] = useState(false);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const [loading, setLoading] = useState(false);
  const loginUsuario = async (data) => {
    setLoading(true);
    try {
      // Intento de login
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        { ...data, tipo: "CON", proceso: "L" },
        { headers: { "Content-Type": "application/json" } }
      );
      // Si el login es exitoso
      if (response.status === 200) {
        // console.log("desde el headers", response.headers['authorization']);

        dispatch(
          loginSuccess({
            user: response.data.user_detalle,
            token: response.headers['authorization'],
            role: response.data.user_detalle.role,
            userType: response.data.tipo,
          })
        );
        toast.success("Inicio de sesión correcto", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "loginSuccess",
        });
        reset();
        navigate("/tienda-linea-cliente/05");
      }
    } catch (error) {
      // Manejo específico para error 403
      if (error.response?.status === 403) {
        // console.log("este es el ", error);

        toast.error(`Error 403: ${error.response.data.detalle || "Acceso denegado"}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        // Manejo de otros errores
        const mensajeError = error.response?.data?.detalle || "Algo salió mal";
        toast.error("Error al iniciar sesión: " + mensajeError, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setValue("username", "");
      setValue("password", "");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="registro-cliente">
        <h2>Bienvenido (a)</h2>
        {/* Nombre  */}
        <form onSubmit={handleSubmit(loginUsuario)}>
          <label htmlFor="username"></label>
          <div className="input">
            <input
              className="place"
              type="email"
              placeholder="Correo:"
              {...register("username", {
                required: {
                  value: true,
                  message: "Correo es requerido",
                },
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Correo no valido",
                },
              })}
            />
          </div>
          {errors.username && (
            <span className={styles.errors}>{errors.username.message}</span>
          )}
          {/* Contraseña  */}
          <label htmlFor="password"></label>

          <div className={style.containerInputsLoginClient}>
            <input
              className={style.inputsLogin}
              type={seePassword ? "text" : "password"}
              placeholder="Contraseña:"
              {...register("password", {
                required: {
                  value: true,
                  message: "Contraseña es requerido",
                },
              })}
            />
            <button
              type="button"
              onClick={() => setSeePassword(!seePassword)}
              className={style.eyeIconInPassword}
            >
              {seePassword ? (
                <IoMdEyeOff size={25} color="#7b7676" />
              ) : (
                <FaEye size={25} color="#7b7676" />
              )}
            </button>
          </div>

          {errors.password && (
            <span className={styles.errors}>{errors.password.message}</span>
          )}
          <div
            className={loading ? styles.loadingScreen : styles.loadingScreenOff}
          />
          <button
            type="submit"
            className="botons"
            disabled={loading}
            onClick={handleScrollToTop}
          >
            {loading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              "Ingresar"
            )}
          </button>
        </form>

        {/* No tienes cuenta ? */}
        <p className="registrate">
          ¿No tienes una cuenta?
          <Link to={"/registro-cliente"} onClick={handleScrollToTop}>
            REGÍSTRATE
          </Link>
        </p>
        <Link
          to={"/contraseña"}
          onClick={handleScrollToTop}
          className="contraseña"
        >
          Olvide mi contraseña
        </Link>
      </div>
    </>
  );
};
