// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoNotAvailable_messageWrapper__GXTyU {\n  display: flex;\n  background-color: #f6f6f6;\n  justify-content: center;\n  width: 80%;\n  margin: auto;\n  align-items: center;\n  height: calc(100vh - 411px);\n  margin-bottom: 3rem;\n  text-align: center;\n\n}\n\n@media (max-width: 768px) {\n  .InfoNotAvailable_messageWrapper__GXTyU {\n    width: 100%;\n    font-size: 25px;\n    padding: 0 20px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/InfoNotAvailable/InfoNotAvailable.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,uBAAuB;EACvB,UAAU;EACV,YAAY;EACZ,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;;AAEpB;;AAEA;EACE;IACE,WAAW;IACX,eAAe;IACf,eAAe;EACjB;AACF","sourcesContent":[".messageWrapper {\n  display: flex;\n  background-color: #f6f6f6;\n  justify-content: center;\n  width: 80%;\n  margin: auto;\n  align-items: center;\n  height: calc(100vh - 411px);\n  margin-bottom: 3rem;\n  text-align: center;\n\n}\n\n@media (width <= 768px) {\n  .messageWrapper {\n    width: 100%;\n    font-size: 25px;\n    padding: 0 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messageWrapper": "InfoNotAvailable_messageWrapper__GXTyU"
};
export default ___CSS_LOADER_EXPORT___;
