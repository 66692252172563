import React, { useState, useEffect } from "react";
import styles from "../Perfil/Perfil.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { FaCheckCircle } from "react-icons/fa";
import { FormularioCambioDatos } from "./FormularioCambioDatos";
import { ButtonClosedForm } from "../../../../homeComun/components/ButtonClosedForm";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { CambioContraseña } from "../../../../HomeDistribuidor/components/DatosDistribuidor/CambioContraseña";

export const DatosGenerales = () => {
  const { token, user, userType } = useSelector((state) => state.auth);
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [thankYouPopupOpen, setThankYouPopupOpen] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false); // Estado para controlar la visibilidad del formulario
  const [cambioContraseña, setCambioContraseña] = useState(false)
  const [actualizar, setActualizar] = useState(true)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [cambioNombre, setCambioNombre] = useState(false)

  useEffect(() => {
    if (thankYouPopupOpen) {
      const timer = setTimeout(() => {
        setThankYouPopupOpen(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [thankYouPopupOpen]);


  const handleClosePopup = () => {
    setThankYouPopupOpen(false);
  };
  // console.log(userType);

  const handleNameSubmit = async (data) => {
    if (!/^[a-zA-Z\s]{3,}$/.test(data.newName)) {
      return toast.error("El nombre no debe poseer caracteres especiales");
    }
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/clientes/send-update-info`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ nombre: data.newName, tipo: userType }),

        }
      );
      if (response.ok) {
        setPopupOpen(false);
        setThankYouPopupOpen(true);
        setMostrarFormulario(false); // Modificar aquí para deshabilitar el formulario
        reset(); // Resetea el formulario
      } else {
        console.error("Error al actualizar el nombre");
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    } finally {
      setLoading(false);
    }
  };



  return (
    <>
      <h2 className={styles.titulo}>Hola, {user?.nombres}</h2>
      <div className={styles.contenedorGenerals}>
        <div className={styles.contenedorTarjetass}>
          <div className={styles.ContenedorDatosGeneralesHistorial}>
            <div className={styles.informacionGeneralesCompra}>
              <Link className={styles.tituloGenerales} to={""}>
                Datos generales{" "}
              </Link>
              <Link
                className={styles.TituloHistorialCompra}
                to={"/home-cliente/historial"}
              >
                Historial de compra
              </Link>
            </div>
          </div>
          <div className={styles.contenedorTarjetaClient}>
            <div className={styles.contenedorTarjetaInfo}>
              <button
                className={styles.editarDatos}
                onClick={() => setPopupOpen(true)}
              >
                Editar datos
              </button>
              <div className={styles.DatosTarjetas}>
                <p className={styles.datosNombre}>Nombre</p>
                <h2 className={styles.nombreTitular}>
                  {user?.nombres + " " + user?.apellidos}
                </h2>

              </div>
            </div>
            <div className={styles.ContenedorContacto}>
              <button
                onClick={() => setMostrarFormulario(!mostrarFormulario)}
                className={styles.editarDatos2}
              >
                Editar datos
              </button>
              <div className={styles.cajaContacto}>
                <div className={styles.datosdeContacto}>
                  <p className={styles.telefono}>Teléfono: </p>
                  <p className={styles.numero}>{user?.celular}</p>
                  <p className={styles.telefono}>Correo electrónico: </p>
                  <p className={styles.numero}>{user?.email}</p>
                  <p className={styles.telefono}>Dirección de domicilio:</p>
                  <p className={styles.numero}>
                    {user?.direccion ? user?.direccion : "No disponible"}
                  </p>
                </div>
                <div className={styles.DetalleContactoClient}>
                  <h3 className={styles.tituloDetallesClient}>
                    Detalles del contacto
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {mostrarFormulario && (
        <FormularioCambioDatos setMostrarFormulario={setMostrarFormulario} />
      )}

      {
        popupOpen && (
          <div className={styles.conteinerPopup}>
            <div className={styles.popup}>
              <ButtonClosedForm setOpenPopup={setPopupOpen} setCambioNombre={setCambioNombre} setCambioContraseña={setCambioContraseña} />
              <div className={styles.popupInner}>

                {actualizar && (
                  <h2 className={styles.actualizarNombre}>


                    Actualización de datos</h2>

                )}

                {!cambioNombre && !cambioContraseña ? (
                  <div className={styles.elegirCambioDatos}>
                    <div className={styles.cambioNombre}>
                      <p className={styles.nombreContraseña}>Nombre</p>
                      <button
                        className={styles.cambiarDatos1}
                        onClick={() => setCambioNombre(true)}
                      >
                        Cambiar
                      </button>
                    </div>
                    <div className={styles.cambioNombre}>
                      <p className={styles.nombreContraseña}>Contraseña</p>
                      <button
                        className={styles.cambiarDatos}
                        onClick={() => setCambioContraseña(true)}
                      >
                        Cambiar
                      </button>
                    </div>
                  </div>
                ) : cambioNombre === true ? (
                  <form
                    onSubmit={handleSubmit(handleNameSubmit)}
                    className={styles.contenedorInputsBtn}
                  >
                    <input
                      className={styles.inputNombres}
                      type="text"
                      placeholder="Nuevo nombre"
                      {...register("newName", { required: true })}
                    />
                    {errors.newName && (
                      <span className={styles.messageError}>
                        Este campo es obligatorio
                      </span>
                    )}
                    <button className={styles.cambiarBtnNombre} type="submit">
                      {loading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Cargando...</span>
                        </Spinner>
                      ) : (
                        "Cambiar"
                      )}
                    </button>
                  </form>
                ) : cambioContraseña === true && (
                  <CambioContraseña setActualizar={setActualizar} setCambioContraseña={setCambioContraseña} />
                )}
              </div>
            </div>
          </div>
        )
      }
      {thankYouPopupOpen && (
        <div className={styles.conteinerPopup}>
          <div className={styles.popup}>
            <svg
              onClick={handleClosePopup}
              className={styles.cerrarMensajeConfirmacion} width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Menu / Close_LG">
                <path id="Vector" d="M21 21L12 12M12 12L3 3M12 12L21.0001 3M12 12L3 21.0001" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
            <div className={styles.popupInner}>
              <div className={styles.contenedorRepuesta}>
                <FaCheckCircle color="green" size={50} />
                <h2 className={styles.tituloGracias}>Gracias.</h2>
                <p className={styles.parrafoRespuesta}>
                  En un lapso de 3 días, nuestro equipo te enviará un mensaje de
                  texto cuando tus datos estén actualizados en la página.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
