import React from "react";
import styles from "./RelatedArticles.module.css";
import { articles } from "../../../../../constants/post";
import { useGetArticlesDate } from "../../../../../hooks/useGetArticlesDate";
import { Link } from "react-router-dom";

export const RelatedArticles = ({ idArticule }) => {
  const { dia, año, nombreMes } = useGetArticlesDate();
  const newArticles = articles.filter((article) => article.id !== idArticule);
  return (
    <div className={styles.similar}>
      <div className={styles.borderArticulo}>
        <h2 className={styles.tituloArticulo}>Articulos Similares</h2>
        <hr />
        {newArticles &&
          newArticles.map((article, i) => (
            <Link
              key={article.id}
              className={`${i !== 0 ? styles.divider : ""} ${
                styles.contenedorArticuloSimilar
              }`}
              state={{ article }}
            >
              <div className={styles.contenedorImagen}>
                <img
                  className={styles.imagenSimilar}
                  src={article.image}
                  alt=""
                />
              </div>
              <div className={styles.textoSimilar}>
                <h4 className={styles.tituloSimilar}>{article.title}</h4>
                <p className={styles.desccipcionSimilar}>
                  {article.description}
                </p>
                <p className={styles.fechaSimilar}>
                  {nombreMes} {dia} {año}
                </p>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};
