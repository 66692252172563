import React, { useState } from "react";
import style from "../../../css/Distribuidora.module.css";
import fondo from "../../../assets/img/distribuidora.png";
import { PreguntasDespliegue } from "./PreguntasDespliegue";
import { useEffect } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { departamentos } from "../../../assets/utils/Departamentos";
import { LoadingText } from "../../../components/LoadingText";
import { benefits } from "../../../constants/benefits";
import { MensajeWhasapps } from "../MensajeFlotant/MensajeWhasapps";

export const Distribuidora = () => {
  const timeoutRef = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVariant, setAlertVariant] = useState("success");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [preguntaDesplegada, setPreguntaDesplegada] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();
  const dataFaq = [
    {
      pregunta: "¿CUÁL ES EL COSTO DE ENVÍO POR MI PAQUETE?",
      respuesta:
        "Si tu envío es en San Salvador (incluyendo Antiguo Cuscatlan, Nuevo Cuscatlan y Zaragoza) es de $2.75 por pedido.\nSi tu envío es departamental es de $3.50 por pedido",
    },
    {
      pregunta: "¿CÓMO PUEDO HACER MI PEDIDO? ",
      respuesta:
        "Realizarlo en nuestro sitio web iniciando sesion con tu codigo de usuario.\nLlamando o escribiendo a nuestro call center 2261 - 8181\nContactando a tu Gerente de Zona  ",
    },
    {
      pregunta: "¿PUEDO RETIRAR MI PEDIDO EN LAS OFICINAS DE VOGUE?",
      respuesta:
        "¡Claro que sí! Puedes retirar tu pedido en nuestras oficinas, siempre y cuando estés dentro de tus fechas de facturación. ",
    },
    {
      pregunta: "¿DESPUÉS DE CUÁNTO TIEMPO RECIBO MI PEDIDO? ",
      respuesta:
        "Si eres consumidor final recibirás tu pedido de 1 a 3 días hábiles.\nSi eres distribuidor tu pedido lo recibirás de 3 a 5 días después de tu fecha de conferencia.\nRecuerda que debes firmar la boleta de recibido y colocar tu número de DUI. ",
    },
  ];
  const departamentoSeleccionado = watch("departamento");

  const municipiosFiltrados =
    departamentos.departamentos.find(
      (dep) => dep.nombre === departamentoSeleccionado
    )?.municipios || [];

  const nuevoDistribuidor = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/clientes/form`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setAlertMessage(
          "Gracias por tu interés, revisaremos la información. Pronto nos pondremos en contacto contigo para terminar el proceso de inscripción."
        );
        setAlertVariant("success");
      } else {
        setAlertMessage(
          "Error en el formulario. Por favor, inténtalo de nuevo."
        );
        setAlertVariant("danger");
      }
    } catch (error) {
      setAlertMessage("Error en el formulario. Por favor, inténtalo de nuevo.");
      setAlertVariant("danger");
    }

    // Resetear el estado isSubmitting a false
    setIsSubmitting(false);

    setShowAlert(true);
    reset();
  };

  const cambiarDespliegue = (index) => {
    if (preguntaDesplegada === index) {
      setPreguntaDesplegada(null); // Si se hace clic en la pregunta ya desplegada, ciérrala
    } else {
      setPreguntaDesplegada(index); // De lo contrario, establece la nueva pregunta desplegada
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false); // Esto cerrará el mensaje de aprobación
      }, 8000); // Cierra el mensaje después de 5 segundos

      return () => clearTimeout(timer); // Limpia el temporizador si el componente se desmonta
    }
  }, [showAlert]);
  return (
    <div>
      <MensajeWhasapps />
      <div className={style.banner}>
        <img className={style.imagen} src={fondo} alt="" />
        <h2 className={style.titulo}>Quiero ser distribuidor(a)</h2>
      </div>
      <h2 className={style.conoce}>
        Conoce los beneficios <br />
        de ser distribuidora
      </h2>
      <div className={style.contenedorBeneficioss}>
        {benefits &&
          benefits.map((benefit, index) => (
            <div
              key={benefit.id}
              className={`${style.beneficios} ${index === 6 && style.ultimoBeneficio}`}
            >
              <div className={style.circulo}>
                {benefit.id === 6 || benefit.id === 7 ? (
                  <img src={benefit.img} className={style.imageCircle} alt={`Imagen ilustativa del beneficio ${benefit.title}`} />
                ) : (
                  benefit.id === 2 ? <p className={style.textMoney}>{benefit.img}</p> : benefit.img
                )}
              </div>
              <div className={style.descripcionS}>
                <p className={style.parrafoBenefit}>{benefit.title}</p>
              </div>
            </div>
          ))}
      </div>
      <p className={style.letrachica}>
        Recibe premios en todas tus campañas, por compras mensuales de $100 o
        más durante dos campañas seguidas (Restricciones aplican).
      </p>
      <p className={style.letrachica}>
        Acumula puntos por compras mensuales arriba de $200 catálogo por 6 meses
        (restricciones aplican).
      </p>
      {/* Formulario  */}
      <div className={style.contenedorFormulario}>
        <h2 className={style.formularioTitulo}>Ingresa tu información</h2>
        <div className={style.formulario}>
          {showAlert ? (
            <div className={style.contenedorMensaje}>
              <svg
                width="50"
                height="50"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19L21 19V5C21 3.9 20.1 3 19 3ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.59L19 8L10 17Z"
                  fill="white"
                />
              </svg>

              <p className={style.parrafoAprobado}>{alertMessage}</p>
            </div>
          ) : (
            <>
              <div className={style.form}>
                <label htmlFor="nombre"></label>
                <input
                  {...register("nombre", {
                    required: {
                      value: true,
                      message: "Nombre es requerido",
                    },
                  })}
                  className={style.input}
                  type="text"
                  placeholder="Nombre completo"
                />
              </div>
              {errors.nombre && (
                <span className={style.errors}>{errors.nombre.message}</span>
              )}

              <div className={style.form}>
                <label htmlFor="telefono"></label>
                <input
                  {...register("numeroTelefono", {
                    required: {
                      value: true,
                      message: "Teléfono es requerido",
                    },
                    pattern: {
                      value: /^[0-9]{1,8}$/,
                      message: "Teléfono debe contener solo números (máximo 8 dígitos)",
                    },
                    maxLength: {
                      value: 8,
                      message: "Teléfono no debe exceder 8 dígitos",
                    },
                  })}
                  className={style.input}
                  type="tel"
                  placeholder="Número de teléfono"
                  maxLength={8} // Restringe la cantidad de caracteres
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "").slice(0, 8); // Elimina letras y recorta a 8 dígitos
                  }}
                />
              </div>
              {errors.telefono && (
                <span className={style.errors}>{errors.telefono.message}</span>
              )}

              <div className={style.form}>
                <label htmlFor="dui"></label>
                <input
                  {...register("dui", {
                    required: {
                      value: true,
                      message: "DUI es requerido",
                    },
                    pattern: {
                      value: /^\d{9}$/, // Asegura que solo sean exactamente 9 números
                      message: "DUI debe contener exactamente 9 dígitos numéricos",
                    },
                    minLength: {
                      value: 9,
                      message: "DUI debe contener exactamente 9 dígitos numéricos",
                    },
                    maxLength: {
                      value: 9,
                      message: "DUI debe contener exactamente 9 dígitos numéricos",
                    },
                  })}
                  className={style.input}
                  type="tel"
                  placeholder="Número de DUI"
                  maxLength={9} // Restringe la cantidad de caracteres en el input
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "").slice(0, 9); // Elimina letras y recorta a 9 dígitos
                  }}
                />
              </div>
              {errors.dui && (
                <span className={style.errors}>{errors.dui.message}</span>
              )}

              <div className={style.form}>
                <select
                  {...register("departamento", {
                    required: "Departamento es requerido",
                  })}
                  className={style.input}
                >
                  <option value="">Seleccione un departamento</option>
                  {departamentos.departamentos.map((dep, index) => (
                    <option
                      className={style.optionsInput}
                      key={index}
                      value={dep.nombre}
                    >
                      {dep.nombre}
                    </option>
                  ))}
                </select>
              </div>
              {errors.departamento && (
                <span className={style.errors}>
                  {errors.departamento.message}
                </span>
              )}

              <div className={style.form}>
                <select
                  {...register("municipio", {
                    required: "Municipio es requerido",
                  })}
                  className={style.input}
                  disabled={!departamentoSeleccionado} // Deshabilita si no se ha seleccionado departamento
                >
                  <option value="">Seleccione un municipio</option>
                  {municipiosFiltrados.map((municipio, index) => (
                    <option key={index} value={municipio}>
                      {municipio}
                    </option>
                  ))}
                </select>
              </div>
              {errors.municipio && (
                <span className={style.errors}>{errors.municipio.message}</span>
              )}

              <div className={style.form}>
                <label htmlFor="otrasLineas"></label>
                <input
                  {...register("lineasCatalogo")}
                  className={style.input}
                  type="text"
                  placeholder="¿Cuáles otras líneas vende por catálogo?"
                />
              </div>

              <button
                className={style.enviar}
                disabled={isSubmitting}
                onClick={handleSubmit((data) => {
                  setIsSubmitting(true);
                  nuevoDistribuidor(data);
                })}
              >
                {isSubmitting ? "Cargando..." : "Enviar"}
              </button>
            </>
          )}
        </div>
      </div>

      <h2 className={style.tienes}>¿Tienes preguntas?</h2>

      {/* Preguntas  */}
      <div className={style.contenedor}>
        {dataFaq ? (
          dataFaq.map((item, index) => (
            <PreguntasDespliegue
              key={index}
              pregunta={item.pregunta}
              respuesta={item.respuesta}
              vineta={item.vineta}
              desplegada={index === preguntaDesplegada}
              cambiarDespliegue={() => cambiarDespliegue(index)}
            />
          ))
        ) : (
          <LoadingText />
        )}
      </div>
      <div className={style.contenedorSaberMas}>
        <Link to={"/preguntasFrencuentes"} className={style.saberMass}>
          Saber más
        </Link>
      </div>
    </div>
  );
};
