import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Cargando } from '../Cargando/Cargando';
import styles from "./Registro.module.css"

export const ValidarCorreo = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    const navigate = useNavigate();

    // Estado para manejar el cargando
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const validacionCorreoActivado = async () => {
            setIsLoading(true); // Mostrar el cargando al iniciar la petición
            try {
                const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register/verify-consumidor`, {
                    code: token,
                    email: email,
                });

                if (res.status === 200) {
                    toast.success('Cuenta verificada con éxito. Redirigiendo...', {
                        autoClose: 3000, // Mostrar el mensaje por 3 segundos
                    });
                    setTimeout(() => {
                        navigate('/login-cliente'); // Redireccionar al login
                    }, 4000);
                }
            } catch (error) {
                // Si el servidor responde con un mensaje de error 500
                if (error.response && error.response.status === 500) {
                    toast.error('La cuenta ya ha sido verificada o no ha sido registrada. Redirigiendo...', {
                        autoClose: 3000,
                    });
                    setTimeout(() => {
                        navigate('/');
                    }, 4000);
                } else {
                    toast.error('Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.');
                }
            } finally {
                setIsLoading(false); // Ocultar el cargando al finalizar la petición
            }
        };

        if (token && email) {
            validacionCorreoActivado();
        }
    }, []);

    return (
        <>
            {isLoading ? <Cargando /> : <h2 className={styles.tituloCorreo}>Validando correo...</h2>}

        </>
    );
};
