import React from "react";
import styles from "../../../css/Order.module.css";
import { Link } from "react-router-dom";

export const Order = () => {
  return (
    <div className={styles.contenedor}>
      <div className={styles.caja}>
        <svg
          className={styles.svg}
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="80"
          viewBox="0 0 80 80"
          fill="none"
        >
          <path
            d="M40.0003 73.3334C58.4098 73.3334 73.3337 58.4096 73.3337 40.0001C73.3337 21.5906 58.4098 6.66675 40.0003 6.66675C21.5908 6.66675 6.66699 21.5906 6.66699 40.0001C6.66699 58.4096 21.5908 73.3334 40.0003 73.3334Z"
            fill="#30CC00"
          />
          <path
            d="M25.0003 40.0001L35.0003 50.0001L55.0003 30.0001M73.3337 40.0001C73.3337 58.4096 58.4098 73.3334 40.0003 73.3334C21.5908 73.3334 6.66699 58.4096 6.66699 40.0001C6.66699 21.5906 21.5908 6.66675 40.0003 6.66675C58.4098 6.66675 73.3337 21.5906 73.3337 40.0001Z"
            stroke="white"
            strokeWidth="6.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className={styles.orden}>Tu orden ha sido exitosa</p>
        <p className={styles.descripcion}>
          Cuentas con 3 días máximo a partir de recibido el pedido para
          presentar algún reclamo. Luego de ese lapso, ya no podrá ser
          procesado.
          <br />
        </p>
        <strong className={styles.stron}>
          {" "}
          AL PAGAR CON TARJETA DEBERÁ PRESENTAR TARJETA Y DUI EN FÍSICO O LA
          COMPRA PODRÁ SER REVERTIDA.{" "}
        </strong>
        <div className={styles.contenedorBtn}>
          <Link className={styles.btn} to={"/"}>
            Continuar comprando
          </Link>
        </div>
      </div>
    </div>
  );
};
