import * as React from "react";
const SvgTictok = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={39}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeWidth={2}
      d="M35.813 14.828a14.239 14.239 0 0 1-7.117-2.692l-1.59-1.163v15.592c0 6.299-5.07 11.435-11.303 11.435C9.566 38 4.5 32.917 4.5 26.565c0-5.949 4.424-10.765 10.078-11.372v4.714c-3.117.58-5.505 3.349-5.505 6.658 0 3.699 2.957 6.765 6.673 6.765 3.717 0 6.674-3.066 6.674-6.765V1h4.62c.453 4.722 4.078 8.526 8.773 9.134v4.694Z"
    />
  </svg>
);
export default SvgTictok;
