import React, { useEffect, useState } from "react";
import styles from "./Banner.module.css";
import axios from "axios";
import { Link } from "react-router-dom";

export const BannerProductoFavorito = () => {

  const [datos, setDatos] = useState()

  const dataBanner = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=1&query=017816`)


    setDatos(response.data.detalle[0]);

  }
  useEffect(() => {
    dataBanner()
  }, [])
  return (
    <div className={styles.bannerFavorito}>
      <div className={styles.texto}>

        <Link to={`/detalle/${datos?.producto_id}`} state={{ data: { ...datos } }} className={styles.producto}>Comprar aquí</Link>
      </div>
    </div>
  );
};
