import React, { useState } from "react";
import { PreguntasDespliegue } from "../../../homeComun/components/Footer/PreguntasDespliegue";
import styles from "./Preguntas.module.css";
import { useGetFaq } from "../../../hooks/use-getFaq";

export const PreguntasFrecuentesDistribuidor = () => {
  const { getDistribuidor } = useGetFaq();
  const data = getDistribuidor();
  const [preguntaDesplegada, setPreguntaDesplegada] = useState(null);

  const cambiarDespliegue = (index) => {
    if (preguntaDesplegada === index) {
      setPreguntaDesplegada(null);
    } else {
      setPreguntaDesplegada(index);
    }
  }

  return (
    <>
      <h2 className={styles.titulo}>¿Tienes preguntas?</h2>
      <div className={styles.contenedor}>
        {data ? (
          data[0].preguntas.texto.map((item, index) => (
            <PreguntasDespliegue
              key={index}
              pregunta={item.pregunta}
              respuesta={item.respuesta}
              vineta={item.vineta}
              desplegada={index === preguntaDesplegada}
              cambiarDespliegue={() => cambiarDespliegue(index)}
              url={item.url}
            />
          ))
        ) : (
          <h2>Cargando....</h2>
        )}
      </div>
    </>
  );
};
