import React, { useEffect, useRef, useState } from "react";
import styles from "../../css/DetalleCards.module.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { useGetBorrador } from "../../hooks/use-getBorrador";
import { useValidateCreditCart } from "../../hooks/use-validateCreditCart";
import { Link, useNavigate } from "react-router-dom";
import { redondearDecimales } from "../../hooks/use-redondearDecimales";
import { logout } from "../../store/slices/authSlice";
const images = require.context(
  "../../../public/imagenesPromociones",
  false,
  /\.png$/
);

const ProductDescriptionInfo = ({ data, botones = false, ruta }) => {
  const { isAuthenticated, token, userType, user } = useSelector(
    (state) => state.auth
  );
  const [smallImages, setSmallImages] = useState({});
  const [descuento, setDescuento] = useState();
  const [conoce, setConoce] = useState(false);
  const [cantidad, setCantidad] = useState(1);
  const [cantidadIngresada, setCantidadIngresada] = useState("1");
  const [mostrarDetalles, setMostrarDetalles] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [, setCarritoCreado] = useState(false);
  const [lookbook, setlookbook] = useState("");
  const { borrador, setBorrador } = useGetBorrador();
  const [pedidos, setPedidos] = useState();
  const [pedidoId, setPedidoId] = useState();
  const [codigoSeleccionado, setCodigoSeleccionado] = useState(null);
  const [agregandoPorBoton, setAgregandoPorBoton] = useState({});
  const [promocionSeleccionada, setPromocionSeleccionada] = useState(null);
  const [agregando, setAgregando] = useState(false);
  const [imageSizes, setImageSizes] = useState({});

  const containerRef = useRef(null);

  const desplegarConoce = () => {
    setConoce(!conoce);
    setMostrarDetalles(!mostrarDetalles);
  };
  const [conoceMas, setConoceMas] = useState(false);
  const [pedidoGuardado, setPedidoGuardado] = useState(
    pedidos?.length > 0 ? pedidos[0].carrito : []
  );
  const { validateProductCart } = useValidateCreditCart();

  const handleConoceMas = () => {
    setConoceMas(!conoceMas);
  };

  const resetCantidad = () => {
    setCantidad(1);
    setCantidadIngresada("1");
  };

  const formaPago = () => {
    if (userType === "DIS") {
      return user?.tipo_cre === "CONTADO" ? "Contado" : "Crédito";
    }
    return null; // Opcional, por si `userType` no es "DIS"
  };

  const paymentType = formaPago();

  const isDistributor = userType === "DIS";

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleGetDataPedidos = async () => {
    const handleLogout = () => {
      dispatch(logout());
      navigate("/rol-registro");
    };

    if (
      !user ||
      (!isDistributor && !user?.email) ||
      (isDistributor && !user?.cliente_id)
    ) {
      console.error("Faltan datos para realizar la solicitud");
      return null;
    }

    try {
      const resPedido = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${
          isDistributor ? user?.cliente_id : user?.email
        }`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      setPedidos(resPedido?.data);
      if (resPedido.data.length > 0) {
        const pedidoId = resPedido.data[0]._id;
        setPedidoId(pedidoId); // Guardamos el pedidoId en el estado
        return pedidoId;
      }
    } catch (error) {
      if (
        error.response?.status === 400 &&
        error.response?.data?.response === "Token is invalid"
      ) {
        toast.error(
          "Su sesión ha expirado. Por favor, vuelva a iniciar sesión."
        );
        handleLogout();
      } else {
        console.error("Error al obtener los pedidos:", error);
      }
    }
    return null;
  };

  const handleCarrito = async (value) => {
    setLoading(true);

    try {
      let currentPedidoId = pedidoId; // Usamos el valor de pedidoId si ya existe en el estado

      if (isAuthenticated) {
        currentPedidoId = (await handleGetDataPedidos()) || pedidoId; // Esperamos la respuesta de handleGetDataPedidos si no existe en el estado
      }

      if (userType && borrador && paymentType !== "Crédito") {
        toast.error(
          "Tu pedido está en proceso. Para continuar, se debe finalizar el proceso"
        );
        resetCantidad();
        return;
      }

      if (userType === "DIS") {
        const { esLimiteSuperado, excedente } = await validateProductCart(
          value.producto_id,
          (value.precio_DIS || value.psrecio_DIS) * cantidad
        );

        if (esLimiteSuperado) {
          toast.error(
            `No puedes agregar más del crédito permitido. Te queda restante ${excedente.toFixed(
              2
            )} de crédito.`
          );

          resetCantidad();
          return;
        }
      }

      // Validar si tiene pagar después

      let body = {
        nameItem: value.descripcion_prod,
        itemId: value.producto_id,
        categoria: value.categoria,
        priceCatalogo: value.precio_CON,
        price:
          userType === "DIS"
            ? value.precio_DIS || value.psrecio_DIS
            : value.precio_CON,
        description: value.descripcion_prod,
      };

      if (value.existencia > body.quantity) {
        toast.error(
          `No puedes agregar más de la existencia del producto, producto existente ${value.existencia}`
        );
        resetCantidad();
        return;
      }

      if (isAuthenticated) {
        if (cantidadIngresada >= 1) {
          body.quantity = parseInt(cantidadIngresada, 10);
        } else {
          throw new Error("La cantidad es requerida");
        }
      } else {
        body.quantity =
          cantidadIngresada >= 1 ? parseInt(cantidadIngresada, 10) : 1;
      }

      if (Array.isArray(pedidoGuardado) && pedidos?.length > 0) {
        const existingProduct = pedidos[0].carrito.find(
          (prod) => prod.producto_id === body.itemId
        );
        let totalQuantity = body.quantity;
        if (existingProduct) {
          totalQuantity += existingProduct.quantity;
        }

        if (userType === "DIS" && totalQuantity > value.existencia) {
          const remainingQuantity =
            value.existencia - (existingProduct ? existingProduct.quantity : 0);
          toast.error(
            `No puedes agregar más de ${value.existencia} unidades. Te faltan ${remainingQuantity} unidades para llegar al límite.`
          );
          resetCantidad();
          return;
        }
      }

      const existencia = parseInt(value.existencia, 10);
      if (body.quantity > existencia) {
        toast.error(
          `No hay suficiente existencia. Solo puedes agregar hasta ${existencia} unidades.`
        );
        resetCantidad();
        return;
      }

      if (!isAuthenticated) {
        const carritoGuardadoLocalStorage =
          JSON.parse(localStorage.getItem("carrito")) || [];

        const existingItemIndex = carritoGuardadoLocalStorage.findIndex(
          (item) => item.itemId === value.producto_id
        );

        if (existingItemIndex !== -1) {
          const newQuantity =
            carritoGuardadoLocalStorage[existingItemIndex].quantity +
            body.quantity;
          if (newQuantity > existencia) {
            toast.error(
              `No hay suficiente existencia. Solo puedes agregar hasta ${existencia} unidades.`
            );
            resetCantidad();
            return;
          } else {
            carritoGuardadoLocalStorage[existingItemIndex].quantity =
              newQuantity;
          }
        } else {
          body = {
            nameItem: value.descripcion_prod,
            itemId: value.producto_id,
            categoria: value.categoria,
            price_catalogo: value.precio_CON,
            price_con: value.precio_CON,
            price_dis: value.precio_DIS || value.psrecio_DIS,
            description: value.descripcion_prod,
            quantity:
              cantidadIngresada >= 1 ? parseInt(cantidadIngresada, 10) : 1,
            imagen: value.imagen,
          };

          carritoGuardadoLocalStorage.push(body);
        }

        localStorage.setItem(
          "carrito",
          JSON.stringify(carritoGuardadoLocalStorage)
        );

        toast.success(
          "Producto agregado al carrito. Recuerda iniciar sesión para completar tu pago del carrito"
        );
        resetCantidad();
        return;
      } else {
        let url;
        if (currentPedidoId) {
          url = `${process.env.REACT_APP_API_URL}/pedidos/car/add-item`;
          body = { idPedido: currentPedidoId, ...body };
        } else {
          url = `${process.env.REACT_APP_API_URL}/pedidos/car`;
        }

        try {
          let res = await axios.post(url, body, {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          });

          if (res.status === 200) {
            setCantidad(1);

            toast.success("Producto agregado al pedido");
            resetCantidad();
          }
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error(
          "No hay existencias disponibles de este producto. No se pueden añadir más unidades a tu carrito."
        );
      } else {
        console.error("Error al agregar producto al carrito:", error);
      }
      resetCantidad();
    } finally {
      resetCantidad();
      setLoading(false);
    }
  };

  const handleDecrement = () => {
    if (cantidad > 1) {
      setCantidad((prevCantidad) => prevCantidad - 1);
      setCantidadIngresada((prevCantidad) =>
        String(Math.max(Number(prevCantidad) - 1, 1))
      );
    }
  };

  const handleIncrement = () => {
    setCantidad((prevCantidad) => Math.min(prevCantidad + 1, 999));
    setCantidadIngresada((prevCantidad) =>
      String(Math.min(Number(prevCantidad) + 1, 999))
    );
  };

  const resultado = ruta?.currentPath?.startsWith("/subLineas/");

  const handleInputChange = (e) => {
    const newValue = e.target.value.trim();
    const newValueNumber = Number(newValue);
    if (newValueNumber > data.existencia) {
      return toast.error(`Cantidad máxima ${data.existencia} unidades`);
    }
    if (/^\d{0,3}$/.test(newValue)) {
      setCantidadIngresada(newValue);
    }
  };

  const handleInputBlur = () => {
    if (cantidadIngresada === "" || cantidadIngresada === "0") {
      setCantidad(1); // Establecer la cantidad predeterminada como 1 si está vacía o es 0
      setCantidadIngresada("1"); // Establecer la cantidad ingresada como 1 si está vacía o es 0
    } else {
      // Si no está vacía, convertirla a un número y establecerla como cantidad
      setCantidad(parseInt(cantidadIngresada, 10));
    }
  };

  let precioConDescuento;

  if (data.producto_id === "1054008") {
    precioConDescuento = data.promocion.precio_regular;
    // console.log(precioConDescuento);
  } else {
    if (data.tipo_incentivo_id === "p") {
      precioConDescuento = data?.precio_CON * (1 + descuento / 100);
    }
  }

  // console.log("precio",precioConDescuento);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (data.tipo_incentivo_id === "p") {
        // Obtener el precio de descuento, considerando si es un usuario distribuidor o no
        const precioDIS = parseFloat(data.precio_DIS || data.psrecio_DIS); // Se usa data en lugar de producto
        const precioCON = parseFloat(data.precio_CON);

        // Calcular la diferencia entre el precio CON y el precio DIS
        const diferencia = precioCON - precioDIS;

        // Calcular el porcentaje de descuento y redondearlo hacia abajo
        const porcentajeDescuento = Math.floor((diferencia / precioCON) * 100);
        setDescuento(porcentajeDescuento);
      }
      datalookbook();
    }, 1000); // 1 segundo

    // Limpieza del timeout
    return () => clearTimeout(timeout);
  }, [data]);

  const datalookbook = async () => {
    let response = await axios.get(
      `${process.env.REACT_APP_API_URL}/productos?id=${data.producto_id}&page=1&pageSize=1`
    );
    setlookbook(response.data.detalle.lookbook);
  };
  // console.log(data);
  const tieneDescuento =
    data.tipo_incentivo_id === "p" || data.producto_id === "1054008";
  // console.log("descuento", tieneDescuento);

  const precioRegularLimpio = redondearDecimales(Number(precioConDescuento), 2);

  // console.log("log", data?.promocion?.productos_relacionados);

  const codigosPromocion =
    data?.promocion?.cantidad?.split(",").slice(1) ||
    data?.promocion?.cantidad_1?.split(",").slice(1) ||
    data?.promocion?.cantidad_2?.split(",").slice(1);

  const codigoPromocionCombinado =
    data?.promocion?.productos_relacionados || [];

  const codigos =
    codigoPromocionCombinado.length > 0 ? codigoPromocionCombinado[0] : [];
  const codigos1 =
    codigoPromocionCombinado.length > 1 ? codigoPromocionCombinado[1] : [];
  const codigos2 =
    codigoPromocionCombinado.length > 2 ? codigoPromocionCombinado[2] : [];
  const codigos3 =
    codigoPromocionCombinado.length > 3 ? codigoPromocionCombinado[3] : [];
  console.log("codigosPromocion:", codigos);

  const manejarSeleccion = (codigo, indexPromocion) => {
    setCodigoSeleccionado(codigo);
    setPromocionSeleccionada(indexPromocion);
  };

  const agregarAlCarrito = async (botonId) => {
    if (!codigoSeleccionado) {
      console.error("No hay un código seleccionado.");
      return;
    }

    setAgregando(true); // Actualiza el estado general de "agregando"
    setAgregandoPorBoton((prev) => ({ ...prev, [botonId]: true }));
    try {
      console.log("Código seleccionado:", codigoSeleccionado);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos?id=${codigoSeleccionado}&page=1&pageSize=1`
      );

      const producto = response.data.detalle;

      console.log("Datos del producto:", producto);

      if (isAuthenticated) {
        const currentPedidoId = (await handleGetDataPedidos()) || pedidoId;

        if (userType && borrador && paymentType !== "Crédito") {
          toast.error(
            "Tu pedido está en proceso. Para continuar, se debe finalizar el proceso."
          );
          return;
        }

        const body = {
          nameItem: producto.descripcion_prod,
          itemId: producto.producto_id,
          categoria: producto.categoria,
          priceCatalogo: producto.precio_CON,
          price:
            userType === "DIS"
              ? producto.precio_DIS || producto.psrecio_DIS
              : producto.precio_CON,
          description: producto.descripcion_prod,
          quantity: 1,
        };

        let url;
        if (currentPedidoId) {
          url = `${process.env.REACT_APP_API_URL}/pedidos/car/add-item`;
          body.idPedido = currentPedidoId;
        } else {
          url = `${process.env.REACT_APP_API_URL}/pedidos/car`;
        }

        const res = await axios.post(url, body, {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        });

        if (res.status === 200) {
          setCantidad(1);
          toast.success("Producto agregado al pedido.");
        }
      } else {
        const carritoGuardadoLocalStorage =
          JSON.parse(localStorage.getItem("carrito")) || [];

        const existingItemIndex = carritoGuardadoLocalStorage.findIndex(
          (item) => item.itemId === producto.producto_id
        );

        if (existingItemIndex !== -1) {
          const existingItem = carritoGuardadoLocalStorage[existingItemIndex];
          const nuevaCantidad = existingItem.quantity + 1;

          if (nuevaCantidad > producto.existencia) {
            toast.error(
              `No hay suficientes existencias. Disponibles: ${producto.existencia}.`
            );
            return;
          }

          carritoGuardadoLocalStorage[existingItemIndex].quantity =
            nuevaCantidad;
        } else {
          const body = {
            nameItem: producto.descripcion_prod,
            itemId: producto.producto_id,
            categoria: producto.categoria,
            priceCatalogo: producto.precio_CON,
            price_con: producto.precio_CON,
            price_dis: producto.precio_DIS || producto.psrecio_DIS,
            description: producto.descripcion_prod,
            quantity: 1,
            imagen: producto.imagen,
          };

          console.log("Producto agregado al carrito local:", body);

          carritoGuardadoLocalStorage.push(body);
        }

        localStorage.setItem(
          "carrito",
          JSON.stringify(carritoGuardadoLocalStorage)
        );

        toast.success(
          "Producto agregado al carrito. Recuerda iniciar sesión para completar tu pago."
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error(
          "No hay existencias disponibles de este producto. No se pueden añadir más unidades a tu carrito."
        );
      } else {
        console.error("Error al agregar producto al carrito:", error);
      }
    } finally {
      setAgregando(false); // Restablece el estado general de "agregando"
      setAgregandoPorBoton((prev) => ({ ...prev, [botonId]: false }));
    }
  };

  return (
    <>
      <div className={styles.product}>
        <div className={styles.contenedorTituloDescuento}>
          {data?.promocion?.tipo_promocion && (
            <div className={styles.descuentos}>
              {data?.promocion.tipo_promocion === "CANT" && `Super promo `}
              {data?.promocion.tipo_promocion === "DESC" &&
                `${data?.promocion.descuento} descuento`}
              {data?.promocion.tipo_promocion === "COMB" && `Super promo `}
            </div>
          )}
          <h2 className={styles.nombre}> {data.descripcion_prod}</h2>
        </div>
        {botones === false && (
          <div className={styles.contenedorPrecio}>
            <div
              className={
                userType === "DIS" ? styles.distribuidora : styles.precio
              }
            >
              $
              {userType === "DIS"
                ? data.precio_DIS || data.psrecio_DIS // Noté un pequeño error en la palabra "precio_DIS" en el ejemplo original
                : data.precio_CON}
            </div>
            {userType === "DIS" && (
              <div className={styles.catalogo}>
                Precio de catálogo:{" "}
                <b className={styles.tachado}>{data.precio_CON}</b>
              </div>
            )}
            {userType !== "DIS" &&
              tieneDescuento &&
              data.promocion.tipo_promocion !== "CANT" && (
                <p>Precio Regular: ${precioRegularLimpio} </p>
              )}
          </div>
        )}
        <p>CÓD. {data.producto_id}</p>

        <div className={styles.descriptionLarge}>{data.descripcion_larga}</div>
        {data.linea_id === "99" ? null : (
          <>
            {!resultado && (
              <p className={styles.conoceMas} onClick={desplegarConoce}>
                {mostrarDetalles ? "VER MENOS" : "CONOCE MÁS"}
              </p>
            )}
            {mostrarDetalles && !resultado && lookbook && (
              <div className={styles.contenedorDetalle}>
                <div className={styles.parrafoDetalle}>
                  <p className={styles.parrafoPregunta}>Tipo de producto:</p>
                  <p className={styles.parrafoRespuesta}>
                    {lookbook.lookbook_tipo_producto}
                  </p>
                </div>
                <div className={styles.parrafoDetalle}>
                  <p className={styles.parrafoPregunta}>Familia:</p>
                  <p className={styles.parrafoRespuesta}>
                    {lookbook.lookbook_familia}
                  </p>
                </div>
                <div className={styles.parrafoDetalle}>
                  <p className={styles.parrafoPregunta}>¿Para quién?:</p>
                  <p className={styles.parrafoRespuesta}>
                    {lookbook.lookbook_para_quien}
                  </p>
                </div>
                <div className={styles.parrafoDetalle}>
                  <p className={styles.parrafoPregunta}>Tamaño:</p>
                  <p className={styles.parrafoRespuesta}>
                    {lookbook.lookbook_tamanio}
                  </p>
                </div>
                <div className={styles.parrafoDetalle}>
                  <p className={styles.parrafoPregunta}>Origen:</p>
                  <p className={styles.parrafoRespuesta}>
                    {lookbook.lookbook_origen}
                  </p>
                </div>
                <div className={styles.parrafoDetalle}>
                  <p className={styles.parrafoPregunta}>
                    ¿Por qué usar esta línea?
                  </p>
                  <p className={styles.parrafoRespuesta}>
                    {lookbook.lookbook_porque_usarlo}
                  </p>
                </div>
                <div className={styles.parrafoDetalle}>
                  <p className={styles.parrafoPregunta}>Cómo usar:</p>
                  <p className={styles.parrafoRespuesta}>
                    {lookbook.lookbook_modo_uso}
                  </p>
                </div>
                <div className={styles.parrafoDetalle}>
                  <p className={styles.parrafoPregunta}>Ingredientes:</p>
                  <p className={styles.parrafoRespuesta}>
                    {lookbook.lookbook_ingredientes}
                  </p>
                </div>
              </div>
            )}
          </>
        )}
        <div className={styles.contenedorbtn}>
          {userType === "DIS" ? (
            <div className={styles.cantidadDis}>
              <button
                className={styles.botonCantidads}
                onClick={handleDecrement}
              >
                -
              </button>
              <input
                type="tel"
                pattern="[0-9]*"
                inputMode="numeric"
                maxLength="3"
                required
                value={cantidadIngresada}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className={styles.inputCantidad}
              />
              <button
                className={styles.botonCantidads}
                onClick={handleIncrement}
              >
                +
              </button>
            </div>
          ) : (
            <div className={styles.cantidad}>
              <button
                className={styles.botonCantidad}
                onClick={handleDecrement}
              >
                -
              </button>
              <div className={styles.botonCantidads}>{cantidad}</div>
              <button
                className={styles.botonCantidad}
                onClick={handleIncrement}
              >
                +
              </button>
            </div>
          )}

          {/* Carrito  */}
          <button
            className={styles.carrito}
            onClick={() => handleCarrito(data)}
            disabled={loading || addedToCart}
          >
            {loading
              ? "Agregando..."
              : addedToCart
              ? "Agregado al carrito"
              : "Agregar al pedido"}
            <svg
              className={styles.svg}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M14.0531 4.66723C13.8179 4.26047 13.3877 4.00889 12.9198 3.99948H4.38723L4.00163 2.49236C3.92169 2.19375 3.64425 1.9892 3.33389 1.9986H2.00075C1.63161 1.9986 1.33301 2.29721 1.33301 2.66635C1.33301 3.03549 1.63161 3.33409 2.00075 3.33409H2.82838L4.66938 10.1738C4.74932 10.4724 5.02676 10.6769 5.33712 10.6675H11.3374C11.589 10.6675 11.8171 10.5241 11.9299 10.3007L14.1165 5.92748C14.3093 5.52307 14.2858 5.05048 14.0554 4.66723H14.0531ZM10.9189 9.33438H5.83793L4.75167 5.33497H12.9198L10.9189 9.33438Z"
                fill="white"
              />
              <path
                d="M5.00122 13.999C5.5531 13.999 6.00048 13.5516 6.00048 12.9998C6.00048 12.4479 5.5531 12.0005 5.00122 12.0005C4.44934 12.0005 4.00195 12.4479 4.00195 12.9998C4.00195 13.5516 4.44934 13.999 5.00122 13.999Z"
                fill="white"
              />
              <path
                d="M11.6672 13.999C12.2191 13.999 12.6665 13.5516 12.6665 12.9998C12.6665 12.4479 12.2191 12.0005 11.6672 12.0005C11.1154 12.0005 10.668 12.4479 10.668 12.9998C10.668 13.5516 11.1154 13.999 11.6672 13.999Z"
                fill="white"
              />
            </svg>
          </button>
        </div>

        {data?.promocion?.tipo_promocion === "CANT" && (
          <>
            <div className={styles.bannerCantidad}>
              {(() => {
                // Obtener los códigos eliminando el prefijo "2x,"
                const codigos =
                  data?.promocion?.cantidad?.split(",").slice(1) ||
                  data?.promocion?.cantidad_1?.split(",").slice(1) ||
                  data?.promocion?.cantidad_2?.split(",").slice(1);

                // Buscar imágenes únicas que coincidan con al menos un código
                const imagenesUnicas = [
                  ...new Set(
                    images
                      .keys()
                      .filter((img) =>
                        codigos.some((codigo) => img.includes(codigo))
                      )
                  ),
                ];

                // Renderizar solo las imágenes únicas
                return imagenesUnicas.map((imagen, index) => (
                  <img
                    key={index}
                    src={images(imagen)}
                    alt={`Imagen promocional ${index}`}
                    className={styles.promoImage}
                  />
                ));
              })()}
            </div>

            {data.promocion.imagenes_cantidad.length > 0 && (
              <p onClick={handleConoceMas}>
                Selecciona producto
                <button className={styles.botonConoceMas}>
                  {conoceMas ? "▲" : "▼"}
                </button>
              </p>
            )}
            {conoceMas && (
              <>
                <div className={styles.contenedorDetallePromo}>
                  {/* {console.log("Códigos a renderizar:", codigosPromocion)} */}
                  {codigosPromocion?.map((imagen, index) => (
                    <div
                      key={index}
                      className={`${styles.contenedorPrincipalImg} ${
                        codigoSeleccionado === imagen ? styles.seleccionado : ""
                      }`}
                      onClick={() => manejarSeleccion(imagen)}
                    >
                      <div
                        className={`${styles.contenedor_img} ${
                          codigoSeleccionado === imagen
                            ? styles.contenedorImgActivo
                            : ""
                        }`}
                      >
                        <img
                          src={`${process.env.REACT_APP_URL_IMG}/${imagen}.webp`}
                          alt={`Imagen promocional ${index}`}
                          className={styles.promoImageSeleccion}
                          onError={(e) => {
                            e.target.onerror = null; // Evita bucles infinitos si la imagen PNG también falla
                            e.target.src = `${process.env.REACT_APP_URL_IMG}/${imagen}.png`;
                          }}
                          onLoad={(e) => {
                            // Registro de dimensiones para debug
                            console.log(`Imagen ${imagen} cargada:`, {
                              naturalWidth: e.target.naturalWidth,
                              naturalHeight: e.target.naturalHeight,
                              renderedWidth: e.target.offsetWidth,
                              renderedHeight: e.target.offsetHeight,
                            });
                          }}
                          onMouseEnter={(e) =>
                            e.target.classList.add(styles.imageHover)
                          }
                          onMouseLeave={(e) =>
                            e.target.classList.remove(styles.imageHover)
                          }
                        />
                      </div>
                      <p className={styles.codigoPromo}>CÓD.{imagen}</p>
                    </div>
                  ))}
                </div>

                <button
                  className={styles.carritoPromociones}
                  onClick={() => agregarAlCarrito(0)} // Pasa un índice único si es necesario
                  disabled={agregando}
                >
                  {agregando ? "Agregando..." : "Agregar al pedido"}{" "}
                  <svg
                    className={styles.svg}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14.0531 4.66723C13.8179 4.26047 13.3877 4.00889 12.9198 3.99948H4.38723L4.00163 2.49236C3.92169 2.19375 3.64425 1.9892 3.33389 1.9986H2.00075C1.63161 1.9986 1.33301 2.29721 1.33301 2.66635C1.33301 3.03549 1.63161 3.33409 2.00075 3.33409H2.82838L4.66938 10.1738C4.74932 10.4724 5.02676 10.6769 5.33712 10.6675H11.3374C11.589 10.6675 11.8171 10.5241 11.9299 10.3007L14.1165 5.92748C14.3093 5.52307 14.2858 5.05048 14.0554 4.66723H14.0531ZM10.9189 9.33438H5.83793L4.75167 5.33497H12.9198L10.9189 9.33438Z"
                      fill="white"
                    />
                    <path
                      d="M5.00122 13.999C5.5531 13.999 6.00048 13.5516 6.00048 12.9998C6.00048 12.4479 5.5531 12.0005 5.00122 12.0005C4.44934 12.0005 4.00195 12.4479 4.00195 12.9998C4.00195 13.5516 4.44934 13.999 5.00122 13.999Z"
                      fill="white"
                    />
                    <path
                      d="M11.6672 13.999C12.2191 13.999 12.6665 13.5516 12.6665 12.9998C12.6665 12.4479 12.2191 12.0005 11.6672 12.0005C11.1154 12.0005 10.668 12.4479 10.668 12.9998C10.668 13.5516 11.1154 13.999 11.6672 13.999Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </>
            )}
          </>
        )}

        {/* //promocion de combinados */}

        {data.promociones?.productos_relacionados?.map((producto, index) => (
          <div key={index}></div>
        ))}

        {data?.promocion?.tipo_promocion === "COMB" && (
          <>
            <div className={styles.bannerCantidad}>
              {(() => {
                try {
                  // Crear el contexto para cargar imágenes
                  const images = require.context(
                    "../../../public/imagenesPromociones",
                    false,
                    /\.png$/
                  );

                  // Obtener los códigos del producto como array
                  const codigos = [data?.producto_id]; // Aseguramos que sea un array

                  const imagenUnica = images
                    .keys()
                    .find((img) =>
                      codigos.some((codigo) => img.includes(codigo))
                    );

                  // Renderizar la imagen única (o mensaje si no se encuentra)
                  if (imagenUnica) {
                    return (
                      <img
                        src={images(imagenUnica)}
                        alt={`Imagen promocional única`}
                        className={styles.promoImage}
                      />
                    );
                  } else {
                    return <p>No se encontró ninguna imagen promocional.</p>;
                  }
                } catch (error) {
                  // console.error("Error al cargar las imágenes:", error);
                  return <p>No se pudieron cargar las imágenes.</p>;
                }
              })()}
            </div>
            <p onClick={handleConoceMas}>
              Selecciona producto
              <button className={styles.botonConoceMas}>
                {conoceMas ? "▲" : "▼"}
              </button>
            </p>
            {conoceMas && (
              <>
                {[codigos, codigos1, codigos2, codigos3].map(
                  (codigosArray, index) => (
                    <div key={index}>
                      <div className={styles.contenedorDetallePromo}>
                        {codigosArray && codigosArray.length > 0 ? ( // Verificamos que haya elementos en el array
                          codigosArray.map((imagen, i) => (
                            <div
                              key={i}
                              className={`${styles.contenedorPrincipalImg} ${
                                codigoSeleccionado === imagen
                                  ? styles.seleccionado
                                  : ""
                              }`}
                              onClick={() => manejarSeleccion(imagen, index)}
                            >
                              <div
                                className={`${styles.contenedor_img} ${
                                  codigoSeleccionado === imagen
                                    ? styles.contenedorImgActivo
                                    : ""
                                }`}
                              >
                                <img
                                  src={`${process.env.REACT_APP_URL_IMG}/${imagen}.webp`}
                                  alt={`Imagen promocional ${index}`}
                                  className={styles.promoImageSeleccion}
                                  onError={(e) => {
                                    e.target.onerror = null; // Evita bucles infinitos si la imagen PNG también falla
                                    e.target.src = `${process.env.REACT_APP_URL_IMG}/${imagen}.png`;
                                  }}
                                  onLoad={(e) => {
                                    // Registro de dimensiones para debug
                                    console.log(`Imagen ${imagen} cargada:`, {
                                      naturalWidth: e.target.naturalWidth,
                                      naturalHeight: e.target.naturalHeight,
                                      renderedWidth: e.target.offsetWidth,
                                      renderedHeight: e.target.offsetHeight,
                                    });
                                  }}
                                  onMouseEnter={(e) =>
                                    e.target.classList.add(styles.imageHover)
                                  }
                                  onMouseLeave={(e) =>
                                    e.target.classList.remove(styles.imageHover)
                                  }
                                />
                              </div>
                              <p className={styles.codigoPromo}>CÓD.{imagen}</p>
                            </div>
                          ))
                        ) : (
                          <></> // Mensaje en caso de que no haya datos
                        )}
                      </div>

                      {codigosArray && codigosArray.length > 0 && (
                        <button
                          className={styles.carritoPromociones}
                          onClick={() => agregarAlCarrito(index)}
                          disabled={
                            agregando || promocionSeleccionada !== index
                          }
                        >
                          {agregandoPorBoton[index]
                            ? "Agregando..."
                            : "Agregar al pedido"}
                          <svg
                            className={styles.svg}
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M14.0531 4.66723C13.8179 4.26047 13.3877 4.00889 12.9198 3.99948H4.38723L4.00163 2.49236C3.92169 2.19375 3.64425 1.9892 3.33389 1.9986H2.00075C1.63161 1.9986 1.33301 2.29721 1.33301 2.66635C1.33301 3.03549 1.63161 3.33409 2.00075 3.33409H2.82838L4.66938 10.1738C4.74932 10.4724 5.02676 10.6769 5.33712 10.6675H11.3374C11.589 10.6675 11.8171 10.5241 11.9299 10.3007L14.1165 5.92748C14.3093 5.52307 14.2858 5.05048 14.0554 4.66723H14.0531ZM10.9189 9.33438H5.83793L4.75167 5.33497H12.9198L10.9189 9.33438Z"
                              fill="white"
                            />
                            <path
                              d="M5.00122 13.999C5.5531 13.999 6.00048 13.5516 6.00048 12.9998C6.00048 12.4479 5.5531 12.0005 5.00122 12.0005C4.44934 12.0005 4.00195 12.4479 4.00195 12.9998C4.00195 13.5516 4.44934 13.999 5.00122 13.999Z"
                              fill="white"
                            />
                            <path
                              d="M11.6672 13.999C12.2191 13.999 12.6665 13.5516 12.6665 12.9998C12.6665 12.4479 12.2191 12.0005 11.6672 12.0005C11.1154 12.0005 10.668 12.4479 10.668 12.9998C10.668 13.5516 11.1154 13.999 11.6672 13.999Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  )
                )}
              </>
            )}
          </>
        )}

        {resultado && lookbook && (
          <div className={styles.contenedorDetalle}>
            <div className={styles.parrafoDetalle}>
              <p className={styles.parrafoPregunta}>Tipo de producto:</p>
              <p className={styles.parrafoRespuesta}>
                {lookbook.lookbook_tipo_producto}
              </p>
            </div>
            <div className={styles.parrafoDetalle}>
              <p className={styles.parrafoPregunta}>Familia:</p>
              <p className={styles.parrafoRespuesta}>
                {lookbook.lookbook_familia}
              </p>
            </div>
            <div className={styles.parrafoDetalle}>
              <p className={styles.parrafoPregunta}>¿Para quién?:</p>
              <p className={styles.parrafoRespuesta}>
                {lookbook.lookbook_para_quien}
              </p>
            </div>
            <div className={styles.parrafoDetalle}>
              <p className={styles.parrafoPregunta}>Tamaño:</p>
              <p className={styles.parrafoRespuesta}>
                {lookbook.lookbook_tamanio}
              </p>
            </div>
            <div className={styles.parrafoDetalle}>
              <p className={styles.parrafoPregunta}>Origen:</p>
              <p className={styles.parrafoRespuesta}>
                {lookbook.lookbook_origen}
              </p>
            </div>
            <div className={styles.parrafoDetalle}>
              <p className={styles.parrafoPregunta}>
                ¿Por qué usar esta línea?
              </p>
              <p className={styles.parrafoRespuesta}>
                {lookbook.lookbook_porque_usarlo}
              </p>
            </div>
            <div className={styles.parrafoDetalle}>
              <p className={styles.parrafoPregunta}>Cómo usar:</p>
              <p className={styles.parrafoRespuesta}>
                {lookbook.lookbook_modo_uso}
              </p>
            </div>
            <div className={styles.parrafoDetalle}>
              <p className={styles.parrafoPregunta}>Ingredientes:</p>
              <p className={styles.parrafoRespuesta}>
                {lookbook.lookbook_ingredientes}
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductDescriptionInfo;
