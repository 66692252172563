import React, { useEffect, useState } from "react";
import style from "../../../css/LoginDistribuidor.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { loginSuccess } from "../../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { PopupChangePassword } from "../PopupChangePassword";
import { FaEye } from "react-icons/fa";
import { IoMdEyeOff } from "react-icons/io";
import { FaEyeSlash } from 'react-icons/fa';

export const LoginDistribuidora = () => {
  const dispatch = useDispatch();
  const [seePassword, setSeePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aqui, setAqui] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const navigate = useNavigate();
  const hanldeBoton = () => {
    setAqui(!aqui);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleRepeatPasswordVisibility = () => setShowRepeatPassword(!showRepeatPassword);

  const {
    register: registerLogin,
    handleSubmit: handleSubmitLogin,
    formState: { errors: errorsLogin },
    setValue: setValueLogin,
    reset: resetLogin,
  } = useForm();

  const {
    register: registerActivate,
    handleSubmit: handleSubmitActivate,
    formState: { errors: errorsActivate },
    reset: resetActivate,
    trigger,
    getValues
  } = useForm({
    mode: "onChange", // Las validaciones se activarán en cuanto haya algún cambio en los inputs
  });

  useEffect(() => {
    trigger(); // Esto dispara todas las validaciones desde el principio
  }, [aqui]);


  const handleCloseAndReset = () => {
    setAqui(false);
    resetActivate();
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const registrarUsuario = async (data) => {
    setLoading(true);
    try {
      const dataConTipoProceso = {
        ...data,
        tipo: "DIS",
        proceso: "R",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        dataConTipoProceso,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        resetActivate();
        dispatch(
          loginSuccess({
            user: response.data.user_detalle,
            token: response.headers['authorization'],
            role: response.data.user_detalle.role, // Asegúrate de que esta información la devuelve tu backend
            userType: response.data.tipo, // Asume que el tipo (CON o DIS) se devuelve en la respuesta
          })
        );
        toast.success("Cuenta activada", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          // Hace que el toast sea persistente
          toastId: "loginSuccess", // Este ID único evita duplicados y permite persistencia
          style: { fontSize: "20px" },
        });
        setTimeout(() => {
          navigate("/home-distribuidor");
        }, 2000);
      }
    } catch (error) {
      const mensajeError = error.response?.data?.detalle || "Algo salió mal";
      toast.error("Error al activar la cuenta: " + mensajeError, {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: "loginError",
        style: { fontSize: "20px" },
      });
    } finally {
      setLoading(false);
    }
  };
  const loginUsuario = async (data) => {
    setLoading(true);
    try {
      const dataConTipoProceso = {
        ...data,
        tipo: "DIS",
        proceso: "L",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        dataConTipoProceso,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        // console.log('Datos de respuesta:', response.data); // Verifica qué datos estás recibiendo
        resetLogin();

        dispatch(
          loginSuccess({
            user: response.data.user_detalle,
            token: response.headers['authorization'],
            role: response.data.user_detalle.role,
            userType: response.data.tipo,
          })
        );
      }

      toast.success("Inicio de sesión correcto", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // Hace que el toast sea persistente
        toastId: "loginSuccess", // Este ID único evita duplicados y permite persistencia
      });
      setTimeout(() => {
        navigate("/home-distribuidor");
      }, 2000);
    } catch (error) {
      const mensajeError = error.response?.data?.detalle || "Algo salió mal";

      // Usar una expresión regular para extraer el mensaje sin el número al principio
      const mensajeSinNumero = mensajeError.replace(/^\d+,\s*/, '');

      if (mensajeSinNumero === "hola. Código no registrado en tienda en linea, registrate") {
        toast.error("Error al iniciar sesión, por favor activa tu cuenta");
      } else {
        toast.error("Error al iniciar sesión: " + mensajeSinNumero);
        setValueLogin("username", "");
        setValueLogin("password", "");
      }
    } finally {
      setLoading(false);
    }
  };


  registerActivate("repeatPassword", {
    required: {
      value: true,
      message: "Confirmar contraseña",
    },
    validate: (value) => value === getValues("password") || "Las contraseñas no coinciden",
  });


  // Verificar si la cadena cumple con el formato 00000000-0
  function isValidFormat(str) {
    const regex = /^\d{8}-\d$/; // Expresión regular para 8 números, un guion, y un número final
    return regex.test(str) && str.length === 10;
  }

  return (
    <div className={style.containerLoginDistributor}>
      <section className={style.sectionLoginInputs}>
        <h2 className={style.titleWelcome}>Bienvenido (a)</h2>
        <form
          className={style.formLoginDistributor}
          onSubmit={handleSubmitLogin(loginUsuario)}
        >
          <div className={style.containerInputsLogin}>
            <input
              className={style.inputsLogin}
              type="text"
              placeholder="Codigo o DUI:"
              {...registerLogin("username", {
                required: {
                  value: true,
                  message: "DUI es requerido",
                },
              })}
            />
            {errorsLogin.username && (
              <span className={style.errorss}>{errorsLogin.username.message}</span>
            )}
          </div>

          <div className={style.containerInputsLogin}>
            <input
              className={style.inputsLogin}
              type={seePassword ? "text" : "password"}
              placeholder="Contraseña:"
              {...registerLogin("password", {
                required: {
                  value: true,
                  message: "Contraseña es requerida",
                },
              })}
            />
            <button
              type="button"
              onClick={() => setSeePassword(!seePassword)}
              className={style.eyeIconInPassword}
            >
              {seePassword ? (
                <IoMdEyeOff size={25} color="#7b7676" />
              ) : (
                <FaEye size={25} color="#7b7676" />
              )}
            </button>

            {errorsLogin.password && (
              <span className={style.errorss}>{errorsLogin.password.message}</span>
            )}
          </div>
          <div className={loading ? style.loadingScreen : style.loadingScreenOff} />

          <button
            type="submit"
            onClick={handleScrollToTop}
            className={style.buttonLoginDistributor}
          >
            {loading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              "Ingresar"
            )}
          </button>
        </form>
        <div>
          <p className={style.confirmationCode}>
            ¿Quieres activar tu código?
            <button className={style.aqui} onClick={hanldeBoton}>
              Aquí
            </button>
          </p>
          <button
            onClick={() => setChangePassword(true)}
            className={style.forgotPasswordButton}
          >
            Olvide mi contraseña
          </button>
        </div>
      </section>
      {aqui === true && (
        <section className={style.popupWrapper}>
          <div className={style.popup} onClick={(e) => e.stopPropagation()}>
            <h2 className={style.titleActiveAcount}>Activa tu cuenta</h2>
            <form onSubmit={handleSubmitActivate(registrarUsuario)} className={style.formulario}>
              <label></label>
              <input
                className={style.inputs}
                type="number"
                placeholder="Código:"
                {...registerActivate("username", {
                  required: {
                    value: true,
                    message: "El código es requerido",
                  },
                })}
              />
              {errorsActivate.username && <span className={style.errorss}>{errorsActivate.username.message}</span>}
              <input
                className={style.inputs}
                type="text"
                placeholder="DUI: 00000000-0"
                {...registerActivate("dui", {
                  required: {
                    value: true,
                    message: "DUI es requerido",
                  },
                  validate: {
                    isValidDui: (value) => isValidFormat(value) || "El DUI no cumple con el formato 00000000-0",
                  },
                })}
              />
              {errorsActivate.dui && <span className={style.errorss}>{errorsActivate.dui.message}</span>}

              <label htmlFor=""></label>

              {/* Input de Contraseña */}
              <div className={style.inputContainer}>
                <input
                  className={style.inputs}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Contraseña:"
                  {...registerActivate("password", {
                    required: "Contraseña es requerida",
                    minLength: { value: 8, message: "Al menos 8 caracteres" },
                    validate: {
                      containsUpperCase: (value) => /[A-Z]/.test(value) || "Una letra mayúscula",
                      containsLowerCase: (value) => /[a-z]/.test(value) || "Una letra minúscula",
                      containsNumber: (value) => /\d/.test(value) || "Un número",
                      containsSpecialChar: (value) => /[@#$%&]/.test(value) || "Uno de estos caracteres especiales: @, #, $, %, &",
                    },
                  })}
                />
                <p className={style.toggleButton} onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </p>
              </div>
              {/* {errorsActivate.password && (
                <span className={style.errorss}>{errorsActivate.password.message}</span>
              )} */}
              {/* Mensaje de error para la contraseña */}
              {errorsActivate.password && (
                <span className={style.errorss}>{errorsActivate.password.message}</span>
              )}

              {/* Requisitos de la contraseña */}

              <ul className={style.requisitosContraseña}>
                <li>Al menos 8 caracteres</li>
                <li>Una letra mayúscula</li>
                <li>Una letra minúscula</li>
                <li>Un número</li>
                <li>Uno de estos caracteres especiales: @, #, $, %, &</li>
              </ul>


              {/* Input de Repetir Contraseña */}
              <div className={style.inputContainer}>
                <input
                  className={style.inputs}
                  type={showRepeatPassword ? 'text' : 'password'}
                  placeholder="Repetir contraseña:"
                  {...registerActivate("repeatPassword", {
                    required: {
                      value: true,
                      message: "Confirmar contraseña",
                    },
                    validate: (value) => value === getValues("password") || "Las contraseñas no coinciden",
                  })}
                />
                <p className={style.toggleButton} onClick={toggleRepeatPasswordVisibility}>
                  {showRepeatPassword ? <FaEyeSlash /> : <FaEye />}
                </p>
              </div>
              {errorsActivate.repeatPassword && <span className={style.errorss}>{errorsActivate.repeatPassword.message}</span>}

              <button type="submit" className={style.enviar} disabled={loading}>
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : (
                  "Enviar"
                )}
              </button>
              <button className={style.cerrar} onClick={handleCloseAndReset}>Cerrar</button>
            </form>
          </div>
        </section>
      )}

      {changePassword && (
        <PopupChangePassword setChangePassword={setChangePassword} />
      )}
    </div>
  );
};
