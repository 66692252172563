import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { articles } from "../../../../../constants/post";
import style from "../../../Blog/Blog.module.css";

export const MultipleSlider = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };

  const settings = {
    dots: true, // Muestra los puntos de navegación
    infinite: true, // Habilita un bucle infinito
    speed: 100, // Velocidad de transición en milisegundos
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // Cuando el ancho de la ventana es igual o mayor a 1024px
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // Cuando el ancho de la ventana es igual o mayor a 768px
        settings: {
          slidesToShow: 1,
        },
      },
    ], // Cantidad de elementos a desplazar en cada cambio

    customPaging: (i) => <p className={style.doctsColorArticles}></p>,
  };

  return (
    <div className={style.carouselContainer}>
      <Slider {...settings}>
        {articles.map((acticle, index) => (
          <div key={index} className={style.cards}>
            <div className={style.cardInnerUltimosArticulos}>
              <div className={style.contenedorTitulo}>
                <h4 className={style.tituloCards}>{acticle.title}</h4>
              </div>
              <div className={style.contenedorImg}>
                <img
                  className={style.imagen}
                  src={acticle.image}
                  alt={`Imagen ilustrativa del producto ${acticle.title}`}
                />
              </div>
              <div className={style.contenedorDescripcion}>
                <p className={style.descripcionUltimosArticulos}>{acticle.description}</p>
              </div>

              <Link
                onClick={handleScrollToTop}
                to={"/detalle-blog"}
                state={{ article: acticle }}
                className={style.contenedorBtn}
              >
                Leer más
              </Link>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
