import React from 'react';
import styles from "./BotonCompra.module.css";
import { Link } from 'react-router-dom';

export const BotonCompra = ({ text, path, currentIndex, index, data, estilos }) => {
    if (!data || !path) {
        return null; // No renderizar nada si path está vacío
    }

    const currentPath = window.location.pathname;
    let ruta = path;

    if (data.length > 1) {
        if (text === "Comprar ahora") {
            ruta = "/tienda-linea-cliente/06?page=1&subCategory=062";
        } else if (text === "Descubrir aquí") {
            ruta = "/tienda-linea-cliente/06?page=1&subCategory=068";
        } else if (text === "Comprar polvos") {
            ruta = "/tienda-linea-cliente/06?page=1&subCategory=067";
        }

        return (
            <Link className={`${styles[estilos[0]]}`} to={ruta}>
                <button className={styles[estilos[1]]}>
                    {text}
                </button>
            </Link>
        );
    }

    return (
        <Link to={ruta} state={{ data: { ...data }, ruta: { currentPath } }} className={`${styles[estilos[0]]}`}>
            {text && (
                <button className={styles[estilos[1]]}>
                    {text}
                </button>
            )}
        </Link>
    );
};
