import { useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";

import styles from "./listMenu.module.css";
import { Link } from "react-router-dom";
import { useBorrador } from "../../../../../state/context/borradorContext";

export const ListMenu = ({ data, handleClose }) => {
  const [openMenu, setOpenMenu] = useState(null);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const { subLineasPorCategoria } = useBorrador();

  const handleMenuClick = (index) => {
    setOpenMenu(openMenu === index ? null : index);
  };

  const handleSubMenuClick = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  return (
    <ul>
      {data.map((menuItem, index) => (
        <li key={index}>
          {menuItem.items && (
            <button
              className={styles.itemButton}
              onClick={() => handleMenuClick(index)}
            >
              <span className={styles.itemTitle}>{menuItem.name}</span>
              {openMenu === index ? (
                <IoIosArrowDown color="#444" />
              ) : (
                <IoIosArrowForward color="#444" />
              )}
            </button>
          )}
          {menuItem.path && (
            <Link
              className={styles.itemButton}
              to={menuItem.path}
              onClick={handleClose}
            >
              <span className={styles.itemTitle}>{menuItem.name}</span>
            </Link>
          )}
          {openMenu === index && menuItem.name === "Productos" ? (
            <ul>
              {Object.keys(subLineasPorCategoria).map((categoria, index) => (
                <li key={`${index}`}>
                  {categoria && (
                    <button
                      className={styles.itemButton}
                      onClick={() => handleSubMenuClick(`${index}`)}
                    >
                      <span className={styles.itemSubTitle}>{categoria}</span>
                      {openSubMenu === `${index}` ? (
                        <IoIosArrowDown color="#444" />
                      ) : (
                        <IoIosArrowForward color="#444" />
                      )}
                    </button>
                  )}
                  {openSubMenu === `${index}` &&
                    subLineasPorCategoria[categoria].map(
                      (subLinea, subIndex) => (
                        <ul>
                          <li key={subIndex}>
                            {subLinea.descripcion_sublinea && (
                              <Link
                                className={styles.itemButton}
                                to={`/subLineas/${categoria}/${subLinea.sublinea_id}`}
                                onClick={handleClose}
                              >
                                <span className={styles.itemCategoryTwo}>
                                  {subLinea.descripcion_sublinea}
                                </span>
                              </Link>
                            )}
                          </li>
                        </ul>
                      )
                    )}
                </li>
              ))}
              {menuItem.name === "Productos" &&
                menuItem?.items?.map(({ name, path }) => (
                  <Link
                    className={styles.itemButton}
                    to={path}
                    onClick={handleClose}
                  >
                    <span className={styles.itemSubTitle}>{name}</span>
                  </Link>
                ))}
            </ul>
          ) : (
            openMenu === index &&
            menuItem.items && (
              <ul>
                {menuItem.items.map((subItem, subIndex) => (
                  <li key={`${index}-${subIndex}`}>
                    {subItem.items && (
                      <button
                        className={styles.itemButton}
                        onClick={() =>
                          handleSubMenuClick(`${index}-${subIndex}`)
                        }
                      >
                        <span className={styles.itemSubTitle}>
                          {subItem.name}
                        </span>
                        {openSubMenu === `${index}-${subIndex}` ? (
                          <IoIosArrowDown color="#444" />
                        ) : (
                          <IoIosArrowForward color="#444" />
                        )}
                      </button>
                    )}
                    {subItem.path && (
                      <Link
                        className={styles.itemButton}
                        to={subItem.path}
                        onClick={handleClose}
                      >
                        <span className={styles.itemCategory}>
                          {subItem.name}
                        </span>
                      </Link>
                    )}
                    {openSubMenu === `${index}-${subIndex}` &&
                      subItem.items && (
                        <ul>
                          {subItem.items.map((subSubItem, subSubIndex) => (
                            <li key={subSubIndex}>
                              {subSubItem.path && (
                                <Link
                                  className={styles.itemButton}
                                  to={subSubItem.path}
                                  onClick={handleClose}
                                >
                                  <span
                                    className={
                                      subSubItem.name === "Tutoriales" ||
                                      subSubItem.name === "Todas las líneas"
                                        ? styles.itemButton
                                        : styles.itemCategoryTwo
                                    }
                                  >
                                    {subSubItem.name}
                                  </span>
                                </Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                  </li>
                ))}
              </ul>
            )
          )}
        </li>
      ))}
    </ul>
  );
};
