export const useGetArticlesDate = () => {
  const fechaActual = new Date();

  const dia = fechaActual.getDate();
  const mes = fechaActual.getMonth() + 1; // Suma 1 porque los meses comienzan en 0 (enero)
  const año = fechaActual.getFullYear();

  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const nombreMes = meses[mes - 1];

  return {
    dia,
    mes,
    año,
    nombreMes,
  };
};
