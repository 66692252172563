import React, { useState } from "react";
import styles from "./MenuCategoria.module.css";
import { Link } from "react-router-dom";
import { menuData } from "../../../assets/utils/SubMenuCategorias";
import { useBorrador } from "../../../state/context/borradorContext";

export const MenuCategoria = ({ mobile }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [subMenus, setSubMenus] = useState({
    "Higiene y cuidado de la piel": false,
    Capilar: false,
    Maquillaje: false,
    Perfumería: false,
    Facial: false,
  });
  const [showTiendaEnLinea, setShowTiendaEnLinea] = useState(false);
  const [showDistribuidorVogue, setShowDistribuidorVogue] = useState(false);
  const [showConoceVogue, setShowConoceVogue] = useState(false);
  const { subLineasPorCategoria } = useBorrador();
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };

  const handleMouseEnter = (menu) => {
    switch (menu) {
      case "TiendaEnLinea":
        setShowTiendaEnLinea(true);
        setShowDistribuidorVogue(false);
        setShowConoceVogue(false);
        break;
      case "DistribuidorVogue":
        setShowDistribuidorVogue(true);
        setShowTiendaEnLinea(false);
        setShowConoceVogue(false);
        break;
      case "ConoceVogue":
        setShowConoceVogue(true);
        setShowTiendaEnLinea(false);
        setShowDistribuidorVogue(false);
        break;
      case "Productos":
        setActiveMenu("Productos");
        break;
      default:
        break;
    }
  };

  const categorias = {
    "Higiene y cuidado de la piel": "05",
    "Capilar": "01",
    "Maquillaje": "06",
    "Facial": "04",
    "Perfumería": "02"
  };

  const closeMenu = () => {
    setShowTiendaEnLinea(false);
    setShowDistribuidorVogue(false);
    setShowConoceVogue(false);
    setActiveMenu("");
  };

  const handleMenuClick = (menuName) => {
    if (menuName === "Productos") {
      const newSubMenus = Object.fromEntries(
        Object.keys(subMenus).map((submenu) => [submenu, false])
      );
      setSubMenus(newSubMenus);
    }
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  return (
    <div className={styles.contenedorGeneral}>
      <div className={mobile ? styles.dropMobile : styles.drop}>
        {/* Tienda en línea */}
        <div
          onMouseEnter={() => handleMouseEnter("TiendaEnLinea")}
          onMouseLeave={closeMenu}
          className={`${styles.menuContainer} ${showTiendaEnLinea ? styles.subrayado : ""
            }`}
        >
          <h4 className={styles.menuTitle}>Tienda en línea</h4>
          {showTiendaEnLinea && (
            <div className={styles.subMenu}>
              <Link
                to="/tienda-linea-cliente/05"
                onClick={() => { closeMenu(); handleScrollToTop(); }}
                className={styles.promociones}
                title="Productos de higiene y cuidado de la piel importados - Vogue El Salvador"
                aria-label="Descubre nuestros productos de higiene y cuidado de la piel importados de España, Brasil y Colombia"
              >
                Higiene y Cuidado de la piel
              </Link>
              <Link
                to="/tienda-linea-cliente/01"
                onClick={() => { closeMenu(); handleScrollToTop(); }}
                className={styles.promociones}
                title="Productos capilares - Vogue El Salvador"
                aria-label="Shampoos, acondicionadores y tratamientos para todo tipo de cabello"
              >
                Capilar
              </Link>
              <Link
                to="/tienda-linea-cliente/02"
                onClick={() => { closeMenu(); handleScrollToTop(); }}
                className={styles.promociones}
                title="Perfumería importada - Vogue El Salvador"
                aria-label="Fragancias y perfumes árabes importados de Dubái y perfumes de Instituto Español"
              >
                Perfumería
              </Link>
              <Link
                to="/tienda-linea-cliente/06"
                onClick={() => { closeMenu(); handleScrollToTop(); }}
                className={styles.promociones}
                title="Productos de maquillaje - Vogue El Salvador"
                aria-label="Labiales matte, gloss y productos de maquillaje importados de alta calidad"
              >
                Maquillaje
              </Link>
              <Link
                to="/tienda-linea-cliente/04"
                onClick={() => { closeMenu(); handleScrollToTop(); }}
                className={styles.promociones}
                title="Productos para cuidado facial - Vogue El Salvador"
                aria-label="Cremas hidratantes, mascarillas, productos para reducir estrías y cuidado facial"
              >
                Facial
              </Link>
            </div>
          )}
        </div>
        {/* Promociones */}
        <Link
          className={styles.promociones}
          to="/oferta"
          onClick={() => handleMenuClick("Promociones")}
        >
          Promociones
        </Link>
        {/* Lo nuevo */}
        <Link
          className={styles.promociones}
          to="/nuevos"
          onClick={() => handleMenuClick("Lo nuevo")}
        >
          Lo nuevo
        </Link>


        {/* Distribuidor Vogue */}
        <div
          onMouseEnter={() => handleMouseEnter("DistribuidorVogue")}
          onMouseLeave={closeMenu}
          className={`${styles.menuContainer} ${showDistribuidorVogue ? styles.subrayado : ""
            }`}
        >
          <h4 className={styles.menuTitle}>Distribuidor Vogue</h4>
          {showDistribuidorVogue && (
            <div className={styles.subMenu}>
              <Link to="/distribuidora" onClick={closeMenu} className={styles.promociones}>Quiero ser distribuidor(a)</Link>
              {/* <Link to="/contactanos" onClick={closeMenu} className={styles.promociones}>Contáctanos</Link> */}
            </div>
          )}
        </div>

        {/* Conoce Vogue */}
        <div
          onMouseEnter={() => handleMouseEnter("ConoceVogue")}
          onMouseLeave={closeMenu}
          className={`${styles.menuContainer} ${showConoceVogue ? styles.subrayado : ""
            }`}
        >
          <h4
            className={styles.menuTitle}>Conoce Vogue</h4>
          {showConoceVogue && (
            <div className={styles.subMenu}>
              <Link to="/quienes-somos" onClick={closeMenu} className={styles.promociones}>Quiénes Somos</Link>
              <Link to="/contactanos" onClick={closeMenu} className={styles.promociones}>Contáctanos</Link>
            </div>
          )}
        </div>

        {/* Productos */}
        <h6
          onMouseEnter={() => handleMouseEnter("Productos")}
          className={`${styles.dropbtn} ${activeMenu === "Productos" ? styles.subrayado : ""
            }`}
          onClick={() => setActiveMenu("Productos")} // Activa el menú desplegable de productos al hacer clic
        >
          {menuData[0].name}
        </h6>
      </div>
      {/* Contenedor de Productos */}
      {activeMenu === "Productos" && (
        <div
          className={styles.contenedorProductos}
          onMouseLeave={() => setActiveMenu("")} // Oculta el menú cuando el mouse sale de la sección
        >
          {Object.keys(subLineasPorCategoria).map((categoria, index) => (
            <div key={index} className={styles.columnas}>
              <Link
                to={`/tienda-linea-cliente/${categorias[categoria]}`} // Aquí obtenemos el número correspondiente al nombre de la subcategoría
                className={styles.subrayada}
                onClick={() => setActiveMenu("")}
              >
                {categoria}
              </Link>
              <ul>
                {subLineasPorCategoria[categoria].map((subLinea, subIndex) => (
                  <li key={subIndex} className={styles.items}>
                    <Link
                      className={styles.promociones}
                      to={`/subLineas/${categoria}/${subLinea.sublinea_id}`}
                      onClick={() => setActiveMenu("")} // Cierra el menú al hacer clic en un enlace
                    >
                      {subLinea.descripcion_sublinea.charAt(0).toUpperCase() + subLinea.descripcion_sublinea.slice(1).toLowerCase()}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className={styles.columnas}>
            <Link to="/todas-lineas" className={styles.subrayada} onClick={() => setActiveMenu("")}>Todas las líneas</Link>
          </div>
          <div className={styles.columnas}>
            <Link to="/videos" className={styles.subrayada} onClick={() => setActiveMenu("")}>Tutoriales</Link>
          </div>

          <div className={styles.columnas}>
            <Link to="/blog" className={styles.subrayada} onClick={() => setActiveMenu("")}>Vogue Tips</Link>
          </div>

        </div>
      )}
    </div>
  );
};
