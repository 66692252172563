import React from "react";
import style from "../Blog/Blog.module.css";
import { SingleSlider } from "./components/SingleSlider";
import { MultipleSlider } from "./components/MultipleSlider";

export const HomeBlog = () => {
  return (
    <div className={style.contenedorPrincipal}>
      {/* <ChatComponent /> */}
      <SingleSlider />
      <p className={style.articulo}>Últimos artículos</p>
      <MultipleSlider />
    </div>
  );
};
