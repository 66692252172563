import React, { useEffect, useState } from "react";
import styles from "./Facturas.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColumDocument } from "../components/ColumDocument";
import axios from "axios";
import { Invoice } from "../components/Invoice";
import { toast } from "react-toastify";
import { useLoading } from "../../state/context/loadingContext";
import { Spinner } from "react-bootstrap";
import { PagoDistribuidor } from "../components/PagarDistribuidor/PagoDistribuidor";
import { redondearDecimales } from "../../hooks/use-redondearDecimales";

export const FacturasPendientes = () => {
  const { user, isAuthenticated, token, userType } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [loadingGetInvoice, setLoadingGetInvoice] = useState(false);
  const [serfinsa, setSerfinsa] = useState(null);
  const [abonos, setAbonos] = useState(0)
  const [openInvoice, setOpenInvoice] = useState(false);
  const [allInvoice, setAllInvoice] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [showPagoDistribuidor, setShowPagoDistribuidor] = useState(false);
  const location = useLocation();
  const message = location.state?.message;
  const { isLoading } = useLoading();
  const [pediIdPagarDespues, setPedidoIdPagarDespues] = useState(null);
  const [factura, setFactura] = useState(null);
  const navigate = useNavigate();



  const getFacturaPedidos = async (invoiceNumber) => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/documentos?id_doc=${invoiceNumber}`, {
      headers: {
        Authorization: `Bearer ${token ?? ""}`,
      },
    });
    setFactura(response.data);
  };
  // console.log("este es la data", allInvoice);

  const getInvoice = async (facturaNo) => {
    try {
      setLoading(true)
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );


      // console.log("factura", facturaNo);

      const abonos = await axios.get(`${process.env.REACT_APP_API_URL}/pedidos/abonos/${facturaNo}`, {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      });



      setAbonos(abonos.data.abonos ? abonos.data?.abonos?.reduce((total, obj) => total + obj.monto, 0) : 0)

      if (res.data.detalle.lista_facturas && res.data.detalle.lista_facturas.length > 0) {
        const selectedInvoice = res.data.detalle.lista_facturas.find(invoice => invoice.factura_no === facturaNo);
        if (selectedInvoice) {
          let totalAbonos = abonos.data.abonos ? abonos.data?.abonos?.reduce((total, obj) => total + obj.monto, 0) : 0;
          setInvoice({
            number_invoice: selectedInvoice.factura_no,
            client: selectedInvoice.nombre,
            id_client: selectedInvoice.cliente_id,
            adress: selectedInvoice.direccion,
            date: selectedInvoice.fecha,
            expired: selectedInvoice.vence,
            detailCart: selectedInvoice.detalles_factura,
            total: selectedInvoice.total,
            saldo: selectedInvoice?.saldo,
            balance: selectedInvoice.total - totalAbonos
          });
          await getFacturaPedidos(selectedInvoice.factura_no);
          setOpenInvoice(true);
        }
      }

      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  // console.log(token);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/")
    }
  }, [])

  const handleGetData = async () => {
    let userIdentifier;
    if (userType === "DIS") {
      userIdentifier = user.cliente_id;
    }
    const resPedido = await axios.get(
      `${process.env.REACT_APP_API_URL}/pedidos/user/${userIdentifier}`,
      {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      }
    );
    setSerfinsa(resPedido?.data);
  };

  const DataPagarDespues = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/pedidos/pagar-despues/${user.cliente_id}`, {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      });
      setPedidoIdPagarDespues(response.data._id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const totalIntereses = allInvoice.reduce((acumulador, factura) => {
    return acumulador + factura.intereses;
  }, 0);
  // console.log(invoice);


  const handleScrollToTop = async (monto, tipoPago) => {


    //sumar todo los intereses

    const total = parseFloat(invoice.saldo) + Number(totalIntereses);
    console.log("este es el abono", abonos);
    console.log("este es el total", total);
    if (redondearDecimales(abonos, 2) >= (Number(total))) {
      toast.error("Se terminó de abonar. En unas horas cambiará de estatus.");
      return;
    }

    const montoRestante = total - abonos;

    if (monto > redondearDecimales(montoRestante, 2)) {
      toast.error(`El monto ingresado supera el monto restante de $${redondearDecimales(montoRestante, 2)}.`);
      return;
    }
    setLoading(true);
    try {
      if (isAuthenticated) {
        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-payment`,
          {
            Monto: monto,
            ConceptoPago: "Pago de cosmeticos",
            Adicionales: "DATOS ADICIONALES",
            facturaId: invoice.number_invoice,
            UrlOrigin: "https://vogueelsalvador.com/home-cliente/informacio-pago",
            UrlRedirect: `https://vogueelsalvador.com/finalizar-abonos/${invoice.number_invoice}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          }
        );
        const { Satisfactorio, Datos } = res.data;
        if (Satisfactorio) {
          window.location.href = `https://www.serfinsacheckout.com/${Datos?.UrlPost}`;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetInvoice = async () => {
    setLoadingGetInvoice(true);
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setAllInvoice(res?.data?.detalle?.lista_facturas);
      // console.log(allInvoice);

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGetInvoice(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleGetInvoice();
      handleGetData();
      if (!isLoading && message) {
        toast.success(message);
      }
      DataPagarDespues();
    }
  }, []);

  const formaPago = () => {
    if (userType === "DIS") {
      return user?.tipo_cre === "CONTADO" ? "Contado" : "Crédito";
    }
    return null; // Opcional, por si `userType` no es "DIS"
  };

  // console.log(allInvoice);


  const handleSeleccionarMonto = async () => {
    const selectedInvoice = allInvoice[0];
    const abonos = await axios.get(`${process.env.REACT_APP_API_URL}/pedidos/abonos/${selectedInvoice.factura_no}`, {
      headers: {
        Authorization: `Bearer ${token ?? ""}`,
      },
    });
    let totalAbonos = abonos.data.abonos ? abonos.data?.abonos?.reduce((total, obj) => total + obj.monto, 0) : 0;
    setInvoice({
      number_invoice: selectedInvoice.factura_no,
      client: selectedInvoice.nombre,
      id_client: selectedInvoice.cliente_id,
      adress: selectedInvoice.direccion,
      date: selectedInvoice.fecha,
      expired: selectedInvoice.vence,
      detailCart: selectedInvoice.detalles_factura,
      total: selectedInvoice.total,
      saldo: selectedInvoice?.saldo,
      balance: selectedInvoice.total - totalAbonos
    });
    setShowPagoDistribuidor(true);
  };

  const handleClosePagoDistribuidor = () => {
    setShowPagoDistribuidor(false);
  };

  const hasInvoice = allInvoice && allInvoice.length > 0;
  const hasInterest = allInvoice.some((invoice) => parseFloat(invoice.intereses) > 0);
  // console.log("invoice", invoice);

  return (
    <>

      {
        isAuthenticated === true && (

          <div className={styles.contenedorPrincipal}>
            <h1 className={styles.titulo}>Hola, {user.nombres.split(" ")[0]}</h1>
            <div className={styles.contenedor}>
              <p className={styles.parrafos}>
                COD. {user.cliente_id}
                <br />
                DUI: {user.dui}
              </p>
            </div>
            <div className={styles.contenedorDatos}>
              <div className={styles.datos}>
                <Link to={"/datos-generales-distribuidor"} className={styles.parrafo}>
                  Datos generales
                </Link>
                <Link to={""} className={styles.parrafo}>
                  Facturas pendientes
                </Link>
                <Link to={"/estado-cuenta"} className={styles.parrafo}>
                  Estado de cuenta
                </Link>
                {allInvoice.length > 0 && formaPago() === "Crédito" && (
                  <button className={styles.realizarPago} onClick={handleSeleccionarMonto}>
                    {(loadingGetInvoice || loading) ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className={styles.miSpinner}
                      >
                        <span className="visually-hidden">Cargando...</span>
                      </Spinner>
                    ) : (
                      "Realizar abono"
                    )}
                  </button>
                )}
              </div>
              <div className={styles.contenedorTabla}>
                <table className={styles.Tabla}>
                  <thead>
                    <tr>
                      <th>No. factura</th>
                      <th>Fecha</th>
                      <th>Vencimiento</th>
                      <th>Total factura</th>
                      <th>Saldo factura</th>
                      <th>Interés</th>
                    </tr>
                  </thead>
                  {loadingGetInvoice ? (
                    <p className={styles.loadingMessage}>Cargando...</p>
                  ) : allInvoice.length > 0 ? (
                    allInvoice.map((doc, index) => (
                      <>
                        <ColumDocument
                          key={index}
                          allInvoice={doc}
                          getInvoice={getInvoice}
                        />

                      </>

                    ))
                  ) : (
                    <h1 className={styles.messageNoFoundData}>
                      No se encontró facturas pendientes
                    </h1>
                  )}
                </table>
                {hasInterest && hasInvoice && (

                  <p className={styles.textoAdvertencia}>El interés de tu factura varía diariamente.</p>
                )

                }

              </div>
            </div>
            {/*
       
        */}
            {openInvoice && invoice && (
              <Invoice
                saldosFactura={invoice}
                invoice={factura}
                setOpenInvoice={setOpenInvoice}
                saldo={invoice.total}
                intereses={allInvoice.find(item => item.factura_no === invoice.number_invoice)?.intereses}
                fecha={allInvoice.find(item => item.factura_no === invoice.number_invoice)?.fecha_vencimiento}
              />
            )}
            {showPagoDistribuidor && invoice && (
              <PagoDistribuidor
                // total={allInvoice[0].saldo}
                total={invoice.total}
                handleScrollToTop={handleScrollToTop}
                handleClose={handleClosePagoDistribuidor}
                // saldo={allInvoice.find(item => item.factura_no === invoice.number_invoice)?.saldo}
                saldo={parseFloat((Number(invoice.saldo) + allInvoice.find(item => item.factura_no === invoice.number_invoice)?.intereses || 0).toFixed(2))}
                balance={redondearDecimales(invoice.balance, 2)}
              />
            )}
          </div>
        )
      }

    </>
  );
};
