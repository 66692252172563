import { useLocation, useSearchParams } from "react-router-dom";

export const useCustomQueryParams = () => {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  const query = new URLSearchParams(location?.search);
  const currentPage = query.get("page");
  const subCategory = query.get("subCategory");

  return {
    currentPage,
    subCategory,
    setSearchParams,
  };
};
