import React, { useEffect } from "react";
import styles from "../../../css/InformacionPago.module.css";
import axios from "axios";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProductsTotal } from "./ProductsTotal";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { LoadingText } from "../../LoadingText";
import { Spinner } from "react-bootstrap";
import { useBorrador } from "../../../state/context/borradorContext";
import { Cargando } from "../../../homeComun/components/Cargando/Cargando";
import { logout } from "../../../store/slices/authSlice";


export const InformacionPago = () => {
  const { token, user, userType } = useSelector((state) => state.auth);
  const { setBorrador, borrador, loadingBorrador } = useBorrador();
  const [seleccionado, setSeleccionado] = useState(null);
  const [loading, setLoading] = useState(false);
  const [zonaSeleccionado, setZonaSeleccionado] = useState(false);
  const [dataUser, setdataUser] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [deliveryData, setDeliveryData] = useState();
  const [optionSend, setOptionSend] = useState(false);
  const [valueSelected, setValueSelected] = useState({
    value: "",
    price: "",
  });

  const [dateUserDefault, setDateUserDefault] = useState({
    id: "",
    nombre: "",
    apellido: "",
    direccion: "",
  });
  const [newDataAddres, setNewDataAddres] = useState({
    nombre: "",
    apellido: "",
    direccion: "",
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [finalPurchaseData, setFinalPurchaseData] = useState(null);
  const location = useLocation();
  const { products } = location.state;
  const [esqueleto, setEsqueleto] = useState()
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      nombre: dataUser?.customer?.name || "",
      apellido: dataUser?.customer?.apellido || "",
      direccion: dataUser?.shippingOption?.address || "",
    },
  });
  const dispatch = useDispatch();


  useEffect(() => {
    handleGetData();
  }, [valueSelected]);

  const redondearDecimales = (num, decimales) => {
    return Number(Math.round(num + 'e' + decimales) + 'e-' + decimales);
  };

  const totalQuantity = () => {
    let total = 0;
    let totalPrice = 0;
    for (let i = 0; i < products?.length; i++) {
      total += products?.[i].quantity;
      totalPrice += products?.[i].price * products?.[i].quantity;
    }

    // Asegurarse de que totalPrice siempre tenga dos decimales
    totalPrice = redondearDecimales(totalPrice, 2);

    return { total, totalPrice };
  };

  const { totalPrice } = totalQuantity();

  // `totalPrice` ahora es un número con precisión de dos decimales
  // console.log("Total Price (numérico):", totalPrice);

  // Convertir `totalPrice` a cadena solo al mostrarlo para asegurar los decimales
  const totalRedondeado = Number(totalPrice);

  const priceTotal = redondearDecimales(
    totalRedondeado + (totalRedondeado.toFixed(2) > 50 ? 0.01 : Number(valueSelected.price)),
    2
  );

  // console.log("Precio total (numérico):", priceTotal);

  // Para mostrarlo con dos decimales
  // console.log("Precio total (formateado):", priceTotal.toFixed(2));


  const handleGetData = async () => {


    const handleLogout = () => {
      dispatch(logout());
      navigate("/rol-registro");
    };

    try {
      const resPedido = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/user/${user.email}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setdataUser(resPedido?.data?.[0]);
    } catch (error) {
      if (
        error.response?.status === 400 &&
        error.response?.data?.response === "Token is invalid"
      ) {
        toast.error("Su sesión ha expirado. Por favor, vuelva a iniciar sesión.");
        handleLogout();
      } else {
        console.error("Error al obtener los datos del usuario:", error);
      }
    } finally {
      setOptionSend(true);
    }
  };


  const handleEdit = async (data) => {
    setLoading(true);

    // Si codigoAdmon es "CARADMI011", se asigna el valor de price a 0.01
    if (data.codigoAdmon === "CARADMI011") {
      data.price = "0.01";
    }

    if (totalRedondeado > 50) {
      data.price = 0;
    }

    try {
      const dataUpdated = await axios.put(
        `${process.env.REACT_APP_API_URL}/pedidos/update-info`,
        {
          name: data.nombre,
          apellido: data.apellido,
          address: data.direccion,
          codigoAdmon: data?.codigoAdmon || valueSelected.value,
          idPedido: dataUser?.carrito?.[0].idPedido,
          totalEnvio: data.price || valueSelected.price,
        },

        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setIsDisabled(true);
      if (dataUpdated.status === 200 || dataUpdated.status === 201) {
        toast.success("Datos actualizados correctamente");
        setSeleccionado(true);
        setOpenForm(false);
        reset();
      } else {
        toast.error("No se pudo actualizar los datos.");
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        toast.error("Solo se permite una dirección por producto.");
      } else if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(
          "Ocurrió un error al enviar los datos. Por favor, inténtalo de nuevo más tarde."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getPedidos = async () => {
    try {
      const resPedido = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/cargos/admon?email=${user.email}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );
      setDeliveryData(resPedido?.data?.detalle);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveData = (value) => {
    setNewDataAddres({
      nombre: value.nombre,
      apellido: value.apellido,
      direccion: value.direccion,
    });
    setDateUserDefault({
      nombre: value.nombre,
      apellido: value.apellido,
      direccion: value.direccion,
    });
    setOpenForm(false);
  };

  const handleConfirmPurchase = async () => {
    localStorage.removeItem("satisFactorio");
    setLoading(true); // Iniciar estado de carga

    try {
      const headers = {
        Authorization: `Bearer ${token ?? ""}`,
      };

      // Consumir predata
      let resPreData = await axios.post(
        `${process.env.REACT_APP_API_URL}/pedidos/pre-data/borrador`,
        {
          idPedido: dataUser?.carrito?.[0].idPedido,
          tipo: "CON",
        },
        { headers }
      );

      // Verificar si la respuesta contiene success: false
      if (resPreData.data.success === false) {
        toast.error(resPreData.data.detalle || "Error al procesar predata.");
        setLoading(false);
        return;
      }

      // Autenticación externa
      const url = "https://mivogue.com:83/APIs";
      const username =
        "c2ac07cafartwetsdADS2356FEDGeiE7xz7Gnn3XcxEkAQlPxtRIqf2gFu7q";
      const password =
        "c2ac07cafartwetsdADS2356FEDGe3LZNmqXlF8NgGg83fp4RjvUdzxswwmO";
      const authHeader = "Basic " + btoa(username + ":" + password);

      const formData = new FormData();
      formData.append("proceso", resPreData.data.proceso);
      formData.append("contenido", JSON.stringify(resPreData.data.items));
      const externalHeaders = {
        Authorization: authHeader,
        "Content-Type": "multipart/form-data",
      };

      // Realizar pedido externo
      let res = await axios.post(`${url}/pedidos/${user.email}`, formData, {
        headers: externalHeaders,
      });

      // Verificar si la respuesta del pedido contiene success: false
      if (res.data.success === false) {
        toast.error(res.data.detalle || "Error al procesar el pedido externo.");
        setEsqueleto(false)
        setLoading(false);
        return;
      }

      // Si el pedido externo es exitoso, continuar con la lógica
      setFinalPurchaseData(res?.data);

      // Enviar borrador
      const borradorData = { borrador: res?.data };

      let resBorrador = await axios.post(
        `${process.env.REACT_APP_API_URL}/borrador`,
        borradorData,
        { headers }
      );

      // Verificar si la respuesta de borrador contiene success: false
      if (resBorrador.data.borrador?.success === false) {
        toast.error(
          resBorrador.data.borrador?.detalle || "Error al enviar el borrador."
        );
        setLoading(false);
        setEsqueleto(false)
        return;
      }

      // const borrador = await getBorrador();

      // Actualizar borrador
      let resActualizarBorrador = await axios.post(
        `${process.env.REACT_APP_API_URL}/pedidos/actualizar/borrador`,
        {
          tipo: userType,
          idPedido: dataUser?.carrito?.[0].idPedido,
          cookie: res.data.detalle.PHPSESSID,
          detalle: resBorrador?.data?.borrador?.detalle,
        },
        { headers }
      );

      // Verificar si la actualización del borrador contiene success: false
      if (resActualizarBorrador.data.success === false) {
        toast.error(
          resActualizarBorrador.data.detalle ||
          "Error al actualizar el borrador."
        );
        setLoading(false);
        setEsqueleto(false)
        return;
      }

      setBorrador(resActualizarBorrador?.data?.detalle);
      // Navegar a la página de pago pendiente
      navigate("/pago-pendiente", {
        state: {
          products: resActualizarBorrador?.data?.detalle,
          envio:
            totalRedondeado > 50
              ? 0.0
              : `${valueSelected.price ? valueSelected.price : 0}`,
        },
      });

      // Mostrar mensaje de éxito
      toast.success("Pedido confirmado con éxito");
      setEsqueleto(false)
    } catch (error) {
      if (error.response) {
        // Mostrar mensaje de error basado en la respuesta de la API
        const errorMessage =
          error.response.data.detalle ||
          "El borrador de esta factura está siendo utilizado. Para continuar se debe finalizar el proceso.";
        toast.error(errorMessage);
        setEsqueleto(false)
      } else {
        // Error genérico si la respuesta de error no está disponible
        toast.error(
          "Ocurrió un error al enviar los datos. Por favor, inténtalo de nuevo más tarde."
        );
      }
    } finally {
      // Detener el estado de carga en cualquier caso
      setLoading(false);
      setEsqueleto(false)
    }
  };

  const handleValue = (e) => {
    const selectedPrice = e.target.id === '0.0' && totalRedondeado < 50 ? '0.01' : e.target.id;

    setValueSelected({
      value: e.target.value,
      price: totalRedondeado > 50 ? '0.01' : selectedPrice, // Solo cambia el precio si es mayor a 50
    });

    setSelectedOption(e.target.value); // Marca que una opción fue seleccionada
  };

  useEffect(() => {
    const handlePopState = (event) => {
      navigate("/", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);
  }, [navigate]);


  useEffect(() => {
    if (borrador) {
      navigate("/pago-pendiente");
    }
  }, [borrador, navigate, loadingBorrador]);

  if (loadingBorrador || !optionSend) {
    return <Cargando />
  }

  return (
    <>
      <div className={styles.cajaContenedor}>
        {esqueleto && (
          <div className={styles.overlay}>

          </div>
        )}

        <div className={styles.contenedorIzquierdo}>

          <div className={styles.entregaContenedor}>
            <h3 className={styles.TituloEntrega}>Entrega de pedido</h3>
          </div>
          {optionSend ? (
            <>
              <div className={styles.conteneroRecoger}>
                <input
                  className={styles.chetRecoger}
                  type="radio"
                  name="opcionesEnvio"
                  id="0.0"
                  value="CARADMI011"
                  onChange={(e) => {
                    setDateUserDefault({
                      id: "",
                      nombre: "",
                      apellido: "",
                      direccion: "",
                    });
                    setZonaSeleccionado(false);
                    handleValue(e);
                    handleEdit({
                      nombre: user.nombres,
                      apellido: user.apellidos,
                      direccion: user.direccion,
                      codigoAdmon: "CARADMI011",
                      price: totalRedondeado < 50 ? "0.01" : "0",
                    });
                  }}
                />
                <label
                  htmlFor="recogerEnTienda"
                  className={styles.parrafoRecoger}
                >
                  Recoger en tienda
                </label>
              </div>

              <div className={styles.conteneroRecoger}>
                <input
                  className={styles.chetRecoger}
                  type="radio"
                  name="opcionesEnvio"
                  id="aDomicilio"
                  onChange={() => {
                    setSeleccionado(false);
                    getPedidos();
                  }}
                />
                <label htmlFor="aDomicilio" className={styles.parrafoRecoger}>
                  A domicilio
                </label>
              </div>
            </>
          ) : (
            <h3>Cargando...</h3>
          )}

          {seleccionado === false && (
            <>
              <div className={styles.entregaContenedor}>
                <h3 className={styles.TituloEntrega}>Zona de entrega</h3>
              </div>
              {deliveryData ? (
                deliveryData
                  .filter((d) => d.mostrar_cliente) // Filtrar los que tienen contenido en mostrar_cliente
                  .map((d) => (
                    <div className={styles.conteneroRecoger} key={d.cargo_id}>
                      <input
                        className={styles.chetRecoger}
                        type="radio"
                        name="opcionesTipo"
                        id={d.importe}
                        value={d.cargo_id}
                        onChange={(e) => {
                          setZonaSeleccionado(true);
                          handleValue(e);
                        }}
                      />
                      <label
                        htmlFor={d.mostrar_cliente}
                        className={styles.optionsZonaDeEntrega}
                      >
                        <span className={styles.parrafoRecoger}>
                          {d.mostrar_cliente}
                        </span>
                      </label>
                    </div>
                  ))
              ) : (
                <LoadingText small />
              )}
            </>
          )}
          {zonaSeleccionado && (
            <>
              <div className={styles.entregaContenedor}>
                <h3 className={styles.TituloEntrega}>Dirección de envío</h3>
              </div>
              <div className={styles.contenedorDireccion}>
                <div className={styles.contenedorCajaDirreciion}>
                  <div className={styles.containerName}>
                    <input
                      className={styles.chet}
                      type="checkbox"
                      value={user?.direccion}
                      checked={dateUserDefault.direccion === user?.direccion}
                      onChange={() => {
                        setDateUserDefault({
                          id: user?.direccion,
                          nombre: user?.nombres,
                          apellido: user?.apellidos,
                          direccion: user?.direccion,
                        });
                        setIsDisabled(false);
                        setOpenForm(false);
                      }}
                    />
                    <p className={styles.nombre}>
                      {user?.nombres + " " + user?.apellidos}
                    </p>
                  </div>

                  <p className={styles.dirreccion}>{user?.direccion}</p>
                </div>

                {newDataAddres?.direccion && (
                  <div className={styles.contenedorCajaDirreciion}>
                    {/* <div className={styles.containerPencil}> */}
                    <div className={styles.containerName}>
                      <input
                        className={styles.chet}
                        type="checkbox"
                        checked={
                          dateUserDefault.direccion === newDataAddres?.direccion
                        }
                        onChange={() => {
                          setDateUserDefault({
                            id: newDataAddres?.direccion,
                            nombre: newDataAddres?.nombre,
                            apellido: newDataAddres?.apellido,
                            direccion: newDataAddres?.direccion,
                          });
                          setIsDisabled(false);
                        }}
                      />
                      <p className={styles.nombre}>
                        {newDataAddres?.nombre + " " + newDataAddres?.apellido}
                      </p>
                    </div>

                    {/* </div> */}

                    <p className={styles.dirreccion}>
                      {newDataAddres?.direccion}
                    </p>
                  </div>
                )}

                {openForm ? (
                  <button
                    className={styles.buttonDeployOptions}
                    onClick={() => setOpenForm(false)}
                  >
                    Ocultar formulario
                  </button>
                ) : (
                  <button
                    className={styles.buttonDeployOptions}
                    onClick={() => setOpenForm(true)}
                  >
                    Usar otra dirección
                  </button>
                )}
                {openForm && (
                  <section className={styles.containerForm}>
                    <h3 className={styles.otraDirreccion}>
                      Utilizar otra dirección
                    </h3>
                    <form onSubmit={handleSubmit(handleSaveData)}>
                      <div className={styles.formulario}>
                        <div className={styles.input}>
                          <p className={styles.nombreform}>Nombre</p>
                          <input
                            className={styles.form}
                            type="text"
                            {...register("nombre", {
                              required: "Este campo es requerido",
                            })}
                          />
                          {errors.nombre && (
                            <p className={styles.error}>
                              {errors.nombre.message}
                            </p>
                          )}
                        </div>
                        <div className={styles.input}>
                          <p className={styles.nombreform}>Apellido</p>
                          <input
                            className={styles.form}
                            type="text"
                            {...register("apellido", {
                              required: "Este campo es requerido",
                            })}
                          />
                          {errors.apellido && (
                            <p className={styles.error}>
                              {errors.apellido.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className={styles.direccionForm}>
                        <p className={styles.dirrec}>Dirección</p>
                        <input
                          className={styles.inputForm}
                          type="text"
                          {...register("direccion", {
                            required: "Este campo es requerido",
                          })}
                        />
                        {errors.direccion && (
                          <p className={styles.error}>
                            {errors.direccion.message}
                          </p>
                        )}
                      </div>
                      <button className={styles.buttonUpdate} type="submit">
                        Agregar nuevos datos
                      </button>
                    </form>
                  </section>
                )}
                <button
                  className={
                    dateUserDefault.direccion && !isDisabled
                      ? styles.buttonUpdate
                      : styles.buttonOff
                  }
                  disabled={!dateUserDefault.direccion || isDisabled}
                  onClick={() => handleEdit(dateUserDefault)}
                >
                  {loading ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Cargando...</span>
                    </Spinner>
                  ) : (
                    "Usar la dirección seleccionada"
                  )}
                </button>
              </div>
            </>
          )}
        </div>
        <div className={styles.contenedorFinalizarProductos}>
          <div>
            <div className={styles.sectionCheckout}>
              {products &&
                products?.map((product, index) => (
                  <ProductsTotal key={index} product={product} />
                ))}
            </div>

            <div className={styles.contenedorEnvio}>
              <h3 className={styles.envioTitulo}>Envío:</h3>

              <p className={styles.envioPrecio}>
                {`$${valueSelected.price ? valueSelected.price : 0.00}`}
              </p>
            </div>

            <div className={styles.btnTotal}>
              <p className={styles.productoTotal}>TOTAL:</p>
              <p className={styles.monto}>
                $
                {totalRedondeado > 50 && !seleccionado
                  ? (totalRedondeado.toFixed(2))
                  : priceTotal.toFixed(2)}
              </p>
            </div>
          </div>
          <div className={styles.contenedorFinalaizar}>
            {seleccionado && !finalPurchaseData && (
              <button
                className={styles.BtnFinalizar}
                id="btPagar"
                onClick={() => {
                  setLoading(true);
                  setEsqueleto(true)
                  handleConfirmPurchase();
                }}
              >
                {loading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Cargando...</span>
                  </Spinner>
                ) : (
                  "Confirmar compra"
                )}
              </button>
            )}
            {/* <input type="hidden" id="MerchantToken" value={tokenSerfinsa} /> */}
          </div>
        </div>
      </div>
    </>
  );
};
