export const productos = [
  {
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0134001",
    "descripcion_prod": "SHAMPOO 2EN1 PRO BOOST 800ML",
    "descripcion_corta": "SHAMPOO 2 EN 1 PRO BOOST 800ML",
    "descripcion_larga": "Fortalece las fibras capilares para tener un cabello visiblemente más grueso, fuerte y saludable. Contiene zinc que ayuda a frenar la caída del cabello revitalizando y fortaleciendo desde las raíces. Con aroma a notas cítricas y marinas. Para CABELLOS DEBILITADOS.",
    "precio_DIS": "10.15",
    "precio_CON": "14.50",
    "existencia": "390",
    "marca_id": "199",
    "marca_descripcion": "DICORA UFF",
    "tipo_incentivo_id": "",
    "keywords": "Shampoo 2 en 1, zinc, caída capilar, dicora",
    "promocion": [],
    "imagen": [
        "0134001.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0134002",
    "descripcion_prod": "SHAMPOO 2EN1 PURE&FRESH 800ML",
    "descripcion_corta": "SHAMPOO 2 EN 1 PURE & FRESH 800ML",
    "descripcion_larga": "Previene la descamación y protege la salud de tu cuero cabelludo. Para un cabello sano y resistente libre de caspa. Su extracto natural de mentol lo deja libre de caspa. El Ginseng estimula el cabello a aumentar su grosor. Aroma a notas cítricas y marinas. Para CABELLOS CON CASPA.",
    "precio_DIS": "10.15",
    "precio_CON": "14.50",
    "existencia": "306",
    "marca_id": "199",
    "marca_descripcion": "DICORA UFF",
    "tipo_incentivo_id": "",
    "keywords": "Shampoo 2 en 1, anticaspa, crecimiento del cabello, dicora",
    "promocion": [],
    "imagen": [
        "0134002.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "7",
    "descripcion_sublinea": "ACONDICIONADOR",
    "producto_id": "0134004",
    "descripcion_prod": "ACOND. SMOOTH & SHINE 400ML",
    "descripcion_corta": "ACONDICIONADOR SMOOTH & SHINE 400ML",
    "descripcion_larga": "Acondicionador con extracto natural de aguacate, que es una fuente de energía que aporta nutrición, suavidad y fortaleza al cabello con aroma a manzana verde. Desenreda y deja el cabello suave con todo el poder del aguacate. Con keratina vegetal. Para TODO TIPO DE CABELLO.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "524",
    "marca_id": "199",
    "marca_descripcion": "DICORA UFF",
    "tipo_incentivo_id": "",
    "keywords": "Aguacate, brillo, dicora",
    "promocion": [],
    "imagen": [
        "0134004.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "7",
    "descripcion_sublinea": "ACONDICIONADOR",
    "producto_id": "0134006",
    "descripcion_prod": "ACOND. BEAUTY CURLS 400ML",
    "descripcion_corta": "ACONDICIONADOR BEAUTY CURLS 400ML",
    "descripcion_larga": "Alíate con el aceite de jojoba y entrena tus rizos. Desenreda, hidrata y define unos rizos perfectos con tu mejor aliado, el aceite de jojoba. Con keratina vegetal. Con aroma a papaya y frutas tropicales. Para CABELLOS RIZADOS.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "605",
    "marca_id": "199",
    "marca_descripcion": "DICORA UFF",
    "tipo_incentivo_id": "",
    "keywords": "Colochos, rizos, dicora",
    "promocion": [],
    "imagen": [
        "0134006.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0134007",
    "descripcion_prod": "SHAMPOO BEST COLOR 800ML",
    "descripcion_corta": "SHAMPOO BEST COLOR 800ML",
    "descripcion_larga": "Potencia el color de tu cabello con la fusión de frutos rojos y aceite de argán. El aceite de argán hidrata, repara y moldea mejor el cabello. Más sano por dentro y por fuera. Con aroma a frutos del bosque. Nutre y protege, para un cabello brillante y luminoso. Para CABELLOS TINTURADOS.",
    "precio_DIS": "9.45",
    "precio_CON": "13.50",
    "existencia": "316",
    "marca_id": "199",
    "marca_descripcion": "DICORA UFF",
    "tipo_incentivo_id": "",
    "keywords": "Cabellos tinturados, tinte, cuidado del color, dicora",
    "promocion": [],
    "imagen": [
        "0134007.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "7",
    "descripcion_sublinea": "ACONDICIONADOR",
    "producto_id": "0134008",
    "descripcion_prod": "ACOND. BEST COLOR 400ML",
    "descripcion_corta": "ACONDICIONADOR BEST COLOR 400ML",
    "descripcion_larga": "Potencia el color de tu cabello con la fusión de frutos rojos y aceite de argán. El aceite de argán hidrata, repara y moldea mejor el cabello. Nutre, protege y potencia el brillo y el color de tu cabello. Con keratina vegetal. Para CABELLOS TINTURADOS.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "174",
    "marca_id": "199",
    "marca_descripcion": "DICORA UFF",
    "tipo_incentivo_id": "",
    "keywords": "Cabellos tinturados, tinte, cuidado del color, dicora",
    "promocion": [],
    "imagen": [
        "0134008.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0134009",
    "descripcion_prod": "SHAMPOO MAX REPAIR 800ML",
    "descripcion_corta": "SHAMPOO MAX REPAIR 800ML",
    "descripcion_larga": "Shampoo que nutre, repara y protege en profundidad tu cabello para un resultado WOW. El extracto natural de macadamia aporta un extra de hidratación que previene, cuida y repara tu cabello. Con aroma a nueces de macadamia. Para CABELLOS DAÑADOS.",
    "precio_DIS": "9.45",
    "precio_CON": "13.50",
    "existencia": "219",
    "marca_id": "199",
    "marca_descripcion": "DICORA UFF",
    "tipo_incentivo_id": "",
    "keywords": "Reparación intensa, dicora",
    "promocion": [],
    "imagen": [
        "0134009.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "7",
    "descripcion_sublinea": "ACONDICIONADOR",
    "producto_id": "0134010",
    "descripcion_prod": "ACONDICIONADOR MAX REPAIR",
    "descripcion_corta": "ACONDICIONADOR MAX REPAIR 400ML",
    "descripcion_larga": "Deja que la macadamia te mime. Acondicionador que repara, mima y nutre en profundidad tu cabello dejándolo extremadamente suave. Con aceite de macadamia y keratina vegetal. Para CABELLOS DAÑADOS.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "352",
    "marca_id": "199",
    "marca_descripcion": "DICORA UFF",
    "tipo_incentivo_id": "",
    "keywords": "Reparación intensa, dicora",
    "promocion": [],
    "imagen": [
        "0134010.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0140142",
    "descripcion_prod": "CHAMPU EXTRA SUAVE BEBE 300ML",
    "descripcion_corta": "CHAMPU EXTRA SUAVE DE BEBE 300ML",
    "descripcion_larga": "Formulado para usarse desde el nacimiento, elimina la costra láctea y es ideal para lavar el cabello fino y delicado de tu bebé. Limpia perfectamente el cabello con suavidad a la vez que respeta el delicado equilibrio del cuero cabelludo. Testado pediátrica, dermatológica y oftalmológicamente.",
    "precio_DIS": "10.49",
    "precio_CON": "14.98",
    "existencia": "147",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Bebé, niños",
    "promocion": [],
    "imagen": [
        "0140142.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "01486",
    "descripcion_prod": "CHAMPU ANTIPIOJOS 250ML",
    "descripcion_corta": "CHAMPU INFANTIL ANTIPIOJOS 250ML",
    "descripcion_larga": "Ayuda a proteger el cabello de los más pequeños sin usar insecticidas o pesticidas en su formulación. Gracias a su eficaz combinación de principios activos, logra evitar el contagio de los piojos con todo cuidado y sin provocar molestas irritaciones. EVITA contagios y no contiene pesticidas.",
    "precio_DIS": "6.47",
    "precio_CON": "9.25",
    "existencia": "1544",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Piojos, cuidado preventivo, niños",
    "promocion": [],
    "imagen": [
        "01486.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "4",
    "descripcion_sublinea": "TRATAMIENTO",
    "producto_id": "0154001",
    "descripcion_prod": "ACLARANTE DE MANZANILLA 125ML",
    "descripcion_corta": "ACLARANTE DE MANZANILLA 125ML",
    "descripcion_larga": "Tratamiento iluminador con extracto de manzanilla y jalea real rica en proteínas y vitaminas, que aclara progresivamente el cabello resaltando el color rubio. Ideal para la temporada de verano, aclara de manera natural y sin ocasionar daños. La fórmula es libre de parabenos y no tiene sal agregada.",
    "precio_DIS": "7.35",
    "precio_CON": "10.50",
    "existencia": "1607",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Aclarante progresivo, manzanilla, sin sal",
    "promocion": [],
    "imagen": [
        "0154001.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0154003",
    "descripcion_prod": "FAMILY HAIR SHAMPOO 650ML",
    "descripcion_corta": "FAMILY HAIR SHAMPOO 650ML",
    "descripcion_larga": "Shampoo que contiene extracto de bambú, con exquisita fragancia, apto para todo tipo de cabello. Ideal para uso familiar. Aumenta el volumen de tu cabello y elimina las células muertas del cuero cabelludo hasta fortalecer tu melena.",
    "precio_DIS": "8.05",
    "precio_CON": "11.50",
    "existencia": "291",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "v",
    "keywords": "Bamboo",
    "promocion": [],
    "imagen": [
        "0154003.png",
        "PV-0154003.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "8",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "0155002",
    "descripcion_prod": "MASCARILLA REP. DE MASAS 1KG",
    "descripcion_corta": "MASCARILLA REPOSICIÓN DE MASAS 1KG",
    "descripcion_larga": "Mascarilla de reconstrucción capilar, que brinda vitalidad y brillo intenso al cabello. Recomendada para aumentar la resistencia del cabello y para una reparación intensa en tan solo 3 minutos. Uso recomendado: a cada 15 días.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "509",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "p",
    "keywords": "Mascarilla PRO, 3 minutos, reparación intensa, cabello fino",
    "promocion": [],
    "imagen": [
        "0155002.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "8",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "0155003",
    "descripcion_prod": "MASCARILLA CAUTERIZACIÓN 1KG",
    "descripcion_corta": "MASCARILLA CAUTERIZACIÓN 1KG",
    "descripcion_larga": "Mascarilla que restaura la elasticidad natural del cabello, restaurando y minimizando el daño causado por los procesos químicos. La Keratina Vegetal penetra en la cutícula del cabello, hidratando y recuperando brillo y suavidad. Recomendado para reposición de keratina, elasticidad y reparación.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "369",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "p",
    "keywords": "Mascarilla PRO, 3 minutos, puntas en flor, puntas abiertas",
    "promocion": [],
    "imagen": [
        "0155003.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "8",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "0155011",
    "descripcion_prod": "MASCARILLA VITAMINADA 1KG",
    "descripcion_corta": "MASCARILLA VITAMINADA 1KG",
    "descripcion_larga": "Mascarilla rica en súper fruta de aguacate y vitaminas, hidrata, revitaliza y deja el cabello maravilloso! Acelera el crecimiento del cabello, reduce el frizz, previene las puntas abiertas, promueve el brillo, combate la caída del cabello, sella las cutículas, ¡entre otros!",
    "precio_DIS": "7.70",
    "precio_CON": "10.99",
    "existencia": "244",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Crecimiento capilar, previene caída, aguacate, puntas abiertas",
    "promocion": [],
    "imagen": [
        "0155011.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "8",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "0155012",
    "descripcion_prod": "MASCARILLA SOSSEGADA 1KG",
    "descripcion_corta": "MASCARILLA SOSSEGADA 1KG",
    "descripcion_larga": "La mascarilla acondicionadora Sossegada, deja el cabello increíblemente suave y con un brillo intenso! Es una mascarilla iluminadora que alinea el cabello. El resultado es un cabello con movimiento, más controlado, muy natural, ligeramente más suave y con menos frizz y volumen.",
    "precio_DIS": "7.70",
    "precio_CON": "10.99",
    "existencia": "392",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Cabello liso, menos frizz, menos volumen",
    "promocion": [],
    "imagen": [
        "0155012.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "8",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "0155014",
    "descripcion_prod": "MASCARILLA MIX FRUIT 1KG",
    "descripcion_corta": "MASCARILLA MIX FRUIT 1KG",
    "descripcion_larga": "La mascarilla Mix Fruit está formulada a base de un rico y poderoso complejo de frutas, que penetra en la fibra capilar, dejando tu cabello mucho más suave y brillante. Aporta restauración y da vida al cabello dañado y seco.",
    "precio_DIS": "4.40",
    "precio_CON": "10.99",
    "existencia": "6",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "v",
    "keywords": "Cabello virgen, natural",
    "promocion": [],
    "imagen": [
        "0155014.png",
        "PV-0155014.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "8",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "0155015",
    "descripcion_prod": "MASCARILLA COCO BOM 1KG",
    "descripcion_corta": "MASCARILLA COCO BOM 1KG",
    "descripcion_larga": "Mascarilla con mix de chocolate y coco formulada especialmente para cabellos rizados, que necesitan más productos hidratantes. Brinda mega suavidad, brillo maravilloso, ¡sin mencionar la reconstrucción inmediata! Previene el frizz, reduce las puntas abiertas, previene la rotura y la sequedad.",
    "precio_DIS": "7.70",
    "precio_CON": "10.99",
    "existencia": "480",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Cabello colocho, rizos, cabello rizado, menos frizz, puntas abiertas",
    "promocion": [],
    "imagen": [
        "0155015.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "5",
    "descripcion_sublinea": "ESTILIZADOR",
    "producto_id": "0155016",
    "descripcion_prod": "LEAVE-IN HIDRATA & REPARA",
    "descripcion_corta": "LEAVE-IN CREAM HIDRATA & REPARA",
    "descripcion_larga": "La crema de peinar sin enjuague Hidrata & Repara promueve la hidratación intensa, la reconstrucción del cabello dañado y ayuda a prevenir el frizz. Brinda protección contra la sequedad, nutrición avanzada, da brillo, vitalidad y luminosidad, disciplina",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "551",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Crema de peinar, menos frizz",
    "promocion": [],
    "imagen": [
        "0155016.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0155017",
    "descripcion_prod": "PROMOPACK FUERZA & VIGOR",
    "descripcion_corta": "PROMOPACK SH+ACON FUERZA & VIGOR",
    "descripcion_larga": "Fortalece el cabello, lo protege y favorece su crecimiento. Resistencia y Vitalidad. Principios activos: Biotina, Jaborandi y Complejo K. Recomendado para: Cabellos quebradizos y debilitados.",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "311",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "p",
    "keywords": "Caída capilar",
    "promocion": [],
    "imagen": [
        "0155017.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0155018",
    "descripcion_prod": "PROMOPACK HIDRATA & REPARA",
    "descripcion_corta": "PROMOPACK SH+ACON HIDRATA & REPARA",
    "descripcion_larga": "Hidratación intensa. Reconstrucción de cabellos dañados. Contiene aceite de Argán, Elixir de aceites especiales y Complejo K. Recomendado para cabello seco y dañado.",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "172",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "p",
    "keywords": "Cabellos secos, daño térmico, daño químico",
    "promocion": [],
    "imagen": [
        "0155018.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0155027",
    "descripcion_prod": "SHAMPOO BIG HIDRATA & REPARA 750ML",
    "descripcion_corta": "SHAMPOO BIG HIDRATA & REPARA 750ML",
    "descripcion_larga": "Hidratación intensa. Reconstrucción de cabellos dañados. Contiene aceite de Argán, Elixir de aceites especiales y Complejo K. Recomendado para cabello seco y dañado.",
    "precio_DIS": "8.05",
    "precio_CON": "11.50",
    "existencia": "340",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Cabellos secos, daño térmico, daño químico",
    "promocion": [],
    "imagen": [
        "0155027.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0155028",
    "descripcion_prod": "SHAMPOO BIG EFECTO LISO 750ML",
    "descripcion_corta": "SHAMPOO BIG EFECTO LISO 750ML",
    "descripcion_larga": "La keratina vegetal y el pantenol realinean el cabello, dejándolo liso, disciplinado y con movimiento. El shampoo limpia sin lastimar las hebras, minimizando el efecto frizz. La keratina protege y mantiene la vitalidad e hidratación del cabello y asegura que el cabello se vea más brillante y sedoso.",
    "precio_DIS": "8.05",
    "precio_CON": "11.50",
    "existencia": "362",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Cabello liso",
    "promocion": [],
    "imagen": [
        "0155028.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0155029",
    "descripcion_prod": "SHAMPOO BIG FUERZA & VIGOR 750ML",
    "descripcion_corta": "SHAMPOO BIG FUERZA & VIGOR 750ML",
    "descripcion_larga": "Promueve una limpieza inteligente, dejando el cabello suave y terso. La Biotina y el Jaborandi aportan fuerza y ​​resistencia al cabello, ayudando al crecimiento y revitalización de las hebras capilares. Ayuda en el crecimiento y fortalecimiento del cabello, previniendo la caída del cabello.",
    "precio_DIS": "8.05",
    "precio_CON": "11.50",
    "existencia": "249",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Caída capilar",
    "promocion": [],
    "imagen": [
        "0155029.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "0155030",
    "descripcion_prod": "SHAMPOO BIG DOMINA RIZOS 750ML",
    "descripcion_corta": "SHAMPOO BIG DOMINA RIZOS 750ML",
    "descripcion_larga": "Rizos definidos, con brillo y movimiento. Contiene Aceite de Coco, Colágeno Vegetal y Complejo K. Recomendado para: Cabellos rizados, ondulados y encrespados (todo tipo de onda).",
    "precio_DIS": "8.05",
    "precio_CON": "11.50",
    "existencia": "229",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Cabellos colochos, rizos, cabello rizado, ondulados",
    "promocion": [],
    "imagen": [
        "0155030.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "5",
    "descripcion_sublinea": "ESTILIZADOR",
    "producto_id": "0155031",
    "descripcion_prod": "LEAVE-IN DOMINA RIZOS",
    "descripcion_corta": "LEAVE-IN CREAM DOMINA RIZOS",
    "descripcion_larga": "Crema de peinar sin enjuague que revitaliza y define los rizos, dándoles brillo y movimiento. Contiene aceite de Coco, Colágeno Vegetal y Complejo K. Recomendado para: Cabellos rizados, ondulados y encrespados (todo tipo de onda).",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "415",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Cabellos colochos, rizos, cabello rizado, ondulados, crema de peinar",
    "promocion": [],
    "imagen": [
        "0155031.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "5",
    "descripcion_sublinea": "ESTILIZADOR",
    "producto_id": "0155035",
    "descripcion_prod": "OLEO DE ARGAN 100ML",
    "descripcion_corta": "OLEO DE ARGAN 100ML",
    "descripcion_larga": "El óleo de argán es conocido como el oro marroquí debido a su poder extremadamente hidratante. Su hidratación permite que la piel y el cabello absorban los nutrientes, dejándolos suaves y protegidos. Se puede utilizar para hidratar y proteger la piel o para potenciar la hidratación capilar.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "469",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Oleo capilar, oleo corporal, aceite",
    "promocion": [],
    "imagen": [
        "0155035.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "5",
    "descripcion_sublinea": "ESTILIZADOR",
    "producto_id": "0155036",
    "descripcion_prod": "OLEO DE COCO 100ML",
    "descripcion_corta": "OLEO DE COCO 100ML",
    "descripcion_larga": "Los óleos imperiales son una extraordinaria combinación de aceites obtenidos a partir de nueces, semillas, hierbas y frutas. Promueve la nutrición y repone la oleosidad esencial para la suavidad y la vitalidad. Se pueden usar para hidratar y proteger la piel o para potenciar la hidratación capilar.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "337",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Oleo capilar, oleo corporal, aceite",
    "promocion": [],
    "imagen": [
        "0155036.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "8",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "0155037",
    "descripcion_prod": "MASCARILLA DE ARGAN 1KG",
    "descripcion_corta": "MASCARILLA DE ARGAN 1KG",
    "descripcion_larga": "Mascarilla que repara profundamente el cabello aportando nutrición, reducción de frizz, aportando suavidad y vitalidad. El aceite de Argán proporciona vida y ayuda a renovar el cabello. Sorpréndete con un resultado desde la primera aplicación.",
    "precio_DIS": "7.70",
    "precio_CON": "10.99",
    "existencia": "360",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Cabello seco, mascarilla tradicional, tratamiento capilar",
    "promocion": [],
    "imagen": [
        "0155037.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "8",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "0155038",
    "descripcion_prod": "MASCARILLA SUPER HIALU 1KG",
    "descripcion_corta": "MASCARILLA SUPER HIALU 1KG",
    "descripcion_larga": "Mascarilla 100% vegana, sin sulfatos, parabenos, siliconas y óleo mineral. Su fórmula exclusiva con Ácido Hialurónico ayuda al relleno capilar y anti quiebre del cabello. Brinda fuerza, resistencia y volumen a tu cabello.",
    "precio_DIS": "7.70",
    "precio_CON": "10.99",
    "existencia": "313",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Low poo, no poo, co-wash, mascarilla capilar, tratamiento",
    "promocion": [],
    "imagen": [
        "0155038.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "8",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "0155039",
    "descripcion_prod": "MASCARILLA 3X1 RIZOS DIVINOS 1KG",
    "descripcion_corta": "MASCARILLA 3X1 RIZOS DIVINOS 1KG",
    "descripcion_larga": "Reúne en un solo producto acondicionador, mascarilla hidratante y crema para peinar, ¡tú eliges cómo usarlo! Este es el producto ideal para quienes buscan volumen, hidratación y fuerza, el aceite de ricino forma una capa protectora para el cabello y mejora la flexibilidad y suavidad.",
    "precio_DIS": "10.49",
    "precio_CON": "14.98",
    "existencia": "404",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Crema de peinar, mascarilla, tratamiento, acondicionador, rizos, cabellos colochos, cabellos rizados, cabellos ondulados",
    "promocion": [],
    "imagen": [
        "0155039.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "8",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "0155040",
    "descripcion_prod": "MASCARILLA 3X1 ARRASA LISOS 1KG",
    "descripcion_corta": "MASCARILLA 3X1 ARRASA LISOS 1KG",
    "descripcion_larga": "Reúne en un solo producto acondicionador, mascarilla hidratante y crema para peinar, ¡tú eliges cómo usarlo! Equilibran y purifican el cabello, dejándolo terso, suave y alineado. Se puede usar a diario para quienes desean un cabello ligero, hidratado y suave durante más tiempo. ¡Adiós al frizz!",
    "precio_DIS": "10.49",
    "precio_CON": "14.98",
    "existencia": "408",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "Crema de peinar, mascarilla, tratamiento, acondicionador, cabellos ondulados, cabellos lisos",
    "promocion": [],
    "imagen": [
        "0155040.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "2",
    "descripcion_sublinea": "KANECHOM",
    "producto_id": "0155041",
    "descripcion_prod": "MUESTRA RIZOS DIVINOS TRATAMIENTO 3X1 30ML",
    "descripcion_corta": "MUESTRA RIZOS DIVINOS TRATAMIENTO 3X1 30ML",
    "descripcion_larga": "MUESTRA RIZOS DIVINOS TRATAMIENTO 3X1 30ML",
    "precio_DIS": "1.50",
    "precio_CON": "1.50",
    "existencia": "23",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "2",
    "descripcion_sublinea": "KANECHOM",
    "producto_id": "0155042",
    "descripcion_prod": "MUESTRA MASCARILLA DE ARGAN 30ML",
    "descripcion_corta": "MUESTRA MASCARILLA DE ARGAN 30ML",
    "descripcion_larga": "MUESTRA MASCARILLA DE ARGAN 30ML\r\nFC:08092023",
    "precio_DIS": "1.50",
    "precio_CON": "1.50",
    "existencia": "29",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "2",
    "descripcion_sublinea": "KANECHOM",
    "producto_id": "0155043",
    "descripcion_prod": "MUESTRA SUPER HIALU K MASCARILLA CAPILAR 30ML",
    "descripcion_corta": "MUESTRA SUPER HIALU K MASCARILLA CAPILAR 30ML",
    "descripcion_larga": "MUESTRA SUPER HIALU K MASCARILLA CAPILAR 30ML\r\nFC:08092023",
    "precio_DIS": "1.50",
    "precio_CON": "1.50",
    "existencia": "97",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "2",
    "descripcion_sublinea": "KANECHOM",
    "producto_id": "0155044",
    "descripcion_prod": "MUESTRA ARRASA LISOS TRATAMIENTO 3X1 30ML",
    "descripcion_corta": "MUESTRA ARRASA LISOS TRATAMIENTO 3X1 30ML",
    "descripcion_larga": "MUESTRA ARRASA LISOS TRATAMIENTO 3X1 30ML",
    "precio_DIS": "1.50",
    "precio_CON": "1.50",
    "existencia": "113",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "017704",
    "descripcion_prod": "SHAMPOO ROMERO Y SALVIA 1 LT",
    "descripcion_corta": "SHAMPOO ROMERO Y SALVIA 1LT",
    "descripcion_larga": "Shampoo con ingredientes naturales que aportan alta suavidad y ayudan al cabello en el combate contra la caída. Gracias a su fórmula e ingredientes como el romero que estimula el cuero cabelludo y fortalece la fibra capilar mientras que la salvia lo protege y da brillo para que crezca sano.",
    "precio_DIS": "9.45",
    "precio_CON": "13.50",
    "existencia": "210",
    "marca_id": "145",
    "marca_descripcion": "LUZKO",
    "tipo_incentivo_id": "p",
    "keywords": "Caída capilar, romero, salvia, crecimiento del cabello",
    "promocion": [],
    "imagen": [
        "017704.png",
        "PC-017704.png",
        "PD-017704.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "4",
    "descripcion_sublinea": "TRATAMIENTO",
    "producto_id": "017711",
    "descripcion_prod": "TRAT. ROMERO Y SALVIA 230G",
    "descripcion_corta": "TRATAMIENTO ROMERO Y SALVIA 230G",
    "descripcion_larga": "Proporciona al cabello hidratación, reparación, brillo, manejabilidad, acondicionamiento, suavidad y humectación. Aplique sobre el cabello limpio y húmedo, masajee, deje actuar por 20 minutos y enjuague.",
    "precio_DIS": "8.75",
    "precio_CON": "12.50",
    "existencia": "130",
    "marca_id": "145",
    "marca_descripcion": "LUZKO",
    "tipo_incentivo_id": "",
    "keywords": "Caída capilar, romero, salvia, crecimiento del cabello, tratamiento",
    "promocion": [],
    "imagen": [
        "017711.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "5",
    "descripcion_sublinea": "ESTILIZADOR",
    "producto_id": "017712",
    "descripcion_prod": "GEL VERDE FIJADOR 290G",
    "descripcion_corta": "GEL VERDE FIJADOR 290G",
    "descripcion_larga": "Fórmula única en gel que ofrece fijación efectiva, nutre e hidrata el cabello, dejándolo suave y sin enredos y proporcionándole un brillo natural y vibrante. Brinda resultados excepcionales sin rigidez, sensación pegajosa, grasa o residuos. Dejando un efecto natural en cabellos ondulados.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "1107",
    "marca_id": "162",
    "marca_descripcion": "NUTRY HAIR",
    "tipo_incentivo_id": "",
    "keywords": "Gel verde, gel fijador, nutrihair, gel nutri",
    "promocion": [],
    "imagen": [
        "017712.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "6",
    "descripcion_sublinea": "COLOR",
    "producto_id": "017803",
    "descripcion_prod": "COLOR & BRILLO CENIZO 40ML",
    "descripcion_corta": "TRATAMIENTO COLOR & BRILLO CENIZO 40ML",
    "descripcion_larga": "Tratamiento que aporta pigmentos para revitalizar el color del cabello como el primer día de tinturado. Evita la aparición de reflejos anaranjados o amarillos manteniendo un rubio hermoso. Baño de color disponible en 5 tonos.",
    "precio_DIS": "1.75",
    "precio_CON": "2.50",
    "existencia": "505",
    "marca_id": "148",
    "marca_descripcion": "VOGUE CORP",
    "tipo_incentivo_id": "",
    "keywords": "Tratamiento, color y brillo, baño de color",
    "promocion": [],
    "imagen": [
        "017803.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "6",
    "descripcion_sublinea": "COLOR",
    "producto_id": "017804",
    "descripcion_prod": "COLOR & BRILLO CHOCOLATE 40ML",
    "descripcion_corta": "TRATAMIENTO COLOR & BRILLO CHOCOLATE 40ML",
    "descripcion_larga": "Tratamiento que aporta pigmentos para revitalizar el color del cabello como el primer día de tinturado. Intensifica los reflejos rojizos para mantener vibrantes los cabellos chocolates. Baño de color disponible en 5 tonos.",
    "precio_DIS": "1.75",
    "precio_CON": "2.50",
    "existencia": "341",
    "marca_id": "148",
    "marca_descripcion": "VOGUE CORP",
    "tipo_incentivo_id": "",
    "keywords": "Tratamiento, color y brillo, baño de color",
    "promocion": [],
    "imagen": [
        "017804.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "017809",
    "descripcion_prod": "SACHET SHAMPOO TRIGO 40ML",
    "descripcion_corta": "SACHET SHAMPOO TRIGO Y SEDA 40ML",
    "descripcion_larga": "FORTALEZA. El trigo y la seda fortalecen la hebra desde adentro para evitar la caída del cabello por quiebre. Con su portátil presentación en sachet obtienes producto hasta para 3 usos.",
    "precio_DIS": "1.39",
    "precio_CON": "1.99",
    "existencia": "1714",
    "marca_id": "150",
    "marca_descripcion": "LUZKO",
    "tipo_incentivo_id": "",
    "keywords": "Sachet, shampoo, caída por quiebre",
    "promocion": [],
    "imagen": [
        "017809.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "3",
    "descripcion_sublinea": "SHAMPOO",
    "producto_id": "017816",
    "descripcion_prod": "SHAMPOO CABELLO TINTURADO 500ML",
    "descripcion_corta": "SHAMPOO CABELLO TINTURADO 500ML",
    "descripcion_larga": "Shampoo libre de sulfatos, ideal para cabellos tinturados, tratados químicamente y expuestos frecuentemente a procesos térmicos. Limpia el cabello suavemente y evita la resequedad y la pérdida del color. Su fórmula enriquecida protegen el cabello y brindan vitalidad y resistencia.",
    "precio_DIS": "8.40",
    "precio_CON": "11.99",
    "existencia": "1068",
    "marca_id": "150",
    "marca_descripcion": "LUZKO",
    "tipo_incentivo_id": "",
    "keywords": "Cabello tinturado, cabello con color, sin sulfato",
    "promocion": [],
    "imagen": [
        "017816.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "6",
    "descripcion_sublinea": "COLOR",
    "producto_id": "017817",
    "descripcion_prod": "COLOR & BRILLO BEIGE 40ML",
    "descripcion_corta": "TRATAMIENTO COLOR & BRILLO BEIGE 40ML",
    "descripcion_larga": "Tratamiento que aporta pigmentos para revitalizar el color del cabello como el primer día de tinturado. Aporta un pigmento dorado irisado para que tu rubio se mantenga. Baño de color disponible en 5 tonos.",
    "precio_DIS": "1.75",
    "precio_CON": "2.50",
    "existencia": "474",
    "marca_id": "148",
    "marca_descripcion": "VOGUE CORP",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "017817.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "6",
    "descripcion_sublinea": "COLOR",
    "producto_id": "017822",
    "descripcion_prod": "TINTE RUBIO SUPERACLARANTE 10.00",
    "descripcion_corta": "TINTE RUBIO SUPERACLARANTE 10.00",
    "descripcion_larga": "Combinación perfecta entre tinte y tratamiento, que permite obtener un cabello saludable. Posee un alto contenido de aceites naturales, entre ellos: argán, oliva y macadamia. Su fórmula baja en amoníaco permite la fijación perfecta del color sin dañar tu cabello. 100% cobertura de canas.",
    "precio_DIS": "5.25",
    "precio_CON": "7.50",
    "existencia": "3",
    "marca_id": "148",
    "marca_descripcion": "VOGUE CORP",
    "tipo_incentivo_id": "p",
    "keywords": "Tinte, tratamiento, cabello tinturado, 100% cobertura de canas, bajo en amoníaco",
    "promocion": [],
    "imagen": [
        "017822.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "6",
    "descripcion_sublinea": "COLOR",
    "producto_id": "017826",
    "descripcion_prod": "TINTE RUBIO OSCURO ROJO INTENSO 6.6",
    "descripcion_corta": "TINTE RUBIO OSCURO ROJO INTENSO 6.6",
    "descripcion_larga": "Combinación perfecta entre tinte y tratamiento, que permite obtener un cabello saludable. Posee un alto contenido de aceites naturales, entre ellos: argán, oliva y macadamia. Su fórmula baja en amoníaco permite la fijación perfecta del color sin dañar tu cabello. 100% cobertura de canas.",
    "precio_DIS": "5.25",
    "precio_CON": "7.50",
    "existencia": "325",
    "marca_id": "193",
    "marca_descripcion": "DEVEN",
    "tipo_incentivo_id": "p",
    "keywords": "Tinte, tratamiento, cabello tinturado, 100% cobertura de canas, bajo en amoníaco",
    "promocion": [],
    "imagen": [
        "017826.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "6",
    "descripcion_sublinea": "COLOR",
    "producto_id": "017828",
    "descripcion_prod": "TINTE RUBIO MEDIO COBRIZO 7.4",
    "descripcion_corta": "TINTE RUBIO MEDIO COBRIZO 7.4",
    "descripcion_larga": "Combinación perfecta entre tinte y tratamiento, que permite obtener un cabello saludable. Posee un alto contenido de aceites naturales, entre ellos: argán, oliva y macadamia. Su fórmula baja en amoníaco permite la fijación perfecta del color sin dañar tu cabello. 100% cobertura de canas.",
    "precio_DIS": "5.25",
    "precio_CON": "7.50",
    "existencia": "598",
    "marca_id": "193",
    "marca_descripcion": "DEVEN",
    "tipo_incentivo_id": "p",
    "keywords": "Tinte, tratamiento, cabello tinturado, 100% cobertura de canas, bajo en amoníaco",
    "promocion": [],
    "imagen": [
        "017828.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "6",
    "descripcion_sublinea": "COLOR",
    "producto_id": "017829",
    "descripcion_prod": "TINTE CASTAÑO CLARO CAOBA 5.5",
    "descripcion_corta": "TINTE CASTAÑO CLARO CAOBA 5.5",
    "descripcion_larga": "Combinación perfecta entre tinte y tratamiento, que permite obtener un cabello saludable. Posee un alto contenido de aceites naturales, entre ellos: argán, oliva y macadamia. Su fórmula baja en amoníaco permite la fijación perfecta del color sin dañar tu cabello. 100% cobertura de canas.",
    "precio_DIS": "5.25",
    "precio_CON": "7.50",
    "existencia": "405",
    "marca_id": "193",
    "marca_descripcion": "DEVEN",
    "tipo_incentivo_id": "p",
    "keywords": "Tinte, tratamiento, cabello tinturado, 100% cobertura de canas, bajo en amoníaco",
    "promocion": [],
    "imagen": [
        "017829.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "6",
    "descripcion_sublinea": "COLOR",
    "producto_id": "017830",
    "descripcion_prod": "COLOR & BRILLO ROJO 40ML",
    "descripcion_corta": "TRATAMIENTO COLOR & BRILLO ROJO 40ML",
    "descripcion_larga": "Tratamiento que aporta pigmentos para revitalizar el color del cabello como el primer día de tinturado. Elimina los reflejos naranjas e intensifica los reflejos rojizos para mantener vibrantes los cabellos rojizos. Baño de color disponible en 5 tonos.",
    "precio_DIS": "1.75",
    "precio_CON": "2.50",
    "existencia": "1255",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "Tratamiento, color y brillo, baño de color",
    "promocion": [],
    "imagen": [
        "017830.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "6",
    "descripcion_sublinea": "COLOR",
    "producto_id": "017831",
    "descripcion_prod": "COLOR MAGIC NEGRO",
    "descripcion_corta": "COLOR MAGIC NEGRO",
    "descripcion_larga": "Tintura en crema que deja como resultado un cabello sedoso y brillante, su novedosa fórmula a base de aminoácidos y soya hace de este tinte un producto muy suave y delicado con el cabello y en menor tiempo de exposición (5 minutos). Disponible en 2 tonos.",
    "precio_DIS": "2.79",
    "precio_CON": "3.99",
    "existencia": "3412",
    "marca_id": "192",
    "marca_descripcion": "LUZCO",
    "tipo_incentivo_id": "",
    "keywords": "100% anticanas, tintura en crema, black magic",
    "promocion": [],
    "imagen": [
        "017831.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "6",
    "descripcion_sublinea": "COLOR",
    "producto_id": "017832",
    "descripcion_prod": "COLOR MAGIC CASTAÑO",
    "descripcion_corta": "COLOR MAGIC CASTAÑO",
    "descripcion_larga": "Tintura en crema que deja como resultado un cabello sedoso y brillante, su novedosa fórmula a base de aminoácidos y soya hace de este tinte un producto muy suave y delicado con el cabello y en menor tiempo de exposición (5 minutos). Disponible en 2 tonos.",
    "precio_DIS": "2.79",
    "precio_CON": "3.99",
    "existencia": "2308",
    "marca_id": "192",
    "marca_descripcion": "LUZCO",
    "tipo_incentivo_id": "",
    "keywords": "100% anticanas, tintura en crema, black magic",
    "promocion": [],
    "imagen": [
        "017832.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "5",
    "descripcion_sublinea": "ESTILIZADOR",
    "producto_id": "017834",
    "descripcion_prod": "SERUM GOTAS DE CRISTAL",
    "descripcion_corta": "SERUM GOTAS DE CRISTAL",
    "descripcion_larga": "Una gota que transforma. Serum de silicona capilar diseñado para brindar brillo extraordinario y suavidad extra al cabello. Contiene ingredientes que promueven la salud del cabello, hidratándolo sin dejar sensación grasosa. Olvídate del frizz y disfruta de un cabello disciplinado durante más tiempo.",
    "precio_DIS": "4.89",
    "precio_CON": "6.99",
    "existencia": "572",
    "marca_id": "200",
    "marca_descripcion": "NUTRI HAIR SALON QUALITY",
    "tipo_incentivo_id": "p",
    "keywords": "Gotitas, silicona, frizz",
    "promocion": [],
    "imagen": [
        "N-017834.png",
        "PC-017834.png",
        "PD-017834.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "5",
    "descripcion_sublinea": "ESTILIZADOR",
    "producto_id": "017835",
    "descripcion_prod": "MOUSSE CAPILAR 220ML",
    "descripcion_corta": "MOUSSE CAPILAR 220ML",
    "descripcion_larga": "Espuma termo protectora, enriquecida con keratina y bio-elixir, facilita el peinado y control del cabello. Proporciona un brillo excepcional, reduce el frizz y protege contra el daño térmico. Puede aplicarse sobre cabello húmedo o seco, no requiere enjuague. No deja efecto acartonado ni pegajoso.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "1606",
    "marca_id": "201",
    "marca_descripcion": "LUZKO",
    "tipo_incentivo_id": "n",
    "keywords": "Protector térmico, mousse, frizz, desenredante",
    "promocion": [],
    "imagen": [
        "017835.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "12",
    "descripcion_sublinea": "OTRO",
    "producto_id": "017836",
    "descripcion_prod": "SACHET GEL VERDE FIJADOR 10ML",
    "descripcion_corta": "SACHET GEL VERDE FIJADOR 10ML",
    "descripcion_larga": "SACHET GEL VERDE FIJADOR 10ML",
    "precio_DIS": "0.35",
    "precio_CON": "0.35",
    "existencia": "943",
    "marca_id": "200",
    "marca_descripcion": "NUTRI HAIR SALON QUALITY",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "12",
    "descripcion_sublinea": "OTRO",
    "producto_id": "017837",
    "descripcion_prod": "SACHET SHAMPOO CABELLO TINTURADO 10ML",
    "descripcion_corta": "SACHET SHAMPOO CABELLO TINTURADO 10ML",
    "descripcion_larga": "SACHET SHAMPOO CABELLO TINTURADO 10ML",
    "precio_DIS": "0.35",
    "precio_CON": "0.35",
    "existencia": "458",
    "marca_id": "192",
    "marca_descripcion": "LUZCO",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "01",
    "categoria": "CABELLO",
    "sublinea_id": "12",
    "descripcion_sublinea": "OTRO",
    "producto_id": "017838",
    "descripcion_prod": "SACHET SHAMPOO ROMERO Y SALVIA 10ML",
    "descripcion_corta": "SACHET SHAMPOO ROMERO Y SALVIA 10ML",
    "descripcion_larga": "SACHET SHAMPOO ROMERO Y SALVIA 10ML",
    "precio_DIS": "0.35",
    "precio_CON": "0.35",
    "existencia": "881",
    "marca_id": "192",
    "marca_descripcion": "LUZCO",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "1",
    "descripcion_sublinea": "PERFUME SPRAY 200ML",
    "producto_id": "0211001",
    "descripcion_prod": "POUR HOMME SPRAY 200ML",
    "descripcion_corta": "POUR HOMME 200ML",
    "descripcion_larga": "Notas amaderadas / florales. Perfume en spray con bruma fina que permite mejor aplicación y absorción, formato liviano y práctico para aplicar todas las veces que necesites.",
    "precio_DIS": "4.89",
    "precio_CON": "6.99",
    "existencia": "502",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "Amaderada, floral, masculino, hombre, perfume en spray, lattafa",
    "promocion": [],
    "imagen": [
        "0211001.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "1",
    "descripcion_sublinea": "PERFUME SPRAY 200ML",
    "producto_id": "0211003",
    "descripcion_prod": "VERY SENSITIVE SPRAY 200ML",
    "descripcion_corta": "VERY SENSITIVE 200ML",
    "descripcion_larga": "Notas florales / frutales que te hacen apreciar los detalles más pequeños y disfrutar cada segundo de la vida. Potencia a la mujer amorosa, dedicada y sensible.",
    "precio_DIS": "4.89",
    "precio_CON": "6.99",
    "existencia": "330",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "Floral, frutal, femenina, perfume en spray, mujer, lattafa",
    "promocion": [],
    "imagen": [
        "0211003.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "1",
    "descripcion_sublinea": "PERFUME SPRAY 200ML",
    "producto_id": "0211004",
    "descripcion_prod": "VICTOR HOMME SPRAY 200ML",
    "descripcion_corta": "VICTOR HOMME 200ML",
    "descripcion_larga": "Notas aromáticas / fougère. Perfume en spray con bruma fina que permite mejor aplicación y absorción, formato liviano y práctico para aplicar todas las veces que necesites.",
    "precio_DIS": "4.89",
    "precio_CON": "6.99",
    "existencia": "224",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "Aromática, fougère, perfume en spray, fresco, masculino, hombre, lattafa",
    "promocion": [],
    "imagen": [
        "0211004.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "2",
    "descripcion_sublinea": "EAU DE PARFUM",
    "producto_id": "0211006",
    "descripcion_prod": "ELEGANZA 100ML",
    "descripcion_corta": "ELEGANZA 100ML",
    "descripcion_larga": "Un perfume dulce y cítrico pensado para mujeres sensuales y llenas de confianza, que te hacen sentir hermosa y romántica, además de dejar una sensación de frescura todo el día.",
    "precio_DIS": "17.14",
    "precio_CON": "24.50",
    "existencia": "676",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "Perfume, eau de parfum, EDP, dulce, cítrico, femenino, mujer, lattafa",
    "promocion": [],
    "imagen": [
        "0211006.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "2",
    "descripcion_sublinea": "EAU DE PARFUM",
    "producto_id": "0211007",
    "descripcion_prod": "EPIC MEN 100ML",
    "descripcion_corta": "EPIC MEN 100ML",
    "descripcion_larga": "Una fragancia que evoca a la elegancia y el estilo, pensada para hombres apasionados y conquistadores. Sus notas especiadas resaltan tu personalidad única y espontaneidad.",
    "precio_DIS": "17.14",
    "precio_CON": "24.50",
    "existencia": "304",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Perfume, eau de parfum, EDP, masculino, hombre, cítrico, fresco, amaderado, lattafa",
    "promocion": [],
    "imagen": [
        "0211007.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "2",
    "descripcion_sublinea": "EAU DE PARFUM",
    "producto_id": "0211009",
    "descripcion_prod": "CASUAL CHIC 100ML",
    "descripcion_corta": "CASUAL CHIC 100ML",
    "descripcion_larga": "Fragancia floral para esas mujeres que bajo la piel esconden su verdadera naturaleza: tierna pero fuerte y sincera pero reconfortante.",
    "precio_DIS": "17.14",
    "precio_CON": "24.50",
    "existencia": "89",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Perfume, eau de parfum, EDP, floral, cítrico, femenino, mujer, lattafa",
    "promocion": [],
    "imagen": [
        "0211009.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "2",
    "descripcion_sublinea": "EAU DE PARFUM",
    "producto_id": "0211010",
    "descripcion_prod": "ATREVIDO 100ML",
    "descripcion_corta": "ATREVIDO 100ML",
    "descripcion_larga": "Una fragancia aromática fougère con notas frescas y especiadas. Pensada para hombres modernos con marcada masculinidad y clase.",
    "precio_DIS": "17.14",
    "precio_CON": "24.50",
    "existencia": "130",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Perfume, eau de parfum, EDP, masculino, hombre, aromática, fougère, lattafa",
    "promocion": [],
    "imagen": [
        "0211010.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "2",
    "descripcion_sublinea": "EAU DE PARFUM",
    "producto_id": "0211011",
    "descripcion_prod": "L AIR DE MUSE 100ML",
    "descripcion_corta": "L AIR DE MUSE 100ML",
    "descripcion_larga": "Un perfume de notas florales y orientales que complementa a la mujer que enfrenta los problemas con estilo y no se deja intimidar por nada.",
    "precio_DIS": "17.14",
    "precio_CON": "24.50",
    "existencia": "401",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Perfume, eau de parfum, EDP, floral, oriental, femenino, mujer, lattafa",
    "promocion": [],
    "imagen": [
        "0211011.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "2",
    "descripcion_sublinea": "EAU DE PARFUM",
    "producto_id": "0211014",
    "descripcion_prod": "BRILLIANT BLEU 100ML",
    "descripcion_corta": "BRILLIANT BLEU 100ML",
    "descripcion_larga": "Fragancia amaderada, fresca, profundamente sensual y de espíritu masculino. Contiene una potente mezcla de cítricos y maderas que liberarán tus sentidos.",
    "precio_DIS": "17.14",
    "precio_CON": "24.50",
    "existencia": "101",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Perfume, eau de parfum, EDP, masculino, hombre, amaderado, fresco, lattafa",
    "promocion": [],
    "imagen": [
        "0211014.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "2",
    "descripcion_sublinea": "EAU DE PARFUM",
    "producto_id": "0211020",
    "descripcion_prod": "VICTOR HOMME 100ML",
    "descripcion_corta": "VICTOR HOMME 100ML",
    "descripcion_larga": "Una fragancia fresca y amaderada para el hombre seductor y misterioso, compuesta de notas que expresan sensualidad y poder. Para esos héroes que no se disculpan por quienes son y defienden apasionadamente sus ideales.",
    "precio_DIS": "17.14",
    "precio_CON": "24.50",
    "existencia": "129",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Perfume, eau de parfum, EDP, masculino, hombre, cítrico, fresco, amaderado, lattafa",
    "promocion": [],
    "imagen": [
        "0211020.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "1",
    "descripcion_sublinea": "PERFUME SPRAY 200ML",
    "producto_id": "0211022",
    "descripcion_prod": "EPIC MEN SPRAY 200ML",
    "descripcion_corta": "EPIC MEN 200ML",
    "descripcion_larga": "Para hombres espontáneos, aventureros y soñadores, en busca de romance. Sus notas florales convierten su aroma en una tentación irresistible para la mujer.",
    "precio_DIS": "4.89",
    "precio_CON": "6.99",
    "existencia": "698",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "Oriental, especiado, perfume en spray, fresco, masculino, hombre, lattafa",
    "promocion": [],
    "imagen": [
        "0211022.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "1",
    "descripcion_sublinea": "PERFUME SPRAY 200ML",
    "producto_id": "0211024",
    "descripcion_prod": "MON COEUR SPRAY 200ML",
    "descripcion_corta": "MON COEUR 200ML",
    "descripcion_larga": "Fragancia ideal para las mujeres amantes de los olores dulces y siaves, con su aroma hace nublar completamente los sentidos.",
    "precio_DIS": "4.89",
    "precio_CON": "6.99",
    "existencia": "165",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "Floral, frutal, femenina, perfume en spray, mujer, lattafa",
    "promocion": [],
    "imagen": [
        "0211024.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "3",
    "descripcion_sublinea": "PERFUME SPRAY 250ML",
    "producto_id": "0211025",
    "descripcion_prod": "YARA PERFUME SPRAY 250ML",
    "descripcion_corta": "YARA 250ML PERFUME SPRAY",
    "descripcion_larga": "Perfume femenino deliciosamente atractivo que encanta con sus notas cremosas de orquídea y vainilla que lo hacen una fragancia cautivadora. Perfume Floral / Frutal.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "903",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Floral, frutal, femenina, perfume en spray, mujer, vainilla, lattafa, EDT, eau de toilette",
    "promocion": [],
    "imagen": [
        "0211025.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "3",
    "descripcion_sublinea": "PERFUME SPRAY 250ML",
    "producto_id": "0211027",
    "descripcion_prod": "EJAAZI PERFUME SPRAY 250ML",
    "descripcion_corta": "EJAAZI 250ML PERFUME SPRAY",
    "descripcion_larga": "Complementa la personalidad de un hombre cautivador con sus notas cítricas y amaderadas.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "174",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Oriental, cítrico, amaderado, masculino, hombre, perfume en spray, lattafa, eau de toilette, EDT",
    "promocion": [],
    "imagen": [
        "0211027.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "3",
    "descripcion_sublinea": "PERFUME SPRAY 250ML",
    "producto_id": "0211028",
    "descripcion_prod": "EKHTIARI PERFUME SPRAY 250ML",
    "descripcion_corta": "EKHTIARI 250ML PERFUME SPRAY",
    "descripcion_larga": "Perfume frutal, con notas de mandarina y almizcle. Complementa tu look con un toque de elegancia y encanto gracias a sus notas frutales.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "2383",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Floral, frutal, femenina, perfume en spray, mujer, lattafa, EDT, eau de toilette",
    "promocion": [],
    "imagen": [
        "0211028.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "3",
    "descripcion_sublinea": "PERFUME SPRAY 250ML",
    "producto_id": "0211029",
    "descripcion_prod": "UROOQ AL OUD PERFUME SPRAY 250ML",
    "descripcion_corta": "UROOQ AL OUD 250ML PERFUME SPRAY",
    "descripcion_larga": "Posee una calidez agradable y una profundidad amaderada que irradia pura armonía oriental. Perfume UNISEX.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "2779",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Amaderada, oriental, unisex, perfume en spray, lattafa, eau de toilette, EDT",
    "promocion": [],
    "imagen": [
        "0211029.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "3",
    "descripcion_sublinea": "PERFUME SPRAY 250ML",
    "producto_id": "0211030",
    "descripcion_prod": "WASHWASHAH PERFUME SPRAY 250ML",
    "descripcion_corta": "WASHWASHAH 250ML PERFUME SPRAY",
    "descripcion_larga": "Perfume floral con notas de frambuesa, pimienta rosa y jazmín.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "381",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Floral, dulce, oriental, femenina, perfume en spray, mujer, lattafa, EDT, eau de toilette",
    "promocion": [],
    "imagen": [
        "0211030.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "3",
    "descripcion_sublinea": "PERFUME SPRAY 250ML",
    "producto_id": "0211031",
    "descripcion_prod": "HAYAATI PERFUME SPRAY 250ML",
    "descripcion_corta": "HAYAATI 250ML PERFUME SPRAY",
    "descripcion_larga": "Perfume en spray con notas aromáticas / amaderadas. Un perfume con notas de manzana con un toque de canela y madera. Te permiten cautivar los sentidos a partir del aroma que refleja tu personalidad.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "1898",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Aromático, amaderado, masculino, hombre, perfume en spray, lattafa, eau de toilette, EDT",
    "promocion": [],
    "imagen": [
        "0211031.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "3",
    "descripcion_sublinea": "PERFUME SPRAY 250ML",
    "producto_id": "0211032",
    "descripcion_prod": "LAIL MALEKI PERFUME SPRAY 250ML",
    "descripcion_corta": "LAIL MALEKI 250ML PERFUME SPRAY",
    "descripcion_larga": "Perfume especiado con notas de cardamomo y albahaca. Perfume UNISEX.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "3021",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Floral, oriental, perfume en spray, eau de toilette, EDT, unisex, lattafa",
    "promocion": [],
    "imagen": [
        "0211032.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "3",
    "descripcion_sublinea": "PERFUME SPRAY 250ML",
    "producto_id": "0211034",
    "descripcion_prod": "AMETHYST PERFUME SPRAY 250ML",
    "descripcion_corta": "AMETHYST 250ML PERFUME SPRAY",
    "descripcion_larga": "Fragancia cálida con notas de vainilla, ámbar y rosas. La nota de salida comienza con un toque de pimienta rosa y bergamota fresca. Familia Oriental / Floral.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "1392",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Floral, oriental, vainilla, femenina, perfume en spray, mujer, lattafa, EDT, eau de toilette",
    "promocion": [],
    "imagen": [
        "0211034.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "3",
    "descripcion_sublinea": "PERFUME SPRAY 250ML",
    "producto_id": "0211035",
    "descripcion_prod": "FAKHAR MAN PERFUME SPRAY 250ML",
    "descripcion_corta": "FAKHAR MAN 250ML PERFUME SPRAY",
    "descripcion_larga": "Una base de madera, ámbar y bergamota, que reflejan la personalidad del hombre moderno y elegante.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "2042",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "p",
    "keywords": "Chipre, cítrico, masculino, hombre, perfume en spray, lattafa, eau de toilette, EDT",
    "promocion": [],
    "imagen": [
        "0211035.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "4",
    "descripcion_sublinea": "COLONIA",
    "producto_id": "0248001",
    "descripcion_prod": "COLONIA GOTITAS DE ORO 250ML",
    "descripcion_corta": "COLONIA GOTITAS DE ORO 250ML",
    "descripcion_larga": "Mezcla un aroma sutil y delicado con una fórmula baja en alcohol para no dañar la piel de tu bebé. Tamaño ideal para llevar a cualquier lugar, con un práctico atomizador para mejor aplicación.",
    "precio_DIS": "5.25",
    "precio_CON": "7.50",
    "existencia": "98",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Bebé, colonia, Instituto Español",
    "promocion": [],
    "imagen": [
        "0248001.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "4",
    "descripcion_sublinea": "COLONIA",
    "producto_id": "0248002",
    "descripcion_prod": "COLONIA AGUA DE LAVANDA 750ML",
    "descripcion_corta": "COLONIA AGUA DE LAVANDA 750ML",
    "descripcion_larga": "Agua de colonia con notas herbales y atalcadas a Lavanda, brindando una sensación refrescante y relajante que calma la mente y reduce el estrés.",
    "precio_DIS": "11.55",
    "precio_CON": "16.50",
    "existencia": "302",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "Lavanda, agua de colonia, Instituto Español",
    "promocion": [],
    "imagen": [
        "0248002.png"
    ]
},
{
    "linea_id": "02",
    "categoria": "PERFUME",
    "sublinea_id": "4",
    "descripcion_sublinea": "COLONIA",
    "producto_id": "0742607",
    "descripcion_prod": "COLONIA AGUA DE ROSAS 750ML",
    "descripcion_corta": "COLONIA AGUA DE ROSAS 750ML",
    "descripcion_larga": "Agua de Rosas es suave y fresca a la vez que elegante. Capaz de transportar a un mundo de sensaciones florales que nos impregna de naturalidad y bienestar. La esencia de rosas es un aroma clásico y tradicional y esta agua de colonia es ligera y suave sin perder su toque romántico y elegante.",
    "precio_DIS": "11.20",
    "precio_CON": "16.00",
    "existencia": "53",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Rosa, agua de colonia, Instituto Español",
    "promocion": [],
    "imagen": [
        "0742607.png"
    ]
},
{
    "linea_id": "03",
    "categoria": "SUPLEMENTOS VITAMINICOS",
    "sublinea_id": "1",
    "descripcion_sublinea": "ADULTOS",
    "producto_id": "0301003",
    "descripcion_prod": "BIOTIN SUPL. VIT.DELIGUMMY",
    "descripcion_corta": "BIOTIN SUPLEMENTO VITAMINICO DELIGUMMY",
    "descripcion_larga": "BIOTIN SUPLEMENTO VITAMINICO DELIGUMMY",
    "precio_DIS": "15.00",
    "precio_CON": "19.99",
    "existencia": "38",
    "marca_id": "187",
    "marca_descripcion": "DELIGUMMY",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "03",
    "categoria": "SUPLEMENTOS VITAMINICOS",
    "sublinea_id": "2",
    "descripcion_sublinea": "INFANTES",
    "producto_id": "0301005",
    "descripcion_prod": "CHILDREN MULTIVITAMIN MIX S. V. DELIGUMMY",
    "descripcion_corta": "CHILDREN MULTIVITAMIN MIX SUPLEMENTO VITAMINICO DELIGUMMY",
    "descripcion_larga": "CHILDREN MULTIVITAMIN MIX SUPLEMENTO VITAMINICO DELIGUMMY",
    "precio_DIS": "13.88",
    "precio_CON": "18.50",
    "existencia": "108",
    "marca_id": "187",
    "marca_descripcion": "DELIGUMMY",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "5",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "1054001",
    "descripcion_prod": "MASCARILLA ACTION A 70G",
    "descripcion_corta": "MASCARILLA ACTION A 70G",
    "descripcion_larga": "Es el complemento perfecto de la crema de día y la crema de noche Action A 24k. Inmediatamente después de una aplicación, la piel del rostro se siente nutrida, más tensa y firme. Después de 4 semanas de uso se mejora la firmeza, suavidad, luminosidad, flexibilidad y elasticidad de la piel.",
    "precio_DIS": "6.66",
    "precio_CON": "9.50",
    "existencia": "347",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Mascarilla facial, 24 K, tensora, antiedad",
    "promocion": [],
    "imagen": [
        "1054001.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "6",
    "descripcion_sublinea": "CREMA DE NOCHE",
    "producto_id": "1054003",
    "descripcion_prod": "CREMA DE NOCHE ACTION A 50G",
    "descripcion_corta": "CREMA FACIAL DE NOCHE ACTION A 50G",
    "descripcion_larga": "Innovadora crema de noche que fusiona Retinol y Oro Coloidal 24k en una fórmula que actúa sobre las zonas flácidas del rostro, el cuello y el escote. Contiene una mayor concentración de ingredientes para activar los mecanismos de regeneración celular durante las horas de sueño.",
    "precio_DIS": "14.69",
    "precio_CON": "20.98",
    "existencia": "793",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Crema de noche, 24 K, antiedad, retinol",
    "promocion": [],
    "imagen": [
        "1054003.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "7",
    "descripcion_sublinea": "CREMA DE DÍA",
    "producto_id": "1054004",
    "descripcion_prod": "CONTORNO DE OJOS HYDRABOMB 15G",
    "descripcion_corta": "CONTORNO DE OJOS HYDRABOMB 15G",
    "descripcion_larga": "Gel crema para contorno de ojos formulado con Coenzima Q10 e Hydrovance. Descongestiona y reduce las bolsas y ojeras. Previene arrugas y suaviza líneas de expresión de tu rostro.",
    "precio_DIS": "5.07",
    "precio_CON": "7.25",
    "existencia": "652",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Coenzima Q10, bolsas, ojeras",
    "promocion": [],
    "imagen": [
        "1054004.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "7",
    "descripcion_sublinea": "CREMA DE DÍA",
    "producto_id": "1054005",
    "descripcion_prod": "CREMA HYDRABOMB 50G",
    "descripcion_corta": "CREMA FACIAL HYDRABOMB 50G",
    "descripcion_larga": "Gel crema facial formulado con Coenzima Q10 e Hydrovance. Hidrata durante todo el día para que tu rostro obtenga el nivel óptimo de agua intracelular. Al mismo tiempo combate las arrugas por deshidratación y sequedad.",
    "precio_DIS": "6.12",
    "precio_CON": "8.75",
    "existencia": "902",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Coenzima Q10, gel crema, piel grasa",
    "promocion": [],
    "imagen": [
        "1054005.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "7",
    "descripcion_sublinea": "CREMA DE DÍA",
    "producto_id": "1054007",
    "descripcion_prod": "CREMA BABA DE CARACOL 110G",
    "descripcion_corta": "CREMA FACIAL BABA DE CARACOL 110G",
    "descripcion_larga": "Crema de regeneración profunda, gracias a su poder reconstituyente atenúa manchas y cicatrices, elimina marcas e imperfecciones y revitaliza la piel del rostro. La baba de caracol estinula la formación dle colágeno, elastina y los componentes dérmicos que reparan los signos del envejecimiento.",
    "precio_DIS": "5.20",
    "precio_CON": "7.99",
    "existencia": "966",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "v",
    "keywords": "Manchas, cicatrices, antiedad",
    "promocion": [],
    "imagen": [
        "1054007.png",
        "PV-1054007.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "7",
    "descripcion_sublinea": "CREMA DE DÍA",
    "producto_id": "1054008",
    "descripcion_prod": "PERFECTION WHITE SKIN 50G",
    "descripcion_corta": "PERFECTION WHITE SKIN 50G",
    "descripcion_larga": "Crema facial blanqueadora con edelweiss y filtro solar. Trata manchas de sol, hormonales, de la edad, acné y suaviza pecas. Reduce líneas finas y arrugas, protege de rayos UV, regenera, nutre y cura la piel,",
    "precio_DIS": "5.99",
    "precio_CON": "14.50",
    "existencia": "3437",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "v",
    "keywords": "Blanqueadora, manchas",
    "promocion": {
        "tipo_promocion": "DESC",
        "descuento": "30%",
        "precio_regular": "20.50"
    },
    "imagen": [
        "1054008.png",
        "PC-1054008.png",
        "PD-1054008.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "5",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "1054009",
    "descripcion_prod": "JELLY ICE MASK 50G",
    "descripcion_corta": "JELLY ICE MASK 50G",
    "descripcion_larga": "Una mascarilla facial con una nueva e innovadora textura gelatinosa, suave y con un efecto casi helado, súper refrescante para la piel. Su fórmula contiene extractos naturales y un increíble aroma a sandía. Hidrata, refresca y tonifica la piel dejando una increíble sensación de frescura.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "122",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "p",
    "keywords": "Mascarilla facial, frescura",
    "promocion": {
        "tipo_promocion": "CANT",
        "cantidad": "2x",
        "precio_promocion": "8.99"
    },
    "imagen": [
        "1054009.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "5",
    "descripcion_sublinea": "MASCARILLA",
    "producto_id": "1054010",
    "descripcion_prod": "MASK PEEL OFF NEGRA 50G",
    "descripcion_corta": "MASK PEEL OFF NEGRA 50G",
    "descripcion_larga": "Poderosa mascarilla efecto peel-off con carbón activado para la limpieza facial. Captura y elimina impurezas, desobstruye poros y elimina células muertas logrando una limpieza muy profunda de la piel",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "3476",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "p",
    "keywords": "Mascarilla facial, carbón, peel off",
    "promocion": {
        "tipo_promocion": "CANT",
        "cantidad": "2x",
        "precio_promocion": "8.99"
    },
    "imagen": [
        "1054010.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "1",
    "descripcion_sublinea": "EXFOLIANTE",
    "producto_id": "1054011",
    "descripcion_prod": "EXFOLIANTE FACIAL KIWI 50G",
    "descripcion_corta": "EXFOLIANTE FACIAL KIWI 50G",
    "descripcion_larga": "Exfoliante para pieles mixtas, ayuda a tener una piel purificada, más tersa y luminosa. Elimina células muertas y disminuye los puntos negros.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "334",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "p",
    "keywords": "Puntos negros, piel mixta",
    "promocion": {
        "tipo_promocion": "CANT",
        "cantidad": "2x",
        "precio_promocion": "8.99"
    },
    "imagen": [
        "1054011.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "1",
    "descripcion_sublinea": "EXFOLIANTE",
    "producto_id": "1054012",
    "descripcion_prod": "EXFOLIANTE FACIAL COCO 50G",
    "descripcion_corta": "EXFOLIANTE FACIAL COCO 50G",
    "descripcion_larga": "Exfoliante para pieles secas, ayuda a obtener una piel purificada, libre de asperezas y nutrida. Elimina células muertas y limpia en profundidad.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "1771",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "p",
    "keywords": "Células muertas, piel seca",
    "promocion": {
        "tipo_promocion": "CANT",
        "cantidad": "2x",
        "precio_promocion": "8.99"
    },
    "imagen": [
        "1054012.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "1",
    "descripcion_sublinea": "EXFOLIANTE",
    "producto_id": "1054016",
    "descripcion_prod": "CREMA EXFOLIANTE C/MENTOL 100G",
    "descripcion_corta": "CREMA EXFOLIANTE C/GRÁNULOS Y MENTOL 100G",
    "descripcion_larga": "Elimina impurezas, células muertas y grasitud. Deja la piel súper limpia, fresca y suave. Contiene gránulos y mentol. Recomendada para todo tipo de piel. ¡Ideal para usar bajo la ducha! No testeada en animales. Libre de Parabenos.",
    "precio_DIS": "5.77",
    "precio_CON": "8.25",
    "existencia": "2417",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Piel fresca, mentol",
    "promocion": [],
    "imagen": [
        "1054016.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "10",
    "descripcion_sublinea": "DESMAQUILLANTE",
    "producto_id": "1054017",
    "descripcion_prod": "AGUA MICELAR ROSAS 100ML",
    "descripcion_corta": "AGUA MICELAR ROSAS Y MANZANILLA 100ML",
    "descripcion_larga": "Su fórmula para piel sensible captura y retira con facilidad impurezas, suciedad y restos de maquillaje con las propiedades suavizantes y calmantes del agua de rosas y la manzanilla. La piel queda purificada, limpia y confortable. Libre de alcohol, libre de parabenos, con fragancia hipoalergénica.",
    "precio_DIS": "5.94",
    "precio_CON": "8.50",
    "existencia": "3535",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "v",
    "keywords": "Rosas, manzanilla, micelar, limpeiza",
    "promocion": [],
    "imagen": [
        "1054017.png"
    ]
},
{
    "linea_id": "04",
    "categoria": "CUIDADO FACIAL",
    "sublinea_id": "10",
    "descripcion_sublinea": "DESMAQUILLANTE",
    "producto_id": "1054020",
    "descripcion_prod": "AGUA MICELAR BIFÁSICA 100ML",
    "descripcion_corta": "AGUA MICELAR BIFÁSICA 100ML",
    "descripcion_larga": "Agua Micelar Bifase retira de una sola pasada todo tipo de maquillaje. Es resistente y a prueba de agua mientras cuida tu piel. Se encuentra oftalmológicamente y dermatológicamente testeado. Es libre de alcohol, parabenos y fragancia.",
    "precio_DIS": "8.40",
    "precio_CON": "11.99",
    "existencia": "1103",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "n",
    "keywords": "Desmaquillante, a prueba de agua",
    "promocion": [],
    "imagen": [
        "1054020.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "3",
    "descripcion_sublinea": "GEL DE BAÑO",
    "producto_id": "0540007",
    "descripcion_prod": "MINI GEL ROSA MOSQUETA 100ML",
    "descripcion_corta": "MINI GEL ROSA MOSQUETA 100ML",
    "descripcion_larga": "Una intensa hidratación a la piel que ayuda a retardar el proceso de envejecimiento y aporta elasticidad. Este gel crema está especialmente indicado para toda la familia y para pieles sensibles. Su versión mini es perfecta para cuidar tu piel donde quiera que vayas.",
    "precio_DIS": "2.45",
    "precio_CON": "3.50",
    "existencia": "509",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Gel crema, jabón, estrías, elasticidad, portátil, Instituto Español",
    "promocion": [],
    "imagen": [
        "0540007.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "7",
    "descripcion_sublinea": "GEL ÍNTIMO",
    "producto_id": "0540009",
    "descripcion_prod": "MINI GEL INTIMO 100ML",
    "descripcion_corta": "MINI GEL INTIMO 100ML",
    "descripcion_larga": "Fórmula estudiada dermatológicamente que ayuda a proteger la piel de posibles irritaciones y consiguiendo una hidratación natural en la zona íntima, así como una agradable sensación de frescor y bienestar durante todo el día. Su versión mini es perfecta para cuidar tu piel donde quiera que vayas.",
    "precio_DIS": "2.97",
    "precio_CON": "4.25",
    "existencia": "145",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Portátil, higiene íntima, Instituto Español",
    "promocion": [],
    "imagen": [
        "0540009.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "2",
    "descripcion_sublinea": "ROLL-ON",
    "producto_id": "0540256",
    "descripcion_prod": "ROLL-ON PIEL SANA 75 ML",
    "descripcion_corta": "ROLL-ON PIEL SANA 75 ML",
    "descripcion_larga": "Dermo, extra hidratante y suavizante, con acción antitranspirante de hasta 48 horas. HIDRATANTES DE ORIGEN 100% NATURAL.",
    "precio_DIS": "5.07",
    "precio_CON": "7.25",
    "existencia": "1348",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Roll-on, desodorante, natural, piel sensible, Instituto Español",
    "promocion": [],
    "imagen": [
        "0540256.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "7",
    "descripcion_sublinea": "GEL ÍNTIMO",
    "producto_id": "0540259",
    "descripcion_prod": "GEL INTIMO PIEL SANA 300ML",
    "descripcion_corta": "GEL INTIMO PIEL SANA 300ML",
    "descripcion_larga": "Antibacterial, indicado para toda la familia (pre-adolescentes en adelante) y es muy beneficiosa para la piel seca y sensible - ayuda a tener una piel más saludable y sana.",
    "precio_DIS": "5.94",
    "precio_CON": "8.50",
    "existencia": "867",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Higiene íntima, pre-adolescente, Instituto Español",
    "promocion": [],
    "imagen": [
        "0540259.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "7",
    "descripcion_sublinea": "GEL ÍNTIMO",
    "producto_id": "0540525",
    "descripcion_prod": "GEL INTIMO 300ML",
    "descripcion_corta": "GEL INTIMO 300ML",
    "descripcion_larga": "Fórmula estudiada dermatológicamente que ayuda a tener la piel sana con la máxima suavidad, protegiéndola de posibles irritaciones y consiguiendo una hidratación natural en los tejidos de las zonas íntimas, así como una agradable sensación de frescor y bienestar durante todo el día.",
    "precio_DIS": "5.94",
    "precio_CON": "8.50",
    "existencia": "360",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Higiene íntima, Instituto Español",
    "promocion": [],
    "imagen": [
        "0540525.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "9",
    "descripcion_sublinea": "PIEL ATÓPICA",
    "producto_id": "0540526",
    "descripcion_prod": "GEL INTIMO ATOPICAS 300ML",
    "descripcion_corta": "GEL INTIMO PIELES ATOPICAS 300ML",
    "descripcion_larga": "Su fórmula mantiene el pH natural de la piel, proporciona excelentes propiedades protectoras, higiénicas, hidratantes y refrescantes. Sus propiedades emolientes y suavizantes previenen las irritaciones provocadas por diferentes motivos, reduciendo el picor y la sensación de malestar.",
    "precio_DIS": "8.75",
    "precio_CON": "12.50",
    "existencia": "124",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Instituto Españo, higiene íntima, piel atópica, piel sensible, alergias",
    "promocion": [],
    "imagen": [
        "0540526.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "3",
    "descripcion_sublinea": "GEL DE BAÑO",
    "producto_id": "0544213",
    "descripcion_prod": "GEL CREMA DE ALMENDRAS 750ML",
    "descripcion_corta": "GEL CREMA DE ALMENDRAS 750ML",
    "descripcion_larga": "Gel cremoso que hidrata la piel en profundidad. Suave y fácil de aclarar, su espuma libera un aroma relajante de notas envolventes. Además ayuda a fortalecer y proteger la piel.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "81",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Gel crema, aromaterapia, Instituto Español, jabón",
    "promocion": [],
    "imagen": [
        "0544213.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "2",
    "descripcion_sublinea": "ROLL-ON",
    "producto_id": "0544510",
    "descripcion_prod": "ROLL-ON ROSA MOSQUETA 75ML",
    "descripcion_corta": "ROLL-ON ROSA MOSQUETA 75ML",
    "descripcion_larga": "La Rosa Mosqueta tiene una gran propiedad, revigoriza las células productoras de colágeno, elastina y ácido hialurónico, responsables de la firmeza y elasticidad de la piel. Sin alcohol.",
    "precio_DIS": "4.89",
    "precio_CON": "6.99",
    "existencia": "3786",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Roll-on, desodorante, Instituto Español",
    "promocion": [],
    "imagen": [
        "0544510.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "2",
    "descripcion_sublinea": "ROLL-ON",
    "producto_id": "05482",
    "descripcion_prod": "ROLL-ON AVENA 75ML",
    "descripcion_corta": "ROLL-ON AVENA 75ML",
    "descripcion_larga": "Este desodorante contiene los principales componentes nutritivos de la Avena, neutralizando los malos olores y proporcionando una agradable sensación de frescor durante todo el día. Es suave, ideal para todo tipo de pieles. No irrita la piel ya que no contiene alcohol.",
    "precio_DIS": "4.89",
    "precio_CON": "6.99",
    "existencia": "12338",
    "marca_id": "00",
    "marca_descripcion": "SIN MARCA",
    "tipo_incentivo_id": "",
    "keywords": "Desodorante, roll on, piel madura, Instituto Español",
    "promocion": [],
    "imagen": [
        "05482.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "3",
    "descripcion_sublinea": "GEL DE BAÑO",
    "producto_id": "05484",
    "descripcion_prod": "GEL DE DUCHA DE AVENA 750ML",
    "descripcion_corta": "GEL DE DUCHA DE AVENA 750ML",
    "descripcion_larga": "El Gel de Ducha de Avena facilita el cuidado de la piel, hidratándola al mismo tiempo que la nutre y limpia. Además le proporciona la elasticidad que una piel sana necesita a diario. Excelente en pieles maduras, sensibles o resecas.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "589",
    "marca_id": "00",
    "marca_descripcion": "SIN MARCA",
    "tipo_incentivo_id": "",
    "keywords": "Gel, ducha, jabón, piel madura, Instituto Español",
    "promocion": [],
    "imagen": [
        "05484.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "3",
    "descripcion_sublinea": "GEL DE BAÑO",
    "producto_id": "054860",
    "descripcion_prod": "MINI GEL DE AVENA 100ML",
    "descripcion_corta": "MINI GEL DE DUCHA DE AVENA 100ML",
    "descripcion_larga": "Facilita el cuidado de la piel, hidratándola al mismo tiempo que la nutre y limpia. Además le proporciona la elasticidad que una piel sana necesita a diario. Excelente en pieles maduras, sensibles o resecas. Su versión mini es perfecta para tu bolso para mantenerte siempre limpia e hidratada.",
    "precio_DIS": "2.27",
    "precio_CON": "3.25",
    "existencia": "13",
    "marca_id": "24",
    "marca_descripcion": "VOGUE D PARIS",
    "tipo_incentivo_id": "",
    "keywords": "Gel, ducha, jabón, piel madura, portátil, Instituto Español",
    "promocion": [],
    "imagen": [
        "054860.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "3",
    "descripcion_sublinea": "GEL DE BAÑO",
    "producto_id": "054862",
    "descripcion_prod": "MINI GEL LACTOADVANCE 100ML",
    "descripcion_corta": "MINI GEL LACTOADVANCE 100ML",
    "descripcion_larga": "Comienza un día perfecto con una relajante ducha que proporciona una extraordinaria hidratación, dejando tu piel más sedosa que nunca. Tu piel perfectamente hidratada, cuidada y suave. Su versión mini es perfecta para tu bolso para mantenerte siempre limpia e hidratada.",
    "precio_DIS": "2.27",
    "precio_CON": "3.25",
    "existencia": "265",
    "marca_id": "24",
    "marca_descripcion": "VOGUE D PARIS",
    "tipo_incentivo_id": "",
    "keywords": "Gel, ducha, jabón, piel joven, portátil, leche, Instituto Español",
    "promocion": [],
    "imagen": [
        "054862.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "3",
    "descripcion_sublinea": "GEL DE BAÑO",
    "producto_id": "054866",
    "descripcion_prod": "GEL CREMA DE VAINILLA 750ML",
    "descripcion_corta": "GEL CREMA DE VAINILLA 750ML",
    "descripcion_larga": "Combina las propiedades nutritivas de la leche con los beneficios de la vainilla, limpiando la piel de forma natural y dejando un delicado aroma.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "795",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Gel crema, jabón, aromaterapia, Instituto Español",
    "promocion": [],
    "imagen": [
        "054866.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "3",
    "descripcion_sublinea": "GEL DE BAÑO",
    "producto_id": "054867",
    "descripcion_prod": "GEL DE BAÑO COCO 750ML",
    "descripcion_corta": "GEL DE BAÑO DE COCO 750ML",
    "descripcion_larga": "Sumérgete en un baño de sensaciones con el gel Coco de Instituto Español. Aporta propiedades nutritivas e hidratantes para tu piel. Limpia tu piel a la vez que la nutre, dejándola hidratada durante todo el día. 0% Parabenos, relajante, nutre y purifica, protege y respeta tu piel.",
    "precio_DIS": "6.12",
    "precio_CON": "8.75",
    "existencia": "2100",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Jabón, gel, piel seca, Instituto Español",
    "promocion": [],
    "imagen": [
        "054867.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "3",
    "descripcion_sublinea": "GEL DE BAÑO",
    "producto_id": "054868",
    "descripcion_prod": "GEL DUCHA LACTOADVANCE 750ML",
    "descripcion_corta": "GEL DE DUCHA LACTOADVANCE 750ML",
    "descripcion_larga": "Comienza un día perfecto con una relajante ducha con Lacto Advance que proporciona una extraordinaria hidratación, dejando tu piel más sedosa que nunca. Tu piel perfectamente hidratada, cuidada y suave. Testado dermatológicamente. No tiene parabenos y es 100% apto veganos.",
    "precio_DIS": "6.12",
    "precio_CON": "8.75",
    "existencia": "1172",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Gel, ducha, jabón, piel joven, leche, Instituto Español",
    "promocion": [],
    "imagen": [
        "054868.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "2",
    "descripcion_sublinea": "ROLL-ON",
    "producto_id": "054877",
    "descripcion_prod": "ROLL-ON LACTOADVANCE 75ML",
    "descripcion_corta": "ROLL-ON LACTOADVANCE 75ML",
    "descripcion_larga": "Nuestro desodorante se caracteriza porque no mancha la ropa, reduce la transpiración y el mal olor, no lleva alcohol y, además, puede usarse en todo tipo de pieles. Sin lactosa y apto para veganos.",
    "precio_DIS": "4.89",
    "precio_CON": "6.99",
    "existencia": "4482",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Desodorante, roll on, leche, Instituto Español",
    "promocion": [],
    "imagen": [
        "054877.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "2",
    "descripcion_sublinea": "ROLL-ON",
    "producto_id": "054879",
    "descripcion_prod": "GEL INTIMO ODOR BLOCK 300ML",
    "descripcion_corta": "GEL INTIMO ODOR BLOCK 300ML",
    "descripcion_larga": "El Gel Íntimo Odor Block está indicado para la higiene y el cuidado femeninos. Aporta una agradable sensación de frescor y bienestar durante 24 horas. Ayuda a neutralizar el olor y protege de posibles irritaciones. Su fórmula íntima está estudiada dermatológicamente.",
    "precio_DIS": "6.66",
    "precio_CON": "9.50",
    "existencia": "1516",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "Jabón, olor, zona íntima, Instituto Español",
    "promocion": [],
    "imagen": [
        "054879.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "9",
    "descripcion_sublinea": "PIEL ATÓPICA",
    "producto_id": "054880",
    "descripcion_prod": "ROLL-ON PIELES ATOPICAS 75ML",
    "descripcion_corta": "ROLL-ON PIELES ATOPICAS 75ML",
    "descripcion_larga": "Formulado para el cuidado y la protección de la piel extra sensible e intolerante, evita la irritación y el picor. Su fórmula sin activos potencialmente alergénicos o irritantes en pieles sensibles, aseguran una acción desodorante fresca y duradera. No contiene alcohol, aluminio ni parabenos.",
    "precio_DIS": "7.18",
    "precio_CON": "10.25",
    "existencia": "146",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "Roll-on, desodorante, sin aluminio, piel atópica, piel sensible, Instituto Español",
    "promocion": [],
    "imagen": [
        "054880.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "2",
    "descripcion_sublinea": "ROLL-ON",
    "producto_id": "0549600",
    "descripcion_prod": "ROLL-ON ACTIVE 75ML",
    "descripcion_corta": "ROLL-ON ACTIVE 75ML",
    "descripcion_larga": "Siente el frescor en tu piel con su avanzada formulación, desarrollada con la mejor tecnología para brindarte hasta 48 horas de protección y frescura. Úsalo con tranquilidad, su fórmula 0% alérgenos lo convierte en una excelente opción para cualquier tipo de piel.",
    "precio_DIS": "5.07",
    "precio_CON": "7.25",
    "existencia": "4650",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "p",
    "keywords": "Roll-on, desodorante, Instituto Español",
    "promocion": [],
    "imagen": [
        "0549600.png",
        "PC-0549600.png",
        "PD-0549600.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "7",
    "descripcion_sublinea": "GEL ÍNTIMO",
    "producto_id": "0554001",
    "descripcion_prod": "LUBRICANTE ÍNTIMO 30G",
    "descripcion_corta": "LUBRICANTE ÍNTIMO 30G",
    "descripcion_larga": "Brinda una lubricación superior y prolongada, haciendo del encuentro sexual una experiencia más placentera, suave y natural. Soluciona problemas de sequedad vaginal por menopausia, ciclos hormonales, estrés y toma de medicamentos. Sin fragancia, no es anticonceptivo, compatible con preservativos.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "1029",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Lubricación, Gigot",
    "promocion": [],
    "imagen": [
        "0554001.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "5",
    "descripcion_sublinea": "EXFOLIANTE",
    "producto_id": "0554002",
    "descripcion_prod": "EXFOLIANTE DE MARACUYÁ 100G",
    "descripcion_corta": "EXFOLIANTE DE MARACUYÁ 100G",
    "descripcion_larga": "Delicado aroma refrescante y tropical que envuelve los sentidos. El maracuyá contiene excelentes propiedades medicinales gracias a su riqueza en vitaminas y minerales. Además, brinda beneficios cosméticos ya que el maracuyá destaca por sus propiedades exfoliantes y rejuvenecedoras de la piel.",
    "precio_DIS": "5.77",
    "precio_CON": "8.25",
    "existencia": "301",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Exfoliante corporal, Gigot, aromaterapia",
    "promocion": [],
    "imagen": [
        "0554002.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "8",
    "descripcion_sublinea": "PIEL",
    "producto_id": "0554003",
    "descripcion_prod": "PROTECT PIE 100G",
    "descripcion_corta": "PROTECT PIE 100G",
    "descripcion_larga": "Talco desodorante para pies con sudoración excesiva. Absorbe y neutraliza la transpiración. Elimina los malos olores y deja un agradable perfume. Pies frescos y secos 24 HORAS.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "623",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Malos olores, ejercicio, Gigot",
    "promocion": [],
    "imagen": [
        "0554003.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "9",
    "descripcion_sublinea": "PIEL ATÓPICA",
    "producto_id": "056658",
    "descripcion_prod": "GEL DUCHA ATOPICAS 500 ML",
    "descripcion_corta": "GEL DE DUCHA PIELES ATOPICAS 500 ML",
    "descripcion_larga": "Protege y calma los síntomas característicos de las pieles sensibles, secas y atópicas, por lo que prolonga el tiempo entre brotes. Minimiza los riesgos de reacciones alérgicas. Su base extra suave limpia sin irritar, y su acción hidratante favorece la elasticidad y tersura de la piel.",
    "precio_DIS": "11.89",
    "precio_CON": "16.98",
    "existencia": "10",
    "marca_id": "24",
    "marca_descripcion": "VOGUE D PARIS",
    "tipo_incentivo_id": "",
    "keywords": "Gel, jabón, piel atópica, piel sensible, Instituto Español",
    "promocion": [],
    "imagen": [
        "056658.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "3",
    "descripcion_sublinea": "GEL DE BAÑO",
    "producto_id": "056659",
    "descripcion_prod": "GEL DUCHA AROMATERAPIA 750 ML",
    "descripcion_corta": "GEL DE DUCHA AROMATERAPIA 750ML",
    "descripcion_larga": "Gel de textura cremosa que al entrar en contacto con la piel proporciona una delicada y envolvente sensación. Deja la piel suave e hidratada, con una sensación de limpieza muy agradable.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "595",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Gel, jabón, ducha, natural, Instituto Español",
    "promocion": [],
    "imagen": [
        "056659.png"
    ]
},
{
    "linea_id": "05",
    "categoria": "HIGIENE",
    "sublinea_id": "3",
    "descripcion_sublinea": "GEL DE BAÑO",
    "producto_id": "056660",
    "descripcion_prod": "GEL CREMA ROSA MOSQUETA 750ML",
    "descripcion_corta": "GEL CREMA ROSA MOSQUETA 750ML",
    "descripcion_larga": "Una intensa hidratación a la piel que ayuda a retardar el proceso de envejecimiento y aporta elasticidad. Este gel crema está especialmente indicado para toda la familia y para pieles sensibles.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "266",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Gel crema, jabón, elasticidad, piel sensible, estrías, embarazo, Instituto Español",
    "promocion": [],
    "imagen": [
        "056660.png"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "8",
    "descripcion_sublinea": "BASE",
    "producto_id": "06500001",
    "descripcion_prod": "BASE DELUXE HAZEL",
    "descripcion_corta": "BASE DELUXE HAZEL",
    "descripcion_larga": "Base de maquillaje cremosa de textura sedosa con protector solar SPF15, cobertura media-alta con tecnología que se adapta al tono de piel, corrige imperfecciones como ligeras líneas de expresión, rojeces, pecas y manchas. Disponible en 4 tonos.",
    "precio_DIS": "9.45",
    "precio_CON": "13.50",
    "existencia": "2367",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "p",
    "keywords": "Base cremosa",
    "promocion": {
        "tipo_promocion": "COMB",
        "productos_relacionados": [
            "06500007",
            "06500005",
            "06500008",
            "06500009",
            "06509606"
        ],
        "precio_promocion": "25.00"
    },
    "imagen": [
        "06500001.webp",
        "S-06500001.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "8",
    "descripcion_sublinea": "BASE",
    "producto_id": "06500002",
    "descripcion_prod": "BASE DELUXE NATURAL",
    "descripcion_corta": "BASE DELUXE NATURAL",
    "descripcion_larga": "Base de maquillaje cremosa de textura sedosa con protector solar SPF15, cobertura media-alta con tecnología que se adapta al tono de piel, corrige imperfecciones como ligeras líneas de expresión, rojeces, pecas y manchas. Disponible en 4 tonos.",
    "precio_DIS": "9.45",
    "precio_CON": "13.50",
    "existencia": "1403",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "p",
    "keywords": "Base cremosa",
    "promocion": {
        "tipo_promocion": "COMB",
        "productos_relacionados": [
            "06500007",
            "06500005",
            "06500008",
            "06500009",
            "06509606"
        ],
        "precio_promocion": "25.00"
    },
    "imagen": [
        "06500002.webp",
        "S-06500002.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "8",
    "descripcion_sublinea": "BASE",
    "producto_id": "06500003",
    "descripcion_prod": "BASE DELUXE NUDE",
    "descripcion_corta": "BASE DELUXE NUDE",
    "descripcion_larga": "Base de maquillaje cremosa de textura sedosa con protector solar SPF15, cobertura media-alta con tecnología que se adapta al tono de piel, corrige imperfecciones como ligeras líneas de expresión, rojeces, pecas y manchas. Disponible en 4 tonos.",
    "precio_DIS": "9.45",
    "precio_CON": "13.50",
    "existencia": "1939",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "p",
    "keywords": "Base cremosa",
    "promocion": {
        "tipo_promocion": "COMB",
        "productos_relacionados": [
            "06500007",
            "06500005",
            "06500008",
            "06500009",
            "06509606"
        ],
        "precio_promocion": "25.00"
    },
    "imagen": [
        "06500003.webp",
        "S-06500003.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "8",
    "descripcion_sublinea": "BASE",
    "producto_id": "06500004",
    "descripcion_prod": "BASE DELUXE PERFECT",
    "descripcion_corta": "BASE DELUXE PERFECT",
    "descripcion_larga": "Base de maquillaje cremosa de textura sedosa con protector solar SPF15, cobertura media-alta con tecnología que se adapta al tono de piel, corrige imperfecciones como ligeras líneas de expresión, rojeces, pecas y manchas. Disponible en 4 tonos.",
    "precio_DIS": "9.45",
    "precio_CON": "13.50",
    "existencia": "2141",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "p",
    "keywords": "Base cremosa",
    "promocion": {
        "tipo_promocion": "COMB",
        "productos_relacionados": [
            "06500007",
            "06500005",
            "06500008",
            "06500009",
            "06509606"
        ],
        "precio_promocion": "25.00"
    },
    "imagen": [
        "06500004.webp",
        "S-06500004.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "7",
    "descripcion_sublinea": "POLVO",
    "producto_id": "06500005",
    "descripcion_prod": "DELUXE POWDER NUDE",
    "descripcion_corta": "DELUXE POWDER NUDE",
    "descripcion_larga": "Polvo compacto de alta cobertura que controla el brillo y ayuda a minimizar imperfecciones, sin dejar efecto acartonado gracias a su fórmula micropulverizada, para llevar una apariencia impecable todo el día. Contiene aceite de argán y protección solar SPF15. Disponible en 5 tonos.",
    "precio_DIS": "9.79",
    "precio_CON": "13.99",
    "existencia": "1755",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "p",
    "keywords": "Polvo compacto, alta cobertura",
    "promocion": {
        "tipo_promocion": "COMB",
        "productos_relacionados": [
            "06500003",
            "06500002",
            "06500004",
            "06500001"
        ],
        "precio_promocion": "25.00"
    },
    "imagen": [
        "06500005.webp",
        "S-06500005.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "7",
    "descripcion_sublinea": "POLVO",
    "producto_id": "06500007",
    "descripcion_prod": "DELUXE POWDER NEUTRAL",
    "descripcion_corta": "DELUXE POWDER NEUTRAL",
    "descripcion_larga": "Polvo compacto de alta cobertura que controla el brillo y ayuda a minimizar imperfecciones, sin dejar efecto acartonado gracias a su fórmula micropulverizada, para llevar una apariencia impecable todo el día. Contiene aceite de argán y protección solar SPF15. Disponible en 5 tonos.",
    "precio_DIS": "9.79",
    "precio_CON": "13.99",
    "existencia": "2178",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "p",
    "keywords": "Polvo compacto, alta cobertura",
    "promocion": {
        "tipo_promocion": "COMB",
        "productos_relacionados": [
            "06500003",
            "06500002",
            "06500004",
            "06500001"
        ],
        "precio_promocion": "25.00"
    },
    "imagen": [
        "06500007.webp",
        "S-06500007.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "7",
    "descripcion_sublinea": "POLVO",
    "producto_id": "06500008",
    "descripcion_prod": "DELUXE POWDER NATURAL",
    "descripcion_corta": "DELUXE POWDER NATURAL",
    "descripcion_larga": "Polvo compacto de alta cobertura que controla el brillo y ayuda a minimizar imperfecciones, sin dejar efecto acartonado gracias a su fórmula micropulverizada, para llevar una apariencia impecable todo el día. Contiene aceite de argán y protección solar SPF15. Disponible en 5 tonos.",
    "precio_DIS": "9.79",
    "precio_CON": "13.99",
    "existencia": "1385",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "p",
    "keywords": "Polvo compacto, alta cobertura",
    "promocion": {
        "tipo_promocion": "COMB",
        "productos_relacionados": [
            "06500003",
            "06500002",
            "06500004",
            "06500001"
        ],
        "precio_promocion": "25.00"
    },
    "imagen": [
        "06500008.webp",
        "S-06500008.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "7",
    "descripcion_sublinea": "POLVO",
    "producto_id": "06500009",
    "descripcion_prod": "DELUXE POWDER HONEY",
    "descripcion_corta": "DELUXE POWDER HONEY",
    "descripcion_larga": "Polvo compacto de alta cobertura que controla el brillo y ayuda a minimizar imperfecciones, sin dejar efecto acartonado gracias a su fórmula micropulverizada, para llevar una apariencia impecable todo el día. Contiene aceite de argán y protección solar SPF15. Disponible en 5 tonos.",
    "precio_DIS": "9.79",
    "precio_CON": "13.99",
    "existencia": "2755",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "p",
    "keywords": "Polvo compacto, alta cobertura",
    "promocion": {
        "tipo_promocion": "COMB",
        "productos_relacionados": [
            "06500003",
            "06500002",
            "06500004",
            "06500001"
        ],
        "precio_promocion": "25.00"
    },
    "imagen": [
        "06500009.webp",
        "S-06500009.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06500010",
    "descripcion_prod": "LARGA DURACION NUDE",
    "descripcion_corta": "LABIAL LARGA DURACION NUDE",
    "descripcion_larga": "Fórmula que se adhiere a tus labios para que goces de una mayor duración sin causar resequedad. Brinda a tus labios un acabado semi-mate. Disponible en 8 tonos.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "2592",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Semi mate, cremoso",
    "promocion": [],
    "imagen": [
        "06500010.webp",
        "S-06500010.webp",
        "S2-06500010.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06500011",
    "descripcion_prod": "LARGA DURACION ROSADO",
    "descripcion_corta": "LABIAL LARGA DURACION ROSADO",
    "descripcion_larga": "Fórmula que se adhiere a tus labios para que goces de una mayor duración sin causar resequedad. Brinda a tus labios un acabado semi-mate. Disponible en 8 tonos.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "2119",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Semi mate, cremoso",
    "promocion": [],
    "imagen": [
        "06500011.webp",
        "S-06500011.webp",
        "S2-06500011.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06500012",
    "descripcion_prod": "LARGA DURACION ROSA CHIC",
    "descripcion_corta": "LABIAL LARGA DURACION ROSA CHIC",
    "descripcion_larga": "Fórmula que se adhiere a tus labios para que goces de una mayor duración sin causar resequedad. Brinda a tus labios un acabado semi-mate. Disponible en 8 tonos.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "1147",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Semi mate, cremoso",
    "promocion": [],
    "imagen": [
        "06500012.webp",
        "S-06500012.webp",
        "S2-06500012.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06500014",
    "descripcion_prod": "LARGA DURACION BRONCE",
    "descripcion_corta": "LABIAL LARGA DURACION BRONCE",
    "descripcion_larga": "Fórmula que se adhiere a tus labios para que goces de una mayor duración sin causar resequedad. Brinda a tus labios un acabado semi-mate. Disponible en 8 tonos.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "1493",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Semi mate, cremoso",
    "promocion": [],
    "imagen": [
        "06500014.webp",
        "S-06500014.webp",
        "S2-06500014.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06500015",
    "descripcion_prod": "LARGA DURACION OCRE",
    "descripcion_corta": "LABIAL LARGA DURACION OCRE",
    "descripcion_larga": "Fórmula que se adhiere a tus labios para que goces de una mayor duración sin causar resequedad. Brinda a tus labios un acabado semi-mate. Disponible en 8 tonos.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "1921",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Semi mate, cremoso",
    "promocion": [],
    "imagen": [
        "06500015.webp",
        "S-06500015.webp",
        "S2-06500015.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "4",
    "descripcion_sublinea": "DELINEADORES",
    "producto_id": "06501001",
    "descripcion_prod": "PLUMON MIDNIGHT WATERPROOF",
    "descripcion_corta": "PLUMON MIDNIGHT WATERPROOF",
    "descripcion_larga": "Plumón delineador de fácil aplicación gracias a su punta fina y flexible, de secado rápido y a prueba de agua. Aporta un acabado negro matte que intensifica tu mirada. Duración de hasta 24 horas.",
    "precio_DIS": "6.66",
    "precio_CON": "9.50",
    "existencia": "2021",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Delineador, negro, a prueba de agua",
    "promocion": [],
    "imagen": [
        "06501001.webp",
        "S-06501001.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06501009",
    "descripcion_prod": "LIQUID MATTE CHOCOLAT",
    "descripcion_corta": "LABIAL LIQUID MATTE CHOCOLAT",
    "descripcion_larga": "Labial líquido de suave aplicación, excelente adherencia y que aporta un acabado aterciopelado. Acabado matte de larga duración que no se transfiere. Disponible en 5 tonos.",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "1734",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Labial líquido, efecto matte, color intenso",
    "promocion": [],
    "imagen": [
        "06501009.webp",
        "S-06501009.webp",
        "S2-06501009.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06501010",
    "descripcion_prod": "LIQUID MATTE RED VELVET",
    "descripcion_corta": "LABIAL LIQUID MATTE RED VELVET",
    "descripcion_larga": "Labial líquido de suave aplicación, excelente adherencia y que aporta un acabado aterciopelado. Acabado matte de larga duración que no se transfiere. Disponible en 5 tonos.",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "485",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Labial líquido, efecto matte, color intenso",
    "promocion": [],
    "imagen": [
        "06501010.webp",
        "S-06501010.webp",
        "S2-06501010.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06501011",
    "descripcion_prod": "LIQUID MATTE RASPBERRY",
    "descripcion_corta": "LABIAL LIQUID MATTE RASPBERRY",
    "descripcion_larga": "Labial líquido de suave aplicación, excelente adherencia y que aporta un acabado aterciopelado. Acabado matte de larga duración que no se transfiere. Disponible en 5 tonos.",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "1164",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Labial líquido, efecto matte, color intenso",
    "promocion": [],
    "imagen": [
        "06501011.webp",
        "S-06501011.webp",
        "S2-06501011.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06501012",
    "descripcion_prod": "LIQUID MATTE CHERRY",
    "descripcion_corta": "LABIAL LIQUID MATTE CHERRY",
    "descripcion_larga": "Labial líquido de suave aplicación, excelente adherencia y que aporta un acabado aterciopelado. Acabado matte de larga duración que no se transfiere. Disponible en 5 tonos.",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "1321",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Labial líquido, efecto matte, color intenso",
    "promocion": [],
    "imagen": [
        "06501012.webp",
        "S-06501012.webp",
        "S2-06501012.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06501013",
    "descripcion_prod": "LIQUID MATTE CARAMEL",
    "descripcion_corta": "LABIAL LIQUID MATTE CARAMEL",
    "descripcion_larga": "Labial líquido de suave aplicación, excelente adherencia y que aporta un acabado aterciopelado. Acabado matte de larga duración que no se transfiere. Disponible en 5 tonos.",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "247",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Labial líquido, efecto matte, color intenso",
    "promocion": [],
    "imagen": [
        "06501013.webp",
        "S-06501013.webp",
        "S2-06501013.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06506368",
    "descripcion_prod": "LARGA DURACION ROJO AMOR",
    "descripcion_corta": "LABIAL LARGA DURACION ROJO AMOR",
    "descripcion_larga": "Fórmula que se adhiere a tus labios para que goces de una mayor duración sin causar resequedad. Brinda a tus labios un acabado semi-mate. Disponible en 8 tonos.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "1660",
    "marca_id": "118",
    "marca_descripcion": "CREATIVE COLOR",
    "tipo_incentivo_id": "",
    "keywords": "Semi mate, cremoso",
    "promocion": [],
    "imagen": [
        "06506368.webp",
        "S-06506368.webp",
        "S2-06506368.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06506369",
    "descripcion_prod": "LARGA DURACION VINO",
    "descripcion_corta": "LABIAL LARGA DURACION VINO",
    "descripcion_larga": "Fórmula que se adhiere a tus labios para que goces de una mayor duración sin causar resequedad. Brinda a tus labios un acabado semi-mate. Disponible en 8 tonos.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "1712",
    "marca_id": "118",
    "marca_descripcion": "CREATIVE COLOR",
    "tipo_incentivo_id": "",
    "keywords": "Semi mate, cremoso",
    "promocion": [],
    "imagen": [
        "06506369.webp",
        "S-06506369.webp",
        "S2-06506369.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "4",
    "descripcion_sublinea": "DELINEADORES",
    "producto_id": "06506370",
    "descripcion_prod": "LAPIZ DELINEADOR NEGRO",
    "descripcion_corta": "LAPIZ DELINEADOR NEGRO",
    "descripcion_larga": "Lápiz delineador cremoso de textura suave y sedosa que brinda a tus ojos un delineado perfecto. Su fórmula que contiene vitamina E puede usarse para delinear tanto el párpado como la línea de agua para el delineado que desees! Disponible en 2 tonos.",
    "precio_DIS": "3.85",
    "precio_CON": "5.50",
    "existencia": "9",
    "marca_id": "118",
    "marca_descripcion": "CREATIVE COLOR",
    "tipo_incentivo_id": "",
    "keywords": "Delineador, negro, madera",
    "promocion": [],
    "imagen": [
        "06506370.webp",
        "S-06506370.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "4",
    "descripcion_sublinea": "DELINEADORES",
    "producto_id": "06506371",
    "descripcion_prod": "LAPIZ DELINEADOR CAFE",
    "descripcion_corta": "LAPIZ DELINEADOR CAFE",
    "descripcion_larga": "Lápiz delineador cremoso de textura suave y sedosa que brinda a tus ojos un delineado perfecto. Su fórmula que contiene vitamina E puede usarse para delinear tanto el párpado como la línea de agua para el delineado que desees! Disponible en 2 tonos.",
    "precio_DIS": "3.85",
    "precio_CON": "5.50",
    "existencia": "1278",
    "marca_id": "118",
    "marca_descripcion": "CREATIVE COLOR",
    "tipo_incentivo_id": "",
    "keywords": "Delineador, café, madera",
    "promocion": [],
    "imagen": [
        "06506371.webp",
        "S-06506371.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06506382",
    "descripcion_prod": "LABIAL MATTE CEREZA",
    "descripcion_corta": "LABIAL MATTE CEREZA",
    "descripcion_larga": "Fórmula especial de aplicación cremosa y suave, volviéndolo más confortable en su uso y alargando su duración, haciendo que los labios no se sientan resecos. A prueba de agua, no se corre y no genera escamas.",
    "precio_DIS": "4.37",
    "precio_CON": "6.25",
    "existencia": "50",
    "marca_id": "118",
    "marca_descripcion": "CREATIVE COLOR",
    "tipo_incentivo_id": "",
    "keywords": "Matte, a prueba de agua",
    "promocion": [],
    "imagen": [
        "06506382.webp",
        "S-06506382.webp",
        "S2-06506382.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "2",
    "descripcion_sublinea": "LABIALES",
    "producto_id": "06506386",
    "descripcion_prod": "LABIAL MATTE CARIOCA",
    "descripcion_corta": "LABIAL MATTE CARIOCA",
    "descripcion_larga": "Fórmula especial de aplicación cremosa y suave, volviéndolo más confortable en su uso y alargando su duración, haciendo que los labios no se sientan resecos. A prueba de agua, no se corre y no genera escamas.",
    "precio_DIS": "4.37",
    "precio_CON": "6.25",
    "existencia": "1",
    "marca_id": "118",
    "marca_descripcion": "CREATIVE COLOR",
    "tipo_incentivo_id": "",
    "keywords": "Matte, a prueba de agua",
    "promocion": [],
    "imagen": [
        "06506386.webp",
        "S-06506386.png",
        "S-06506386.webp",
        "S2-06506386.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "4",
    "descripcion_sublinea": "DELINEADORES",
    "producto_id": "06506388",
    "descripcion_prod": "DELINEADOR LIQUIDO NEGRO",
    "descripcion_corta": "DELINEADOR LIQUIDO NEGRO",
    "descripcion_larga": "Permite obtener un delineado preciso con trazos más negros y dramáticos, de rápido secado y alta fijación.",
    "precio_DIS": "4.19",
    "precio_CON": "5.99",
    "existencia": "2804",
    "marca_id": "118",
    "marca_descripcion": "CREATIVE COLOR",
    "tipo_incentivo_id": "p",
    "keywords": "Punta pincel, delineado preciso",
    "promocion": {
        "tipo_promocion": "DESC",
        "descuento": "20%",
        "precio_regular": "7.50"
    },
    "imagen": [
        "06506388.webp",
        "PC-06506388.png",
        "PD-06506388.png",
        "S-06506388.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "4",
    "descripcion_sublinea": "DELINEADORES",
    "producto_id": "06507711",
    "descripcion_prod": "LÁPIZ WATERPROOF NEGRO",
    "descripcion_corta": "LÁPIZ DE OJOS WATERPROOF NEGRO",
    "descripcion_larga": "Lápiz de mina suave con un intenso color negro y una fórmula única a prueba de agua.",
    "precio_DIS": "5.25",
    "precio_CON": "7.50",
    "existencia": "2392",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "A prueba de agua, negro",
    "promocion": [],
    "imagen": [
        "06507711.webp",
        "S-06507711.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "3",
    "descripcion_sublinea": "OJOS/CEJAS",
    "producto_id": "06509599",
    "descripcion_prod": "WOW BROW COCOA",
    "descripcion_corta": "WOW BROW COCOA",
    "descripcion_larga": "Su fórmula altamente pigmentada le permiten crear fácilmente la apariencia de cejas llenas, definidas y expresivas. Crema de secado rápido que no mancha, a prueba de agua y sebo. Cuenta con una brocha para aplicar y cepillo para peinar las cejas y difuminar el producto para un acabado natural.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "2069",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Pomada, cejas",
    "promocion": [],
    "imagen": [
        "06509599.webp",
        "S-06509599.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "3",
    "descripcion_sublinea": "OJOS/CEJAS",
    "producto_id": "06509600",
    "descripcion_prod": "WOW BROW CASTAÑO",
    "descripcion_corta": "WOW BROW CASTAÑO",
    "descripcion_larga": "Su fórmula altamente pigmentada le permiten crear fácilmente la apariencia de cejas llenas, definidas y expresivas. Crema de secado rápido que no mancha, a prueba de agua y sebo. Cuenta con una brocha para aplicar y cepillo para peinar las cejas y difuminar el producto para un acabado natural.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "1020",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Pomada, cejas",
    "promocion": [],
    "imagen": [
        "06509600.webp",
        "S-06509600.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "3",
    "descripcion_sublinea": "OJOS/CEJAS",
    "producto_id": "06509602",
    "descripcion_prod": "MASCARA LASH LEGEND",
    "descripcion_corta": "MASCARA LASH LEGEND",
    "descripcion_larga": "Pestañas super largas y con volumen! Su cepillo tiene un diseño sobredimensionado, con cerdas suaves que permiten un volumen óptimo de capas. Sus fibras flexibles aseguran un recubrimiento uniforme sobre las pestañas. ¡La máscara perfecta para una mirada impactante! Fórmula resistente al agua.",
    "precio_DIS": "6.47",
    "precio_CON": "9.25",
    "existencia": "883",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Rimmel, pestañas",
    "promocion": [],
    "imagen": [
        "06509602.webp",
        "S-06509602.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "1",
    "descripcion_sublinea": "MEJILLAS",
    "producto_id": "06509605",
    "descripcion_prod": "OH! MY BLUSH BERRY",
    "descripcion_corta": "OH! MY BLUSH BERRY",
    "descripcion_larga": "Brinda a tu rostro un ruborizado hermoso y saludable con nada más que un par de toques de tu brocha. Puedes agregar fácilmente un toque de color a tus mejillas o úsalo para dar forma y esculpir tu rostro o darle un toque de vida a tus ojos. Acabado mate natural y sedoso con excelente cobertura.",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "591",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Rubor",
    "promocion": [],
    "imagen": [
        "06509605.webp",
        "S-06509605.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "1",
    "descripcion_sublinea": "MEJILLAS",
    "producto_id": "06509606",
    "descripcion_prod": "OH! MY BLUSH PEACH",
    "descripcion_corta": "OH! MY BLUSH PEACH",
    "descripcion_larga": "Brinda a tu rostro un ruborizado hermoso y saludable con nada más que un par de toques de tu brocha. Puedes agregar fácilmente un toque de color a tus mejillas o úsalo para dar forma y esculpir tu rostro o darle un toque de vida a tus ojos. Acabado mate natural y sedoso con excelente cobertura.",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "600",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "p",
    "keywords": "Rubor",
    "promocion": {
        "tipo_promocion": "COMB",
        "productos_relacionados": [
            "06500003",
            "06500002",
            "06500004",
            "06500001"
        ],
        "precio_promocion": "25.00"
    },
    "imagen": [
        "06509606.webp",
        "S-06509606.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "6",
    "descripcion_sublinea": "SOMBRAS",
    "producto_id": "06509638",
    "descripcion_prod": "SOMBRAS MYSTYLE IS CHIC",
    "descripcion_corta": "SOMBRAS MYSTYLE IS CHIC",
    "descripcion_larga": "Paleta de 4 tonos, desde mattes intensos hasta satinados brillantes, cada acabado se mantiene suave con un efecto vibrante. Sus pigmentos crean un color rico y de alto impacto. Su fórmula con aceite de jojoba es liviana, muy suave al aplicar y que pueden ser difuminadas fácilmente.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "3229",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Look noche",
    "promocion": [],
    "imagen": [
        "06509638.webp",
        "S-06509638.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "6",
    "descripcion_sublinea": "SOMBRAS",
    "producto_id": "06509639",
    "descripcion_prod": "SOMBRAS MYSTYLE IS FEMININE",
    "descripcion_corta": "SOMBRAS MYSTYLE IS FEMININE",
    "descripcion_larga": "Paleta de 4 tonos, desde mattes intensos hasta satinados brillantes, cada acabado se mantiene suave con un efecto vibrante. Sus pigmentos crean un color rico y de alto impacto. Su fórmula con aceite de jojoba es liviana, muy suave al aplicar y que pueden ser difuminadas fácilmente.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "2814",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Look femenino",
    "promocion": [],
    "imagen": [
        "06509639.webp",
        "S-06509639.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "6",
    "descripcion_sublinea": "SOMBRAS",
    "producto_id": "06509640",
    "descripcion_prod": "SOMBRAS MYSTYLE IS CLASSY",
    "descripcion_corta": "SOMBRAS MYSTYLE IS CLASSY",
    "descripcion_larga": "Paleta de 4 tonos, desde mattes intensos hasta satinados brillantes, cada acabado se mantiene suave con un efecto vibrante. Sus pigmentos crean un color rico y de alto impacto. Su fórmula con aceite de jojoba es liviana, muy suave al aplicar y que pueden ser difuminadas fácilmente.",
    "precio_DIS": "6.99",
    "precio_CON": "9.99",
    "existencia": "2956",
    "marca_id": "156",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Look profesional",
    "promocion": [],
    "imagen": [
        "06509640.webp",
        "S-06509640.webp"
    ]
},
{
    "linea_id": "06",
    "categoria": "MAQUILLAJE",
    "sublinea_id": "1",
    "descripcion_sublinea": "MEJILLAS",
    "producto_id": "067904",
    "descripcion_prod": "POLVO BRONCEADOR MYSTYLE",
    "descripcion_corta": "POLVO BRONCEADOR MYSTYLE",
    "descripcion_larga": "Versátil bronceador que se ajusta a la tonalidad de tu piel, resaltando tu rostro con una iluminación cálida y natural.",
    "precio_DIS": "9.45",
    "precio_CON": "13.50",
    "existencia": "647",
    "marca_id": "151",
    "marca_descripcion": "MY INSTYLE",
    "tipo_incentivo_id": "",
    "keywords": "Bronzer, contorno, bronceado, ilumina",
    "promocion": [],
    "imagen": [
        "067904.webp",
        "S-067904.webp"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "0740006",
    "descripcion_prod": "MINI LOCION ROSA MOSQUETA 100ML",
    "descripcion_corta": "MINI LOCION ROSA MOSQUETA 100ML",
    "descripcion_larga": "Loción que estimula la hidratación y la elasticidad natural de la piel y ayuda a eliminar cicatrices y estrías. La potente combinación de Rosa Mosqueta y ácido hialurónico retrasan el envejecimiento de la piel. Su versión mini es perfecta para tu bolso para mantener tu piel siempre hidratada.",
    "precio_DIS": "2.97",
    "precio_CON": "4.25",
    "existencia": "13",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Cicatrices, estrías, elasticidad, ácido hialurónico, embarazo, portátil, Instituto Español",
    "promocion": [],
    "imagen": [
        "0740006.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "07485",
    "descripcion_prod": "LOCION DE AVENA 500ML",
    "descripcion_corta": "LOCION DE AVENA 500ML",
    "descripcion_larga": "Excelente en pieles maduras, sensibles o resecas, ya que cuenta con propiedades calmantes, emolientes e hidratantes; que recuperan la elasticidad.",
    "precio_DIS": "6.66",
    "precio_CON": "9.50",
    "existencia": "890",
    "marca_id": "00",
    "marca_descripcion": "SIN MARCA",
    "tipo_incentivo_id": "",
    "keywords": "Loción, crema, hidratante, piel madura, Instituto Español",
    "promocion": [],
    "imagen": [
        "07485.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "5",
    "descripcion_sublinea": "TARRO",
    "producto_id": "074854",
    "descripcion_prod": "TARRO 50ML AVENA",
    "descripcion_corta": "MINI TARRO AVENA 50ML",
    "descripcion_larga": "Hidratante de avena con textura más cremosa para una hidratación intensa, ideal para manos y codos o pieles más resecas. Su tamaño es portátil y perfecto para tu bolso, para que la lleves contigo a todas partes.",
    "precio_DIS": "2.79",
    "precio_CON": "3.99",
    "existencia": "334",
    "marca_id": "24",
    "marca_descripcion": "VOGUE D PARIS",
    "tipo_incentivo_id": "",
    "keywords": "Crema, hidratante, portátil, piel seca, Instituto Español",
    "promocion": [],
    "imagen": [
        "074854.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "074864",
    "descripcion_prod": "LOCION ARNICA 500ML",
    "descripcion_corta": "LOCION ARNICA PIERNAS CANSADAS 500ML",
    "descripcion_larga": "Esta loción energizante con extracto de flores de Árnica relaja, tonifica y rejuvenece las piernas cansadas. Ayuda a mantener el equilibrio de hidratación ideal de la piel, dejando las piernas suaves y refrescadas.",
    "precio_DIS": "7.70",
    "precio_CON": "10.99",
    "existencia": "75",
    "marca_id": "24",
    "marca_descripcion": "VOGUE D PARIS",
    "tipo_incentivo_id": "",
    "keywords": "Várices, piernas cansadas, Instituto Español",
    "promocion": [],
    "imagen": [
        "074864.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "074865",
    "descripcion_prod": "MINI LOCION ARNICA 100ML",
    "descripcion_corta": "MINI LOCIÓN ARNICA PIERNAS CANSADAS 100ML",
    "descripcion_larga": "Esta loción energizante con extracto de flores de Árnica relaja, tonifica y rejuvenece las piernas cansadas. Ayuda a mantener el equilibrio de hidratación ideal de la piel, dejando las piernas suaves y refrescadas.",
    "precio_DIS": "2.79",
    "precio_CON": "3.99",
    "existencia": "44",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Várices, piernas cansadas, portátil, Instituto Español",
    "promocion": [],
    "imagen": [
        "074865.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "074866",
    "descripcion_prod": "MINI LOCION DE AVENA 100ML",
    "descripcion_corta": "MINI LOCION DE AVENA 100ML",
    "descripcion_larga": "Excelente en pieles maduras, sensibles o resecas, ya que cuenta con propiedades calmantes, emolientes e hidratantes; que recuperan la elasticidad. Su versión mini es perfecta para tu bolso para mantener tu piel siempre hidratada.",
    "precio_DIS": "2.97",
    "precio_CON": "4.25",
    "existencia": "180",
    "marca_id": "24",
    "marca_descripcion": "VOGUE D PARIS",
    "tipo_incentivo_id": "",
    "keywords": "Loción, crema, hidratante, piel madura, portátil, Instituto Español",
    "promocion": [],
    "imagen": [
        "074866.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "5",
    "descripcion_sublinea": "TARRO",
    "producto_id": "074869",
    "descripcion_prod": "TARRO AVENA COLAGENO 200GR",
    "descripcion_corta": "TARRO AVENA COLAGENO 200GR",
    "descripcion_larga": "El colágeno favorece la reducción y prevención de arrugas. Gracias a su capacidad para hacer que las células retengan agua, es un excelente agente hidratante para la epidermis, favoreciendo su limpieza y ayudándola a combatir los radicales libres y polución ambiental.",
    "precio_DIS": "9.28",
    "precio_CON": "13.25",
    "existencia": "1969",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Antiedad, piel seca, baba de caracol, rejuvenece, crema, hidratante, Instituto Español",
    "promocion": [],
    "imagen": [
        "074869.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "5",
    "descripcion_sublinea": "TARRO",
    "producto_id": "074870",
    "descripcion_prod": "TARRO ROSA MOSQUETA 400ML",
    "descripcion_corta": "TARRO ROSA MOSQUETA 400ML",
    "descripcion_larga": "La Crema Regeneradora Rosa Mosqueta ayuda con la hidratación a la vez que reafirma tu piel. La Rosa Mosqueta es pieza clave para la prevención de estrías, esta fórmula esta además enriquecida con ácido hialurónico para potenciar los beneficios de la Rosa Mosqueta.",
    "precio_DIS": "10.49",
    "precio_CON": "14.98",
    "existencia": "193",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Crema, hidratante, estrías, embarazo, Instituto Español",
    "promocion": [],
    "imagen": [
        "074870.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "11",
    "descripcion_sublinea": "BEBÉ",
    "producto_id": "074874",
    "descripcion_prod": "LOCION HIDRATANTE BEBE 300ML",
    "descripcion_corta": "LOCION HIDRATANTE BEBE 300ML",
    "descripcion_larga": "Loción ligera de fácil absorción. Su fórmula con activos naturales contribuye al excelente cuidado y suavidad de la piel, incluso en recién nacidos. Apta para pieles sensibles y atópicas. Válvula sin contacto, que no contamina el producto. Testado pediátrica, dermatológica y oftalmológicamente.",
    "precio_DIS": "11.37",
    "precio_CON": "16.25",
    "existencia": "356",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Bebé, niños, hidratante, recién nacido, Instituto Español",
    "promocion": [],
    "imagen": [
        "074874.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "074875",
    "descripcion_prod": "LOCION LACTOADVANCE 500ML",
    "descripcion_corta": "LOCION LACTOADVANCE 500ML",
    "descripcion_larga": "Loción que nutre la piel dejando una sensación de profundo bienestar. Absorción inmediata sin textura grasa que deja una suavidad extraordinaria. Apto para veganos y celíacos.",
    "precio_DIS": "6.66",
    "precio_CON": "9.50",
    "existencia": "3806",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Hidratante, crema, piel joven, leche, Instituto Español",
    "promocion": [],
    "imagen": [
        "074875.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "074876",
    "descripcion_prod": "MINI LOCION LACTOADVANCE 100ML",
    "descripcion_corta": "MINI LOCION LACTOADVANCE 100ML",
    "descripcion_larga": "Loción que nutre la piel dejando una sensación de profundo bienestar. Absorción inmediata sin textura grasa que deja una suavidad extraordinaria. Apto para veganos y celíacos. Su versión mini es perfecta para tu bolso para mantener tu piel siempre suave e hidratada.",
    "precio_DIS": "2.97",
    "precio_CON": "4.25",
    "existencia": "808",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Hidratante, crema, piel joven, leche, portátil, Instituto Español",
    "promocion": [],
    "imagen": [
        "074876.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "6",
    "descripcion_sublinea": "ACEITE",
    "producto_id": "074878",
    "descripcion_prod": "ACEITE DE ARGAN 250ML",
    "descripcion_corta": "ACEITE CORPORAL DE ARGAN 250ML",
    "descripcion_larga": "Aceite hidratante, ayuda a luchar contra la sequedad de la piel, estimula la oxigenación celular, alivia la piel dañada o irritada. Puede usarse en baños aromáticos, duchas hidratantes o en masajes para una sensación relajante y de bienestar. Se puede aplicar sobre la piel mojada, húmeda o seca.",
    "precio_DIS": "7.35",
    "precio_CON": "10.50",
    "existencia": "742",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Corporal, hidratante, Instituto Español",
    "promocion": [],
    "imagen": [
        "074878.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "074879",
    "descripcion_prod": "LOCIÓN DE COCO 500ML",
    "descripcion_corta": "LOCION DE COCO 500ML",
    "descripcion_larga": "Cuida y protege tu piel con la loción corporal Coco de Instituto Español. Suaviza e hidrata en profundidad mientras su fragancia aporta bienestar. Sentirás tu piel protegida, restaurada y extremadamente suave.",
    "precio_DIS": "6.66",
    "precio_CON": "9.50",
    "existencia": "1233",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Crema, hidratante, piel seca, Instituto Español",
    "promocion": [],
    "imagen": [
        "074879.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "6",
    "descripcion_sublinea": "ACEITE",
    "producto_id": "074880",
    "descripcion_prod": "ACEITE ROSA MOSQUETA 250ML",
    "descripcion_corta": "ACEITE CORPORAL ROSA MOSQUETA 250ML",
    "descripcion_larga": "La Rosa Mosqueta revigoriza las células productoras de colágeno, elastina y ácido hialurónico, responsables de la firmeza y elasticidad de la piel. Puede usarlo con la piel mojada después de la ducha, extender el aceite y secarlo con la toalla. Quedará el aroma tanto en su piel como en la toalla.",
    "precio_DIS": "7.70",
    "precio_CON": "10.99",
    "existencia": "394",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Corporal, hidratante, estrías, embarazo, Instituto Español",
    "promocion": [],
    "imagen": [
        "074880.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "4",
    "descripcion_sublinea": "PIEL ATÓPICA",
    "producto_id": "074882",
    "descripcion_prod": "CREMA DE MANOS ATÓPICAS",
    "descripcion_corta": "CREMA MANOS PIELES ATOPICAS 75ML",
    "descripcion_larga": "Cuida y protege la piel, previene la sequedad y rehidrata las manos secas y estropeadas del uso diario gracias a sus ingredientes activos con propiedades anti-pruriginosas, anti-inflamatorias y anti-radicales, aliviando la irritación y el picor al mismo tiempo que protege y suaviza la piel.",
    "precio_DIS": "5.59",
    "precio_CON": "7.99",
    "existencia": "14",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Piel atópica, piel sensible, rash, irritación, alergias, alcohol en gel, Instituto Español",
    "promocion": [],
    "imagen": [
        "074882.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "11",
    "descripcion_sublinea": "BEBÉ",
    "producto_id": "074883",
    "descripcion_prod": "MINI LOCION PARA BEBE 100ML",
    "descripcion_corta": "MINI LOCION PARA BEBE 100ML",
    "descripcion_larga": "Loción ligera de fácil absorción que contribuye al excelente cuidado y suavidad de la piel, incluso en recién nacidos. Apta para pieles sensibles y atópicas. Testado pediátrica, dermatológica y oftalmológicamente. Su versión mini es perfecta para la pañalera o para viajes.",
    "precio_DIS": "4.37",
    "precio_CON": "6.25",
    "existencia": "728",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "p",
    "keywords": "Bebé, niños, hidratante, recién nacido, portátil, Instituto Español",
    "promocion": [],
    "imagen": [
        "074883.png",
        "PC-074883.png",
        "PD-074883.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "6",
    "descripcion_sublinea": "ACEITE",
    "producto_id": "074889",
    "descripcion_prod": "ACEITE DE COCO 250ML",
    "descripcion_corta": "ACEITE CORPORAL DE COCO 250ML",
    "descripcion_larga": "El Aceite corporal Coco, incorpora las propiedades naturales y cosméticas del coco, que proporcionan un aporte extra de hidratación, logrando que la piel se mantenga suave, sana y tersa. Especialmente recomendado en pieles secas. Se puede aplicar sobre la piel mojada, húmeda o seca.",
    "precio_DIS": "5.94",
    "precio_CON": "8.50",
    "existencia": "4",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "p",
    "keywords": "Corporal, hidratante, piel seca, Instituto Español",
    "promocion": [],
    "imagen": [
        "074889.png",
        "PC-074889.png",
        "PD-074889.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "074890",
    "descripcion_prod": "LOCIÓN COLÁGENO 500ML",
    "descripcion_corta": "LOCIÓN COLÁGENO 500ML",
    "descripcion_larga": "El colágeno favorece la reducción y prevención de arrugas, le da firmeza y flexibilidad a la piel. Además, es un excelente agente hidratante para la epidermis, favoreciendo su limpieza y ayudándola a combatir los radicales libres y polución ambiental.",
    "precio_DIS": "11.19",
    "precio_CON": "15.99",
    "existencia": "1500",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "Hidratante, crema, antiedad, Instituto Español",
    "promocion": [],
    "imagen": [
        "074890.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "074891",
    "descripcion_prod": "LOCIÓN DBTS 500ML",
    "descripcion_corta": "LOCIÓN DBTS 500ML",
    "descripcion_larga": "La Loción DBTs alivia los síntomas y molestias de la sequedad, proporciona una acción emoliente y contribuye a la regeneración de la epidermis. La fórmula aporta actividad como protector vascular y venotónico, actividad antioxidante y excelentes propiedades antinflamatorias.",
    "precio_DIS": "10.67",
    "precio_CON": "15.24",
    "existencia": "1908",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "Diabetes, piel sensible, piel seca, Instituto Español",
    "promocion": [],
    "imagen": [
        "074891.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "6",
    "descripcion_sublinea": "ACEITE",
    "producto_id": "074892",
    "descripcion_prod": "ACEITE DE ARNICA 250ML",
    "descripcion_corta": "ACEITE CORPORAL DE ARNICA 250ML",
    "descripcion_larga": "Libera las tensiones musculares y reanima tu cuerpo con el aceite de Árnica. Su fórmula está diseñada para calmar y aliviar los dolores de tu piel. Brinda profunda hidratación, calma y suaviza el dolor. Se puede aplicar sobre la piel mojada, húmeda o seca. Apto para veganos y para celiacos.",
    "precio_DIS": "7.70",
    "precio_CON": "10.99",
    "existencia": "92",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "Corporal, hidratante, várices, piernas cansadas, Instituto Español",
    "promocion": [],
    "imagen": [
        "074892.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "9",
    "descripcion_sublinea": "ÓLEO",
    "producto_id": "0754001",
    "descripcion_prod": "BÁLSAMO RESPIRAR BIEN 10ML",
    "descripcion_corta": "BÁLSAMO RESPIRAR BIEN 10ML",
    "descripcion_larga": "Bálsamo para respirar mejor con aceites esenciales de eucalipto, tomillo y menta. Descongestiona, despeja la respiración, relaja, purifica y brinda bienestar en época invernal. Recomendado para toda la familia.",
    "precio_DIS": "4.19",
    "precio_CON": "5.99",
    "existencia": "794",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Óleo, descongestiona, natural, Gigot, aceite esencial, difusor",
    "promocion": [],
    "imagen": [
        "0754001.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "10",
    "descripcion_sublinea": "BIENESTAR",
    "producto_id": "0754002",
    "descripcion_prod": "ROLL-ON EFECTO FRIO 90ML",
    "descripcion_corta": "ROLL-ON CORPORAL EFECTO FRIO 90ML",
    "descripcion_larga": "Su fórmula con árnica, enebro, sauce, consuelda y menta reconforta, alivia y relaja. Su efecto frío ayuda a recuperar los músculos después de la actividad física. Elimina el cansancio del día y de largas horas de manejo.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "582",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Ejercicio, músculos, relaja, Gigot, natural",
    "promocion": [],
    "imagen": [
        "0754002.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "7",
    "descripcion_sublinea": "PIEL",
    "producto_id": "0754003",
    "descripcion_prod": "CICATRIFIN OIL 25ML",
    "descripcion_corta": "CICATRIFIN OIL 25ML",
    "descripcion_larga": "Óleo concentrado reparador con aceite de Purcelina. Regenera la piel severamente agrietada, repara, humecta, nutre y ayuda a suavizar la apariencia de cicatrices y estrías. Hidratante y emoliente, aporta suavidad a toda la piel del cuerpo. Mejora la elasticidad de la piel dándole mayor resistencia.",
    "precio_DIS": "5.25",
    "precio_CON": "7.50",
    "existencia": "1094",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "p",
    "keywords": "Cicatrices, estrías, Gigot",
    "promocion": [],
    "imagen": [
        "0754003.png",
        "PC-0754003.png",
        "PD-0754003.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "7",
    "descripcion_sublinea": "PIEL",
    "producto_id": "0754004",
    "descripcion_prod": "CREMA BLANQUEADORA 30G",
    "descripcion_corta": "CREMA BLANQUEADORA ENTREPIERNAS 30G",
    "descripcion_larga": "Crema dermoaclarante. Para uso específico en áreas de piel oscurecida por roce o irritación. Modo de uso: secar la piel y aplicar en la zona a tratar dos veces al día en forma continua y durante no menos de dos meses.",
    "precio_DIS": "4.89",
    "precio_CON": "6.99",
    "existencia": "920",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Blanqueadora, manchas, roce, irritación, Gigot",
    "promocion": [],
    "imagen": [
        "0754004.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "10",
    "descripcion_sublinea": "BIENESTAR",
    "producto_id": "0754005",
    "descripcion_prod": "ROLL-ON PHYTO ARAÑAS 90ML",
    "descripcion_corta": "ROLL-ON PHYTO ARAÑAS 90ML",
    "descripcion_larga": "Gel concentrado para piernas con arañitas. Fórmula concentrada que combina extractos naturales que activan la circulación y atenúa la visibilidad de las arañitas con el uso continuo. Su envase con aplicador Roll-On facilita el masaje y la aplicación en las zonas de la piel con arañitas.",
    "precio_DIS": "5.94",
    "precio_CON": "8.50",
    "existencia": "757",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Arañitas, várices, relaja, Gigot, natural",
    "promocion": [],
    "imagen": [
        "0754005.png",
        "PC-0754005.png",
        "PD-0754005.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "9",
    "descripcion_sublinea": "ÓLEO",
    "producto_id": "0754006",
    "descripcion_prod": "ÓLEO ANTIESTRÉS 10ML",
    "descripcion_corta": "ÓLEO ANTIESTRÉS 10ML",
    "descripcion_larga": "Óleo corporal con aceites esenciales de lavanda, tilo, petit grain, manzanilla y anís. Despeja la mente, ayuda a conciliar el sueño y relaja las tensiones. Ayuda a recuperar la calma ante situaciones de estrés. Transmite una sensación única de bienestar y relajación en momentos de alto estrés.",
    "precio_DIS": "4.19",
    "precio_CON": "5.99",
    "existencia": "2082",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Aceite esencial, relaja, natural, Gigot, difusor",
    "promocion": [],
    "imagen": [
        "0754006.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "9",
    "descripcion_sublinea": "ÓLEO",
    "producto_id": "0754007",
    "descripcion_prod": "ÓLEO ENERGIZANTE 10ML",
    "descripcion_corta": "ÓLEO ENERGIZANTE 10ML",
    "descripcion_larga": "Óleo con aceites esenciales de naranja, lima, pomelo, azahar y jengibre. Activa el buen ánimo, ayuda a enfrentar con vitalidad y optimismo las actividades diarias y evita que nos invada la angustia y la tristeza. Disipa el cansancio y despierta sensaciones de felicidad y alegría.",
    "precio_DIS": "4.19",
    "precio_CON": "5.99",
    "existencia": "926",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Aceite esencial, activa, alegría, natural, Gigot, difusor",
    "promocion": [],
    "imagen": [
        "0754007.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "9",
    "descripcion_sublinea": "ÓLEO",
    "producto_id": "0754008",
    "descripcion_prod": "ÓLEO RUDA 10ML",
    "descripcion_corta": "ÓLEO RUDA 10ML",
    "descripcion_larga": "Aceite esencial de ruda. Protege y armoniza la energía interior. La ruda es una hierba tradicional que se puede usar en ambientes contra hongos aéreos que causan alergias y enfermedades respiratorias. Además, es un poderoso repelente que evita varios parásitos domésticos.",
    "precio_DIS": "4.19",
    "precio_CON": "5.99",
    "existencia": "949",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Aceite esencial, enfermedad, repelente, natural, difusor, Gigot",
    "promocion": [],
    "imagen": [
        "0754008.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "10",
    "descripcion_sublinea": "BIENESTAR",
    "producto_id": "0754009",
    "descripcion_prod": "PHYTOSIEN 15G",
    "descripcion_corta": "CREMA BALSÁMICA PHYTOSIEN 15G",
    "descripcion_larga": "Crema balsámica para el alivio de dolores de cabeza o migrañas. Relaja y afloja tensiones, despeja la mente, brinda sensación refrescante gracias a su efecto frío. Contiene árnica, enebro, sauce, consuelda y menta; que cuentan con propiedades analgésicas, antiinflamatorias y antirreumáticas.",
    "precio_DIS": "4.54",
    "precio_CON": "6.50",
    "existencia": "2723",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "",
    "keywords": "Dolor de cabeza, migraña, cansancio, natural, Gigot",
    "promocion": [],
    "imagen": [
        "0754009.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "10",
    "descripcion_sublinea": "BIENESTAR",
    "producto_id": "0754010",
    "descripcion_prod": "POMADA PARA GOLPES Y MORETES",
    "descripcion_corta": "POMADA BALSAMICA PARA GOLPES Y MORETES",
    "descripcion_larga": "Alivio inmediato de golpes y moretones. Contiene árnica, enebro, sauce, menta y consuelda, que brindan acción antiinflamatoria, estimulan la circulación sanguínea y regeneran la piel afectada por un golpe. Evitan la formación de moretones y chichotes, desinflama y mejora rápidamente la piel.",
    "precio_DIS": "6.29",
    "precio_CON": "8.99",
    "existencia": "1117",
    "marca_id": "174",
    "marca_descripcion": "GIGOT",
    "tipo_incentivo_id": "n",
    "keywords": "Moretón, alivio, natural, antiinflamatorio, Gigot",
    "promocion": [],
    "imagen": [
        "0754010.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "4",
    "descripcion_sublinea": "PIEL ATÓPICA",
    "producto_id": "07680",
    "descripcion_prod": "LOCION PIELES ATOPICAS 300ML",
    "descripcion_corta": "LOCION CORPORAL PIELES ATOPICAS 300ML",
    "descripcion_larga": "Disminuye la sequedad cutánea en pieles atópicas e hipersensibles gracias a sus excelentes propiedades anti descamativas, hidratantes y emolientes. Intensa hidratación, acción anti-sequedad y regeneración de las defensas naturales, aliviando la irritación y el picor.",
    "precio_DIS": "8.92",
    "precio_CON": "12.75",
    "existencia": "286",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "p",
    "keywords": "Loción, piel atópica, piel sensible, irritación, rash, Instituto Español",
    "promocion": {
        "tipo_promocion": "DESC",
        "descuento": "20%",
        "precio_regular": "15.99"
    },
    "imagen": [
        "07680.png",
        "PC-07680.png",
        "PD-07680.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "3",
    "descripcion_sublinea": "LOCIÓN",
    "producto_id": "07682",
    "descripcion_prod": "LOCION ROSA MOSQUETA 500ML",
    "descripcion_corta": "LOCION ROSA MOSQUETA 500ML",
    "descripcion_larga": "Loción que estimula la hidratación y la elasticidad natural de la piel y ayuda a eliminar cicatrices y estrías. Revigoriza las células productoras de colágeno, elastina y ácido hialurónico para mayor firmeza y elasticidad de la piel. La potente combinación retrasa el envejecimiento de la piel.",
    "precio_DIS": "7.18",
    "precio_CON": "10.25",
    "existencia": "968",
    "marca_id": "117",
    "marca_descripcion": "INSTITUTO ESPAÑOL",
    "tipo_incentivo_id": "",
    "keywords": "Cicatrices, estrías, elasticidad, ácido hialurónico, embarazo, Instituto Español",
    "promocion": [],
    "imagen": [
        "07682.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "8",
    "descripcion_sublinea": "PROTECTOR SOLAR",
    "producto_id": "0778001",
    "descripcion_prod": "PROTECTOR SOLAR SPF30 120ML",
    "descripcion_corta": "PROTECTOR SOLAR SPF30 120ML",
    "descripcion_larga": "Protector solar para rostro y cuerpo con factor de protección SPF 30+ protege segura y eficazmente la piel de los rayos UVB y UVA. Con vitamina E, extracto de aloe vera, es el perfecto balance para proteger la piel mientras se hidrata, dejando un efecto suave y terso, y sin dejar sensación grasa.",
    "precio_DIS": "8.75",
    "precio_CON": "12.50",
    "existencia": "2536",
    "marca_id": "204",
    "marca_descripcion": "HEALTHY FAMILY",
    "tipo_incentivo_id": "n",
    "keywords": "No graso, protector solar",
    "promocion": [],
    "imagen": [
        "0778001.png"
    ]
},
{
    "linea_id": "07",
    "categoria": "PRODUCTOS PARA LA PIEL",
    "sublinea_id": "01",
    "descripcion_sublinea": "CORPORAL",
    "producto_id": "0778002",
    "descripcion_prod": "SACHET PROTECTOR SOLAR SPF30 10ML",
    "descripcion_corta": "SACHET PROTECTOR SOLAR SPF30 10ML",
    "descripcion_larga": "SACHET PROTECTOR SOLAR SPF30 10ML",
    "precio_DIS": "0.35",
    "precio_CON": "0.35",
    "existencia": "1080",
    "marca_id": "204",
    "marca_descripcion": "HEALTHY FAMILY",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "107",
    "categoria": "OTROS SERVICIOS",
    "sublinea_id": "1",
    "descripcion_sublinea": "ENTREGA DOMICILIAR",
    "producto_id": "10001",
    "descripcion_prod": "CARGO POR ENVIO SAN SALVADOR",
    "descripcion_corta": "CARGO POR ENVIO SAN SALVADOR",
    "descripcion_larga": "CARGO A DISTRIBUIDOR QUE SOLICITA ENTREGA PACK O PEDIDOS PREVENTA POR GERENTES ZONA SAN SALVADOR",
    "precio_DIS": "2.75",
    "precio_CON": "2.75",
    "existencia": "208",
    "marca_id": "03",
    "marca_descripcion": "V",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0155020",
    "descripcion_prod": "MUESTRA HIDRATACION PROFUNDA MASCARILLA 30ML",
    "descripcion_corta": "MUESTRA HIDRACION PROFUNDA MASCARILLA 30ML",
    "descripcion_larga": "MUESTRA HIDRATACION PROFUNDA MASCARILLA 30ML  CONTENIDO EN FRASCO DE 2 ONZ",
    "precio_DIS": "1.99",
    "precio_CON": "1.99",
    "existencia": "76",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0155021",
    "descripcion_prod": "MUESTRA CAUTERIZACION MASCARILLA 30ML",
    "descripcion_corta": "MUESTRA CAUTERIZACION MASCARILLA 30ML",
    "descripcion_larga": "MUESTRA CAUTERIZACION MASCARILLA 30ML\r\nCONTENIDO EN TARRO DE 2ONZ",
    "precio_DIS": "1.99",
    "precio_CON": "1.99",
    "existencia": "21",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0155022",
    "descripcion_prod": "MUESTRA REPOSICION DE MASAS MASCARILLA 30 ML",
    "descripcion_corta": "MUESTRA REPOSICION DE MASAS MASCARILLA 30ML",
    "descripcion_larga": "MUESTRA REPOSICIÓN DE MASAS MASCARILLA 30ML\r\nCONTENIDO EN TARRO DE 2ONZ",
    "precio_DIS": "1.99",
    "precio_CON": "1.99",
    "existencia": "9",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0155023",
    "descripcion_prod": "MUESTRA COCO BOM MASCARILLA 30ML",
    "descripcion_corta": "MUESTRA COCO BOM MASCARILLA 30 ML",
    "descripcion_larga": "MUESTRA COCO BOM MASCARILLA 30ML\r\nCONTENIDO EN FRASCO DE 2ONZ",
    "precio_DIS": "1.50",
    "precio_CON": "1.50",
    "existencia": "37",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0155024",
    "descripcion_prod": "MUESTRA SOSSEGADA MASCARILLA 30ML",
    "descripcion_corta": "MUESTRA SOSSEGADA MASCARILLA 30ML",
    "descripcion_larga": "MUESTRA SOSSEGADA MASCARILLA 2 ML\r\nCONTENIDO EN TARRO DE 2 ONZ",
    "precio_DIS": "1.50",
    "precio_CON": "1.50",
    "existencia": "53",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0155025",
    "descripcion_prod": "MUESTRA VITAMINADA MASCARILLA 30ML",
    "descripcion_corta": "MUESTRA VITAMINADA MASCARILLA 30ML",
    "descripcion_larga": "MUESTRA VITAMINADA MASCARILLA 30ML\r\nCONTENIDO EN TARRO DE 2ONZ",
    "precio_DIS": "1.50",
    "precio_CON": "1.50",
    "existencia": "80",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0155026",
    "descripcion_prod": "MUESTRAS MIX FRUIT MASCARILLA 30ML",
    "descripcion_corta": "MUESTRAS MIX FRUIT MASCARILLA 30ML",
    "descripcion_larga": "MUESTRAS MIX FRUIT MASCARILLA 30ML\r\nCONTENIDO EN TARRO DE 2ONZ",
    "precio_DIS": "1.50",
    "precio_CON": "1.50",
    "existencia": "65",
    "marca_id": "178",
    "marca_descripcion": "KANECHOM",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "01771",
    "descripcion_prod": "MINI SHAM. SABILA Y TRIGO",
    "descripcion_corta": "MUESTRA SHAMPOO SABILA Y TRIGO LUZKO",
    "descripcion_larga": "MUESTRA SHAMPOO SABILA Y TRIGO LUZKO",
    "precio_DIS": "0.75",
    "precio_CON": "0.75",
    "existencia": "73",
    "marca_id": "145",
    "marca_descripcion": "LUZKO",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "1",
    "descripcion_sublinea": "EMPAQUE",
    "producto_id": "02990122",
    "descripcion_prod": "MUESTRA ECSTASY  2ML",
    "descripcion_corta": "MUESTRA ECSTASY  2ML",
    "descripcion_larga": "MUESTRA ECSTASY  2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "61",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "02990125",
    "descripcion_prod": "MUESTRA LAIR DE MUSE 2ML",
    "descripcion_corta": "MUESTRA L AIR DE MUSE 2ML",
    "descripcion_larga": "MUESTRA L AIR DE MUSE 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "121",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "02990126",
    "descripcion_prod": "MUESTRA ELEGANZA 2ML",
    "descripcion_corta": "MUESTRA ELEGANZA 2ML",
    "descripcion_larga": "MUESTRA ELEGANZA 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "49",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": {
        "tipo_promocion": "CANT",
        "cantidad": "3x",
        "precio_promocion": "25.00"
    },
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "02990127",
    "descripcion_prod": "MUESTRA CASUAL CHIC 2ML",
    "descripcion_corta": "MUESTRA CASUAL CHIC 2ML",
    "descripcion_larga": "MUESTRA CASUAL CHIC 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "103",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "1",
    "descripcion_sublinea": "EMPAQUE",
    "producto_id": "02990128",
    "descripcion_prod": "MUESTRA BRILLIANT BLEU 2ML",
    "descripcion_corta": "MUESTRA BRILLIANT BLEU 2ML",
    "descripcion_larga": "MUESTRA BRILLIANT BLEU 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "77",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "1",
    "descripcion_sublinea": "EMPAQUE",
    "producto_id": "02990129",
    "descripcion_prod": "MUESTRA ANCIENT ECHO 2ML",
    "descripcion_corta": "MUESTRA ANCIENT ECHO 2ML",
    "descripcion_larga": "MUESTRA ANCIENT ECHO 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "4",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "1",
    "descripcion_sublinea": "EMPAQUE",
    "producto_id": "02990130",
    "descripcion_prod": "MUESTRA ATREVIDO 2ML",
    "descripcion_corta": "MUESTRA ATREVIDO 2ML",
    "descripcion_larga": "MUESTRA ATREVIDO 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "207",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "1",
    "descripcion_sublinea": "EMPAQUE",
    "producto_id": "02990131",
    "descripcion_prod": "MUESTRA EPIC MEN 2ML",
    "descripcion_corta": "MUESTRA EPIC MEN 2ML",
    "descripcion_larga": "MUESTRA EPIC MEN 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "49",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "1",
    "descripcion_sublinea": "EMPAQUE",
    "producto_id": "0299147",
    "descripcion_prod": "MUESTRA SEXY WOMEN 2ML",
    "descripcion_corta": "MUESTRA SEXY WOMEN 2ML",
    "descripcion_larga": "MUESTRA SEXY WOMEN 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "5",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "1",
    "descripcion_sublinea": "EMPAQUE",
    "producto_id": "0299148",
    "descripcion_prod": "MUESTRA VICTOR HOMME 2ML",
    "descripcion_corta": "MUESTRA VICTOR HOMME 2ML",
    "descripcion_larga": "MUESTRA VICTOR HOMME 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "49",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0299149",
    "descripcion_prod": "MUESTRA ECSTASY INTENSE",
    "descripcion_corta": "MUESTRA ECSTASY INTENSE",
    "descripcion_larga": "MUESTRA ECSTASY INTENSE",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "82",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0299150",
    "descripcion_prod": "MUESTRA POUR HOMME 2ML",
    "descripcion_corta": "MUESTRA POUR HOMME 2ML",
    "descripcion_larga": "MUESTRA POUR HOMME 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "105",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0299151",
    "descripcion_prod": "MUESTRA PRIVATE BLACK 2ML",
    "descripcion_corta": "MUESTRA PRIVATE BLACK 2ML",
    "descripcion_larga": "MUESTRA PRIVATE BLACK 2ML",
    "precio_DIS": "0.45",
    "precio_CON": "0.45",
    "existencia": "175",
    "marca_id": "160",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "2",
    "descripcion_sublinea": "MUESTRAS ",
    "producto_id": "0899506",
    "descripcion_prod": "PACK 8 MUESTRAS FRAGANCIA DUBAI",
    "descripcion_corta": "PACK 8 MUESTRAS FRAGANCIA DUBAI",
    "descripcion_larga": "PACK 8 MUESTRAS FRAGANCIA DUBAI",
    "precio_DIS": "2.25",
    "precio_CON": "2.25",
    "existencia": "20",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "1",
    "descripcion_sublinea": "EMPAQUE",
    "producto_id": "30301581-1",
    "descripcion_prod": "PAQUETE 25 BOLSAS MEDIA",
    "descripcion_corta": "PAQUETE 25 BOLSAS 15X17.75 MEDIANA FRASES ÉXITO",
    "descripcion_larga": "PAQUETE 25 BOLSAS 15X17.75 MEDIANA FRASES ÉXITO",
    "precio_DIS": "1.50",
    "precio_CON": "1.50",
    "existencia": "35",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "1",
    "descripcion_sublinea": "EMPAQUE",
    "producto_id": "30301637-1",
    "descripcion_prod": "PAQUETE 25 BOLSA GRANDE",
    "descripcion_corta": "PAQUETE 25 BOLSA COD.30301637 17\"X23 1/2\"",
    "descripcion_larga": "PAQUETE 25 BOLSA COD.30301637 17\"X23 1/2\"",
    "precio_DIS": "2.50",
    "precio_CON": "2.50",
    "existencia": "24",
    "marca_id": "71",
    "marca_descripcion": "IPSA",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "4",
    "descripcion_sublinea": "CATALOGOS",
    "producto_id": "30301781",
    "descripcion_prod": "VOGUEFOLIO",
    "descripcion_corta": "VOGUEFOLIO",
    "descripcion_larga": "VOGUEFOLIO",
    "precio_DIS": "1.25",
    "precio_CON": "1.25",
    "existencia": "14",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "1",
    "descripcion_sublinea": "EMPAQUE",
    "producto_id": "30301876-1",
    "descripcion_prod": "PAQUETE 25 BOLSA PEQUEÑA LOGO VOGUE",
    "descripcion_corta": "PAQUETE 25 BOLSA PEQUEÑA LOGO VOGUE",
    "descripcion_larga": "PAQUETE 25 BOLSA PEQUEÑA LOGO VOGUE",
    "precio_DIS": "0.99",
    "precio_CON": "0.99",
    "existencia": "22",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "118",
    "categoria": "INSTRUMENTOS DE VENTA",
    "sublinea_id": "4",
    "descripcion_sublinea": "CATALOGOS",
    "producto_id": "30301968",
    "descripcion_prod": "BOLSA DE REGALO 30X20CM",
    "descripcion_corta": "BOLSA DE REGALO NEGRA REALZA TU BELLEZA30X20CM",
    "descripcion_larga": "BOLSA NEGRA DE REGALO 30X20CM\r\nBOLSA DE REGALO NEGRA REALZA",
    "precio_DIS": "1.50",
    "precio_CON": "1.50",
    "existencia": "885",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "120",
    "categoria": "LIMPIEZA Y DESINFECCION",
    "sublinea_id": "2",
    "descripcion_sublinea": "PISOS Y SUPERFICIES",
    "producto_id": "0400001",
    "descripcion_prod": "DESINFECTANT LIMA LIMON1G",
    "descripcion_corta": "LIMPIEADOR DESINFECTANTE LIMA LIMON 1 GALON",
    "descripcion_larga": "LIMPIADOR DESINFECTANTE LIMA LIMON 1 G\r\nDESINFECTANT LIMA LIMON1G",
    "precio_DIS": "5.99",
    "precio_CON": "8.99",
    "existencia": "45",
    "marca_id": "166",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "120",
    "categoria": "LIMPIEZA Y DESINFECCION",
    "sublinea_id": "3",
    "descripcion_sublinea": "ALIMIENTOS",
    "producto_id": "0400003",
    "descripcion_prod": "DESINSFECTANTE VERD/FRUTA",
    "descripcion_corta": "DESINFECTANTE VERDURAS Y FRUTAS 1L",
    "descripcion_larga": "DESINFECTANTE VERDURAS Y FRUTAS 1000ML\r\nDESINSFECTANTE VERD/FRUTA",
    "precio_DIS": "4.50",
    "precio_CON": "11.50",
    "existencia": "17",
    "marca_id": "166",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "120",
    "categoria": "LIMPIEZA Y DESINFECCION",
    "sublinea_id": "4",
    "descripcion_sublinea": "PERSONAL",
    "producto_id": "0400006",
    "descripcion_prod": "GEL SIN ALCOHOL 1000ML",
    "descripcion_corta": "GEL SIN ALCOHOL 1000ML",
    "descripcion_larga": "GEL SIN ALCOHOL 1000ML",
    "precio_DIS": "4.50",
    "precio_CON": "10.50",
    "existencia": "32",
    "marca_id": "166",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "120",
    "categoria": "LIMPIEZA Y DESINFECCION",
    "sublinea_id": "4",
    "descripcion_sublinea": "PERSONAL",
    "producto_id": "0400007",
    "descripcion_prod": "ALCOHOL GEL 1GL",
    "descripcion_corta": "ALCOHOL EN GEL 1GL",
    "descripcion_larga": "ALCOHOL GEL 1GL",
    "precio_DIS": "10.99",
    "precio_CON": "15.50",
    "existencia": "3",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "120",
    "categoria": "LIMPIEZA Y DESINFECCION",
    "sublinea_id": "4",
    "descripcion_sublinea": "PERSONAL",
    "producto_id": "0400008",
    "descripcion_prod": "ALCOHOL EN GEL E.V. 1GALON",
    "descripcion_corta": "ALCOHOL EN GEL E.V. 1GALON",
    "descripcion_larga": "ALCOHOL EN GEL E.V. 1GALON",
    "precio_DIS": "10.99",
    "precio_CON": "15.50",
    "existencia": "120",
    "marca_id": "166",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "120",
    "categoria": "LIMPIEZA Y DESINFECCION",
    "sublinea_id": "4",
    "descripcion_sublinea": "PERSONAL",
    "producto_id": "0400009",
    "descripcion_prod": "ALCOHOL EN GEL 500ML",
    "descripcion_corta": "ALCOHOL EN GEL 500ML E.V.",
    "descripcion_larga": "ALCOHOL EN GEL 500ML EMVASADO VOGUE",
    "precio_DIS": "3.99",
    "precio_CON": "6.75",
    "existencia": "46",
    "marca_id": "166",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "120",
    "categoria": "LIMPIEZA Y DESINFECCION",
    "sublinea_id": "2",
    "descripcion_sublinea": "PISOS Y SUPERFICIES",
    "producto_id": "0400011",
    "descripcion_prod": "BRISA FRESCA DESINFECT 1GL",
    "descripcion_corta": "BRISA FRESCA DESINFECTANTE 1GL",
    "descripcion_larga": "BRISA FRESCA DESINFECTANTE 1GL",
    "precio_DIS": "5.25",
    "precio_CON": "7.94",
    "existencia": "9",
    "marca_id": "166",
    "marca_descripcion": "VOGUE",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "99",
    "categoria": "LINEA PROMOCIONES SETS",
    "sublinea_id": "01",
    "descripcion_sublinea": "OFERTAS CREADAS EN EL SALVADOR",
    "producto_id": "08991313",
    "descripcion_prod": "@ PREVENTA PACK AGOSTO FEST C08-2024 @",
    "descripcion_corta": "@ PREVENTA PACK AGOSTO FEST C08-2024 @",
    "descripcion_larga": "@ PREVENTA PACK AGOSTO FEST C08-2024 @",
    "precio_DIS": "54.26",
    "precio_CON": "74.95",
    "existencia": "76",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "99",
    "categoria": "LINEA PROMOCIONES SETS",
    "sublinea_id": "01",
    "descripcion_sublinea": "OFERTAS CREADAS EN EL SALVADOR",
    "producto_id": "08991316",
    "descripcion_prod": "@ PREVENTA PACK MANTENIMIENTO C08-2024 @",
    "descripcion_corta": "@ PREVENTA PACK MANTENIMIENTO C08-2024 @",
    "descripcion_larga": "@ PREVENTA PACK MANTENIMIENTO C08-2024 @",
    "precio_DIS": "71.96",
    "precio_CON": "98.19",
    "existencia": "98",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "99",
    "categoria": "LINEA PROMOCIONES SETS",
    "sublinea_id": "01",
    "descripcion_sublinea": "OFERTAS CREADAS EN EL SALVADOR",
    "producto_id": "08991320",
    "descripcion_prod": "@ PREVENTA PACK AGOSTO FEST 2  C08-2024 @",
    "descripcion_corta": "@ PREVENTA PACK AGOSTO FEST 2  C08-2024 @",
    "descripcion_larga": "@ PREVENTA PACK AGOSTO FEST 2  C08-2024 @",
    "precio_DIS": "54.26",
    "precio_CON": "74.95",
    "existencia": "101",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "99",
    "categoria": "LINEA PROMOCIONES SETS",
    "sublinea_id": "01",
    "descripcion_sublinea": "OFERTAS CREADAS EN EL SALVADOR",
    "producto_id": "08991330",
    "descripcion_prod": "@ PREVENTA PACK ZONA 29 C08-2024 @",
    "descripcion_corta": "@ PREVENTA PACK ZONA 29 C08-2024 @",
    "descripcion_larga": "@ PREVENTA PACK ZONA 29 C08-2024 @",
    "precio_DIS": "65.42",
    "precio_CON": "93.45",
    "existencia": "75",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "99",
    "categoria": "LINEA PROMOCIONES SETS",
    "sublinea_id": "01",
    "descripcion_sublinea": "OFERTAS CREADAS EN EL SALVADOR",
    "producto_id": "08991333",
    "descripcion_prod": "@ PREVENTA PACK ZONA 16 C08-2024 @",
    "descripcion_corta": "@ PREVENTA PACK ZONA 16 C08-2024 @",
    "descripcion_larga": "@ PREVENTA PACK ZONA 16 C08-2024 @",
    "precio_DIS": "54.59",
    "precio_CON": "77.98",
    "existencia": "15",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "99",
    "categoria": "LINEA PROMOCIONES SETS",
    "sublinea_id": "01",
    "descripcion_sublinea": "OFERTAS CREADAS EN EL SALVADOR",
    "producto_id": "08991336",
    "descripcion_prod": "@ PREVENTA PACK ZONA 23 C08-2024 @",
    "descripcion_corta": "@ PREVENTA PACK ZONA 23 C08-2024 @",
    "descripcion_larga": "@ PREVENTA PACK ZONA 23 C08-2024 @",
    "precio_DIS": "47.61",
    "precio_CON": "68.00",
    "existencia": "25",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "",
    "keywords": "",
    "promocion": [],
    "imagen": [
        "default.png"
    ]
},
{
    "linea_id": "99",
    "categoria": "LINEA PROMOCIONES SETS",
    "sublinea_id": "01",
    "descripcion_sublinea": "OFERTAS CREADAS EN EL SALVADOR",
    "producto_id": "0899592",
    "descripcion_prod": "CHAMPU PIELES ATOPICAS 300ML",
    "descripcion_corta": "CHAMPU PIELES ATOPICAS 300ML",
    "descripcion_larga": "Protege el cuero cabelludo y ayuda a evitar la descamación del mismo. Proporciona extra protección y una acción emoliente, nutritiva y calmante. No contienen parabenos, ni colorantes; formulado sin siliconas ni triclosán, por lo que no irrita y calma el picor, minimizando el riesgo de alergias.",
    "precio_DIS": "11.55",
    "precio_CON": "16.50",
    "existencia": "21",
    "marca_id": "55",
    "marca_descripcion": "VOGUEPARIS",
    "tipo_incentivo_id": "p",
    "keywords": "",
    "promocion": {
        "tipo_promocion": "DESC",
        "descuento": "33%",
        "precio_regular": "24.49"
    },
    "imagen": [
        "PC-0899592.png"
    ]
}
]
