import React from "react";

import styles from "../../../css/InformacionPago.module.css";
import producto from "../../../assets/img/producto3.png";

import { Link } from "react-router-dom";

export const Chekout = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };
  return (
    <>
      <div className={styles.contenedorTitulo}>
        <div className={styles.infoPago}>
          <h3 className={styles.info}>Información de pago</h3>
        </div>

        <div className={styles.subtitle}>Resumen de la compra</div>
      </div>
      <div className={styles.cajaContenedor}>
        <div className={styles.contenedorIsquierdo}>
          <div className={styles.contenedor}>
            <div className={styles.cajaTarjeta}>
              <div className={styles.colummnas}>
                <input className={styles.elegir} type="checkbox" />
                <p className={styles.debito}>Débito que termina en 5869</p>
              </div>

              <p className={styles.titular}>
                Nombre de la tarjeta: Nadine Torres
              </p>
            </div>
            <p className={styles.otraTarjeta}>Utilizar otra tarjeta</p>
            <p className={styles.nombreTarjeta}>Nombre de la tarjeta:</p>
            <input className={styles.NumeroTarjeta} type="text" />
            <p className={styles.nombreTarjeta}>Número de la tarjeta:</p>
            <input className={styles.NumeroTarjeta} type="text" />
            <p className={styles.nombreTarjeta}>Fecha de expiración:</p>
            <div className={styles.contenedorFecha}>
              <div className={styles.cajita}>
                <input
                  className={styles.inputData}
                  placeholder="DD"
                  type="text"
                />
              </div>
              <div className={styles.cajita}>
                <input
                  className={styles.inputData}
                  placeholder="MM"
                  type="text"
                />
              </div>
              <div className={styles.cajita}>
                <input
                  className={styles.inputData}
                  placeholder="AA"
                  type="text"
                />
              </div>
            </div>
            <div className={styles.guadar}>
              <input
                className={styles.guardarCheck}
                type="checkbox"
                name=""
                id=""
              />
              <p className={styles.parrafo}>
                Guardar la información de la tarjeta
              </p>
            </div>
          </div>
          <div className={styles.entregaContenedor}>
            <h3 className={styles.TituloEntrega}>Entrega de pedido</h3>
          </div>
          <div className={styles.contenedorGris}>
            <div className={styles.infos}>
              <input className={styles.elegir} type="checkbox" name="" id="" />
              <p className={styles.recoger}>Recoger en tienda</p>
            </div>
            <div className={styles.infos}>
              <input className={styles.elegir} type="checkbox" name="" id="" />
              <p className={styles.recoger}>Por gerente</p>
            </div>
            <div className={styles.infos}>
              <input className={styles.elegir} type="checkbox" name="" id="" />
              <p className={styles.recoger}>Aeroflash</p>
            </div>
            <div className={styles.infos}>
              <input className={styles.elegir} type="checkbox" name="" id="" />
              <p className={styles.recoger}>Motouber</p>
            </div>
          </div>
          {/* Pedidos a Domicilio  */}
          <div className={styles.entregaContenedor}>
            <h3 className={styles.TituloEntrega}>Pedido a domicilio</h3>
          </div>
          <div className={styles.contenedorDireccion}>
            <div className={styles.contenedorCajaDirreciion}>
              <input className={styles.chet} type="checkbox" />
              <p className={styles.nombre}>Nadine Torres</p>
              <p className={styles.dirreccion}>
                Edificio Vogue, Alameda Franklin Delano Roosevelt 2117, San
                Salvador
              </p>
            </div>
            <h3 className={styles.otraDirreccion}>Utilizar otra dirección</h3>
            <div className={styles.formulario}>
              <div className={styles.input}>
                <p className={styles.nombreform}>Nombre</p>
                <input className={styles.form} type="text" />
              </div>
              <div className={styles.input}>
                <p className={styles.nombreform}>Apellido</p>
                <input className={styles.form} type="text" />
              </div>
            </div>
            <div className={styles.direccionForm}>
              <p className={styles.dirrec}>Dirección</p>
              <input className={styles.inputForm} type="text" />
            </div>
          </div>
          <Link className={styles.btnFinalizar} to={"/finalizar-comprass"}>
            Finalizar compra
          </Link>
        </div>

        <div className={styles.contenedorProductos}>
          <div className={styles.producto}>
            <div className={styles.contenedorimg}>
              <img className={styles.imagen} src={producto} alt="" />
            </div>
            <p className={styles.descripcion}>Gel crema de Rosa Mosqueta</p>
            <p className={styles.cantidad}>2x</p>
            <p></p>
          </div>
          <div className={styles.linea}></div>
          <div className={styles.producto}>
            <div className={styles.contenedorimg}>
              <img className={styles.imagen} src={producto} alt="" />
            </div>
            <p className={styles.descripcion}>Gel crema de Rosa Mosqueta</p>
            <p className={styles.cantidad}>2x</p>
            <p></p>
          </div>
          <div className={styles.linea}></div>
          <div className={styles.producto}>
            <div className={styles.contenedorimg}>
              <img className={styles.imagen} src={producto} alt="" />
            </div>
            <p className={styles.descripcion}>Gel crema de Rosa Mosqueta</p>
            <p className={styles.cantidad}>2x</p>
            <p></p>
          </div>
          <div className={styles.linea}></div>
          <div className={styles.btnTotal}>
            <p className={styles.productoTotal}>TOTAL:</p>
            <p className={styles.monto}>$0.00</p>
          </div>
        </div>
      </div>
    </>
  );
};
