import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null, // Información del usuario
  isAuthenticated: false,
  isLoading: false,
  error: null,
  role: null, // 'cliente' o 'distribuidor'
  token: null, // Token de autenticación
  userType: null, // Tipo de usuario: 'CON' o 'DIS'
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.error = null;
      state.token = action.payload.token;
      state.userType = action.payload.userType; // Asume que el tipo de usuario viene en el objeto user como 'tipo'
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;
      state.role = null;
      state.userType = null; // Limpia el tipo de usuario al cerrar sesión
    },
    registerSuccess: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isAuthenticated = true;
      state.isLoading = false;
      state.error = null;
      state.role = action.payload.role; // Agregar el rol al estado
      state.userType = action.payload.userType; // Agregar el tipo de usuario al estado
    },
  },
});

// Export actions
export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  registerSuccess,
} = authSlice.actions;

// Export reducer
export default authSlice.reducer;
