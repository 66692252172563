import { useState } from 'react'

export const useSidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  
  const handleClose = () => setShowSidebar(false);
  const handleShow = () => setShowSidebar(true);
  return {
    showSidebar,
    handleClose,
    setShowSidebar,
    handleShow
  }
}
