import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/store";

import "animate.css";
import "swiper/swiper-bundle.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import { ToastContainer } from 'react-toastify';
import "../src/css/MensajeDeError.css"

const container = document.getElementById("root");
const root = createRoot(container);

const RootComponent = () => {
  return (
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  );
};

root.render(<RootComponent />);
