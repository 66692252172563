import styles from "../Promociones/Promociones.module.css";
import { Link } from "react-router-dom";
import { Card } from "../../../homeComun/components/Cards/Card";


export const PromocionesVogue = ({ data, pedidos, apiGet }) => {

  return (
    <>
      <h2 className={styles.titulo}> Promociones Vogue Plus</h2>
      <div className={styles.contenedorBtn}>
        <Link to={"/vogue-plus"} className={styles.btnPromociones}>
          Ver todas las promociones
        </Link>
      </div>
      <div className={styles.contenedorCards}>
        {data?.map((ele, index) => (
          <Card
            key={index}
            data={ele}
            cantidadProp
            detalle={true}
            pedidos={pedidos}
            onPedido={apiGet}
            agregar="pedido"
          />
        ))}
      </div>
    </>
  );
};
