// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer_contenedor__SxlFk {\n  display: flex;\n  justify-content: space-around;\n  align-items: start;\n  padding: 4.9rem 0;\n  background-color: #89182f;\n  height: 300px;\n  overflow: hidden;\n}\n.Footer_logo__iMRaO {\n  width: 300px;\n}\n.Footer_FooterSections__GGhzW {\n  color: #fff;\n  font-family: \"Lato\";\n  font-size: 20px;\n  font-weight: bold;\n}\n\n@media (max-width: 765px) {\n  .Footer_contenedor__SxlFk {\n    height: auto;\n    flex-direction: column;\n    align-items: start;\n    padding: 50px 30px;\n    gap: 15px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/homeComun/components/Footer/Footer.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;EACzB,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;IAClB,SAAS;EACX;AACF","sourcesContent":[".contenedor {\n  display: flex;\n  justify-content: space-around;\n  align-items: start;\n  padding: 4.9rem 0;\n  background-color: #89182f;\n  height: 300px;\n  overflow: hidden;\n}\n.logo {\n  width: 300px;\n}\n.FooterSections {\n  color: #fff;\n  font-family: \"Lato\";\n  font-size: 20px;\n  font-weight: bold;\n}\n\n@media (max-width: 765px) {\n  .contenedor {\n    height: auto;\n    flex-direction: column;\n    align-items: start;\n    padding: 50px 30px;\n    gap: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contenedor": "Footer_contenedor__SxlFk",
	"logo": "Footer_logo__iMRaO",
	"FooterSections": "Footer_FooterSections__GGhzW"
};
export default ___CSS_LOADER_EXPORT___;
