import React from 'react';
import styles from '../SectionDescriptionCremaSolar/SectionDescription.module.css'

export const SectionDescriptionRomeroYSalvia = () => {
  return (
    <div className={styles.contenedorDescripcion}>
      <section className={styles.decripcionParrafo}>
        <h1 className={styles.title}>S.O.S control de caída</h1>
        <p>El romero y la salvia son hierbas aromáticas ampliamente conocidas por sus propiedades beneficiosas para la salud.</p>
        <div>
          <h2>Beneficios del romero en el cabello</h2>
          <ul>
            <li>Estimulación del Crecimiento del Cabello: El romero es conocido por mejorar la circulación sanguínea en el cuero cabelludo, lo que puede ayudar a estimular el crecimiento del cabello y prevenir la caída excesiva.
            </li>
            <li>Fortalecimiento del Folículo Piloso: Los antioxidantes presentes en el romero ayudan a fortalecer los folículos pilosos, promoviendo así un cabello más fuerte y resistente a la rotura.</li>
            <li>Control de la Caspa: Las propiedades antibacterianas y antifúngicas del romero pueden ayudar a combatir la caspa y a mantener el cuero cabelludo limpio y saludable.</li>
          </ul>
        </div>
        <div>
          <h2>Beneficios de la salvia en el cabello</h2>
          <ul>
            <li>Control de la Producción de Sebo: La salvia tiene propiedades astringentes que pueden ayudar a regular la producción de sebo en el cuero cabelludo.</li>
            <li>Acondicionamiento Natural: La salvia es rica en nutrientes que pueden ayudar a acondicionar el cabello, dejándolo suave, brillante y fácil de peinar.</li>
            <li>Propiedades Antioxidantes: Los antioxidantes presentes en la salvia pueden ayudar a proteger el cabello de los daños causados por los radicales libres, como el daño solar y el envejecimiento prematuro.</li>
          </ul>
        </div>
        <div>
          <h2>Modo de uso</h2>
          <p>El shampoo de romero y salvia es una opción natural y efectiva para mantener un cabello saludable y radiante. ¿Qué esperas para incorporar el romero y la salvia en tu rutina de cabello?</p>
        </div>
      </section>
    </div>
  )
}
