import React, { Fragment, useEffect } from "react";
import styles from "./EstadoCuenta.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const EstadoCuenta = () => {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const {
    saldo_vencido = 0,
    saldo_no_vencido = 0,
    calculo_interes = 0,
    total_vales = 0,
    saldo_favor = 0,
    total_deuda = 0,
    vencimiento_letra_cambio = "",
  } = user || {}; // Manejar null o undefined para user
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  const dataCard = isAuthenticated && [
    {
      title: "Saldo vencido",
      balance: saldo_vencido,
    },
    {
      title: "Saldo no vencido",
      balance: saldo_no_vencido,
    },
    {
      title: "Interés",
      balance: calculo_interes,
    },
    {
      title: "Vales generados",
      balance: total_vales,
    },
    {
      title: "Saldo a favor",
      balance: saldo_favor,
    },
    {
      title: "Total de la deuda",
      balance: total_deuda,
    },
  ];

  return (
    <>
      {isAuthenticated && (
        <div className={styles.contenedorPrincipalEstado}>
          <div className={styles.contenedorGeneral}>
            <div className={styles.contenedorTitulo}>
              <h2 className={styles.titulo}>
                Hola, {user?.nombres?.split(" ")[0]}
              </h2>
              <p className={styles.diu}>
                COD. {user?.cliente_id}
                <br />
                DUI: {user?.dui}
              </p>
            </div>
          </div>
          <div className={styles.contenedor}>
            <div className={styles.contenedorLink}>
              <Link className={styles.link} to={"/datos-generales-distribuidor"}>
                Datos generales{" "}
              </Link>
              <Link className={styles.link} to={"/facturas-pendientes"}>
                Facturas pendientes
              </Link>
              <Link className={styles.link} to={""}>
                Estado de cuenta
              </Link>
            </div>

            <div className={styles.tabla}>
              <section className={styles.tableTitles}>
                <p>Estado de cuenta</p>
                <div className={styles.sectionRight}>
                  <p>Vencimiento Letra de cambio:</p>
                  <p>{vencimiento_letra_cambio}</p>
                </div>
              </section>
              <section>
                {dataCard?.length > 0 &&
                  dataCard.map((data) => (
                    <Fragment key={data.title}>
                      {data.title === "Vales generados" ||
                        data.title === "Saldo a favor" ? (
                        <div className={styles.tableTextPink}>
                          <p>{data.title}:</p>
                          <p>${data.balance}</p>
                        </div>
                      ) : (
                        <div
                          className={
                            data.title === "Interés"
                              ? styles.tableDataWithoutLine
                              : styles.tableData
                          }
                        >
                          <p>{data.title}:</p>
                          <p>${data.balance}</p>
                        </div>
                      )}
                    </Fragment>
                  ))}
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
