import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from "../Carrito/Carrito.module.css";
import { Cargando } from '../../../homeComun/components/Cargando/Cargando';
import { Link, useNavigate, useParams } from "react-router-dom";
import { SvgError } from '../../../assets/svg/errorSvg';
import { MdError } from 'react-icons/md';


const Abonos = (props) => {
    const { isAuthenticated, userType, token, user } = useSelector(
        (state) => state.auth
    );
    const { invoice } = useParams();

    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(false);
    const [statePedido, setStatePedido] = useState(false);
    const navigate = useNavigate()
    const [cliente, setCliente] = useState(null);


    const getCliente = async () => {
        setLoading(true);
        try {
            const headers = {
                Authorization: `Bearer ${token ?? ""}`,
            };
            const cliente = await axios.get(`${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`, { headers });
            setCliente(cliente.data);
        } catch (error) {
            console.error("Error al obtener cliente:", error);
        } finally {
            setLoading(false);
        }
    }

    const getData = async () => {
        console.log("Ejecutando getData");
        try {
            setLoading(true);

            const headers = {
                Authorization: `Bearer ${token ?? ""}`,
            };

            // Obtener cliente y abonos simultáneamente
            const [clienteResponse, abonosResponse] = await Promise.all([
                axios.get(`${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`, { headers }),
                axios.get(`${process.env.REACT_APP_API_URL}/pedidos/find/abonos`, { headers }),
            ]);

            const cliente = clienteResponse.data;
            const abonos = abonosResponse.data;

            if (!abonos || abonos.length === 0) {
                throw new Error("No se encontraron abonos.");
            }

            const idAbonos = abonos[0]._id;
            const totalFactura = cliente?.detalle?.lista_facturas.find(
                (el) => el.factura_no === Number(invoice)
            )?.saldo;

            // Obtener transacción
            const transactionResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/last-pago/${invoice}`,
                { headers }
            );

            console.log("transacccion", transactionResponse.data);



            const transaccionId = transactionResponse?.data?.transaccionId;

            if (transaccionId) {
                const statusResponse = await axios.get(
                    `${process.env.REACT_APP_API_URL}/transaccion/${transaccionId}`,
                    { headers }
                );

                const statusData = statusResponse.data;

                setBalance(Number(totalFactura));
                setStatePedido(statusData.SatisFactorio);

                if (statusData.SatisFactorio) {
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/documentos/send-abono-email`,
                        {
                            monto: Number(statusData.Monto),
                            numAutorizacion: Number(statusData.NumeroAutorizacion)
                        },
                        { headers }
                    );

                    const saldoActual = Number(abonos[0].total_guardado);
                    const nuevoTotalGuardado = saldoActual - Number(statusData.Monto);

                    // Actualizar abono
                    await axios.put(
                        `${process.env.REACT_APP_API_URL}/pedidos/update-abono`,
                        {
                            total_guardado: nuevoTotalGuardado.toString(),
                            total_abonado: Number(abonosResponse.data[0].total_abonado) + Number(statusData.Monto.toString()),
                            id: idAbonos,
                        },
                        { headers }
                    );
                }

                // Actualizar transacción
                await axios.put(
                    `${process.env.REACT_APP_API_URL}/last-pago`,
                    { transaccionId },
                    { headers }
                );
            }
        } catch (error) {
            console.error("Error al obtener datos:", error);
        } finally {
            setLoading(false);
        }
    };


    // console.log(cliente)
    useEffect(() => {
        if (isAuthenticated) {
            getCliente();
        } else {
            navigate("/");
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (cliente && cliente.detalle) {
            // console.log("Cliente obtenido, ejecutando getData");
            getData();
        }
    }, [cliente]);

    return (
        <>

            {
                isAuthenticated === true && (
                    <div className={styles.contenedorH}>
                        <div className={styles.contenedorGris}>
                            {loading ? (
                                <Cargando />
                            ) : !statePedido ? (
                                <>
                                    <MdError color="#ff0000" size={80} />
                                    <h3 className={styles.orden}>Tu abono ha sido rechazado</h3>
                                    <p className={styles.parrafo}>
                                        Esto puede deberse a muchos motivos. Por favor intente nuevamente verificando que su tarjeta o datos sean ingresados correctamente o comuníquese a nuestra zona de soporte. Disculpe los inconvenientes.
                                    </p>
                                    <Link className={styles.continuar} to={"/tienda-linea-cliente/05"}>
                                        Continuar comprando
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <SvgError />
                                    <h3 className={styles.orden}>El abono se ha realizado exitosamente</h3>
                                    <p className={styles.parrafo}>
                                        Cuentas con 3 días máximo a partir de recibido el pedido para presentar algún reclamo. Luego de ese lapso, ya no podrá ser procesado.
                                        <br />
                                        Un miembro de atención al cliente se comunicará contigo para los siguientes pasos.
                                    </p>
                                    <Link className={styles.continuar} to={"/tienda-linea-cliente/05"}>
                                        Continuar comprando
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>

                )
            }
        </>

    );
};

export default Abonos;
