import React from "react";
import style from "../../../css/LoginDistribuidor.module.css";
import imagen from "../../../assets/img/6.jpg";
import imagen1 from "../../../assets/img/Propuesta-de-valor.png";
import historia from "../../../assets/img/historia.png";
import { MensajeWhasapps } from "../MensajeFlotant/MensajeWhasapps";
import img from "../../../assets/img/Banner/Info de distribuidores-Cambios Sitio Web.png"

export const QuienesSomos = () => {
  return (
    <>
      <div className={style.imagen}>
        <img className={style.imagens} src={imagen} alt="" />
      </div>
      <MensajeWhasapps titulo="Suscríbete a nuestro Newsletter" />
      {/* Quines somos  */}
      <div className={style.contenedorRed}>

        <div className={style.contenedorSomos}>
          <p className={style.somos}>¿Quiénes somos?</p>
          <p className={style.somosEmpresa}>
            Vogue es una empresa, que busca mejorar tu cuidado, belleza e
            higiene personal como consumidor. También ofrecemos a nuestros
            distribuidores autorizados una oportunidad de negocio para el
            desarrollo personal y profesional, ofreciendo productos de alta
            calidad, integrando{" "}
            <span style={{ whiteSpace: "nowrap", color: "grey" }}>
              el cuidado y la belleza.
            </span>
          </p>

          <div className={style.contenedorImgLetras}>
            <img src={img} alt="Imagen que refleja la cantidad de distribuidores, productos y equipo de ventas de Vogue" />
          </div>
        </div>

        {/* Vision y Mision  */}
        <div className={style.contenedorVisionMision}>
          <div className={style.mison}>
            <div className={style.contenedorCaja}>
              <h4 className={style.tituloMision}>Misión</h4>
            </div>
            <p className={style.parrafoMisions}>
              Proporcionar calidad en servicios y productos, generando fuertes y
              duraderos lazos con distribuidores y clientes.
            </p>
          </div>
          <div className={style.linea}></div>
          {/* Vision  */}
          <div className={style.mison}>
            <div className={style.contenedorCaja}>
              <h4 className={style.tituloMision}>Visión</h4>
            </div>
            <p className={style.parrafoMisions}>
              Ser la mejor opción de venta por catálogo en El Salvador.  Dar la
              oportunidad de un mejor estilo de vida en el área de cuidado
              personal y belleza.
            </p>
          </div>
        </div>
      </div>
      {/* Nuestra Historia  */}
      <div className={style.contenedorHistoria}>
        <div className={style.imgHistoria}>
          <img className={style.imgHistorias} src={historia} alt="" />
        </div>
        <div className={style.historiaTexto}>
          <h2 className={style.tituloHistoria}>Nuestra Historia</h2>
          <p className={style.parrafoHistoria}>
            Vogue tiene +27 años de experiencia en el rubro de belleza y
            cuidado, inició operaciones un 19 de abril de 1996 ofreciendo
            productos de alta calidad para sus distribuidores, en el 2018
            cambiamos nuestro modelo de negocio, introduciendo la venta por
            catálogo. Esto significó poder llegar a nuevos clientes y estar en
            una constante innovación en nuestro catálogo de productos que
            ofrecemos año con año.
          </p>
        </div>
      </div>

      {/* Contenedor Propuesta  */}
      <div className={style.contenedorPropuesta}>
        <div className={style.contenedorImgs}>
          <img className={style.imagenChiquita} src={imagen1} alt="Imagen ilustrativo de una crema" />
        </div>
        <div className={style.textoContenedorPropuesta}>
          <h2 className={style.tituloPropuesta}>PROPUESTA DE VALOR</h2>
          <p className={style.parrafoTexto}>
            Brindar productos de calidad que le permitan a las personas mejorar
            su estilo de vida con productos que cuidan de su bienestar emocional
            y corporal.
          </p>
        </div>
      </div>
    </>
  );
};
