import React from "react";
import { Link } from "react-router-dom";
import style from "../../../css/BanerDistribuidora.module.css";
import señora from "../../../assets/img/señora12.jpg";

export const BannerDistribuidora = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };
  return (
    <>
      <div className={style.contenedor}>
        <img className={style.señora} src={señora} alt="" />
        <Link
          to="/distribuidora"
          onClick={handleScrollToTop}
          className={style.descubre}
        >
          Descubre más
        </Link>
      </div>
    </>
  );
};
