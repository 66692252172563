export const subLineasPorCategoria = {
  "05": [
    { nombre: "Gel ", buscar: "baño" },
    { nombre: "Tarro", buscar: "tarro" },
    { nombre: "Roll-on", buscar: "roll" },
    { nombre: "Champu", buscar: "Champu" },
    { nombre: "Aceite ", buscar: "Aceite corporal" },
    { nombre: "Exfoliante ", buscar: "exfoliante" },
    { nombre: "Colonia ", buscar: "colonia" },
    { nombre: "Lociones", buscar: "locion" },
  ],
  "01": [
    { nombre: "Shampoo", buscar: "Shampoo" },
    { nombre: "Crema", buscar: "crema" },
    { nombre: "Acondicionador", buscar: "Acondicionador" },
    { nombre: "Mascarilla", buscar: "Mascarilla" },
    { nombre: "Tratamiento", buscar: "tratamiento" },
    { nombre: "Crema peinar", buscar: "peinar" },
    { nombre: "Tinte", buscar: "Tinte" },
    { nombre: "Sachet", buscar: "Sachet" },
    { nombre: "Color", buscar: "Color" },
    { nombre: "Ampolleta", buscar: "ampolleta" },

    // Aquí puedes hacer cambios similares si necesitas añadir propiedades a estos elementos
  ],
  "02": [
    { nombre: "Dama", buscar: "ECSTASY" },
    { nombre: "Caballero", buscar: "HOMME" },
  ],
  "06": [
    { nombre: "Base", buscar: "base" },
    { nombre: "Iluminador", buscar: "iluminador" },
    { nombre: "Sombras", buscar: "sombras" },
    { nombre: "Delineador", buscar: "delineador" },
    { nombre: "Labial", buscar: "labial" },

    // Similar para los elementos de esta categoría, si es necesario
  ],
  "04": [
    // Y lo mismo
    { nombre: "Exfoliante", buscar: "exfoliante" },
    { nombre: "Agua micelar", buscar: "agua micelar" },
    { nombre: "Mascarilla", buscar: "mascarilla" },
    { nombre: "Serum", buscar: "serum" },
    { nombre: "Contorno", buscar: "contorno" },
  ],
};
