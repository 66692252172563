import React, { useEffect } from "react";
import { MenuCategoria } from "../MenuCategoria/MenuCategoria";
import { Nav } from "../Nav/Nav";
import { Outlet, useLocation } from "react-router-dom";
import { Cargando } from "../Cargando/Cargando";
import { useSelector } from "react-redux";
import { MenuCategoriaDistribuidor } from "../../../HomeDistribuidor/components/MenuCategoria/MenuCategoriaDistribuidor";
import {
  useLoading,
} from "../../../state/context/loadingContext";
import { useBorrador } from "../../../state/context/borradorContext";

export const LayoutComun = () => {
  const { userType } = useSelector((state) => state.auth);
  const { isLoading, setIsLoading } = useLoading();
  const { loadingBorrador } = useBorrador();
  const location = useLocation();
  useEffect(() => {
    setIsLoading(true); // Activa el estado de carga al cambiar de página

    const timeout = setTimeout(() => {
      setIsLoading(false); // Desactiva el estado de carga después de 2 segundos (ajusta según sea necesario)
    }, 2000);

    return () => clearTimeout(timeout); // Limpia el temporizador al desmontar el componente
  }, [location]);
  return (
    <>
      <Nav />
      {userType === "DIS" ? <MenuCategoriaDistribuidor /> : <MenuCategoria />}
      {loadingBorrador || (isLoading && location.pathname !== '/facturas-pendientes') ? ( // Renderiza el componente de carga si isLoading es true
        <Cargando />
      ) : (
        <Outlet />
      )}
    </>
  );
};
