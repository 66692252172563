import React, { useEffect, useState } from "react";
import ProductDescriptionInfo from "../components/product/ProductDescriptionInfo";
import styles from "../css/ProductoCarruselImagen.module.css";
import { useLocation } from "react-router-dom";
import { Reseñas } from "../components/homeCliente/components/Reseñas";
import { ProductosRelacionados } from "../components/homeCliente/components/ProductosRelacionados";
import { useSelector } from "react-redux";
import axios from "axios";
import { Cargando } from "../homeComun/components/Cargando/Cargando";

export const DetalleProducto = () => {

  const location = useLocation();
  const { ruta = true } = location.state || {};
  const [datas, setDatas] = useState("")
  const [cargando, setCargando] = useState(true)


  const getDetailId = () => {
    const urlParts = window.location.href.split("/detalle/");
    return urlParts[1];
  };

  const detailId = getDetailId();

  const dataInfo = async () => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/productos?id=${detailId}&page=1&pageSize=1`)
    setDatas(response.data.detalle)

  }
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const hasMultipleImages = datas?.imagen?.length > 1;

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  const getCurrentImageUrl = () => {
    return `${process.env.REACT_APP_URL_IMG}/${datas?.imagen?.[currentImageIndex]}`;
  };

  // Filtrar la imagen principal de la lista de imágenes secundarias
  const secondaryImages = datas?.imagen?.filter(
    (_, index) => index !== currentImageIndex
  );



  useEffect(() => {
    const timeout = setTimeout(() => {
      setCargando(false);
      dataInfo();
    }, 1000); // 1000 ms = 1 segundo

    // Limpieza del timeout en caso de que el componente se desmonte
    return () => clearTimeout(timeout);

    
  }, []);

  return (
    datas && !cargando ? (
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorGeneral}>
          <div className={`${styles.carouselContainer} ${hasMultipleImages ? '' : styles.sinListaImagen}`}>
            {hasMultipleImages && (
              <div className={styles.imageList}>
                {secondaryImages.map((imageName, index) => (
                  <div
                    key={index}
                    className={styles.imageItem}
                    onClick={() =>
                      handleImageClick(datas.imagen.indexOf(imageName))
                    }
                  >
                    <img
                      className={styles.imagen}
                      src={`${process.env.REACT_APP_URL_IMG}/${imageName}`}
                      alt="Imagen del producto"
                    />
                  </div>
                ))}
              </div>
            )}
            <div className={`${styles.mainImage} ${hasMultipleImages ? '' : styles.centradoImg}`}>
              <img
                className={styles.productoSeleccionado}
                src={getCurrentImageUrl()}
                alt="Imagen del producto principal"
              />
            </div>
          </div>
          <div className={styles.contenedorInfo}>
            <ProductDescriptionInfo
              data={datas}
              ruta={ruta}
            />
          </div>
        </div>
        <Reseñas data={datas} />
        <ProductosRelacionados data={datas} />
      </div>
    ) : (
      <Cargando />
    )
  );
};