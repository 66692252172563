import marcaImagen from "../img/400x150-Instituto-Español-Logo.png";
import higiene from "../img/shutterstock_1543598711.jpg";
import Higuiene1 from "../img/Mesa de trabajo 21.png";
import Higuiene2 from "../img/Mesa de trabajo 22.png";
import Higuiene3 from "../img/Mesa de trabajo 23 copia.png";

import Capilar1 from "../img/Certificaciones-05.png";
import Capilar2 from "../img/Certificaciones-07.png";
import Capilar3 from "../img/Certificaciones-06.png";

import Canekom1 from "../img/Mesa de trabajo 82.png";
import Canekom2 from "../img/Mesa de trabajo 23 copia.png";
import Canekom3 from "../img/Mesa de trabajo 81.png";

import Perfume1 from "../img/Mesa de trabajo 4.png";
import Perfume2 from "../img/Mesa de trabajo 5.png";
// import Perfume3 from "../img/Mesa de trabajo 81.png";

import Dicora1 from "../img/Dicora-01.png";
import Dicora2 from "../img/Dicora-02.png";
import Dicora3 from "../img/Dicora-03.png";
import Dicora4 from "../img/Dicora-04.png";
import Dicora5 from "../img/Dicora-05.png";

import Facial1 from "../img/Certificaciones-02.png";
import Facial2 from "../img/Certificaciones-03.png";
import Facial3 from "../img/Certificaciones_Mesa de trabajo 1.png";

import Bienestar1 from "../img/gitgot1.png";
import Bienestar2 from "../img/gigot2.png";
import Bienestar3 from "../img/gigot3.png";

import capilar from "../img/capilar.png";
import imagenMarcaKanekom from "../img/kanekom12.png";
import imagenMarcaLatafa from "../img/Lattafa-Logo12.png";
import perfumeria from "../img/Perfumeria12.png";
import maquillaje from "../img/maquillaje12.png";
import imagenMarcaDicora from "../img/Dicora-logo12.jpg";
import CuidadoFacial from "../img/cuidadoFacial12.jpg";
import imagenMarcaGigot from "../img/Gigot-logo12.png";
import imagenDenver from "../img/400x150-Deven-Logo.png";
import imagenStyles from "../img/400x150-MyStyle-Logo.png";
import imagenBienestar from "../img/222.png";

export const categorias = [
  {
    titulo: "Higiene y Cuidado de la piel",
    id: "05",
    idScroll: "instituto",
    imagenPrincipal: higiene,
    description:
      "Descubre la esencia del cuidado corporal con nuestra exclusiva línea importada de España. Cada producto ofrece una experiencia única, elaborada con ingredientes naturales y fórmulas diseñadas para revitalizar la piel y el cuerpo. Disfruta de una piel radiante y un cuidado incomparable realzando tu belleza natural. ¡Bienvenidos a nuestra colección de cuidado e higiene corporal, donde encontrarás el mejor producto para cada tipo de piel!",
    marcas: [
      {
        marcaId: "117",
        nombres: "Instituto Español",
        mostrar: false,
        imagenMarcas: [
          {
            img: marcaImagen,
          },
        ],
        marcaDescription: (
          <div>
            Una empresa 100% española con más de 120 años de trayectoria.
            Investigación, innovación, calidad, tecnología… Todas estas palabras
            describen a la marca Instituto Español.
            <br />
            Su portafolio cuenta con una amplia variedad de productos que buscan
            reforzar el cuidado desde los más pequeños de la casa hasta los
            mayores.
            <br />
            Compromiso con la sostenibilidad
            <br />
            Uso de 65% energía renovable
            <br />
            No testado en animales
            <br />
            Envases reciclables y reciclados
          </div>
        ),
        imgCertificaciones: [Higuiene1, Higuiene2, Higuiene3],
      },
    ],
  },

  // otro
  {
    titulo: "Capilar",
    id: "01",
    idScroll: "dicorakanechom",
    imagenPrincipal: capilar,
    description: (
      <div>
        ¡Descubre el secreto de un cabello radiante y saludable con nuestra
        exclusiva línea de cuidado capilar! Desde los básicos esenciales como
        shampoo y acondicionador hasta tratamientos especializados, cremas de
        peinar, tintes innovadores, baños de color revitalizantes y gelatinas
        para estilos creativos, nuestra colección ofrece todo lo que necesitas
        para lograr el cabello de tus sueños. Con fórmulas enriquecidas con
        ingredientes de alta calidad, nuestros productos nutren, fortalecen y
        embellecen tu cabello, ofreciéndote la confianza y el estilo que te
        mereces. ¡Prepárate para deslumbrar con un cabello que refleje tu
        verdadera esencia y estilo único!
      </div>
    ),
    marcas: [
      {
        marcaId: "199",
        nombres: "Dicora",
        mostrar: false,
        imagenMarcas: [
          {
            img: imagenMarcaDicora,
          },
        ],
        marcaDescription: (
          <div>
            Porque no queremos ser solamente productos. Queremos compartir
            contigo una forma de ver la vida. No creemos en la “belleza
            perfecta”. Creemos que la belleza comienza en el interior y se
            proyecta hacia el exterior. <br />
            Porque la belleza también es una actitud. Es la manera en la que
            sales a la calle cada día, con seguridad, con confianza y con una
            sonrisa. Porque podemos ayudarte a sacar lo mejor de ti. Porque
            dentro de cada uno de nuestros envases, hay algo más que calidad.
            Hay entusiasmo. Hay responsabilidad. Hay fuerza. Hay energía. Una
            combinación perfecta entre actitud y compromiso. Te cuidamos. <br />
          </div>
        ),

        imgCertificaciones: [Dicora1, Dicora2, Dicora3, Dicora4, Dicora5],
      },
      {
        marcaId: "193",
        nombres: "Deven",
        mostrar: false,
        imagenMarcas: [
          {
            img: imagenDenver,
          },
        ],
        marcaDescription: (
          <div>
            Bienvenido a Deven, donde la belleza se encuentra con lo mejor de la
            naturaleza. Nuestra marca ofrece una gama de tintes y baños de color
            formulados con ingredientes naturales para realzar tu cabello de
            forma vibrante y saludable. Puedes confiar en que estás cuidando tu
            cabello con lo mejor que la naturaleza tiene para ofrecer, mientras
            disfrutas de resultados sorprendentes y duraderos. ¡Descubre una
            nueva forma de belleza con Deven!
          </div>
        ),

        imgCertificaciones: [Capilar1, Capilar2, Capilar3],
      },
      {
        marcaId: "178",
        nombres: "Kanechom",
        mostrar: false,
        imagenMarcas: [
          {
            img: imagenMarcaKanekom,
          },
        ],
        marcaDescription: (
          <div>
            Kanechom llegó para marcar la diferencia y conquistar los corazones
            y deseos de los consumidores. Los productos son desarrollados para
            satisfacer las necesidades de cada consumidor, pensando en los
            beneficios y resultados para cada tipo de cabello. Producto 100%
            vegano, ¡no testado en animales! Como empresa, estamos comprometidos
            con la eliminación de residuos y minimizar la contaminación en el
            planeta. Por eso adoptamos plástico reciclable en los envases de
            Kanechom. <br />
            ¡Cada belleza es única! Autoestima, belleza y cuidado sin renunciar
            a la calidad y un precio justo.
          </div>
        ),
        imgCertificaciones: [Canekom1, Canekom2, Canekom3],
      },
    ],
  },

  {
    titulo: "Perfumería",
    id: "02",
    idScroll: "lattafa",
    imagenPrincipal: perfumeria,
    description: (
      <div>
        Déjate cautivar por la elegancia de nuestra exclusiva línea de
        perfumería importada directamente desde Emiratos Árabes. Cada fragancia
        es una obra maestra que encapsula la esencia del lujo y la sofisticación
        del Medio Oriente, creando una experiencia sensorial única que perdura
        en el tiempo. Descubre el arte de la perfumería de clase mundial con
        nuestra colección de perfumes de Dubai, donde el lujo y la elegancia se
        encuentran en cada gota.
      </div>
    ),
    marcas: [
      {
        marcaId: "160",
        nombres: "Lattafa",
        mostrar: false,
        imagenMarcas: [
          {
            img: imagenMarcaLatafa,
          },
        ],
        marcaDescription: (
          <div>
            Inspirada en las palabras árabes "Latif" para bondad y "Lateefa"
            para agradable, Lattafa resuena con el lujo y la elegancia de la
            cultura árabe y captura vívidamente la esencia de su gloriosa
            herencia en su cautivadora fragancia. Con ingredientes exquisitos y
            procesos de elaboración meticulosos, nuestras fragancias transportan
            tus sentidos.
          </div>
        ),
        imgCertificaciones: [Perfume1, Perfume2],
      },
    ],
  },
  {
    titulo: "Maquillaje",
    id: "06",
    imagenPrincipal: maquillaje,
    description: (
      <div>
        Descubre el poder transformador del maquillaje con nuestra línea
        completa de productos. Desde bases que perfeccionan la piel hasta polvos
        compactos que sellan el mejor acabado, labiales vibrantes que destacan
        tu sonrisa, delineadores precisos que realzan tu mirada, productos para
        cejas que enmarcan tu rostro y máscaras de pestañas que añaden volumen y
        longitud, nuestra colección ofrece todo lo que necesitas para crear
        looks espectaculares para cada ocasión. Con fórmulas de alta calidad y
        una amplia gama de tonos y acabados, nuestros productos te permiten
        expresar tu creatividad y realzar tu belleza única.
      </div>
    ),
    marcas: [
      {
        marcaId: "156",
        nombres: "My Style",
        mostrar: false,
        imagenMarcas: [
          {
            img: imagenStyles,
          },
        ],
        marcaDescription: (
          <div>
            Caracterizada por ofrecer una experiencia completa de maquillaje que
            se adapta a cada estilo. Su enfoque es la versatilidad y la
            autenticidad, permitiendo a cada persona expresar su individualidad
            a través del maquillaje. Con una amplia gama de productos que van
            desde bases de maquillaje hasta labiales y sombras de ojos, My Style
            se destaca por sus fórmulas de alta calidad y su compromiso con la
            innovación. Con su variedad de tonos y productos que se adaptan a
            diferentes estilos y tipos de piel, encontrarás lo que tú necesitas.
            My Style celebra la creatividad, inspirando a las personas a
            encontrar y expresar su propio estilo único.
          </div>
        ),
        imgCertificaciones: [],
      },
    ],
  },
  {
    titulo: "Cuidado Facial",
    id: "04",
    idScroll: "gigot",
    imagenPrincipal: CuidadoFacial,
    description: (
      <div>
        Sumérgete en el mundo del cuidado facial con nuestra completa línea de
        productos, donde la belleza se encuentra con la eficacia. Desde cremas
        hidratantes que nutren profundamente la piel hasta sueros potentes que
        combaten los signos del envejecimiento, contornos de ojos que iluminan y
        reafirman, mascarillas que revitalizan instantáneamente, desmaquillantes
        suaves que eliminan impurezas y maquillaje sin esfuerzo, hasta
        exfoliantes que renuevan la luminosidad de la piel, nuestra colección
        ofrece una solución para cada necesidad de cuidado facial. Con fórmulas
        innovadoras e ingredientes cuidadosamente seleccionados, nuestros
        productos están diseñados para brindar resultados visibles y duraderos,
        revelando una piel radiante y saludable en cada aplicación. Descubre el
        poder transformador del cuidado facial con nuestra línea de productos,
        donde cada paso de tu rutina de belleza se convierte en un momento de
        lujo y bienestar para tu piel.
      </div>
    ),
    marcas: [
      {
        marcaId: "174",
        nombres: "Gigot",
        mostrar: false,
        imagenMarcas: [
          {
            img: imagenMarcaGigot,
          },
        ],
        marcaDescription: (
          <div>
            En el universo de productos GIGOT las personas encuentran
            herramientas para fortalecer su autoestima, crecer, descubrir su
            belleza única e individual y llevar bienestar a quienes las rodean.
            Cuenta con más de 40 años de experiencia y se especializa en el
            desarrollo de productos para el cuidado de la piel con fórmulas
            exclusivas, diseño original y tecnología de avanzada. GIGOT impulsa
            a todas las personas a que puedan sentirse bien, quererse, verse
            lindas y disfrutar. Encontrarse a sí mismas, valorar su ser único y
            particular, expresarse y hacer aquello que les haga bien y las lleve
            a estar cómodas en su propia piel.
          </div>
        ),
        imgCertificaciones: [Facial1, Facial2, Facial3],
      },
    ],
  },
  {
    titulo: "Bienestar",
    id: "07",
    imagenPrincipal: imagenBienestar,
    description: (
      <div>
        Nuestra colección de productos, que incluye aceites esenciales puros,
        bálsamos para dolores musculares y varices, entre otros, está diseñada
        para proporcionar alivio y confort de manera natural. Nos enorgullece
        ofrecer productos 100% naturales y libres de crueldad animal, formulados
        con ingredientes cuidadosamente seleccionados para promover el bienestar
        físico y emocional. Descubre el poder curativo de la naturaleza con
        nuestra línea de bienestar, donde cada producto está diseñado para
        ayudarte a sentirte mejor de adentro hacia afuera.
      </div>
    ),
    marcas: [
      {
        marcaId: "174",
        nombres: "Gigot",
        mostrar: false,
        imagenMarcas: [
          {
            img: imagenMarcaGigot,
          },
        ],
        marcaDescription: (
          <div>
            Se propone crear productos que ayuden a las personas a alcanzar su
            bienestar desde un estándar de calidad que sea respetuoso con el
            medio ambiente, las personas y los animales, tendiendo a un
            desarrollo más sustentable.
          </div>
        ),
        imgCertificaciones: [Bienestar1, Bienestar2, Bienestar3],
      },
    ],
  },
];
