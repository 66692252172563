import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from "../Carrito/Carrito.module.css";
import { Cargando } from '../../../homeComun/components/Cargando/Cargando';
import { Link, useNavigate, useParams } from "react-router-dom";
import { SvgError } from '../../../assets/svg/errorSvg';
import { MdError } from 'react-icons/md';


const Abonos = (props) => {
    const { isAuthenticated, userType, token, user } = useSelector(
        (state) => state.auth
    );
    const { invoice } = useParams();

    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(false);
    const [statePedido, setStatePedido] = useState(false);
    const navigate = useNavigate()

    const getData = async () => {


        try {
            setLoading(true);

            const cliente = await axios.get(
                `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token ?? ""}`,
                    },
                }
            );

            const abonos = await axios.get(`${process.env.REACT_APP_API_URL}/pedidos/abonos/${invoice}`, {
                headers: {
                    Authorization: `Bearer ${token ?? ""}`,
                },
            });

            let totalAbonos = abonos.data.abonos ? abonos.data.abonos.reduce((total, obj) => total + obj.monto, 0) : 0;
            let totalFactura = cliente?.data?.detalle?.lista_facturas.find(el => el.factura_no == Number(invoice))?.saldo;

            const resTransactionId = await axios.get(`${process.env.REACT_APP_API_URL}/last-pago/${invoice}`, {
                headers: {
                    Authorization: `Bearer ${token ?? ""}`,
                },
            });

            if (resTransactionId?.data?.transaccionId) {
                const status = await axios.get(
                    `${process.env.REACT_APP_API_URL}/transaccion/${resTransactionId?.data?.transaccionId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token ?? ""}`,
                        },
                    }
                );

                setBalance(Number(totalFactura) - totalAbonos);
                setStatePedido(status.data.SatisFactorio);

                if (status.data.SatisFactorio && !abonos.data?.abonos?.find(el => el.transaccionId === resTransactionId?.data?.transaccionId)) {
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/pedidos/abonos`,
                        {
                            "facturaId": Number(invoice),
                            "transaccionId": resTransactionId?.data?.transaccionId,
                            "monto": 0,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token ?? ""}`,
                            },
                        }
                    );
                    await axios.post(
                        `${process.env.REACT_APP_API_URL}/documentos/send-abono-email`,
                        {

                            "monto": Number(status.data.Monto),
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token ?? ""}`,
                            },
                        }
                    );
                }

                await axios.put(
                    `${process.env.REACT_APP_API_URL}/last-pago`,
                    {
                        "transaccionId": resTransactionId?.data?.transaccionId,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token ?? ""}`,
                        },
                    }
                );
            }
        } catch (error) {
            console.error('error', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isAuthenticated === true) {
            getData();
        } else {

            navigate("/")
        }

    }, []);


    return (
        <>

            {
                isAuthenticated === true && (
                    <div className={styles.contenedorH}>
                        <div className={styles.contenedorGris}>
                            {loading ? (
                                <Cargando />
                            ) : !statePedido ? (
                                <>
                                    <MdError color="#ff0000" size={80} />
                                    <h3 className={styles.orden}>Tu abono ha sido rechazado</h3>
                                    <p className={styles.parrafo}>
                                        Esto puede deberse a muchos motivos. Por favor intente nuevamente verificando que su tarjeta o datos sean ingresados correctamente o comuníquese a nuestra zona de soporte. Disculpe los inconvenientes.
                                    </p>
                                    <Link className={styles.continuar} to={"/tienda-linea-cliente/05"}>
                                        Continuar comprando
                                    </Link>
                                </>
                            ) : (
                                <>
                                    <SvgError />
                                    <h3 className={styles.orden}>El abono se ha realizado exitosamente</h3>
                                    <p className={styles.parrafo}>
                                        Cuentas con 3 días máximo a partir de recibido el pedido para presentar algún reclamo. Luego de ese lapso, ya no podrá ser procesado.
                                        <br />
                                        Un miembro de atención al cliente se comunicará contigo para los siguientes pasos.
                                    </p>
                                    <Link className={styles.continuar} to={"/tienda-linea-cliente/05"}>
                                        Continuar comprando
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>

                )
            }
        </>

    );
};

export default Abonos;
