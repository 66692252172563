import React from "react";
import styles from '../SectionDescriptionCremaSolar/SectionDescription.module.css'

export const SectionDescriptionCicatrifin = () => {
  return (
    <div className={styles.contenedorDescripcion}>
      <section className={styles.decripcionParrafo}>
        <h1 className={styles.title}>¿Cómo amar tus cicatrices?</h1>
        <p>
          En la vida, todos llevamos nuestras propias marcas: las cicatrices que
          cuentan historias de luchas pasadas, desafíos superados y las cicatrices
          de la piel. Ama tus cicatrices porque son parte de ti. Pero amarlas
          significa mejorar su apariencia, reparar, humectar y nutrir tu piel,
          para que progresivamente puedas suavizar la apariencia de las estrías y
          cicatrices.
        </p>
        <h2>La belleza de la imperfección</h2>
        <p>
          En un mundo obsesionado con la perfección y la belleza superficial,
          nuestras cicatrices nos recuerdan la belleza de la imperfección y la
          autenticidad. Son recordatorios tangibles de que nuestras experiencias,
          tanto las positivas como las negativas, nos han moldeado y han
          contribuido a nuestra singularidad como individuos.
        </p>
        <p>En Vogue tenemos tu esencial que se convertirá en tu favorito, tu infaltable por siempre Cicatrifín. Es un óleo concentrado reparador con aceite de purcelina, diseñado para regenerar los tejidos y suavizar cicatrices de manera efectiva.</p>
        <p>Recuerda siempre amarte a ti mismo y a tus cicatrices, porque son parte de lo que te hace hermosa y completa.</p>
      </section>
    </div>
  );
};
