import React from "react";
import { Link } from "react-router-dom";

export const ProductoNuevo = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };
  return (
    <div className="contenedor-productos">
      <div className="contenedor-titulo">
        <h2 className="productosNuevosh2">Nuevos Productos</h2>

        <Link
          onClick={handleScrollToTop}
          className="btn-productos"
          to={"/nuevos"}
        >
          Ver todos los productos nuevos
        </Link>
      </div>
    </div>
  );
};
