import React, { useEffect, useState } from "react";
import styles from "./Facturas.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ColumDocument } from "../components/ColumDocument";
import axios from "axios";
import { Invoice } from "../components/Invoice";
import { toast } from "react-toastify";
import { useLoading } from "../../state/context/loadingContext";
import { Spinner } from "react-bootstrap";
import { PagoDistribuidor } from "../components/PagarDistribuidor/PagoDistribuidor";
import { redondearDecimales } from "../../hooks/use-redondearDecimales";

export const FacturasPendientes = () => {
  const { user, isAuthenticated, token, userType } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [loadingGetInvoice, setLoadingGetInvoice] = useState(false);
  const [serfinsa, setSerfinsa] = useState(null);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [allInvoice, setAllInvoice] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [showPagoDistribuidor, setShowPagoDistribuidor] = useState(false);
  const location = useLocation();
  const message = location.state?.message;
  const { isLoading } = useLoading();
  const [factura, setFactura] = useState(null);
  const navigate = useNavigate();
  const [saldo, setSaldo] = useState()
  const [vales, setVales] = useState()
  const [totalFacturas, setTotalFacturas] = useState()
  const [totalIntereses, setTotalIntereses] = useState(0);

  const [isLoadings, setIsLoading] = useState(false);



  const calcularTotalIntereses = (facturas) => {
    return facturas.reduce((total, factura) => {
      const interes = parseFloat(factura.intereses.replace("$", "").trim()) || 0;
      return total + interes;
    }, 0);
  };


  const getFacturaPedidos = async (invoiceNumber) => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}/documentos?id_doc=${invoiceNumber}`, {
      headers: {
        Authorization: `Bearer ${token ?? ""}`,
      },
    });
    setFactura(response.data);
  };

  const getInvoice = async (facturaNo) => {
    try {
      setLoading(true)
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );


      setVales(Number(res.data.detalle.total_vales))

      if (res.data.detalle.lista_facturas && res.data.detalle.lista_facturas.length > 0) {
        const selectedInvoice = res.data.detalle.lista_facturas.find(invoice => invoice.factura_no === facturaNo);
        if (selectedInvoice) {

          setInvoice({
            number_invoice: selectedInvoice.factura_no,
            client: selectedInvoice.nombre,
            id_client: selectedInvoice.cliente_id,
            adress: selectedInvoice.direccion,
            date: selectedInvoice.fecha,
            expired: selectedInvoice.vence,
            detailCart: selectedInvoice.detalles_factura,
            total: selectedInvoice.total,
            saldo: selectedInvoice?.saldo,

          });
          await getFacturaPedidos(selectedInvoice.factura_no);
          setOpenInvoice(true);
        }
      }

      setLoading(false)
    } catch (error) {
      console.error(error);
      setLoading(false)
    }
  };

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate("/")
    }
  }, [])




  const handleGetData = async () => {
    let userIdentifier;
    if (userType === "DIS") {
      userIdentifier = user.cliente_id;
    }
    const resPedido = await axios.get(
      `${process.env.REACT_APP_API_URL}/pedidos/user/${userIdentifier}`,
      {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      }
    );
    setSerfinsa(resPedido?.data);
  };


  const handleScrollToTop = async (monto, tipoPago) => {
    setLoading(true);
    try {
      if (isAuthenticated) {
        let res = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-payment`,
          {
            Monto: monto,
            ConceptoPago: "Pago de cosmeticos",
            Adicionales: "DATOS ADICIONALES",
            facturaId: invoice.number_invoice,
            UrlOrigin:
              "https://vogueelsalvador.com/facturas-pendientes",
            UrlRedirect: `https://vogueelsalvador.com/finalizar-abonos/${invoice.number_invoice}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token ?? ""}`,
            },
          }
        );
        const { Satisfactorio, Datos } = res.data;
        if (Satisfactorio) {

          // prueba: window.location.href = `https://testcheckout.redserfinsa.com:8087/${Datos?.UrlPost}`;
          // produccion:window.location.href = `https://www.serfinsacheckout.com/${Datos.UrlPost}`;
          

          window.location.href = `https://www.serfinsacheckout.com/${Datos.UrlPost}`;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const handleGetInvoice = async () => {
    setLoadingGetInvoice(true);
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/clientes?id=${user.cliente_id}`,
        {
          headers: {
            Authorization: `Bearer ${token ?? ""}`,
          },
        }
      );

      const facturas = res?.data?.detalle?.lista_facturas || [];

      setAllInvoice(facturas);
      setTotalIntereses(calcularTotalIntereses(facturas)); // Guardar el total de intereses
      console.log("💰 Total de intereses:", calcularTotalIntereses(facturas));

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGetInvoice(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleGetInvoice();
      handleGetData();
      if (!isLoading && message) {
        toast.success(message);
      }

    }
  }, []);

  const formaPago = () => {
    if (userType === "DIS") {
      return user?.tipo_cre === "CONTADO" ? "Contado" : "Crédito";
    }
    return null;
  };

  console.log(allInvoice);


  const handleSeleccionarMonto = async () => {
    const selectedInvoice = allInvoice[0];

    let totalAbonos = 0
    setInvoice({
      number_invoice: selectedInvoice.factura_no,
      client: selectedInvoice.nombre,
      id_client: selectedInvoice.cliente_id,
      adress: selectedInvoice.direccion,
      date: selectedInvoice.fecha,
      expired: selectedInvoice.vence,
      detailCart: selectedInvoice.detalles_factura,
      total: selectedInvoice.total,
      saldo: selectedInvoice?.saldo,
      balance: selectedInvoice.total - totalAbonos
    });
    setShowPagoDistribuidor(true);
  }

  const handleClosePagoDistribuidor = () => {
    setShowPagoDistribuidor(false);
  };



  const hasInvoice = allInvoice && allInvoice.length > 0;
  const hasInterest = allInvoice.some((invoice) => parseFloat(invoice.intereses) > 0);
  // console.log("invoice", allInvoice);

  // console.log("saldo", saldo);


  const handleGetSaldo = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      if (!token) throw new Error("❌ Autenticación requerida - Token no proporcionado");

      const safeParseNumber = (value) => {
        if (typeof value === "string") {
          value = value.replace("$", "").trim();
        }
        const parsed = parseFloat(value);
        return isNaN(parsed) ? 0 : parsed;
      };

      const calculateTotal = (facturas, key) =>
        facturas.reduce((acc, f) => acc + safeParseNumber(f[key]), 0);

      // console.log("⏳ Iniciando solicitud para obtener abonos...");
      const apiConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      // 🔹 Obtener los abonos almacenados
      const { data: abonosData } = await axios.get(
        `${process.env.REACT_APP_API_URL}/pedidos/find/abonos`,
        apiConfig
      );
      // console.log("📥 Abonos obtenidos de la API:", abonosData);

      // 🔹 Obtener y ordenar las facturas actuales (allInvoice)
      const facturasActuales = [...allInvoice].sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
      // console.log("📑 Facturas ordenadas:", facturasActuales);

      // 🔹 Separar facturas de meses anteriores y del mes actual
      const hoy = new Date();
      const mesActual = hoy.getMonth();
      const añoActual = hoy.getFullYear();

      const facturasViejas = facturasActuales.filter((f) => {
        const fechaFactura = new Date(f.fecha);
        return fechaFactura.getFullYear() < añoActual || fechaFactura.getMonth() < mesActual;
      });

      const facturasMesActual = facturasActuales.filter((f) => {
        const fechaFactura = new Date(f.fecha);
        return fechaFactura.getFullYear() === añoActual && fechaFactura.getMonth() === mesActual;
      });

      // console.log("📆 Facturas de meses anteriores:", facturasViejas);
      // console.log("📅 Facturas del mes actual:", facturasMesActual);

      // 🔹 Calcular los totales
      const totalFacturasCalculado = safeParseNumber(
        calculateTotal(facturasViejas.length ? facturasViejas : facturasMesActual, "saldo")
      );

      const totalIntereses = safeParseNumber(
        calculateTotal([...facturasViejas, ...facturasMesActual], "intereses")
      );

      const totalConIntereses = totalFacturasCalculado + totalIntereses;
      setTotalFacturas(totalConIntereses);

      const facturaInicial = facturasViejas.length ? facturasViejas[0].factura_no : facturasMesActual[0]?.factura_no;
      const facturaFinal = facturasViejas.length
        ? facturasViejas[facturasViejas.length - 1].factura_no
        : facturasMesActual[facturasMesActual.length - 1]?.factura_no;

      // console.log("📌 Factura Inicial:", facturaInicial);
      // console.log("📌 Factura Final:", facturaFinal);
      // console.log("💰 Total con intereses:", totalConIntereses);

      // 🔹 No hay abonos previos: crear uno nuevo
      if (!abonosData?.length) {
        console.log("⚠️ No se encontraron abonos previos, creando uno nuevo...");

        const payload = {
          total_guardado: totalFacturasCalculado - safeParseNumber(vales) + totalIntereses,
          total_abonado: "0.00",
          factura_inicial: facturaInicial,
          factura_final: facturaFinal,
          log_facturas: facturasViejas.length ? facturasViejas : facturasMesActual, // Guardar las facturas usadas
        };

        console.log("📤 Enviando nuevo abono:", payload);
        const { data: newData } = await axios.post(
          `${process.env.REACT_APP_API_URL}/pedidos/abonos`,
          payload,
          apiConfig
        );

        console.log("✅ Nuevo abono creado exitosamente:", newData);
        setSaldo(safeParseNumber(newData.total_guardado));
        return;
      }

      // 🔹 Hay un abono previo almacenado
      const stored = abonosData[0];
      // console.log("📂 Abono almacenado encontrado:", stored);

      const storedFactura = stored.log_facturas.find(
        (factura) => factura.factura_no === facturaInicial
      );

      const saldoStored = safeParseNumber(storedFactura?.saldo || 0);
      const saldoAllInvoice = safeParseNumber(
        facturasActuales.find((factura) => factura.factura_no === facturaInicial)?.saldo || 0
      );

      // console.log("📌 Comparación de Facturas:");
      // console.log("🔹 Tipo stored.factura_inicial:", typeof stored.factura_inicial);
      // console.log("🔹 Tipo facturaInicial:", typeof facturaInicial);
      // console.log("🔹 Factura Inicial (allInvoice):", facturaInicial);
      // console.log("🔹 Factura Inicial (stored):", stored.factura_inicial);
      // console.log("🔹 Saldo en stored:", saldoStored);
      // console.log("🔹 Saldo en allInvoice:", saldoAllInvoice);

      // 🔍 Corrección: Comparación flexible de tipos
      const shouldUpdate =
        Number(stored.factura_inicial) !== Number(facturaInicial) ||
        Math.abs(saldoStored - saldoAllInvoice) > 0.01;

      if (shouldUpdate) {
        // 🚀 Factura inicial cambió o saldo es diferente: Actualizar total_guardado
        // console.log("⚡ Cambio detectado. Actualizando abono...");

        const payload = {
          id: stored._id,
          total_guardado: totalFacturasCalculado - safeParseNumber(vales) + totalIntereses,
          total_abonado: "0.00",
          factura_inicial: facturaInicial,
          factura_final: facturaFinal,
          log_facturas: facturasViejas.length ? facturasViejas : facturasMesActual,
        };

        console.log("📤 Enviando actualización de abono:", payload);
        const { data: updatedData } = await axios.put(
          `${process.env.REACT_APP_API_URL}/pedidos/update-abono`,
          payload,
          apiConfig
        );

        // console.log("✅ Abono actualizado con éxito:", updatedData);
        setSaldo(safeParseNumber(updatedData.total_guardado));
      } else {
        // ✅ Factura inicial y saldo coinciden: No actualizar total_guardado, solo calcular saldo restante
        const totalGuardado = safeParseNumber(stored.total_guardado);
        const totalAbonado = safeParseNumber(stored.total_abonado);
        const nuevoSaldo = totalGuardado;

        // console.log("✅ Sin cambios significativos, no se actualiza total_guardado.");
        // console.log("💰 Total Guardado:", totalGuardado);
        // console.log("💸 Total Abonado:", totalAbonado);
        // console.log("📉 Nuevo Saldo Calculado:", nuevoSaldo);

        setSaldo(Math.max(nuevoSaldo, 0));
      }
    } catch (error) {
      console.error("❌ Error en handleGetSaldo:", error);
      setSaldo(0);
    } finally {
      setIsLoading(false);
      // console.log("🔄 Proceso finalizado.");
    }
  };

  useEffect(() => {
    if (allInvoice.length > 0) {
      handleGetSaldo()
    }
  }, [allInvoice.length > 0])

  return (
    <>
      {
        isAuthenticated === true && (

          <div className={styles.contenedorPrincipal}>
            <h1 className={styles.titulo}>Hola, {user.nombres.split(" ")[0]}</h1>
            <div className={styles.contenedor}>
              <p className={styles.parrafos}>
                COD. {user.cliente_id}
                <br />
                DUI: {user.dui}
              </p>
            </div>
            <div className={styles.contenedorDatos}>
              <div className={styles.datos}>
                <Link to={"/datos-generales-distribuidor"} className={styles.parrafo}>
                  Datos generales
                </Link>
                <Link to={""} className={styles.parrafo}>
                  Facturas pendientes
                </Link>
                <Link to={"/estado-cuenta"} className={styles.parrafo}>
                  Estado de cuenta
                </Link>
                {allInvoice.length > 0 && formaPago() === "Crédito" && (
                  <>  <button className={styles.realizarPago} onClick={handleSeleccionarMonto} >
                    {(loadingGetInvoice || loading) ? (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className={styles.miSpinner}
                      >
                        <span className="visually-hidden">Cargando...</span>
                      </Spinner>
                    ) : (
                      "Realizar abono"
                    )}
                  </button>
                    <p className={styles.parrafoDiscleimer}>*Los abonos realizados se procesarán en un maximo de  48 horas.</p>
                  </>

                )}
              </div>
              <div className={styles.contenedorTabla}>
                <table className={styles.Tabla}>
                  <thead>
                    <tr>
                      <th>No. factura</th>
                      <th>Fecha</th>
                      <th>Vencimiento</th>
                      <th>Total factura</th>
                      <th>Saldo factura</th>
                      <th>Interés</th>
                    </tr>
                  </thead>
                  {loadingGetInvoice ? (
                    <p className={styles.loadingMessage}>Cargando...</p>
                  ) : allInvoice.length > 0 ? (
                    allInvoice.map((doc, index) => (
                      <>
                        <ColumDocument
                          key={index}
                          allInvoice={doc}
                          getInvoice={getInvoice}
                        />

                      </>

                    ))
                  ) : (
                    <h1 className={styles.messageNoFoundData}>
                      No se encontró facturas pendientes
                    </h1>
                  )}
                </table>
                {hasInterest && hasInvoice && (

                  <p className={styles.textoAdvertencia}>El interés de tu factura varía diariamente.</p>
                )
                }
              </div>
            </div>

            {openInvoice && invoice && (
              <Invoice
                saldosFactura={invoice}
                invoice={factura}
                setOpenInvoice={setOpenInvoice}
                saldo={invoice.total}
                intereses={allInvoice.find(item => item.factura_no === invoice.number_invoice)?.intereses}
                fecha={allInvoice.find(item => item.factura_no === invoice.number_invoice)?.fecha_vencimiento}
              />
            )}
            {showPagoDistribuidor && invoice && (
              <PagoDistribuidor
                // total={allInvoice[0].saldo}
                total={invoice.total}
                handleScrollToTop={handleScrollToTop}
                handleClose={handleClosePagoDistribuidor}
                // saldo={allInvoice.find(item => item.factura_no === invoice.number_invoice)?.saldo}
                saldo={saldo}
                balance={redondearDecimales(invoice.balance, 2)}
              />
            )}
          </div>
        )
      }
    </>
  );
};
