import React, { useEffect, useState } from "react";
import styles from "../Perfil/Pedidos.module.css";
import { ProductosRelacionados } from "../ProductosRelacionados";
import axios from "axios";
import { useSelector } from "react-redux";
import { CardOrderPaid } from "./cardOrderPaid";
import { Cargando } from "../../../../homeComun/components/Cargando/Cargando";
import { InfoNotAvailable } from "../../../InfoNotAvailable";
import { redondearDecimales } from "../../../../hooks/use-redondearDecimales";


export const Pedidos = () => {
  const [data, setData] = useState([]);
  const [dataAllPedido, setDataAllPedido] = useState([]);
  const [loading, setLoading] = useState(true);

  const { token, user, userType } = useSelector((state) => state.auth);
  const isDistributor = userType === "DIS";

  const handleGetData = async () => {
    const resPedido = await axios.get(
      `${process.env.REACT_APP_API_URL}/pedidos/finalizados/${isDistributor ? user.cliente_id : user.email}`,
      {
        headers: {
          Authorization: `Bearer ${token ?? ""}`,
        },
      }
    );
    setDataAllPedido(resPedido?.data);
  };

  const getData = async () => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos?page=1&pageSize=50`
      );
      setData(response.data.detalle);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]); // Asegúrate de manejar el error adecuadamente
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    handleGetData();
  }, []);

  if (loading) {
    return <Cargando />;
  }

  return (
    <div className={styles.cajaPadrePedidos}>
      <h1 className={styles.resumen}>Resumen de la compra</h1>
      <div className={styles.ordersWrapper}>
        {dataAllPedido.length > 0 ? (
          dataAllPedido.map((order, index) => {
            // Lógica para ajustar el precio de envío según el código
            let totalEnvio = 0;
            const { codigoAdmon } = order;

            switch (codigoAdmon) {
              case "CARADMI027":
                totalEnvio = 2.99;
                break;
              case "CARADMI021":
                totalEnvio = 1.50;
                break;
              case "CARADMI011":
                totalEnvio = 0.00;
                break;
              default:
                totalEnvio = parseFloat(order?.detailPayment?.totalEnvio) || 0;
            }

            const totalPaid = parseFloat(order?.detailPayment?.totalPaid) || 0;
            const limpioDecimales = redondearDecimales(totalEnvio, 2)
            const totalEnvioLimpio = redondearDecimales(totalPaid + totalEnvio, 2)
            return (
              <div key={index}>
                {order?.carrito.length > 0 &&
                  order?.carrito?.map((pedido) => (
                    <CardOrderPaid key={pedido.producto_id} pedido={pedido} />
                  ))}
                <div className={styles.contenedorProductoTotal}>
                  <div className={styles.contenedorTotal}>
                    <p className={styles.total}>Envío:</p>
                    <p className={styles.precioTotal}>${limpioDecimales}</p>
                  </div>
                  <div className={styles.contenedorTotal}>
                    <p className={styles.total}>TOTAL:</p>
                    {/* Sumar el total pagado y el envío, luego formatear con dos decimales */}
                    <p className={styles.precioTotal}>${totalEnvioLimpio}</p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <InfoNotAvailable message="Aún no tienes compras realizadas" />
        )}
      </div>

      {data && data.length > 0 && <ProductosRelacionados data={data[30]} />}
    </div>
  );
};
