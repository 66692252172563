import React from "react";
import styles from "../../Perfil/Pedidos.module.css";

export const CardOrderPaid = ({ pedido }) => {
  const { nameItem, quantity, price, imagen } = pedido;

  return (
    <div className={styles.contenedorProductos}>
      <div className={styles.productos}>
        <div className={styles.contenedorImg}>
          <img
            className={styles.img}
            src={`${process.env.REACT_APP_URL_IMG}/${imagen?.[0]}`}
            // src={`${itemId}.png`}
            alt="producto comprado"
          />
          <p className={styles.desccipcion}>{nameItem}</p>
        </div>

        <div className={styles.contenedorCantidad}>
          <p className={styles.cantidad}>x{quantity}</p>
          <p className={styles.precio}>${price}</p>
        </div>
      </div>

      <div className={styles.lineas}></div>
    </div>
  );
};
