import * as React from "react";
const SvgCheckVerde = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#2ABB53"
      d="m5 8 2 2 4-4m3.667 2A6.667 6.667 0 1 1 1.333 8a6.667 6.667 0 0 1 13.334 0Z"
    />
  </svg>
);
export default SvgCheckVerde;
