import React from "react";
import styles from "./paginacion.module.css";
import classNames from "classnames";

export const Paginacion = ({
  currentPage,
  totalPages,
  onPageChange,
  isLoading,
}) => {


  const renderPaginationNumbers = () => {
    const screenSmall = window.innerWidth < 608;
    const maxPagesToShow = screenSmall ? 5 : 10;
    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    // Ajuste en caso de que no haya suficientes páginas al final
    if (totalPages - currentPage < Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={classNames(styles.pageNumber, {
            [styles.currentPage]: i === Number(currentPage),
          })}
          onClick={() => {
            onPageChange(i);

          }}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <div className={styles.contenedor}>
      <button
        onClick={() => {
          onPageChange(Math.max(1, currentPage - 1));

        }}
        disabled={currentPage === 1 || isLoading}
        className={styles.btnAumentar}
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="12.5" r="12.5" fill="#DD7F93" />
          <path d="M6 12.5L15 5.13878L15 19.8612L6 12.5Z" fill="white" />
        </svg>
      </button>
      {renderPaginationNumbers()}
      <button
        className={styles.btnAumentar}
        onClick={() => {
          onPageChange(Math.min(totalPages, currentPage + 1));
          // Desplazar suavemente hacia arriba
        }}
        disabled={currentPage === totalPages || isLoading}
      >
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12.5" cy="12.5" r="12.5" fill="#DD7F93" />
          <path d="M19 12.5L10 5.13878L10 19.8612L19 12.5Z" fill="white" />
        </svg>
      </button>
    </div>
  );
};
