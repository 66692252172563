import React, { useState } from "react";
import styles from "../../FacturasPendientes/Facturas.module.css";
import { Spinner } from "react-bootstrap";

export const ColumDocument = ({ allInvoice, getInvoice }) => {
  const [loading, setloading] = useState(false);
  const { factura_no, fecha, fecha_vencimiento, saldo, total, fecha_interes, intereses } =
    allInvoice;

  const handleGetInvoice = async () => {
    setloading(true);
    await getInvoice(factura_no)
    setloading(false);
  }
  return (
    <tbody>
      <tr className={styles.tableValues}>
        <td>
          <button
            className={styles.buttonIdInvoice}
            onClick={() => handleGetInvoice()}
          >
            {loading ? (
              <Spinner animation="border" role="status" size='sm'>
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              `${factura_no}`
            )}
          </button>
        </td>
        <td>{fecha}</td>
        <td>{fecha_vencimiento}</td>
        <td>{(Number(total) + intereses).toFixed(2)}</td>
        <td>{(Number(saldo) + intereses).toFixed(2)}</td>
        <td>{intereses}</td>
      </tr>
    </tbody>
  );
};
