import React from "react";
import { Link } from "react-router-dom";
import banner from "../../../assets/img/py5.png";
import banner2 from "../../../assets/img/PY4.png";
import banner3 from "../../../assets/img/PY3.png";
import banner4 from "../../../assets/img/PY7.png";
import banner5 from "../../../assets/img/PV2.png";
import banner6 from "../../../assets/img/PY1.png";
import styles from "../../../css/Bannner.module.css";

export const Baners = () => {
  const images = [banner, banner2, banner4, banner3, banner6, banner5];
  const texts = [
    "Higiene y cuidado",
    "Capilar",
    "Perfumería",
    "Bienestar",
    "Maquillaje",
    "Facial",
  ];
  const paths = [
    "/tienda-linea-cliente/05",
    "/tienda-linea-cliente/01",
    "/tienda-linea-cliente/02",
    "/tienda-linea-cliente/07",
    "/tienda-linea-cliente/06",
    "/tienda-linea-cliente/04",
  ];

  return (
    <div className={styles.contenedor}>
      {images.map((image, index) => (
        <div className={styles.banner} key={index}>
          <img className={styles.imagen} src={image} alt="" />
          <Link className={styles.link} to={paths[index]}>
            {texts[index]}
          </Link>
        </div>
      ))}
    </div>
  );
};
