import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shoppingCart: []
};

export const shoppingCart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    shoppingCartStart: (state, action) => {
      state.shoppingCart = action.payload;
    }
  },
});

// Export actions
export const { shoppingCartStart } =
  shoppingCart.actions;

// Export reducer
export default shoppingCart.reducer;
