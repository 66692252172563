import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css"; // Importa el CSS necesario para el modal
import styles from "./Comoutilizar.module.css";
import imgPrueba from "../../../assets/img/03Copia de Tutorial Oleos.jpg";
import imgPrueba2 from "../../../assets/img/08Copia de Tutorial Baño de color.jpg";
import imgPrueba3 from "../../../assets/img/06Copia de Tutorial Color Magic.jpg";

export const ComoUtilizarProductos = () => {
  const [isOpen, setOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState("");

  const videos = [
    {
      id: "118FeIjrvuNWFfLNGo-LEds-M45hVoVKk",
      title: "Rutina para hidratar tu cabello",
      imagen: imgPrueba
    },
    {
      id: "1GVqbPLLxpKLmQjE180v64UUDixE41txB",
      title: "Intensifica el color de tu cabello",
      imagen: imgPrueba2

    },
    {
      id: "1b4InIcD5ZKkmfepZU7dGkBn8S7AkUei9",
      title: "Descubre el poder de color magic",
      imagen: imgPrueba3
    },
  ];

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };

  const openModal = (videoId) => {
    setCurrentVideo(videoId);
    setOpen(true);
  };
  return (
    <>
      <h2 className={styles.titulo}>Conoce cómo utilizar nuestros productos</h2>
      <div className={styles.contenedorBtn}>
        <Link className={styles.btn} onClick={handleScrollToTop} to={"/videos"}>
          Ver todos los videos
        </Link>
      </div>
      <ModalVideo
        channel="custom"
        autoplay
        isOpen={isOpen}
        url={`https://drive.google.com/file/d/${currentVideo}/preview`}
        onClose={() => setOpen(false)}
      />
      <div className={styles.contenedor}>
        {videos.map((video) => (
          <div
            key={video.id}
            className={styles.contenedorVideo}
            onClick={() => openModal(video.id)}
          >
            <img
              src={video.imagen}
              alt={video.title}
            />
            <h2 className={styles.tituloVideo}>{video.title}</h2>
          </div>
        ))}
      </div>
    </>
  );
};
