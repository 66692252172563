import React, { useEffect, useState } from "react";
import styles from "../../../css/Reseñas.module.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


export const Reseñas = ({ data, conoce }) => {
  const [valoracion, setValoracion] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const productoId = data.producto_id;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();



  const onSubmit = async (dataForm) => {
    setIsSubmitting(true);
    const payload = {
      productoId: productoId,
      texto: dataForm.mensaje,
      calificacion: valoracion,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/productos/create-review`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Reseña creada exitosamente");
        reset();
        setValoracion(0);
        getReviews();
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error("Para realizar una reseña, es necesario iniciar sesión");
        } else if (error.response.status === 500) {
          toast.error("Ya has creado una reseña para este producto.");
        } else {
          toast.error(
            "Ocurrió un error al enviar la reseña. Por favor, inténtalo de nuevo más tarde."
          );
        }
      } else {
        toast.error(
          "Ocurrió un error al enviar la reseña. Por favor, inténtalo de nuevo más tarde."
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };


  const getReviews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/productos/reviews/${productoId}`
      );
      setReviews(response.data.reviews[0].reviews);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <div className={styles.reviewsContainer}>
      <h3 className={styles.titulo}>Reseñas</h3>
      <div className={styles.contenedorGeneral}>
        {isLoading ? (
          <p>Cargando reseñas...</p>
        ) : (
          <div className={styles.contenedorReseñas}>
            {reviews.length > 0 ? (
              reviews.slice(0,5).map((review, index) => (
                <div key={index} className={styles.reseñas}>
                  <div className={styles.contenedorImg}>
                    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m 8 1 c -1.65625 0 -3 1.34375 -3 3 s 1.34375 3 3 3 s 3 -1.34375 3 -3 s -1.34375 -3 -3 -3 z m -1.5 7 c -2.492188 0 -4.5 2.007812 -4.5 4.5 v 0.5 c 0 1.109375 0.890625 2 2 2 h 8 c 1.109375 0 2 -0.890625 2 -2 v -0.5 c 0 -2.492188 -2.007812 -4.5 -4.5 -4.5 z m 0 0" fill="#2e3436"></path> </g></svg>
                  </div>
                  <div className={styles.comentarios}>
                    <div className={styles.comentUser}>
                      <div className={styles.name}>{review.nombre}</div>{" "}

                    </div>
                    <p className={styles.parrafo}>{review.texto}</p>
                  </div>
                </div>
              ))
            ) : (
              <p style={{ margin: "auto", marginLeft: "2rem" }}>
                No hay reseñas para este producto aún.
              </p>
            )}
          </div>
        )}

        <form className={styles.formulario} onSubmit={handleSubmit(onSubmit)}>
          <h3 className={styles.tituloFormulario}>Agregar una reseña</h3>

          <div className={styles.inputContenedor}>

            <textarea
              className={styles.textarea}
              placeholder="Mensaje"
              {...register("mensaje", { required: "Este campo es requerido" })}
            ></textarea>
            {errors.mensaje && (
              <p className={styles.error}>{errors.mensaje.message}</p>
            )}
          </div>
          <button className={styles.enviar} type="submit" disabled={isSubmitting}>
            {isSubmitting ? "Publicando..." : "Publicar"}
          </button>
        </form>
      </div>
    </div>
  );
};
