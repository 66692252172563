// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Preguntas_contenedor__HlIpQ {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  margin-top: 80px;\n  margin-bottom: 80px;\n}\n.Preguntas_titulo__5AA-s {\n  color: #9c182f;\n  text-align: center;\n  font-family: \"Lato\";\n  font-size: 50px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  margin-top: 40px;\n  margin-left: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/HomeDistribuidor/components/Footer/Preguntas.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".contenedor {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  margin-top: 80px;\n  margin-bottom: 80px;\n}\n.titulo {\n  color: #9c182f;\n  text-align: center;\n  font-family: \"Lato\";\n  font-size: 50px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  margin-top: 40px;\n  margin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contenedor": "Preguntas_contenedor__HlIpQ",
	"titulo": "Preguntas_titulo__5AA-s"
};
export default ___CSS_LOADER_EXPORT___;
