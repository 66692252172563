import React from "react";
import styles from "./SectionDescription.module.css";

export const SectionDescriptionCremaSolar = () => {
  return (
    <div className={styles.contenedorDescripcion}>
      <section className={styles.decripcionParrafo}>
        <h1 className={styles.title}>El secreto para proteger tu piel</h1>
        <p>
          Uno de los elementos esenciales para el cuidado de la piel: El
          protector solar
        </p>
        <h3>¿Por qué deberíamos usar protector solar regularmente?</h3>
        <p>
          Los rayos UVB Y UVA pueden causar daños graves a nuestra piel. Es
          crucial entender la importancia de proteger nuestra piel de estos
          dañinos rayos.
        </p>
        <p>
          Los rayos UVB son responsables principalmente de causar quemaduras
          solares en la piel, cáncer de piel.
        </p>
        <p>
          Rayos UVA penetran más profundamente en la piel que los UVB y pueden
          causar daño a largo plazo, como envejecimiento prematuro de la piel y
          contribuir al riesgo de cáncer de piel.
        </p>
        <p>
          La luz azul de las pantallas también conocida como luz de alta energía
          visible (HEV): Puede penetrar en las capas más profundas de la piel,
          dañando el colágeno y la elastina, provocando arrugas, manchas y
          flacidez.
        </p>
        <p>
          La aplicación adecuada del protector solar es clave para garantizar
          una protección óptima. Recuerda aplicar suficiente producto para
          cubrir toda la piel expuesta, incluyendo áreas olvidadas como las
          orejas y el cuero cabelludo. No olvides aplicar protector solar cada
          tres horas.
        </p>
        <p>
          Así que prepárate para descubrir los secretos detrás de una piel
          radiante y saludable, usando protector solar a diario
        </p>
      </section>
    </div>
  );
};
