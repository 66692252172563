import React, { Fragment, useEffect, useState } from "react";
import registro from "../../../assets/img/registroCliente.png";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./Registro.module.css";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";


export const ClienteRegistro = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Configuración de useForm
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger, // Para forzar la validación manualmente
    reset,
    watch
  } = useForm({
    mode: "onChange", // Las validaciones se activarán en cuanto haya algún cambio en los inputs
  });

  const password = watch("password");

  // Forzar validación de todos los campos al cargar el componente
  useEffect(() => {
    trigger(); // Esto dispara todas las validaciones desde el principio
  }, [trigger]);

  const registrarUsuario = async (data) => {
    setLoading(true);
    try {
      const dataConTipoProceso = {
        ...data,
        tipo: "CON",
        proceso: "R",
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        dataConTipoProceso,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Por favor revisar su correo para validar ");
        reset();
        setTimeout(() => {
          handleScrollToTop();
          navigate("/login-cliente");
        }, 4000);
      }

    } catch (error) {
      const mensajeError = error.response?.data?.msg || "Algo salió mal";
      // console.log("este es el error", error);

      toast.error("Error al registro: " + mensajeError);
    } finally {
      setLoading(false);
    }
  };

  const camposFormulario = [
    {
      nombre: "nombres",
      label: "Nombre:",
      tipo: "text",
      validaciones: {
        required: { value: true, message: "Nombre es requerido" },
        minLength: {
          value: 4,
          message: "Nombre debe ser mayor a 3 caracteres",
        },
        maxLength: {
          value: 20,
          message: "Nombre debe tener máximo 20 caracteres",
        },
      },
    },
    {
      nombre: "apellidos",
      label: "Apellidos:",
      tipo: "text",
      validaciones: {
        required: { value: true, message: "Apellido es requerido" },
        minLength: {
          value: 4,
          message: "Apellido debe ser mayor a 3 caracteres",
        },
        maxLength: {
          value: 20,
          message: "Apellido debe tener máximo 20 caracteres",
        },
        validate: {
          maxTwoWords: (v) =>
            v.split(" ").filter(Boolean).length <= 3 ||
            "Debe ingresar como máximo tres apellidos",
        },
      },
    },
    {
      nombre: "username",
      label: "Correo del usuario:",
      tipo: "email",
      validaciones: {
        required: {
          value: true,
          message: "Correo de usuario es requerido",
        },
        pattern: {
          value: /^([a-zA-Z0-9._-]+)@([a-zA-Z0-9-]+)\.([a-zA-Z]{2,4})(\.[a-zA-Z]{2,4})?$/,
          message: "El correo no es válido",
        },
      },
    },
    {
      nombre: "password",
      label: "Contraseña:",
      tipo: showPassword ? "text" : "password",
      validaciones: {
        required: { value: true, message: "Contraseña es requerida" },
        minLength: { value: 8, message: "Al menos 8 caracteres" },
        validate: {
          containsUpperCase: (value) =>
            /[A-Z]/.test(value) || "Una letra mayúscula",
          containsLowerCase: (value) =>
            /[a-z]/.test(value) || "Una letra minúscula",
          containsNumber: (value) =>
            /\d/.test(value) || "Un número",
          containsSpecialChar: (value) =>
            /[@#$%&]/.test(value) ||
            "Uno de estos caracteres especiales: @, #, $, %, &",
        },
      },
    },
    {
      nombre: "repeatPassword",
      label: "Repetir contraseña:",
      tipo: showRepeatPassword ? "text" : "password", // Cambia entre 'text' y 'password' según el estado
      validaciones: {
        required: {
          value: true,
          message: "Confirmar Contraseña",
        },
        validate: {
          matchPassword: (value) =>
            value === password || "Las contraseñas no coinciden",
        },
      },
    },
    {
      nombre: "telefono",
      label: "Teléfono:",
      tipo: "tel",
      validaciones: {
        required: { value: true, message: "Teléfono es requerido" },
        minLength: { value: 8, message: "Teléfono no válido" },
        maxLength: { value: 12, message: "Teléfono no válido" },
      },
    },
    {
      nombre: "direccion",
      label: "Dirección:",
      tipo: "text",
      validaciones: {
        required: { value: true, message: "Dirección es requerida" },
        pattern: { value: "[a-z]{1,15}", message: "Dirección no válida" },
      },
    },
  ];

  const renderizarCampos = camposFormulario.map((campo) => (
    <div className={styles.contenedorPrincipal} key={campo.nombre}>
      <div className={styles.contenedorInputss}>
        <label htmlFor={campo.nombre} className={styles.label}></label>
        <div className={styles.contenedorInputsContraseña}>
          <input
            className={styles.inputs}
            type={campo.tipo}
            placeholder={campo.label}
            {...register(campo.nombre, campo.validaciones)}
          />
          {/* Icono de ojo para mostrar/ocultar contraseña */}
          {(campo.nombre === "password" || campo.nombre === "repeatPassword") && (
            <span
              className={styles.eyeIcon}
              onClick={() =>
                campo.nombre === "password"
                  ? setShowPassword(!showPassword)
                  : setShowRepeatPassword(!showRepeatPassword)
              }
            >
              {campo.nombre === "password" && (showPassword ? <FaEyeSlash /> : <FaEye />)}
              {campo.nombre === "repeatPassword" && (showRepeatPassword ? <FaEyeSlash /> : <FaEye />)}
            </span>
          )}

        </div>

      </div>

      {errors[campo.nombre] && (
        <ul className={styles.errorList}>
          {errors[campo.nombre].message && (
            <li className={styles.errorItem}>
              {errors[campo.nombre].message}
            </li>
          )}
          {errors[campo.nombre].types?.containsUpperCase && (
            <li className={styles.errorItem}>
              {errors[campo.nombre].types.containsUpperCase}
            </li>
          )}
          {errors[campo.nombre].types?.containsLowerCase && (
            <li className={styles.errorItem}>
              {errors[campo.nombre].types.containsLowerCase}
            </li>
          )}
          {errors[campo.nombre].types?.containsNumber && (
            <li className={styles.errorItem}>
              {errors[campo.nombre].types.containsNumber}
            </li>
          )}
          {errors[campo.nombre].types?.containsSpecialChar && (
            <li className={styles.errorItem}>
              {errors[campo.nombre].types.containsSpecialChar}
            </li>
          )}
        </ul>
      )}
      {campo.nombre === "password" && (
        <ul className={styles.requisitosContraseña}>
          <li>Al menos 8 caracteres</li>
          <li>Una letra mayúscula</li>
          <li>Una letra minúscula</li>
          <li>Un número</li>
          <li>Uno de estos caracteres especiales: @, #, $, %, &</li>
        </ul>
      )}
    </div>
  ));

  return (
    <>
      <div className={styles.contenedor}>
        <div className={styles.contenedorImg}>
          <img className={styles.img} src={registro} alt="" />
        </div>

        <form
          className={styles.formularioContenedor}
          onSubmit={handleSubmit(registrarUsuario)}
        >
          <h2 className={styles.titulo}>Realza tu belleza con Vogue</h2>
          {renderizarCampos}
          <div className={loading ? styles.loadingScreen : styles.loadingScreenOff} />
          <div className={styles.contenedorBtn}>
            <button
              type="submit"
              className={styles.boton}
              disabled={loading ? true : false}
              onClick={handleScrollToTop}
            >
              {loading ? (
                <Spinner animation="border" role="status" size="md" className={styles.miSpinner}>
                  <span className="visually-hidden">Cargando...</span>
                </Spinner>
              ) : (
                "Inscribirme"
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
