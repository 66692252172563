import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/slices/authSlice";
import styles from "./NavHomeCliente.module.css";
import { Sidebar } from "../../../homeComun/components/Sidebar/Sidebar";
import SvgSidebar from "../../../assets/svg/SvgSidebar";
import LogoVogue from "../../../assets/svg/carritio";
import SvgBuscador from "../../../assets/svg/buscadorSvg";
import SvgClosedButtonSearch from "../../../assets/svg/SvgClosedButtonSearch";
import SvgCarrito from "../../../assets/svg/SvgCarrito";
import { LuUserCircle2 } from "react-icons/lu";
import { useBorrador } from "../../../state/context/borradorContext";
import { productos } from "../../../assets/utils/Productos";
import useWindowSize from "../../../homeComun/components/Nav/useWindowSize";
import { Spinner } from "react-bootstrap";

export const NavHomeCliente = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSearch, setShowSearch] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const { userType } = useSelector((state) => state.auth);
  const [suggestions, setSuggestions] = useState([]);
  const handleClose = () => setShowSidebar(false);
  const handleShow = () => setShowSidebar(true);

  const nombreUsuario = user.nombres;
  const nombreFormateado =
    nombreUsuario.charAt(0).toUpperCase() +
    nombreUsuario.slice(1).toLowerCase();
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Desplaza suavemente hacia arriba
  };
  const { borrador, loadingBorrador } = useBorrador();

  const inputRef = useRef(null); // Referencia al input
  const wrapperRef = useRef(null);
  const size = useWindowSize();

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 0) {
      const filteredSuggestions = productos.filter((product) => {
        const productKeywords = product.keywords
          .toLowerCase()
          .split(",")
          .map((kw) => kw.trim());

        // Excluir productos de "118" si el userType es "CON"
        if (userType === "CON" && (product.marca_id === "118" || product.linea_id === "118")) {
          return false;
        }

        return productKeywords.some((kw) => kw.includes(value.toLowerCase()));
      });
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleKeyDown = (e) => {
    // Verifica si la tecla presionada es Enter
    if (e.key === "Enter") {
      buscarProductos();
      setSuggestions([]);
    }
  };

  const buscarProductos = (productoId) => {
    setSuggestions([]);
    if (productoId) {
      navigate(`/detalle/${productoId}`);
    } else {
      const productosFiltrados = productos.filter((producto) =>
        producto.descripcion_prod.toLowerCase().includes(query.toLowerCase())
      );

      const productosSimplificados = productosFiltrados.map((producto) => ({
        categoria: producto.categoria,
        descripcion_sublinea: producto.descripcion_sublinea,
        descripcion_corta: producto.descripcion_corta,
      }));

      const productosJSON = JSON.stringify(productosSimplificados);

      navigate(
        `/resultados?query=${query}&productos=${encodeURIComponent(
          productosJSON
        )}`
      );
    }
    setQuery("");
  };



  const handleClick = (product) => {
    setQuery(product.descripcion_corta);
    setSuggestions([]);
    buscarProductos(product.producto_id);
  };

  const handleBlur = (e) => {
    setTimeout(() => {
      if (!wrapperRef.current.contains(document.activeElement)) {
        setSuggestions([]);
      }
    }, 100);
  };

  const handleOutsideClick = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setSuggestions([]);
    }
  };



  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/rol-registro");
  };

  const handleCartClick = async () => {
    setLoading(true);
    if (borrador) {
      navigate("/pago-pendiente");
    } else {
      navigate("/home-cliente/carrito-cliente");
    }
    setLoading(false);
  };

  return (
    <header className={styles.headerWrapperClient}>
      <nav className={styles.navWrapperClient}>
        <section className={styles.navWrapperLeftLogosClient}>
          {/* sidebar */}
          <Sidebar
            showSidebar={showSidebar}
            handleClose={handleClose}
            setShowSidebar={setShowSidebar}
          />
          <button onClick={handleShow} className={styles.sidebarButton}>
            <SvgSidebar />
          </button>

          {/* Logo  */}
          <Link onClick={handleScrollToTop} to={"/"}>
            <LogoVogue className={styles.logotipoClient} />
          </Link>
        </section>

        {/* Buscador  */}
        {size.width < 750 ? (
          <div
            className={styles.searchWrapperResponsive}
            ref={wrapperRef}
            style={{
              display: showSearch ? "flex" : "none",
            }}
          >
            <button
              className={styles.searchIconWrapperResponsive}
              onClick={() => buscarProductos(null)}
            >
              <SvgBuscador />
            </button>

            <div className={styles.sectionInput}>
              <input
                type="text"
                placeholder="Buscar"
                className={styles.searchInputResponsive}
                ref={inputRef}
                value={query}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
              />
              {suggestions.length > 0 && (
                <ul
                  className={
                    userType === "CON"
                      ? styles.desplegableClienteResponsive
                      : styles.styles.suggestionsList
                  }
                >
                  {suggestions.map((product, index) => (
                    <li
                      className={styles.optiondesplegableClient}
                      key={index}
                      onClick={() => handleClick(product)}
                      tabIndex="0"
                    >
                      {product.descripcion_prod}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.wrapperSearchMain} ref={wrapperRef}>
            <div
              className={
                suggestions.length > 0
                  ? styles.searchWrapperSuggestions
                  : styles.searchWrapperClient
              }
            >
              <button
                className={styles.searchIconWrapperClient}
                onClick={() => buscarProductos(null)}
              >
                <SvgBuscador />
              </button>
              <input
                type="text"
                className={styles.inputSearchClient}
                ref={inputRef}
                placeholder="Buscar"
                value={query}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
              />
            </div>
            {suggestions.length > 0 && (
              <ul
                className={
                  userType === "CON"
                    ? styles.desplegableCliente
                    : styles.styles.suggestionsList
                }
              >
                {suggestions.map((product, index) => (
                  <li
                    className={styles.optiondesplegableClient}
                    key={index}
                    onClick={() => handleClick(product)}
                    tabIndex="0"
                  >
                    {product.descripcion_prod}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {/* Carrito  */}
        <div className={styles.navIconsWrapper}>
          <div className={styles.searchIcon}>
            {showSearch ? (
              <SvgClosedButtonSearch
                onClick={() => setShowSearch(!showSearch)}
              />
            ) : (
              <SvgBuscador onClick={() => setShowSearch(!showSearch)} />
            )}
          </div>

          <button
            disabled={loadingBorrador}
            onClick={handleCartClick}
            className={styles.cartButton}
          >
            {loading ? (
              <Spinner
                animation="border"
                role="status"
                style={{ color: "#89182f" }}
              >
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              <>
                <SvgCarrito />
                <p className={styles.cartText}>Mi Carrito</p>
              </>
            )}
          </button>

          {/* Iniciar Seccion  */}
          <div className={styles.sectionUser}>
            <DropdownButton
              className={styles.dropdownButton}
              title={
                <span className={styles.userDates}>
                  <LuUserCircle2 color="white" size={25} />
                  <span className={styles.nameUser}>
                    ¡Hola, {nombreFormateado}!
                  </span>
                </span>
              }
              variant="white"
            >
              <Dropdown.Item
                onClick={() => navigate("/home-cliente/datos-generales")}
              >
                Datos generales
              </Dropdown.Item>

              <Dropdown.Item
                onClick={() => {
                  handleScrollToTop();
                  handleCartClick();
                }}
              >
                Mi Carrito
              </Dropdown.Item>
              <Dropdown.Item>
                <button
                  style={{
                    border: "none",
                    background: "none",
                    padding: 0,
                    margin: 0,
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  onClick={handleLogout}
                >
                  Cerrar sesión
                </button>
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </nav>
    </header>
  );
};
