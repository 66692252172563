import React from 'react';
import styles from './ButtonClosedForm.module.css';

export const ButtonClosedForm = ({ setOpenPopup, setCambioNombre, setCambioContraseña }) => {
  return (
    <button
      onClick={() => {
        setOpenPopup(false);
        setCambioContraseña(false)
        setCambioNombre(false); // Cambia `cambioNombre` a `false` al cerrar el popup
      }}
      className={styles.buttonClosed}
    >
      X
    </button>
  );
};