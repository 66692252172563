import React, { useState } from "react";
import styles from "./PopupChangePassword.module.css";
import { ButtonClosedForm } from "../ButtonClosedForm";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const PopupChangePassword = ({ setChangePassword }) => {
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const handleSendNumber = async (value) => {
    setLoading(true);
    try {
      const dataPhone = {
        valor: value.phone,
        tipo: location.pathname = "/login-distribuidora" ? "DIS" : "CON",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/send-reset-password`,
        dataPhone,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Código enviado exitosamente");
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error("No se encontró un usuario que coincida");
      } else {
        toast.error("Ocurrió un problema. Intentelo más tarde");
      }
    } finally {
      setLoading(false)
    }
  };

  return (
    <div className={styles.popupChangedPasswordWrapper}>
      <div className={styles.containerChangedPasswordWrapper}>
        <ButtonClosedForm setOpenPopup={setChangePassword} />

        <form
          action=""
          className={styles.formChange}
          onSubmit={handleSubmit(handleSendNumber)}
        >
          <label htmlFor="" className={styles.questionForm}>
            ¿Olvidaste tu contraseña?
          </label>
          <div className={styles.inputPhoneNumber}>
            <input
              type="text"  // Cambiado a "text" para permitir validaciones con patrones
              placeholder="Codigo Distribuidor:"
              className={styles.inputNumberPhone}
              maxLength="10"
              {...register("phone", {
                required: "Este campo es requerido",
                pattern: {
                  value: /^[0-9]{1,10}$/, // Patrón para solo números de hasta 10 dígitos
                  message: "Solo se permiten números y hasta 10 caracteres"
                }
              })
              }
            />
            {
              errors?.phone && <p className={styles.error}>{errors?.phone?.message}</p>
            }

          </div>
          <button className={styles.buttonSend} type="submit">
            {loading ? (
              <Spinner animation="border" role="status" size="sm" >
                <span className="visually-hidden">Cargando...</span>
              </Spinner>
            ) : (
              "Enviar código"
            )}
          </button>
        </form>

      </div>
    </div>
  );
};
